import React, { Component } from "react";
import "swiper/dist/css/swiper.css";
import navArrowRight from "../../assets/images/svg/nav-arrow-right-blue.svg";
import navCloseSvg from "../../assets/images/svg/nav-close.svg";
import cameraSvg from "../../assets/images/svg/camera.svg";
import { fileUpload } from "../../services/fileUpload.service";
import Select2ComponentId from "../Select2/Select2ComponentId";
import AppInputComponent from "../../layouts/components/sidebars/AppInputComponent";
import "../../styles/shared.scss";

class AvailableSpaceUpdateBrandComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { adsPlacements: false, isPublish: false };
    this.uploadLogo = this.uploadLogo.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.getColClass = this.getColClass.bind(this);
    this.onSubmitForm = this.onSubmitForm.bind(this);
  }

  removeImage(imgType) {
    this.props.updateBrandAdsFormUpdateField(imgType, "");
  }
  componentDidMount() {
    const {
      invalidateBrandAdsDetails,
      fetchBrandAdsDetailsIfNeeded,
      invalidatebrandbyPlatformList,
      fetchbrandbyPlatformListIfNeeded
    } = this.props;
    invalidateBrandAdsDetails();
    fetchBrandAdsDetailsIfNeeded(this.props.match.params.id).then(response => {
      invalidatebrandbyPlatformList();
      fetchbrandbyPlatformListIfNeeded(
        this.props.updateBrandAdsForm.fields.envId.value
      );
    });
  }

  onSubmitForm() {
    let postParam = {
      ownerBrandId: this.props.updateBrandAdsForm.fields.ownerBrandId.value,
      imageUrl: this.props.updateBrandAdsForm.fields.imageUrl.value,
      isPublished: this.props.updateBrandAdsForm.fields.isPublished.value
    };
    if (!this.props.updateBrandAdsForm.fields.ownerBrandId.value) {
      this.props.updateBrandAdsFormSetError("ownerBrandId", "NOT_EMPTY");
    }
    if (!this.props.updateBrandAdsForm.fields.imageUrl.value) {
      this.props.updateBrandAdsFormSetError("imageUrl", "NOT_EMPTY");
    }
    if (
      !this.props.updateBrandAdsForm.fields.ownerBrandId.value ||
      !this.props.updateBrandAdsForm.fields.imageUrl.value
    ) {
      return false;
    }
    this.props.updateBrandAdsFormSubmit(postParam, this.props.match.params.id);
  }
  uploadLogo(event, imgType) {
    if (event.target.files.length > 0) {
      const fileSize = event.target.files[0].size / 1000000;
      if (fileSize < 2) {
        if (event.target.files[0].type !== "") {
          const fileType = event.target.files[0].type
            ? event.target.files[0].type.split("/")[1].toLowerCase()
            : "";
          if (fileType !== "jpeg" && fileType !== "jpg" && fileType !== "png") {
            this.props.updateBrandAdsFormSetError("imageUrl", "FILE_TYPE");
            return false;
          }
        } else {
          this.props.updateBrandAdsFormSetError("imageUrl", "FILE_TYPE");
          return false;
        }
      } else {
        this.props.updateBrandAdsFormSetError("imageUrl", "FILE_SIZE");
        return false;
      }
      this.props.updateBrandAdsFormResetError(imgType);
      fileUpload(event.target.files)
        .then(url => {
          this.props.updateBrandAdsFormUpdateField(imgType, url);
        })
        .catch(() => {
          console.log(this);
        });
    } else {
      this.props.updateBrandAdsFormUpdateField(imgType, "");
    }
  }
  getColClass() {
    const sizes = {
      "950": "col-sm-12",
      "970": "col-sm-12",
      "728": "col-sm-12",
      "300": "col-sm-6"
    };
    return sizes[this.props.updateBrandAdsForm.fields.brandAdsWidth.value]
      ? sizes[this.props.updateBrandAdsForm.fields.brandAdsWidth.value]
      : "col-sm-12";
  }
  render() {
    const { platformByBrandList } = this.props;
    const category = {};
    let platform = "";
    const hostDropDown = platformByBrandList.items.map((data, index) => {
      platform = data.envName;
      return {
        ...category,
        id: data.id,
        value: data.name
      };
    });
    return (
      <section className="premium" id="rootwizard">
        <section className="container-fluid tab-content-wrapper">
          <div className="row">
            <div className="col-sm-12">
              <div className="container mt-6">
                <form>
                  <div className="padder-main ">
                    <div className="row">
                      <div className="col-sm-12  text-right d-flex justify-content-start mb-4 back-arrow">
                        <a
                          href="/available-space"
                          className="view-all d-flex align-items-center"
                        >
                          <span>
                            <img src={navArrowRight} alt="" />
                          </span>
                          <h5 className="bold text-secondary"> Back </h5>
                        </a>
                      </div>
                      <div className="col-sm-12 mb-4">
                        <h3 className="bold">Edit Host Advertisement</h3>
                      </div>
                      <div className="col-sm-6  zIndex-3 mb-4">
                        <h5 className="bold">Host</h5>
                        <div className=" form-group with-lable mt-4">
                          <div className="input-group-wrapper disable-div">
                            <AppInputComponent
                              value={
                                this.props.updateBrandAdsForm.fields.brandName
                                  .value
                              }
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6  zIndex-2 mb-4">
                        <h5 className="bold">Owner</h5>
                        <div className=" form-group with-lable mt-4">
                          <div className="input-group-wrapper">
                            <Select2ComponentId
                              selectClass={
                                "select-2-input " +
                                (this.props.updateBrandAdsForm.fields
                                  .ownerBrandId.error
                                  ? "form-control effect-17 "
                                  : "form-control effect-16")
                              }
                              onChange={event => {
                                this.props.updateBrandAdsFormResetError(
                                  "ownerBrandId"
                                );
                                this.props.updateBrandAdsFormUpdateField(
                                  "ownerBrandName",
                                  event.name
                                );
                                this.props.updateBrandAdsFormUpdateField(
                                  "ownerBrandId",
                                  event.id
                                );
                              }}
                              selectOptions={hostDropDown}
                              value={
                                this.props.updateBrandAdsForm.fields
                                  .ownerBrandName.value
                              }
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6  zIndex-2 mb-4">
                        <h5 className="bold">Type</h5>
                        <div className=" form-group with-lable mt-4">
                          <div className="input-group-wrapper disable-div">
                            <AppInputComponent
                              value={
                                this.props.updateBrandAdsForm.fields
                                  .brandAdvertisementType.value
                              }
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6 mb-4 ">
                        <h5 className="bold">Platform</h5>
                        <div className="form-group with-lable mt-4 disable-div">
                          <div className="input-group-wrapper">
                            <input
                              type="text"
                              placeholder="Platform"
                              className="form-control effect-16 "
                              tabIndex="1"
                              value={platform}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div
                        className={this.getColClass() + " zIndex-1 mb-5 mt-4"}
                      >
                        <div className="upload-image d-flex align-items-center ">
                          {this.props.updateBrandAdsForm.fields.imageUrl
                            .value ? (
                            <div
                              style={{
                                minHeight:
                                  this.props.updateBrandAdsForm.fields
                                    .brandAdsHeight.value + "px"
                              }}
                              className=" image-container col h-100  d-flex flex-column justify-content-center align-items-center"
                            >
                              <img
                                src={
                                  this.props.updateBrandAdsForm.fields.imageUrl
                                    .value
                                }
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                          ) : (
                            <>
                              <input
                                type="file"
                                name="img[]"
                                className="file"
                                onChange={e => this.uploadLogo(e, "imageUrl")}
                                ref={input => (this.inputImg = input)}
                              />
                              <div
                                onClick={() => this.inputImg.click()}
                                style={{
                                  minHeight:
                                    this.props.updateBrandAdsForm.fields
                                      .brandAdsHeight.value + "px"
                                }}
                                className={
                                  "image-container col h-100  d-flex flex-column justify-content-center align-items-center" +
                                  (this.props.updateBrandAdsForm.fields.imageUrl
                                    .error ||
                                  this.props.updateBrandAdsForm.fields.imageUrl
                                    .errorMsg == "FILE_SIZE" ||
                                  this.props.updateBrandAdsForm.fields.imageUrl
                                    .errorMsg == "FILE_TYPE"
                                    ? " add-new-rewards-danger"
                                    : "")
                                }
                              >
                                <img
                                  src={cameraSvg}
                                  className="img-fluid"
                                  alt=""
                                />
                                <p className="choose-photo mb-0 bold">
                                  Upload{" "}
                                  {
                                    this.props.updateBrandAdsForm.fields
                                      .brandAdvertisementPlace.value
                                  }
                                </p>
                                <p className="mt-1 small text-center">
                                  {`Size ${
                                    this.props.updateBrandAdsForm.fields
                                      .brandAdsWidth.value
                                  }px width ${
                                    this.props.updateBrandAdsForm.fields
                                      .brandAdsHeight.value
                                  }px height`}{" "}
                                  <br />{" "}
                                  <span
                                    className={
                                      this.props.updateBrandAdsForm.fields
                                        .imageUrl.errorMsg == "FILE_SIZE"
                                        ? " file-size-danger"
                                        : ""
                                    }
                                  >
                                    * Maximum size 2 MB
                                  </span>
                                  <br />
                                  <span
                                    className={
                                      this.props.updateBrandAdsForm.fields
                                        .imageUrl.errorMsg == "FILE_TYPE"
                                        ? "file-size-danger"
                                        : ""
                                    }
                                  >
                                    * Supported file types : jpg, jpeg and png
                                  </span>
                                </p>
                              </div>
                            </>
                          )}

                          <div className="col-auto ">
                            {this.props.updateBrandAdsForm.fields.imageUrl
                              .value ? (
                              <div
                                className="popup-dismiss"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => this.removeImage("imageUrl")}
                              >
                                {" "}
                                <img src={navCloseSvg} alt="" />{" "}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-2 ml-2">
                        <button
                          type="button"
                          className="btn btn-pink py-3 btn-preview dark mw-150 mt-5"
                          onClick={() => {
                            this.props.updateBrandAdsFormUpdateField(
                              "isPublished",
                              false
                            );

                            setTimeout(() => {
                              this.onSubmitForm();
                            }, 1000);
                          }}
                        >
                          Save
                        </button>
                      </div>
                      <div className="col-sm-6">
                        <button
                          type="button"
                          className="btn btn-pink py-3 btn-preview dark mw-150 mt-5"
                          onClick={() => {
                            this.props.updateBrandAdsFormUpdateField(
                              "isPublished",
                              true
                            );
                            setTimeout(() => {
                              this.onSubmitForm();
                            }, 1000);
                          }}
                        >
                          Save & Publish
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </section>
      </section>
    );
  }
}

export default AvailableSpaceUpdateBrandComponent;
