export default {
  loginProfile: "/admin/login",
  createProfile: "/profiles/create",
  getProfile: "/profiles/getProfileV2",
  updateProfile: "/profiles/update",
  activateProfile: "/profiles/activate",
  usernameExists: "/profiles/usernameExists",
  allUsers: "/admin/users",
  upload: "/images/upload",
  uploadVideo: "/videos/upload",
  changePasswordRequest: "/profiles/renewPassword",
  resetPasswordRequest: "/profiles/resetPasswordRequest",
  resetPassword: "/profiles/resetPassword",
  publishChallenge: "/admin/challenges/create",
  updateChallenge: "/admin/challenges/update/",
  getStaticData: "/tournaments/getStaticData",
  createRaffles: "/admin/raffles/create",
  allRaffles: "/admin/raffles",
  raffleDetail: "/raffles/##id##",
  raffleRepeatEnable: "/admin/raffles/##id##/enable",
  raffleRepeatDisable: "/admin/raffles/##id##/disable",
  raffleDelete: "/admin/raffles/##id##/delete",
  newsEnable: "/admin/news/##id##/enable",
  newsDisable: "/admin/news/##id##/disable",
  getHostList: "/admin/brands",
  createHost: "/admin/brands/create",
  updateHost: "/admin/brands/update/",
  getHostDetails: "/brands/",
  getHost: "/brands/",
  getChallengeFormDetail: "/admin/challenge/",
  getTournaments: "/admin/challenges",
  searchUser: "/profile/search",
  getBrandData: "/brands/list",
  getAnalyze: "/admin/analyze",
  getNews: "/admin/news",
  getNewsCategoriesList: "/admin/news/categories",
  createNews: "/admin/news/create",
  getNewsDetails: "/news/",
  updateNews: "/admin/news/update/",
  brandAdvertisementsAvailable: "/admin/brand/advertisements/available",
  challengeAdvertisementsAvailable:
    "/admin/challenge/advertisements/available",
  brandAdvertisementsUpdate: "/admin/brand/advertisements/update/",
  brandAdvertisementsDetails: "/admin/brand/advertisements/detail/",
  challengeAdvertisementsUpdate:
    "/admin/challenge/advertisements/update/",
  challengeAdvertisementsDetails:
    "/admin/challenge/advertisements/detail/",
  getBanUser: "/admin/bans/user",
  getAnalyze: "/admin/analyze",
  getDashboard: "/admin/dashboard/advertisements",
  createDashboardAdvertisement: "/admin/dashboard/advertisements/add",
  editDashboardAdvertisement:
    "/admin/dashboard/advertisements/update/##id##",
  dashboardAdvertisementDetail:
    "/admin/dashboard/advertisements/detail/##id##",
  publishBrandAds: "/admin/brand/advertisements/##id##/publish",
  publishChallengeAds: "/admin/challenge/advertisements/##id##/publish",
  revokeBrandAds: "/admin/brand/advertisements/##id##/revoke",
  revokeChallengeAds: "/admin/challenge/advertisements/##id##/revoke",
  addpoints: "/admin/points/add",
  banLifted: "/admin/bans/##id##",
  banCreate: "/admin/bans/create",
  banReasons: "/admin/bans/reasons",
  dashboardDisabledAdvertisements:
    "/admin/dashboard/disabled-advertisements-by-place/##id##",
  dashboardAdPublish: "/admin/dashboard/advertisements/##id##/publish",
  dashboardAdRevoke: "/admin/dashboard/advertisements/##id##/revoke",
  usersChallenge: "/admin/user/##id##/challenges",
  platformByBrandList: "/brands/listByEnv/##id##",
  deleteTournament: "/admin/challenge/delete/##id##",
  deleteHost: "/admin/brand/delete/##id##",
  getLanguagesList: "/admin/languages",
  getRafflesWinnerList: "/admin/raffleWinners",
  getChallengeWinnerList: "/admin/challengeWinners",
  resetBrandLeaderboard: "/admin/brand/##id##/leaderboards/reset",
  userHistory: "/billing/user-history"
};
