import { ToggleActionCreator } from "./creators";
import {
  PasswordEyeLogin,
  PasswordEyeResetPass,
  PasswordEyeChangeOldPass,
  PasswordEyeChangePass
} from "../redux-config/toggleActionMap";

export const togglePasswordEyeLogin = ToggleActionCreator(PasswordEyeLogin);
export const togglePasswordEyeReset = ToggleActionCreator(PasswordEyeResetPass);
export const togglePasswordEyeChangeOldPass = ToggleActionCreator(
  PasswordEyeChangeOldPass
);
export const togglePasswordEyeChangePass = ToggleActionCreator(
  PasswordEyeChangePass
);
