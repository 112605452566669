import { axios } from "../utils/axios";
import config from "./config";
import { API_SERVER_URL } from "./config/apiEndpoints";

export const onLogInUser = postParams => {
  return axios.request({
    url: API_SERVER_URL + config.loginProfile,
    method: "post",
    headers: {
      "content-type": "application/json"
    },
    data: postParams
  });
};

export const onForgotPasswordRequest = postParams => {
  return axios.request({
    url: API_SERVER_URL + config.resetPasswordRequest,
    method: "post",
    headers: {
      "content-type": "application/json"
    },
    data: postParams
  });
};

export const onChangePasswordRequest = postParams => {
  return axios.request({
    url: API_SERVER_URL + config.changePasswordRequest,
    method: "post",
    headers: {
      "content-type": "application/json"
    },
    data: postParams
  });
};

export const onResetPassword = postParams => {
  return axios.request({
    url: API_SERVER_URL + config.resetPassword,
    method: "post",
    headers: {
      "content-type": "application/json"
    },
    data: postParams
  });
};

export const onCreateUserName = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.createUsername,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const onUsernameExists = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.usernameExists,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getUsersList = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.allUsers,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const addPoints = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.addpoints,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const banLifted = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.banLifted.replace("##id##", postParams ? postParams : 0),
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const banUser = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.banCreate,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getBanReasons = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.banReasons,
      method: "GET",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const userChallenge = (postParams, userId) => {
  let languageId = postParams.languageId;
  delete (postParams.languageId);
  return axios
    .request({
      url: API_SERVER_URL + config.usersChallenge.replace("##id##", userId ? userId : 0),
      method: "post",
      headers: {
        "content-type": "application/json",
        "language": languageId
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getUserSubscriptionHistoryList = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.userHistory,
      method: "post",
      headers: {
        "content-type": "application/json",
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
}