import { ListActionCreator, DetailActionCreator, PaginationListActionCreator } from "./creators";
import {
  getAllRaffleService,
  getRaffleDetail
} from "../services/raffles.service";
import { RafflesList } from "../redux-config/listActionMap";
import { RaffleDetails } from "../redux-config/detailActionMap";
import { RafflesListViewAll } from "../redux-config/plistActionMap";

export const rafflesList = ListActionCreator({
  ...RafflesList,
  service: getAllRaffleService,
  dataMapper: json => json.data.data.items
});

export const rafflesListViewAll = PaginationListActionCreator({
  ...RafflesListViewAll,
  service: getAllRaffleService,
  dataMapper: json => json.data.data.items,
  lastPageMapper: json => json.data.data.isLastPage
});

export const rafflesDetails = DetailActionCreator({
  ...RaffleDetails,
  service: getRaffleDetail,
  dataMapper: json => json.data.data
});
