import React, { Component } from "react";
import { connect } from "react-redux";
import camerasvg from "../../../assets/images/svg/camera.svg";
import navCloseSvg from "../../../assets/images/svg/nav-close.svg";
import {
  updateChallengeForm
} from "../../../actions/forms";
import AppInputComponent from "../../../layouts/components/sidebars/AppInputComponent";
import '../ChallengeBannerComponent.scss';

class FeaturedBannerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { direction: '' };
    this.changeContentDirection = this.changeContentDirection.bind(this);
    this.removeUploadFile = this.removeUploadFile.bind(this);
  }
  componentDidMount(){
    this.setState((props, state) => {
      return{
        direction: this.props.updateChallengeForm.fields.promoTextDirection.value,
        game: this.props.gameLists.filter(data => data.id == this.props.updateChallengeForm.fields.gamesId.value)
      }
    });
  }
  changeContentDirection() {
    let promoTextDirection = this.props.updateChallengeForm.fields.promoTextDirection.value == 'left' ?  'right' : 'left';
    this.setState((props, state) => {
      return{
        direction: promoTextDirection
      }
    });
    this.props.updateChallengeFormUpdateField("promoTextDirection",  promoTextDirection);
    this.props.translatedValues(this.props.currentLang, 'promoTextDirection',  promoTextDirection);
  }
  changeHasButtonField(hasButton) {
    this.props.updateChallengeFormUpdateField("promoHasButton", !hasButton);
    this.props.translatedValues(this.props.currentLang, 'promoHasButton', !hasButton);
  }
  
  backToDefault(field){
    switch(field){
      case "promoLogo":
		    this.props.updateChallengeFormUpdateField("promoLogo", this.state.game[0]['promoLogo'] );
        this.props.updateChallengeFormUpdateField("isCustomPromoLogo", false);
        break;
      case "promoBackgroundOverlayImage":
        this.props.updateChallengeFormUpdateField("promoBackgroundOverlayImage", this.state.game[0]['promoBackgroundOverlayImage'] );
        this.props.updateChallengeFormUpdateField("isCustomPromoBackgroundOverlayImage", false);
        break;
      case "promoBackgroundImage":
        this.props.updateChallengeFormUpdateField("promoBackgroundImage", this.state.game[0]['promoBackgroundImage'] );
        this.props.updateChallengeFormUpdateField("isCustomPromoBackgroundImage", false);
        break;
    }
  }
  removeUploadFile(field) {
    switch(field){
      case "promoLogo":
        this.props.updateChallengeFormUpdateField("promoLogo", "");
        this.props.updateChallengeFormUpdateField("isCustomPromoLogo", true);
        break;
      case "promoBackgroundOverlayImage":
        this.props.updateChallengeFormUpdateField("promoBackgroundOverlayImage", "");
        this.props.updateChallengeFormUpdateField("isCustomPromoBackgroundOverlayImage", true);
        break;
      case "promoBackgroundImage":
        this.props.updateChallengeFormUpdateField("promoBackgroundImage", "");
        this.props.updateChallengeFormUpdateField("isCustomPromoBackgroundImage", true);
        break;
    }
  }
  render() {
    let { uploadFile, translatedValues } = this.props;
    let challengeTranslationData = this.props.updateChallengeForm.fields.challengeTranslation.value ? this.props.updateChallengeForm.fields.challengeTranslation.value : [];
    let nameExistOrNot = challengeTranslationData.filter(e => e['languageId'] == this.props.currentLang)
    return (
      <>
        <div className="col-sm-12">
          <div className="col-sm-6">
            <div className="form-group with-lable w-100">
              <AppInputComponent
                inputClass={
                  this.props.currentLang == this.props.defaultLang ? !this.props.updateChallengeForm.fields.promoTitle.error
                    ? "form-control effect-16"
                    : "form-control effect-17" : "form-control effect-16"
                }
                onChange={event => {
                    if (this.props.currentLang == this.props.defaultLang) {
                      this.props.updateChallengeFormResetError(
                        "promoTitle"
                      );
                      this.props.updateChallengeFormUpdateField(
                        "promoTitle",
                        event.target.value
                      );
                    }
                    translatedValues(this.props.currentLang, 'promoTitle', event.target.value);
                  }
                }
                divClass="input-group-wrapper mt-2 mb-5 create-tournament-wrapper"
                // readOnly={true}
                labelText="Enter Banner Title"
                withLabel="true"
                maxlength="100"
                value={ nameExistOrNot[0]['promoTitle']}
              />
            </div>
          </div>
          <div className="col-sm-6"></div>
        </div>

        <div className="col-sm-12 ">
          <div className="col-sm-6">
            <div className="form-group with-lable w-100">
              <AppInputComponent
                onChange={event => {
                    if (this.props.currentLang == this.props.defaultLang) {
                      this.props.updateChallengeFormResetError(
                        "promoSubTitle"
                      );
                      this.props.updateChallengeFormUpdateField(
                        "promoSubTitle",
                        event.target.value
                      );
                    }
                    translatedValues(this.props.currentLang, 'promoSubTitle', event.target.value);
                  }
                }
                inputClass={
                  this.props.currentLang == this.props.defaultLang ? !this.props.updateChallengeForm.fields.promoSubTitle.error
                    ? "form-control effect-16"
                    : "form-control effect-17" : "form-control effect-16"
                }
                divClass="input-group-wrapper mt-2 mb-5 create-tournament-wrapper"
                // readOnly={true}
                labelText="Enter Banner Sub Title"
                withLabel="true"
                maxlength="100"
                value={nameExistOrNot[0]['promoSubTitle']}
              />
            </div>
          </div>
          <div className="col-sm-6"></div>
        </div>

        <div className="col-sm-12">
          <div className="col-sm-6">
            <div className="form-group with-lable w-100">
              <AppInputComponent
                onChange={event => {
                    if (this.props.currentLang == this.props.defaultLang) {
                      this.props.updateChallengeFormResetError(
                        "promoBackgroundColor"
                      );
                      this.props.updateChallengeFormUpdateField(
                        "promoBackgroundColor",
                        event.target.value
                      );
                    }
                  }
                }
                inputClass={
                  this.props.currentLang == this.props.defaultLang ? !this.props.updateChallengeForm.fields.promoBackgroundColor.error
                    ? "form-control effect-16"
                    : "form-control effect-17" : "form-control effect-16"
                }
                type='color'
                style={{ backgroundColor: this.props.updateChallengeForm.fields.promoBackgroundColor.value}}
                divClass="input-group-wrapper mt-2 mb-5 create-tournament-wrapper"
                labelText="Enter Banner Background Gradient Color"
                withLabel="true"
                maxlength="100"
                // readOnly={true}
                value={this.props.updateChallengeForm.fields.promoBackgroundColor.value}
              />
            </div>
          </div>
          <div className="col-sm-6"></div>
        </div>

        <div className="col-sm-12">
          <div className="col-sm-6">
            <div className="form-group with-lable w-100">
              <AppInputComponent
                onChange={event => {
                    if (this.props.currentLang == this.props.defaultLang) {
                      this.props.updateChallengeFormResetError(
                        "promoBackgroundColor2"
                      );
                      this.props.updateChallengeFormUpdateField(
                        "promoBackgroundColor2",
                        event.target.value
                      );
                    }
                    translatedValues(this.props.currentLang, 'promoBackgroundColor2', event.target.value);
                  }
                }
                inputClass={
                  this.props.currentLang == this.props.defaultLang ? !this.props.updateChallengeForm.fields.promoBackgroundColor2.error
                    ? "form-control effect-16 has-content"
                    : "form-control effect-17 has-content" : "form-control effect-16 has-content"
                }
                type='color'
                divClass="input-group-wrapper mt-2 mb-5 create-tournament-wrapper"
                labelText="Enter Banner Background Gradient Color 2"
                withLabel={true}
                maxlength="100"
                // readOnly={true}
                style={{ backgroundColor: this.props.updateChallengeForm.fields.promoBackgroundColor2.value}}
                value={this.props.updateChallengeForm.fields.promoBackgroundColor2.value}
              />
            </div>
          </div>
          <div className="col-sm-6"></div>
        </div>

        <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-6">
              {/* <span>promoLogo</span> */}
              <div className="upload-image d-flex align-items-center mb-3">
                { this.props.updateChallengeForm.fields.promoLogo.value && 
                <div className="upload-button image-area-1 col h-100 d-flex flex-column justify-content-center align-items-center">
                    <img
                    src={ this.props.updateChallengeForm.fields.promoLogo.value }
                    className="img-fluid"
                    alt=""
                    />
                </div>}
                { this.props.updateChallengeForm.fields.isCustomPromoLogo.value && !this.props.updateChallengeForm.fields.promoLogo.value && 
                <>
                    <input
                        type="file"
                        name="img[]"
                        className="file"
                        onChange={e =>
                          uploadFile(e, "promoLogo", "promoLogoType")
                        }
                        ref={input => (this.inputPromoLogoImg = input)}
                      />
                      <div
                        className={
                          "upload-button image-area-1 col h-100  d-flex flex-column justify-content-center align-items-center " +
                          (this.props.defaultLang == this.props.currentLang ? this.props.updateChallengeForm.fields.promoLogo
                            .error ||
                            this.props.updateChallengeForm.fields.promoLogoType
                              .error 
                              // || !nameExistOrNot[0]['promoLogo']
                            ? " add-new-rewards-danger"
                            : "" : "")
                        }
                        onClick={() => this.inputPromoLogoImg.click()}
                      >
                        <img src={camerasvg} className="img-fluid" alt="" />
                        <p className="choose-photo mb-0 bold">
                          Upload Featured Banner Logo
                              </p>
                        <p className="mt-4 small">
                            Minimum size 340px width 340px height
                                <br /> Transparent PNG works best for Logo
                              </p>
                        <p className="small">
                          <span
                            className={
                              this.props.updateChallengeForm.fields.promoLogo
                                .error
                                ? " danger"
                                : ""
                            }
                          >
                            * Maximum size 2 MB
                                </span>
                          <br />{" "}
                          <span
                            className={
                              this.props.updateChallengeForm.fields
                                .promoLogoType.error
                                ? " danger"
                                : ""
                            }
                          >
                            * Supported file types : jpg, jpeg and png
                                </span>
                        </p>
                      </div>
                    </>
                }
              </div>
            </div>
            <div className="col-sm-2">
              <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                { this.props.updateChallengeForm.fields.isCustomPromoLogo.value ? 
                    <button className="btn btn-gray py-3 unlock dark  mw-100 mx-1" 
                      onClick={() => this.backToDefault("promoLogo") } > Back to default</button>
                    : 
                    <div className="col-auto ">
                      {nameExistOrNot[0]['promoLogo'] ? (
                        <div
                        className="popup-dismiss"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => this.removeUploadFile("promoLogo")}
                        >
                          {" "}
                          <img src={navCloseSvg} alt="" />{" "}
                        </div>
                      ) : (
                        ""
                      )}
                  </div>
                }  
              </div> 
            </div>
            <div className="col-sm-4"></div>
          </div>
        </div>

				<div className="col-sm-12">
          <div className="row">
            <div className="col-sm-8">
              {/* <span>promoBackgroundImage</span> */}
              <div className="upload-image d-flex align-items-center mb-3">
                { this.props.updateChallengeForm.fields.promoBackgroundImage.value && 
                <div className="upload-button image-area-1 col h-100 d-flex flex-column justify-content-center align-items-center">
                    <img
                    src={ this.props.updateChallengeForm.fields.promoBackgroundImage.value }
                    className="img-fluid"
                    alt=""
                    />
                </div>}
                { this.props.updateChallengeForm.fields.isCustomPromoBackgroundImage.value && !this.props.updateChallengeForm.fields.promoBackgroundImage.value && 
                <>
                    <input
                        type="file"
                        name="img[]"
                        className="file"
                        onChange={e =>
                          uploadFile(e, "promoBackgroundImage", "promoBackgroundImageType")
                        }
                        ref={input => (this.inputPromoBackgroundImg = input)}
                      />
                      <div
                        className={
                          "upload-button image-area-1 col h-100  d-flex flex-column justify-content-center align-items-center " +
                          (this.props.defaultLang == this.props.currentLang ? this.props.updateChallengeForm.fields.promoBackgroundImage
                            .error ||
                            this.props.updateChallengeForm.fields.promoBackgroundImageType
                              .error 
                              // || !nameExistOrNot[0]['promoBackgroundImage']
                            ? " add-new-rewards-danger"
                            : "" : "")
                        }
                        onClick={() => this.inputPromoBackgroundImg.click()}
                      >
                        <img src={camerasvg} className="img-fluid" alt="" />
                        <p className="choose-photo mb-0 bold">
                          Upload Featured Banner promoBackgroundImage
                              </p>
                        <p className="mt-4 small">
                            Minimum size 340px width 340px height
                                <br /> Transparent PNG works best for promoBackgroundImage
                              </p>
                        <p className="small">
                          <span
                            className={
                              this.props.updateChallengeForm.fields.promoBackgroundImage
                                .error
                                ? " danger"
                                : ""
                            }
                          >
                            * Maximum size 2 MB
                                </span>
                          <br />{" "}
                          <span
                            className={
                              this.props.updateChallengeForm.fields
                                .promoLogoType.error
                                ? " danger"
                                : ""
                            }
                          >
                            * Supported file types : jpg, jpeg and png
                                </span>
                        </p>
                      </div>
                    </>
                }
              </div>
            </div>
            <div className="col-sm-2">
              <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                { this.props.updateChallengeForm.fields.isCustomPromoBackgroundImage.value ? 
                    <button className="btn btn-gray py-3 unlock dark  mw-100 mx-1" 
                      onClick={() => this.backToDefault("promoBackgroundImage") } > Back to default</button>
                    : 
                    <div className="col-auto ">
                      {nameExistOrNot[0]['promoBackgroundImage'] ? (
                        <div
                        className="popup-dismiss"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => this.removeUploadFile("promoBackgroundImage")}
                        >
                          {" "}
                          <img src={navCloseSvg} alt="" />{" "}
                        </div>
                      ) : (
                        ""
                      )}
                  </div>
                }  
              </div> 
            </div>
            <div className="col-sm-2"></div>
          </div>
          </div>

        <div className="col-sm-12">
        <div className="row">
          <div className="col-sm-6">
            {/* <span>promoBackgroundOverlayImage</span> */}
            <div className="upload-image d-flex align-items-center mb-3">
              { this.props.updateChallengeForm.fields.promoBackgroundOverlayImage.value && 
              <div className="upload-button image-area-1 col h-100 d-flex flex-column justify-content-center align-items-center">
                  <img
                  src={ this.props.updateChallengeForm.fields.promoBackgroundOverlayImage.value }
                  className="img-fluid"
                  alt=""
                  />
              </div>}
              { this.props.updateChallengeForm.fields.isCustomPromoBackgroundOverlayImage.value && !this.props.updateChallengeForm.fields.promoBackgroundOverlayImage.value && 
              <>
                  <input
                      type="file"
                      name="img[]"
                      className="file"
                      onChange={e =>
                        uploadFile(e, "promoBackgroundOverlayImage", "promoBackgroundOverlayImageType")
                      }
                      ref={input => (this.promoBackgroundOverlayImg = input)}
                    />
                    <div
                      className={
                        "upload-button image-area-1 col h-100  d-flex flex-column justify-content-center align-items-center " +
                        (this.props.defaultLang == this.props.currentLang ? this.props.updateChallengeForm.fields.promoBackgroundOverlayImage
                          .error ||
                          this.props.updateChallengeForm.fields.promoBackgroundOverlayImageType
                            .error 
                            // || !nameExistOrNot[0]['promoBackgroundOverlayImage']
                          ? " add-new-rewards-danger"
                          : "" : "")
                      }
                      onClick={() => this.promoBackgroundOverlayImg.click()}
                    >
                      <img src={camerasvg} className="img-fluid" alt="" />
                      <p className="choose-photo mb-0 bold">
                        Upload Featured Banner promoBackgroundOverlayImg
                            </p>
                      <p className="mt-4 small">
                          Minimum size 340px width 340px height
                              <br /> Transparent PNG works best for promoBackgroundOverlayImg
                            </p>
                      <p className="small">
                        <span
                          className={
                            this.props.updateChallengeForm.fields.promoBackgroundOverlayImage
                              .error
                              ? " danger"
                              : ""
                          }
                        >
                          * Maximum size 2 MB
                              </span>
                        <br />{" "}
                        <span
                          className={
                            this.props.updateChallengeForm.fields
                              .promoBackgroundOverlayImageType.error
                              ? " danger"
                              : ""
                          }
                        >
                          * Supported file types : jpg, jpeg and png
                              </span>
                      </p>
                    </div>
                  </>
              }
            </div>
          </div>
          <div className="col-sm-2">
            <div className="h-100 d-flex flex-column justify-content-center align-items-center">
              { this.props.updateChallengeForm.fields.isCustomPromoBackgroundOverlayImage.value ? 
                  <button className="btn btn-gray py-3 unlock dark  mw-100 mx-1" 
                    onClick={() => this.backToDefault("promoBackgroundOverlayImage") } > Back to default</button>
                  : 
                  <div className="col-auto ">
                    {nameExistOrNot[0]['promoBackgroundOverlayImage'] ? (
                      <div
                      className="popup-dismiss"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => this.removeUploadFile("promoBackgroundOverlayImage")}
                      >
                        {" "}
                        <img src={navCloseSvg} alt="" />{" "}
                      </div>
                    ) : (
                      ""
                    )}
                </div>
              }  
            </div> 
          </div>
          <div className="col-sm-4"></div>
        </div>
        </div>
			</>	
    )
  }
}

const mapDispatchToProps = {
  updateChallengeFormSubmit: updateChallengeForm.submit,
  updateChallengeFormUpdateField: updateChallengeForm.updateField,
  updateChallengeFormReset: updateChallengeForm.reset,
  updateChallengeFormResetField: updateChallengeForm.resetField,
  updateChallengeFormSetError: updateChallengeForm.setError,
  updateChallengeFormSetSuccess: updateChallengeForm.setSuccess,
  updateChallengeFormResetError: updateChallengeForm.resetError,
  updateChallengeFormResetSuccess: updateChallengeForm.resetSuccess
};

const mapStateToProps = state => {
  const { forms, user, languages } = state;
  return {
    updateChallengeForm: forms.updateChallengeForm,
    stats: user.item.adminStats.CHALLENGES,
    languagesList: languages.languagesList.items ? languages.languagesList.items : [],
    defaultLang: user.item.defaultLanguage ? user.item.defaultLanguage.id : 0,
    gameLists: user.item.adminStats.ARCADEX_GAMES || null,
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FeaturedBannerComponent);