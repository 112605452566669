import React, { Component } from "react";
import { connect } from "react-redux";
import camerasvg from "../../../assets/images/svg/camera.svg";
import navCloseSvg from "../../../assets/images/svg/nav-close.svg";
import {
  createChallengeForm
} from "../../../actions/forms";
import AppInputComponent from "../../../layouts/components/sidebars/AppInputComponent";
import '../ChallengeBannerComponent.scss';

class BrandBannerComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { direction: '' };
    this.changeContentDirection = this.changeContentDirection.bind(this);
    this.removeUploadFile = this.removeUploadFile.bind(this);
    this.backToDefault = this.backToDefault.bind(this);
  }
  componentDidMount(){
    this.setState((props, state) => {
      return{
        direction: this.props.createChallengeForm.fields.promoTextDirection.value,
        game: this.props.gameLists.filter(data => data.id == this.props.createChallengeForm.fields.gamesId.value),
        selectHostOption: this.props.hostList.filter(data => data.id == this.props.createChallengeForm.fields.selectHost.value )
      }
    });
  }
  changeContentDirection() {
    let promoTextDirection = this.props.createChallengeForm.fields.promoTextDirection.value == 'left' ?  'right' : 'left';
    this.setState((props, state) => {
      return{
        direction: promoTextDirection
      }
    });
    this.props.createChallengeFormUpdateField("promoTextDirection",  promoTextDirection);
    this.props.translatedValues(this.props.currentLang, 'promoTextDirection',  promoTextDirection);
  }
  changeHasButtonField(hasButton) {
    this.props.createChallengeFormUpdateField("promoHasButton", !hasButton);
    this.props.translatedValues(this.props.currentLang, 'promoHasButton', !hasButton);
  }
  
  backToDefault(field){
    switch(field){
      case "logoUrl":
        let brand = this.props.hostList.filter(data => data.id == this.props.createChallengeForm.fields.selectHost.value );
        this.props.createChallengeFormUpdateField("logoUrl", brand[0]['avatar'] );
        this.props.createChallengeFormUpdateField("isCustomLogoUrl", false);
        break;
      case "challengeImageUrl":
        let game =  this.props.gameLists.filter(data => data.id == this.props.createChallengeForm.fields.gamesId.value);
        this.props.createChallengeFormUpdateField("challengeImageUrl", game[0]['bannerImage'] );
        this.props.createChallengeFormUpdateField("isCustomImageUrl", false);
        break;
    }
  }
  removeUploadFile(field) {
    switch(field){
      case "logoUrl":
        this.props.createChallengeFormUpdateField("logoUrl", "");
        this.props.createChallengeFormUpdateField("isCustomLogoUrl", true);
        break;
      case "challengeImageUrl":
        this.props.createChallengeFormUpdateField("challengeImageUrl", "");
        this.props.createChallengeFormUpdateField("isCustomImageUrl", true);
        break;
    }
  }
  render() {
    let { uploadFile, hostList } = this.props;
    let challengeTranslationData = this.props.createChallengeForm.fields.challengeTranslation.value ? this.props.createChallengeForm.fields.challengeTranslation.value : [];
    let nameExistOrNot = challengeTranslationData.filter(e => e['languageId'] == this.props.currentLang)
    // let selectHostOption = [];
    // if(this.props.createChallengeForm.fields.selectHost.value){
    //   selectHostOption = hostList.filter(data => data.id == this.props.createChallengeForm.fields.selectHost.value ); 
    // }
    // console.log("selectHostOption:", selectHostOption);
    return (
      <>

				<div className="col-sm-12">
          <div className="row">
            <div className="col-sm-8">
              {/* <span>challengeImageUrl</span> */}
              <div className="upload-image d-flex align-items-center mb-3">
                { this.props.createChallengeForm.fields.challengeImageUrl.value && 
                <div className="upload-button image-area-1 col h-100 d-flex flex-column justify-content-center align-items-center">
                    <img
                    src={ this.props.createChallengeForm.fields.challengeImageUrl.value }
                    className="img-fluid"
                    alt=""
                    />
                </div>}
                { this.props.createChallengeForm.fields.isCustomImageUrl.value && !this.props.createChallengeForm.fields.challengeImageUrl.value && 
                <>
                    <input
                        type="file"
                        name="img[]"
                        className="file"
                        onChange={e =>
                          uploadFile(e, "challengeImageUrl", "challengeImageUrlType")
                        }
                        ref={input => (this.inputChallengeImageUrlImg = input)}
                      />
                      <div
                        className={
                          "upload-button image-area-1 col h-100  d-flex flex-column justify-content-center align-items-center " +
                          (this.props.defaultLang == this.props.currentLang ? this.props.createChallengeForm.fields.challengeImageUrl
                            .error ||
                            this.props.createChallengeForm.fields.challengeImageUrlType
                              .error 
                              // || !nameExistOrNot[0]['challengeImageUrl']
                            ? " add-new-rewards-danger"
                            : "" : "")
                        }
                        onClick={() => this.inputChallengeImageUrlImg.click()}
                      >
                        <img src={camerasvg} className="img-fluid" alt="" />
                        <p className="choose-photo mb-0 bold">
                            Upload Challenge Image
                        </p>
                          <p className="mt-4 small">
                            Minimum size 1920px width 200px height
                          <br /> Transparent PNG works best for Desktop
                        </p>
                        <p className="small">
                          <span
                            className={
                              this.props.createChallengeForm.fields.challengeImageUrl
                                .error
                                ? " danger"
                                : ""
                            }
                          >
                            * Maximum size 2 MB
                                </span>
                          <br />{" "}
                          <span
                            className={
                              this.props.createChallengeForm.fields
                                .promoLogoType.error
                                ? " danger"
                                : ""
                            }
                          >
                            * Supported file types : jpg, jpeg and png
                                </span>
                        </p>
                      </div>
                    </>
                }
              </div>
            </div>
            <div className="col-sm-2">
              <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                { this.props.createChallengeForm.fields.isCustomImageUrl.value ? 
                    <button className="btn btn-gray py-3 unlock dark  mw-100 mx-1" 
                      onClick={() => this.backToDefault("challengeImageUrl") } > Back to default</button>
                    : 
                    <div className="col-auto ">
                      {nameExistOrNot[0]['challengeImageUrl'] ? (
                        <div
                        className="popup-dismiss"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => this.removeUploadFile("challengeImageUrl")}
                        >
                          {" "}
                          <img src={navCloseSvg} alt="" />{" "}
                        </div>
                      ) : (
                        ""
                      )}
                  </div>
                }  
              </div> 
            </div>
            <div className="col-sm-2"></div>
          </div>
          </div>
 
          <div className="col-sm-12">
          <div className="row">
            <div className="col-sm-6">
              {/* <span>logoUrl</span> */}
              <div className="upload-image d-flex align-items-center mb-3">
                { this.props.createChallengeForm.fields.logoUrl.value && 
                <div className="upload-button image-area-1 col h-100 d-flex flex-column justify-content-center align-items-center">
                    <img
                    src={ this.props.createChallengeForm.fields.logoUrl.value }
                    className="img-fluid"
                    alt=""
                    />
                </div>}
                { this.props.createChallengeForm.fields.isCustomLogoUrl.value && !this.props.createChallengeForm.fields.logoUrl.value && 
                <>
                    <input
                        type="file"
                        name="img[]"
                        className="file"
                        onChange={e =>
                          uploadFile(e, "logoUrl", "logoUrlType")
                        }
                        ref={input => (this.inputLogoUrlImg = input)}
                      />
                      <div
                        className={
                          "upload-button image-area-1 col h-100  d-flex flex-column justify-content-center align-items-center " +
                          (this.props.defaultLang == this.props.currentLang ? this.props.createChallengeForm.fields.logoUrl
                            .error ||
                            this.props.createChallengeForm.fields.logoUrlType
                              .error 
                              // || !nameExistOrNot[0]['logoUrl']
                            ? " add-new-rewards-danger"
                            : "" : "")
                        }
                        onClick={() => this.inputLogoUrlImg.click()}
                      >
                        <img src={camerasvg} className="img-fluid" alt="" />
                        <p className="choose-photo mb-0 bold">Upload Logo</p>
                            <p className="mt-4 small">
                              Minimum size 300px width 250px height
                            <br /> Transparent PNG works best for Desktop
                          </p>
                        <p className="small">
                          <span
                            className={
                              this.props.createChallengeForm.fields.logoUrl
                                .error
                                ? " danger"
                                : ""
                            }
                          >
                            * Maximum size 2 MB
                                </span>
                          <br />{" "}
                          <span
                            className={
                              this.props.createChallengeForm.fields
                                .logoUrlType.error
                                ? " danger"
                                : ""
                            }
                          >
                            * Supported file types : jpg, jpeg and png
                                </span>
                        </p>
                      </div>
                    </>
                }
              </div>
            </div>
            <div className="col-sm-2">
              <div className="h-100 d-flex flex-column justify-content-center align-items-center">
                { this.props.createChallengeForm.fields.isCustomLogoUrl.value ? 
                    <button className="btn btn-gray py-3 unlock dark  mw-100 mx-1" 
                      onClick={() => this.backToDefault("logoUrl") } > Back to default</button>
                    : 
                    <div className="col-auto ">
                      {nameExistOrNot[0]['logoUrl'] ? (
                        <div
                        className="popup-dismiss"
                        data-dismiss="modal"
                        aria-label="Close"
                        onClick={() => this.removeUploadFile("logoUrl")}
                        >
                          {" "}
                          <img src={navCloseSvg} alt="" />{" "}
                        </div>
                      ) : (
                        ""
                      )}
                  </div>
                }  
              </div> 
            </div>
            <div className="col-sm-4"></div>
          </div>
        </div>

      </>	
    )
  }
}

const mapDispatchToProps = {
  createChallengeFormSubmit: createChallengeForm.submit,
  createChallengeFormUpdateField: createChallengeForm.updateField,
  createChallengeFormReset: createChallengeForm.reset,
  createChallengeFormResetField: createChallengeForm.resetField,
  createChallengeFormSetError: createChallengeForm.setError,
  createChallengeFormSetSuccess: createChallengeForm.setSuccess,
  createChallengeFormResetError: createChallengeForm.resetError,
  createChallengeFormResetSuccess: createChallengeForm.resetSuccess
};

const mapStateToProps = state => {
  const { forms, user, languages, host } = state;
  return {
    createChallengeForm: forms.createChallengeForm,
    stats: user.item.adminStats.CHALLENGES,
    languagesList: languages.languagesList.items ? languages.languagesList.items : [],
    defaultLang: user.item.defaultLanguage ? user.item.defaultLanguage.id : 0,
    gameLists: user.item.adminStats.ARCADEX_GAMES || null,
    hostList: host.brandList ? host.brandList.items : [],
  };
};


export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandBannerComponent);