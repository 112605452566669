import React, { Component } from "react";
import { connect } from "react-redux";
import PaginationComponent from "../../Pagination/Pagination";
import { banList } from "../../../actions/bans";
import { banLiftedForm } from "../../../actions/forms";
import { refactorTitle } from "../../../utils/misc";

class BanListsComponent extends Component {
  constructor(props) {
    super(props);
    this.getBanUserData = this.getBanUserData.bind(this);
    this.selectBanUser = this.selectBanUser.bind(this);

    this.state = {
      page: 1,
      selectedRow: "",
      selectedBan: ""
    };
  }
  componentDidMount() {
    this.props.invalidateBanListViewAll();
    this.props.fetchBanListIfNeeded({
      page: "1",
      sortBy: "",
      sortOrder: "",
      searchText: ""
    });
    this.props.resetSearchAndSorBanListViewAll();
  }
  getBanUserData() {
    this.setState({
      selectedBan: ""
    });
    this.props.invalidateBanListViewAll();
    this.props.fetchBanListIfNeeded({
      page: "1",
      sortBy: this.props.sortBanUsers.field
        ? this.props.sortBanUsers.field
        : "",
      sortOrder: this.props.sortBanUsers.orderBy
        ? this.props.sortBanUsers.orderBy
        : "",
      searchText: this.props.searchBanUsers.name
        ? this.props.searchBanUsers.name
        : ""
    });
  }
  selectBanUser(banId) {
    this.setState({
      selectedBan:
        this.props.banLiftedForm.fields.banId.value == banId ? "" : banId
    });
    if (this.props.banLiftedForm.fields.banId.value != banId) {
      this.props.banLiftedFormUpdateField("banId", banId);
    } else {
      this.props.banLiftedFormResetField("banId");
    }
  }
  render() {
    const {
      banList,
      isBansUserLastPage,
      banUsersTotalPage,
      banUsersTotalCount,
      isBanUsersRecordDisplayed
    } = this.props;
    return (
      <>
        <div className="row ">
          <div className="col-sm-12 table-responsive admin-table">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th
                    width="15%"
                    scope="col"
                    className={
                      "sortable " +
                      (this.props.sortBanUsers.field === "id"
                        ? this.props.sortBanUsers.orderBy
                        : "")
                    }
                    onClick={() => {
                      this.props.setSortByBanListViewAll("id");
                      setTimeout(() => {
                        this.getBanUserData();
                      }, 100);
                    }}
                  >
                    Id
                  </th>
                  <th
                    scope="col"
                    className={
                      "text-center sortable " +
                      (this.props.sortBanUsers.field === "name"
                        ? this.props.sortBanUsers.orderBy
                        : "")
                    }
                    onClick={() => {
                      this.props.setSortByBanListViewAll("name");
                      setTimeout(() => {
                        this.getBanUserData();
                      }, 100);
                    }}
                  >
                    Name
                  </th>
                  <th scope="col" className="text-center">
                    Reason
                  </th>
                  <th scope="col" className="text-center">
                    Comment
                  </th>
                  <th scope="col" className="text-center">
                    Lifted
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td />
                  <td className="text-center">
                    <div className="form-group with-lable">
                      <div className="input-group-wrapper">
                        <input
                          type="text"
                          placeholder="Search by name"
                          className="form-control effect-16"
                          onChange={e => {
                            this.props.setSearchByBanListViewAll(
                              "name",
                              e.target.value
                            );
                            setTimeout(() => {
                              this.getBanUserData();
                            }, 100);
                          }}
                        />
                        <span className="focus-border" />
                      </div>
                    </div>
                  </td>
                  <td className="text-center" />
                  <td className="text-center" />
                  <td className="text-center" />
                </tr>
                {banList.length > 0 ? (
                  banList.map((ban, index) => {
                    return (
                      <tr
                        className={
                          this.state.selectedBan == ban.id ? "selected-row" : ""
                        }
                        key={index}
                        onClick={() => {
                          this.selectBanUser(ban.id);
                        }}
                      >
                        <td>{ban.id}</td>
                        <td className="text-center">{ban.profile.name}</td>
                        <td className="text-center">
                          {ban.banReason.description}
                        </td>
                        <td className="text-center">
                          {refactorTitle(ban.comment, 50)}
                        </td>
                        <td className="text-center">
                          {ban.lifted ? "true" : "false"}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr className="text-center">
                    <td colSpan="5">No Records Found</td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>

          {banList.length > 0 ? (
            <>
              <div className="col-sm-12">
                <PaginationComponent
                  totalPage={banUsersTotalPage}
                  totalCount={banUsersTotalCount}
                  recordDisplayed={isBanUsersRecordDisplayed}
                  isLastPage={isBansUserLastPage}
                  invalidateAllAction={this.props.invalidateBanListViewAll}
                  fetchListAllAction={this.props.fetchBanListIfNeeded}
                  sortBy={this.props.sortBanUsers.field}
                  sort={this.props.sortBanUsers.orderBy}
                  searchSort={this.props.searchBanUsers.name ? true : false}
                  param={{
                    sortBy: this.props.sortBanUsers.field
                      ? this.props.sortBanUsers.field
                      : "",
                    sortOrder: this.props.sortBanUsers.orderBy
                      ? this.props.sortBanUsers.orderBy
                      : "",
                    searchText: this.props.searchBanUsers.name
                      ? this.props.searchBanUsers.name
                      : ""
                  }}
                  resetSearchPage={[
                    this.props.searchBanUsers.name
                      ? this.props.searchBanUsers.name
                      : ""
                  ]}
                />
              </div>
            </>
          ) : (
            ""
          )}
        </div>
        <div className="row justify-content-center align-items-center">
          <div className="col-sm-3 mb-4">
            <div className="row justify-content-center align-items-center">
              <div className="form-group with-lable mt-4 ">
                <button
                  onClick={e => {
                    this.props.banLiftedFormSubmit(
                      this.props.banLiftedForm.fields.banId.value
                    );
                  }}
                  type="button"
                  className={
                    this.state.selectedBan &&
                    this.state.selectedBan ==
                      this.props.banLiftedForm.fields.banId.value
                      ? "[ btn btn-pink light  py-3 btn-preview dark mt-2 ml-4]"
                      : "[ btn btn-pink light  py-3 btn-preview dark mt-2 ml-4] disabled"
                  }
                >
                  Lift Ban
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {
  fetchBanListIfNeeded: banList.fetchIfNeeded,
  invalidateBanListViewAll: banList.invalidate,
  setSortByBanListViewAll: banList.setSortBy,
  setSearchByBanListViewAll: banList.setSearchBy,
  resetSearchAndSorBanListViewAll: banList.resetSearchAndSort,

  banLiftedFormReset: banLiftedForm.reset,
  banLiftedFormResetField: banLiftedForm.resetField,
  banLiftedFormUpdateField: banLiftedForm.updateField,
  banLiftedFormSetError: banLiftedForm.setError,
  banLiftedFormResetError: banLiftedForm.resetError,
  banLiftedFormSubmit: banLiftedForm.submit
};

const mapStateToProps = state => {
  const { bans, forms } = state;
  return {
    banLiftedForm: forms.banLiftedForm,

    banList: bans.banUsersList ? bans.banUsersList.items : [],
    searchBanUsers: bans.banUsersList.search,
    sortBanUsers: bans.banUsersList.sort,
    isBansUserLastPage: bans.banUsersList.isLastPage,
    banUsersTotalPage: bans.banUsersList.totalPage
      ? bans.banUsersList.totalPage
      : 0,
    banUsersTotalCount: bans.banUsersList.totalCount
      ? bans.banUsersList.totalCount
      : 0,
    isBanUsersRecordDisplayed: bans.banUsersList.recordDisplayed
      ? bans.banUsersList.recordDisplayed
      : 0
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BanListsComponent);
