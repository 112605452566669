import { TabInitialStateGenerator } from "./creators";
import { CreateChallengeTabing } from "../redux-config/tabActionMap";
import { combineReducers } from "redux";

const tab = combineReducers({
  [CreateChallengeTabing.stateSubKey]: TabInitialStateGenerator(
    CreateChallengeTabing
  )
});
export default tab;
