import axiosLib from "axios";

export class AxiosInstance {
  constructor() {
    this.axios = axiosLib.create();
    this.axios.interceptors.request.use(
      config => {
        config.headers["x-bearer-token"] =
          "Bearer " + localStorage.getItem("token");
//        config.headers["language"] = localStorage.getItem("language")
        return config;
      },
      error => {
        return Promise.reject(error);
      }
    );
  }

  setToken(token) {
    localStorage.setItem("token", token);
    this.axios.defaults.headers.common["x-bearer-token"] = "Bearer " + token;
  }

  removeToken() {
    localStorage.removeItem("token");
    delete this.axios.defaults.headers.common["x-bearer-token"];
    this.axios = axiosLib.create();
  }

  getAxios() {
    return this.axios;
  }
}
export let axiosInstance = new AxiosInstance();
// have to export the raw axios from axios because of a bug in karma test server with moxios
// a.k.a. axios in test should be just axios (we don't need x-bearer at least for starters)
export let axios =
  process.env.NODE_ENV === "test" ? axiosLib : new AxiosInstance().getAxios();