const tabInitialStateGenerator = params => {
  let steps = {};
  params.steps.map((tabStep, index) => {
    steps[tabStep] = {
      isCompleted: false,
      isActive: !(index > 0),
      keyIndex: index
    };
  });
  let tab = {
    isLastTab: false,
    activeTab: params.steps[0],
    currentIndex: 0,
    previousTab: false,
    nextTab: params.steps[1],
    steps: steps
  };
  return tab;
};

const tabInactivateAll = (params, state) => {
  let steps = {};
  params.steps.map((tabStep, index) => {
    steps[tabStep] = { ...state.steps[tabStep], isActive: false };
  });
  return steps;
};

export default params => (state = tabInitialStateGenerator(params), action) => {
  switch (action.type) {
    case "TAB_" + params.code + "_NEXT_PREVIOUS_TAB":
      return {
        ...state,
        previousTab: action.nextPrevoiusTabas.previousTab1,
        nextTab: action.nextPrevoiusTabas.nextTab1
      };
    case "TAB_" + params.code + "_CHANGE_TAB/" + action.field:
      return {
        ...state,
        activeTab: action.field,
        currentIndex: state.steps[action.field].keyIndex,
        isLastTab:
          params.steps.length === state.steps[action.field].keyIndex + 1
            ? true
            : false,
        steps: {
          ...tabInactivateAll(params, state),
          [action.field]: {
            ...state.steps[action.field],
            isActive: true
          }
        }
      };
    case "TAB_" + params.code + "_COMPLETE_TAB/" + action.field:
      return {
        ...state,
        steps: {
          ...state.steps,
          [action.field]: {
            ...state.steps[action.field],
            isCompleted: true
          }
        }
      };
    default:
      return state;
  }
};
