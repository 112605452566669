import React, { Component } from "react";
import ReactDOM from "react-dom";
import { parseQuery, getContainerLeftWidth } from "../../utils/misc";
import "swiper/dist/css/swiper.css";

class HomeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      mlxContainer: 0
    };
    this.autoSetContainerImg = this.autoSetContainerImg.bind(this);
    this.scrollToDiv = this.scrollToDiv.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.aboutRef = React.createRef();
    this.rafflesRef = React.createRef();
    this.tournamentsRef = React.createRef();
    this.newsRef = React.createRef();
  }

  autoSetContainerImg() {
    this.setState((state, props) => {
      return { mlxContainer: getContainerLeftWidth() * -1 - 30 };
    });
  }
  updateDimensions() {
    this.autoSetContainerImg();
  }
  componentDidMount() {
    window.scrollTo(0, 0);
    if (this.props.location.search) {
      const query = parseQuery(this.props.location.search);
      if (query.email && query.activationHash) {
        this.props.history.push(
          "/activate/" + query.email + "/" + query.activationHash
        );
      }
      if (query.email && query.resetPasswordHash) {
        this.props.history.push(
          "/resetpassword/" + query.email + "/" + query.resetPasswordHash
        );
      }
    }
    this.autoSetContainerImg();
    if (this.props.location.hash) {
      this.scrollToDiv(this.props.location.hash);
    }
    if (this.props.location.resetPasswordHash) {
      this.scrollToDiv(this.props.location.resetPasswordHash);
    }
  }
  scrollToDiv(linkHref) {
    let scrollX = linkHref === "#tournaments" ? -92 : -72;
    if (this.props.isLeftSidebarOpen) this.props.leftSidebarClose();
    const elmnt = this[linkHref.replace("#", "") + "Ref"];
    if (elmnt) {
      ReactDOM.findDOMNode(elmnt.current).focus();
      setTimeout(() => {
        window.scrollBy(0, scrollX);
      }, 100);
    }
  }
  render() {
    return (
      <>
        <section className="container-fluid tab-content-wrapper">
          <div className="row">
            <div className="col-sm-12">
              <div>
                <div className="container">
                  <div className="padder-main">
                    <div className="row">
                      <div className="col-sm-12 mt-6">
                        <h1 className="text-center">Admin Panel</h1>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default HomeComponent;
