export const CreateChallengeTabing = {
  code: "CREATE_CHALLENGE",
  stateKey: "tabing",
  stateSubKey: "createChallenge",

  steps: [
    "gameSetupTab",
    "timeDateTab",
    "rewardsTab",
    "brandingTab",
    "rulesTab",
    "publishTab",
    "previewTab"
  ]
};
