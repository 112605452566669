import { axios } from "../utils/axios";
import config from "./config";
import { API_SERVER_URL } from "./config/apiEndpoints";

export const publishRaffleService = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.createRaffles,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getAllRaffleService = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.allRaffles,
      method: "post",
      headers: {
        "content-type": "application/json",
        "language": postParams.languageId
      },
      data: { page: postParams.page }
    })
    .catch(err => {
      console.log(err);
    });
};

export const getRaffleDetail = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.raffleDetail.replace("##id##", postParams.id ? postParams.id : 0),
      method: "get",
      headers: {
        "content-type": "application/json",
        "language": postParams.languageId
      },
    })
    .catch(err => {
      console.log(err);
    });
};

export const getRaffleRepeatEnable = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.raffleRepeatEnable.replace("##id##", postParams ? postParams : 0),
      method: "get",
      headers: {
        "content-type": "application/json"
      },
      // data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getRaffleRepeatDisable = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.raffleRepeatDisable.replace("##id##", postParams ? postParams : 0),
      method: "get",
      headers: {
        "content-type": "application/json"
      },
    })
    .catch(err => {
      console.log(err);
    });
};

export const deleteRaffleService = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.raffleDelete.replace("##id##", postParams.id ? postParams.id : 0),
      method: "get",
      headers: {
        "content-type": "application/json",
        "language": postParams.languageId
      },
    })
    .catch(err => {
      console.log(err);
    });
}