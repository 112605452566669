import React, { Component } from "react";
import { connect } from "react-redux";
import CardImage from "../../../assets/images/card-sm.png";
import { getNoOfDay } from "../../../utils/date";
import { refactorTitle } from "../../../utils/misc";
import adv10 from "../../../assets/images/adv-dashboard/adv-5.png";
import { gameImages } from "../../../utils/gameImages";
class TournamentCardComponent extends Component {
  addDefaultSrc(ev) {
    ev.target.src = CardImage;
  }
  challengeType;

  prepareTagClass() {
    if (this.props.challengeType && this.props.challengeType === "Live") {
      return "gtmJoinTournament-Tournament" + this.props.order;
    }
    if (this.props.challengeType && this.props.challengeType === "Quick") {
      return "gtmJoinQuickPlay-QuickPlay" + this.props.order;
    }
    if (this.props.challengeType && this.props.challengeType === "Upcoming") {
      return "gtmJoinUpcoming-Upcoming" + this.props.order;
    }
  }

  gtmJoinUpcoming;

  render() {
    const {
      brandName,
      brandAvatar,
      challengeTitle,
      timeOfStart,
      timeOfEnd
    } = this.props;

    return (
      <>
        {this.props.type && this.props.type === "ad" ? (
          <div
            className="card popular-arcadex d-flex justify-content-center align-items-center"
            style={{ width: "300px" }}
          >
            {this.props.advertisementPlace == 1 ||
            this.props.advertisementPlace == 4 ? (
              <img
                src={this.props.advertisementUrl}
                alt="Advertisement"
                className="w-100"
              />
            ) : (
              <img
                src={adv10}
                alt="Advertisement"
                className="w-100 disabledAd"
              />
            )}
          </div>
        ) : (
          <a href={"#"} className={this.prepareTagClass()}>
            <div className={this.props.cardClass}>
              <div className="card-header light">
                {getNoOfDay(timeOfStart, timeOfEnd)}
              </div>
              <img
                className="card-img"
                src={gameImages[this.props.casual_game_id]}
                alt=""
                onError={this.addDefaultSrc}
              />
              <div className="card-body">
                <span className="brand-img-parent">
                  <figure>
                    <img
                      src={brandAvatar}
                      alt="Popular ArcadeX"
                      className={this.props.brandAvatarClass}
                    />
                    <figcaption className="online-72" />
                    <figcaption className="semi-bold mt-2">
                      {brandName}
                    </figcaption>
                  </figure>
                </span>
                <h4 className="bold">{refactorTitle(challengeTitle)}</h4>
              </div>
            </div>
          </a>
        )}
      </>
    );
  }
}

const defaultProps = {
  id: 1,
  brandName: "MTN",
  brandAvatar: "",
  challengeImageUrl: CardImage,
  challengeTitle: "test",
  timeOfStart: "2018-10-16 18:30:00+00",
  timeOfEnd: "2018-10-23 06:42:00+00",
  cardClass: "card",
  brandAvatarClass: "profile-pic-72",
  type: "card",
  advertisementPlace: "",
  advertisementUrl: ""
};
TournamentCardComponent.defaultProps = defaultProps;

const mapStateToProps = state => {
  return {
    isLoggedIn: true
  };
};

export default connect(
  mapStateToProps,
  defaultProps
)(TournamentCardComponent);
