export const GameList = {
  code: "GAMELIST",
  stateKey: "games",
  stateSubKey: "gamesList",
  type: "list"
};
export const HostList = {
  code: "HOST",
  stateKey: "host",
  stateSubKey: "hostList"
};

export const TournamentsList = {
  code: "TOURNAMENTS",
  stateKey: "tournaments",
  stateSubKey: "tournamentsList"
};

export const NewsList = {
  code: "NEWS",
  stateKey: "news",
  stateSubKey: "newsList"
};

export const NewsCategoriesList = {
  code: "NEWS_CATEGORIES",
  stateKey: "news",
  stateSubKey: "newsCategoriesList"
};
export const BrandList = {
  code: "BRAND_LIST",
  stateKey: "host",
  stateSubKey: "brandList"
};

export const RafflesList = {
  code: "RAFFLES_LIST",
  stateKey: "raffles",
  stateSubKey: "raffleslist"
};

export const BanReasonList = {
  code: "BAN_REASON_LIST",
  stateKey: "bans",
  stateSubKey: "banReasonList"
};
export const dashboardDisabledAdvertisementList = {
  code: "DASHBOARD_DISABLED_ADVERTISEMENTS",
  stateKey: "dashboard",
  stateSubKey: "dashboardDisabledAdvertisementList"
};
export const PlatformByBrandList = {
  code: "PLATFORM_BY_BRAND_LIST",
  stateKey: "host",
  stateSubKey: "platformByBrandList"
};

export const LanguagesList = {
  code: "LANGUAGES_LIST",
  stateKey: "languages",
  stateSubKey: "languagesList"
};

export const UserSubscriptionHistoryList = {
  code: "USER_SUBSCRIPTION_HISTORY_LIST",
  stateKey: "user",
  stateSubKey: "userSubscriptionHistoryList"
};