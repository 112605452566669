import { LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGIN_USER } from "../types/user";

const initialState = {
  isLogging: false,
  isLoggedIn: false
};

const user = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_REQUEST:
      return {
        ...state,
        isLogging: true
      };
    case LOGIN_USER:
      return {
        ...state,
        item: { ...state.item, ...action.userdata.data },
        isLoggedIn: true
      };
    case LOGOUT_SUCCESS:
      return {
        ...initialState
      };
    default:
      return state;
  }
};
export default user;
