import React, { Component } from "react";
import CardImage from "../../../assets/images/card-sm.png";
import { refactorTitle } from "./../../../utils/misc";
import { getNoOfDay } from "../../../utils/date";
import { gameImages } from "../../../utils/gameImages";

class QuickPlayCardComponent extends Component {
  addDefaultSrc(ev) {
    ev.target.src = CardImage;
  }
  render() {
    return (
      <div className="card quick-play">
        <div className="card-header light">
          {getNoOfDay(this.props.timeOfStart, this.props.timeOfEnd)}
        </div>
        <img
          className="card-img"
          src={gameImages[this.props.casual_game_id]}
          alt={this.props.challengeTitle}
          onError={this.addDefaultSrc}
        />
        <h4 className="mt-3 bold" title={this.props.challengeTitle}>
          {refactorTitle(this.props.challengeTitle, 15)}
        </h4>
      </div>
    );
  }
}

const defaultProps = {
  id: 1,
  challengeTitle: "Title of championship here",
  challengeImageUrl: CardImage,
  timeOfStart: "2018-10-16 18:30:00+00",
  timeOfEnd: "2018-10-23 06:42:00+00",
  casual_game_id: 7
};

QuickPlayCardComponent.defaultProps = defaultProps;

export default QuickPlayCardComponent;
