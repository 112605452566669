import React, { Component } from "react";
import SwiperContainer from "../Slider/SwiperContainer";
import RafflesCardComponent from "./RafflesCardComponent";
import { getContainerLeftWidth } from "../../utils/misc";

class RafflesCardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slidesOffsetBefore: 0,
      calculating: true
    };
    this.calculateWidth = this.calculateWidth.bind(this);
  }

  componentDidMount() {
    this.calculateWidth();
  }

  calculateWidth() {
    this.setState((state, props) => {
      return {
        slidesOffsetBefore: getContainerLeftWidth(),
        calculating: false
      };
    });
  }

  render() {
    const params = {
      fixDrag: true,
      speed: 1000,
      slidesPerView: "auto",
      loop: false,
      spaceBetween: 25,
      slidesOffsetBefore: this.state.slidesOffsetBefore,
      slidesOffsetAfter: 50,
      breakpoints: {
        1024: {
          slidesPerView: "auto",
          initialSlide: 0,
          loop: false,
          slidesOffsetBefore: this.state.slidesOffsetBefore
        },
        768: {
          slidesPerView: "auto",
          initialSlide: 0,
          loop: false,
          slidesOffsetBefore: this.state.slidesOffsetBefore + 15
        },
        640: {
          spaceBetween: 18,
          slidesPerView: "auto",
          initialSlide: 0,
          loop: false,
          slidesOffsetBefore: 15,
          slidesOffsetAfter: 15
        },
        320: {
          spaceBetween: 18,
          slidesPerView: "auto",
          initialSlide: 0,
          loop: false,
          slidesOffsetBefore: 15,
          slidesOffsetAfter: 15
        }
      }
    };

    const { list } = this.props;
    let listWithCreate = [];
    listWithCreate = [
      ...list.slice(0, 0),
      ...[{ id: "add" }],
      ...list.slice(0)
    ];
    return !this.state.calculating &&
      listWithCreate !== undefined &&
      listWithCreate.length > 0 ? (
        <SwiperContainer {...params}>
          {listWithCreate.map((item, index) => (
            <RafflesCardComponent {...item} key={index} showDeleteButton={true} />
          ))}
        </SwiperContainer>
      ) : (
        ""
      );
  }
}

export default RafflesCardContainer;
