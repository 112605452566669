import { axios } from "../utils/axios";
import config from "./config";
import { API_SERVER_URL } from "./config/apiEndpoints";

export const getStaticData = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.getStaticData,
      method: "get",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const onGetTournaments = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.getTournaments,
      method: "post",
      headers: {
        "content-type": "application/json",
        "language": postParams.languageId
      },
      data: { page: postParams.page }
    })
    .catch(err => {
      console.log(err);
    });
};

export const onTournamentDelete = postParams => {
  return axios
    .request({
      url:
        API_SERVER_URL +
        config.deleteTournament.replace(
          "##id##",
          postParams ? postParams.Id : 0
        ),
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};
