import { connect } from "react-redux";
import AvailableSpaceComponent from "../../components/AvailableSpace/AvailableSpaceComponent";
import {
  brandAdvertisementsAvailable,
  challengeAdvertisementsAvailable
} from "../../actions/availablespace";
import {
  publishBrandAdsForm,
  publishChallengeAdsForm,
  revokeBrandAdsForm,
  revokeChallengeAdsForm
} from "../../actions/forms";

const mapDispatchToProps = {
  fetchbrandAdAvailabletIfNeeded: brandAdvertisementsAvailable.fetchIfNeeded,
  invalidatebrandAdAvailable: brandAdvertisementsAvailable.invalidate,
  setSearchBybrandAdAvailable: brandAdvertisementsAvailable.setSearchBy,
  setSortBybrandAdAvailable: brandAdvertisementsAvailable.setSortBy,
  resetSearchAndSortbrandAdAvailable:
    brandAdvertisementsAvailable.resetSearchAndSort,

  //challenge
  fetchchallengeAdAvailabletIfNeeded:
    challengeAdvertisementsAvailable.fetchIfNeeded,
  invalidatechallengeAdAvailable: challengeAdvertisementsAvailable.invalidate,
  setSearchBychallengeAdAvailable: challengeAdvertisementsAvailable.setSearchBy,
  setSortBychallengeAdAvailable: challengeAdvertisementsAvailable.setSortBy,
  resetSearchAndSortchallengeAdAvailable:
    challengeAdvertisementsAvailable.resetSearchAndSort,

  //publish brand
  publishBrandAdsFormSubmit: publishBrandAdsForm.submit,

  //publish challenge
  publishChallengeAdsFormSubmit: publishChallengeAdsForm.submit,

  //revoke brand
  revokeBrandAdsFormSubmit: revokeBrandAdsForm.submit,

  //revoke challenge
  revokeChallengeAdsFormSubmit: revokeChallengeAdsForm.submit
};

const mapStateToProps = state => {
  const { availablespace, user } = state;
  return {
    //brand
    brandAvailableSpace: availablespace.brandAvailableSpace,
    isBrandLastPage: availablespace.brandAvailableSpace.isLastPage,
    brandTotalPage: availablespace.brandAvailableSpace.totalPage
      ? availablespace.brandAvailableSpace.totalPage
      : 0,
    brandTotalCount: availablespace.brandAvailableSpace.totalCount
      ? availablespace.brandAvailableSpace.totalCount
      : 0,
    isBrandrecordDisplayed: availablespace.brandAvailableSpace.recordDisplayed
      ? availablespace.brandAvailableSpace.recordDisplayed
      : 0,
    brandlistSort: availablespace.brandAvailableSpace.sort,
    brandlistSearch: availablespace.brandAvailableSpace.search,

    //challenge
    challengeAvailableSpace: availablespace.challengeAvailableSpace,
    ischallengeLastPage: availablespace.challengeAvailableSpace.isLastPage,
    challengeTotalPage: availablespace.challengeAvailableSpace.totalPage
      ? availablespace.challengeAvailableSpace.totalPage
      : 0,
    challengeTotalCount: availablespace.challengeAvailableSpace.totalCount
      ? availablespace.challengeAvailableSpace.totalCount
      : 0,
    ischallengerecordDisplayed: availablespace.challengeAvailableSpace
      .recordDisplayed
      ? availablespace.challengeAvailableSpace.recordDisplayed
      : 0,
    challengelistSort: availablespace.challengeAvailableSpace.sort,
    challengelistSearch: availablespace.challengeAvailableSpace.search,

    challengeState: user.item.adminStats.CHALLENGES,
    brandState: user.item.adminStats.BRANDS
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AvailableSpaceComponent);
