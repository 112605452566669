import { axios } from "../utils/axios";
import config from "./config";
import { API_SERVER_URL } from "./config/apiEndpoints";

export const publishChallengeService = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.publishChallenge,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};
export const updateChallengeService = (postParams, id) => {
  return axios
    .request({
      url: API_SERVER_URL + config.updateChallenge + id,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};
export const onGetChallengeFormDetails = postParams => {
  return axios
    .request({
      url:
        API_SERVER_URL +
        config.getChallengeFormDetail +
        postParams,
      method: "get",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};
export const getChallengeAdvertisementsAvailable = postParams => {
  return axios
    .request({
      url:
        API_SERVER_URL +
        config.challengeAdvertisementsAvailable,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};
export const updateChallengeAdsAvailable = (postParams, id) => {
  return axios
    .request({
      url:
        API_SERVER_URL +
        config.challengeAdvertisementsUpdate +
        id,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};
export const onGetChallengeAdsDetails = postParams => {
  return axios
    .request({
      url:
        API_SERVER_URL +
        config.challengeAdvertisementsDetails +
        postParams,
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};
export const onPublishChallenge = postParams => {
  return axios
    .request({
      url:
        API_SERVER_URL +
        config.publishChallengeAds.replace(
          "##id##",
          postParams ? postParams : 0
        ),
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};
export const onRevokeChallengeAds = postParams => {
  return axios
    .request({
      url:
        API_SERVER_URL +
        config.revokeChallengeAds.replace(
          "##id##",
          postParams ? postParams : 0
        ),
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};
