import React, { Component } from "react";
import { connect } from "react-redux";
import ProfileImage from "../../../assets/images/svg/profile-pic-120.svg";
import { ValidURL } from "../../../utils/date";

class DashboardPopularHostCardComponent extends Component {
  addDefaultSrc(ev) {
    ev.target.src = ProfileImage;
  }

  prepareTagClass(brandName) {
    return "gtmViewBrand-" + this.props.order;
  }

  render() {
    return (
      <div className="card popular-host text-center popularBox">
        <figure className="mr-2 mr-md-4">
          <a href={"#"}>
            <img
              className="profile-pic-120"
              src={
                ValidURL(this.props.brandAvatar)
                  ? this.props.brandAvatar
                  : ProfileImage
              }
              alt={this.props.brandName}
              onError={this.addDefaultSrc}
            />
          </a>
          <figcaption className="online-120" />
          <figcaption className="text-secondary mt-3 brand-label">
            <a
              href={"/host/" + this.props.brandId}
              className={
                "text-secondary " + this.prepareTagClass(this.props.brandName)
              }
            >
              {this.props.brandName}
            </a>
          </figcaption>
        </figure>
      </div>
    );
  }
}

const defaultProps = {
  brandId: 1,
  brandName: "Dunkin Donuts",
  brandAvatar: ProfileImage
};

const mapStateToProps = state => {
  return {
    isLoggedIn: true
  };
};

DashboardPopularHostCardComponent.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  defaultProps
)(DashboardPopularHostCardComponent);
