import React, { Component } from "react";
import { connect } from "react-redux";
import { refactorTitle, refactorContent } from "./../../../utils/misc";
import CardImage from "../../../assets/images/card-sm.png";
import { timeSince } from "../../../utils/date";

class DashboardNewsCardComponent extends Component {
  addDefaultSrc(ev) {
    ev.target.src = CardImage;
  }

  render() {
    return (
      <div className="card quick-play">
        <a href="#">
          <div className="card-header light">{this.props.categoryName}</div>
          <img
            className="card-img"
            src={this.props.thumbImageUrl}
            alt={this.props.title}
            onError={this.addDefaultSrc}
          />
          <h4 className="mt-3 bold" title={this.props.title}>
            {" "}
            {refactorTitle(this.props.title, 15)}
          </h4>
        </a>
        {!this.props.displayContent ? (
          ""
        ) : (
          <p className="my-3">{refactorContent(this.props.content)}</p>
        )}
        <small>{timeSince(this.props.createdOn)} ago</small>
      </div>
    );
  }
}

const mapDispatchToProps = {
  id: "9",
  title: "Vodafone partnering with GamingBattleGround!",
  content: "",
  imageUrl: CardImage,
  createdOn: "2017-09-15 07:29:56+00",
  categoryName: "Tournament"
};

const mapStateToProps = state => {
  return {
    isLoggedIn: false
  };
};

DashboardNewsCardComponent.defaultProps = mapDispatchToProps;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardNewsCardComponent);
