import {
  ListActionCreator,
  PaginationListActionCreator,
  DetailActionCreator
} from "./creators";
import { onGetTournaments } from "../services/tournaments.service";
import { onGetChallengeFormDetails } from "../services/challenges.service";
import { TournamentsList } from "../redux-config/listActionMap";
import { TournamentsListViewAll } from "../redux-config/plistActionMap";
import { ChallengeFormDetails } from "../redux-config/detailActionMap";
import { updateChallengeForm } from "./forms";

export const tournamentsList = ListActionCreator({
  ...TournamentsList,
  service: onGetTournaments,
  dataMapper: json => json.data.data.items
});

export const tournamentsListViewAll = PaginationListActionCreator({
  ...TournamentsListViewAll,
  service: onGetTournaments,
  dataMapper: json => json.data.data.items,
  lastPageMapper: json => json.data.data.isLastPage
});

export const challengeFormDetails = DetailActionCreator({
  ...ChallengeFormDetails,
  service: onGetChallengeFormDetails,
  dataMapper: json => {
    return json.data.data;
  },
  onResponse: (json, dispatch) => {
    const { data } = json.data;
    dispatch(
      updateChallengeForm.updateField(
        "challengeName",
        data.challengeTitle ? data.challengeTitle : ""
      )
    );
    dispatch(
      updateChallengeForm.updateField(
        "challengeTranslation",
        data.challengeTranslation ? data.challengeTranslation : []
      )
    );
    dispatch(
      updateChallengeForm.updateField(
        "envName",
        data.brand.env.name ? data.brand.env.name : ""
      )
    );
    dispatch(
      updateChallengeForm.updateField(
        "gamesId",
        data.casualGameId ? data.casualGameId : ""
      )
    );
    dispatch(
      updateChallengeForm.updateField("gamesName", data.game ? data.game : "")
    );
    dispatch(
      updateChallengeForm.updateField(
        "gamesUrl",
        data.gameThumbSmallUrl ? data.gameThumbSmallUrl : ""
      )
    );
    dispatch(updateChallengeForm.updateField("winCriterionId", 1));
    dispatch(
      updateChallengeForm.updateField(
        "selectHost",
        data.brandId ? data.brandId : ""
      )
    );
    dispatch(
      updateChallengeForm.updateField(
        "selectHostName",
        data.brandName ? data.brandName : ""
      )
    );
    dispatch(
      updateChallengeForm.updateField(
        "selectPlayer",
        data.seatsMaximum ? data.seatsMaximum : ""
      )
    );
    dispatch(
      updateChallengeForm.updateField(
        "selectSubscription",
        data.subscriptionTypeId ? data.subscriptionTypeId : 0
      )
    );
    dispatch(
      updateChallengeForm.updateField(
        "tournamentStartDate",
        data.timeOfStart ? data.timeOfStart : ""
      )
    );
    dispatch(
      updateChallengeForm.updateField(
        "tournamentEndingDate",
        data.timeOfEnd ? data.timeOfEnd : ""
      )
    );
    dispatch(
      updateChallengeForm.updateField(
        "tournamentVisible",
        data.visibleFrom ? data.visibleFrom : ""
      )
    );
    dispatch(
      updateChallengeForm.updateField(
        "rewards",
        data.challengePlacementRewards ? data.challengePlacementRewards : ""
      )
    );
    dispatch(
      updateChallengeForm.updateField(
        "challengeImageUrl",
        data.challengeImageUrl ? data.challengeImageUrl : ""
      )
    );
    dispatch(
      updateChallengeForm.updateField(
        "logoUrl",
        data.logoUrl ? data.logoUrl : ""
      )
    );
    dispatch(
      updateChallengeForm.updateField(
        "hostAvatar",
        data.brandAvatar ? data.brandAvatar : ""
      )
    );
    dispatch(
      updateChallengeForm.updateField(
        "promoImage",
        data.promoImage ? data.promoImage : ""
      )
    );
    dispatch(
      updateChallengeForm.updateField(
        "isFeatured",
        data.featured ? data.featured : false
      )
    );
    dispatch(updateChallengeForm.updateField("promoTitle", data.promoTitle ? data.promoTitle : ""));
    dispatch(updateChallengeForm.updateField("promoSubTitle", data.promoSubTitle ? data.promoSubTitle : ""));
    dispatch(updateChallengeForm.updateField("promoDescription", data.promoDescription ? data.promoDescription : ""));
    dispatch(updateChallengeForm.updateField("promoLogo", data.promoLogo ? data.promoLogo : ""));
    dispatch(updateChallengeForm.updateField("promoBackgroundImage", data.promoBackgroundImage ? data.promoBackgroundImage : ""));
    dispatch(updateChallengeForm.updateField("promoBackgroundOverlayImage", data.promoBackgroundOverlayImage ? data.promoBackgroundOverlayImage : ""));
    dispatch(updateChallengeForm.updateField("promoBackgroundColor", data.promoBackgroundColor ? data.promoBackgroundColor : ""));
    dispatch(updateChallengeForm.updateField("promoBackgroundColor2", data.promoBackgroundColor2 ? data.promoBackgroundColor2 : ""));
    dispatch(updateChallengeForm.updateField("promoTextDirection", data.promoTextDirection ? data.promoTextDirection : "left"));
    dispatch(updateChallengeForm.updateField("promoHasButton", data.promoHasButton ? data.promoHasButton : ""));
    dispatch(updateChallengeForm.updateField("isCustomPromoLogo", data.isCustomPromoLogo ? data.isCustomPromoLogo : ""));
    dispatch(updateChallengeForm.updateField("isCustomLogoUrl", data.isCustomLogoUrl ? data.isCustomLogoUrl : ""));
    dispatch(updateChallengeForm.updateField("isCustomImageUrl", data.isCustomImageUrl ? data.isCustomImageUrl : ""));
    dispatch(updateChallengeForm.updateField("isCustomPromoBackgroundImage", data.isCustomPromoBackgroundImage ? data.isCustomPromoBackgroundImage : ""));
    dispatch(updateChallengeForm.updateField("isCustomPromoBackgroundOverlayImage", data.isCustomPromoBackgroundOverlayImage ? data.isCustomPromoBackgroundOverlayImage : ""));
    dispatch(updateChallengeForm.updateField("promoButtonText", data.promoButtonText ? data.promoButtonText : ""));
    dispatch(updateChallengeForm.updateField("additionalPassword", data.passwordForEntry ? data.passwordForEntry : ""));

    let brand = data.challengeAdvertisements
      ? data.challengeAdvertisements.map((item, index) => {
        dispatch(
          updateChallengeForm.updateField(
            item.challengeAdvertisementPlace.type +
            "-" +
            item.challengeAdvertisementPlace.placeCode,
            item.imageUrl
          )
        );
      })
      : [];

    let ownerBrandId = data.challengeAdvertisements
      ? data.challengeAdvertisements.map((item, index) => {
        dispatch(
          updateChallengeForm.updateField(
            item.challengeAdvertisementPlace.type +
            "-" +
            item.challengeAdvertisementPlace.placeCode +
            "-" +
            "ownerBrandID",
            item.ownerBrandId
          )
        );
      })
      : [];

    dispatch(
      updateChallengeForm.updateField("rules", data.rules ? data.rules : "")
    );
    dispatch(
      updateChallengeForm.updateField(
        "previewBanner",
        data.gameThumbLargeUrl ? data.gameThumbLargeUrl : ""
      )
    );
    dispatch(
      updateChallengeForm.updateField(
        "previewLogo",
        data.brandAvatar ? data.brandAvatar : ""
      )
    );
  }
});
