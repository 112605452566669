import { MODAL_CLOSE_ALL, MODAL_OPEN_ANY } from "../types/modals";

import { combineReducers } from "redux";
import { ModalReducerCreator } from "./creators";
import {
  Login,
  ForgotPassword,
  ResetPassword,
  ChangePassword,
  RafflesRepeat,
  CreateChallengeRewardsTranslation,
  PublishRaffleTranslation,
  ResetLeaderboard
} from "../redux-config/modalActionMap";

const modals = combineReducers({
  [Login.stateSubKey]: ModalReducerCreator(Login),
  [ForgotPassword.stateSubKey]: ModalReducerCreator(ForgotPassword),
  [ResetPassword.stateSubKey]: ModalReducerCreator(ResetPassword),
  [ChangePassword.stateSubKey]: ModalReducerCreator(ChangePassword),
  [RafflesRepeat.stateSubKey]: ModalReducerCreator(RafflesRepeat),
  [CreateChallengeRewardsTranslation.stateSubKey]: ModalReducerCreator(CreateChallengeRewardsTranslation),
  [PublishRaffleTranslation.stateSubKey]: ModalReducerCreator(PublishRaffleTranslation),
  [ResetLeaderboard.stateSubKey]: ModalReducerCreator(ResetLeaderboard),
  isAnyModalOpen: (state = false, action) => {
    switch (action.type) {
      case MODAL_OPEN_ANY:
        return true;
      case MODAL_CLOSE_ALL:
        return false;
      default:
        return state;
    }
  }
});
export default modals;
