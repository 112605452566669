import { combineReducers } from "redux";
import {
  ListReducerCreator,
  DetailReducerCreator,
  TListReducerCreator
} from "./creators";

import {
  DashboardAdvertisementDetails,
  DashboardAdvertisementPublish,
  DashboardAdvertisementRevoke
} from "../redux-config/detailActionMap";
import { DashboardList } from "../redux-config/plistActionMap";
import { dashboardDisabledAdvertisementList } from "../redux-config/listActionMap";

const host = combineReducers({
  [DashboardList.stateSubKey]: TListReducerCreator(DashboardList),
  [DashboardAdvertisementDetails.stateSubKey]: DetailReducerCreator(
    DashboardAdvertisementDetails
  ),
  [DashboardAdvertisementPublish.stateSubKey]: DetailReducerCreator(
    DashboardAdvertisementPublish
  ),
  [DashboardAdvertisementRevoke.stateSubKey]: DetailReducerCreator(
    DashboardAdvertisementRevoke
  ),
  [dashboardDisabledAdvertisementList.stateSubKey]: ListReducerCreator(
    dashboardDisabledAdvertisementList
  )
});

export default host;
