import React, { Component } from "react";
import banner1 from "../../../assets/images/adv-dashboard/banner-1.png";
import telokomBanner1 from "../../../assets/images/adv-dashboard/telkom-banner-1.png";
import "./DashboardBannerComponent.scss";

class DashboardBannerComponent extends Component {
  render() {
    const divStyle = {
      width: "100%"
    };

    return (
      <section className="container-fluid1 banner-wrapper">
        <div className="row no-gutters no-space">
          <div className="col-sm-12">
            <div className="banner dashboard d-flex flex-column align-items-center">
              <img
                src={
                  process.env.REACT_APP_ENV === "DEV" ? telokomBanner1 : banner1
                }
                alt=""
                className="dynamic-banner"
                style={divStyle}
              />

              <div className="book-yoour-seat w-100 mt-auto">
                <div className="container">
                  <div className="row justify-content-center">
                    <div className="col-sm-12 col-lg-7">
                      <div className="row">
                        <div className="col d-flex align-items-center justify-content-center">
                          <h4 className="text-white my-3">
                            Brand New Tournament!
                          </h4>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default DashboardBannerComponent;
