const nextPreviousTab = (params, field) => (dispatch, getState) => {
  var tabIndex = getState().tab[params.stateSubKey].currentIndex
    ? getState().tab[params.stateSubKey].currentIndex
    : 0;

  let nextPrevoiusTabas = { previousTab1: false, nextTab1: false };

  if (tabIndex !== 0) {
    nextPrevoiusTabas.previousTab1 = params.steps[tabIndex - 1]
      ? params.steps[tabIndex - 1]
      : false;
  }
  nextPrevoiusTabas.nextTab1 = params.steps[tabIndex + 1]
    ? params.steps[tabIndex + 1]
    : false;
  dispatch({
    type: "TAB_" + params.code + "_NEXT_PREVIOUS_TAB",
    nextPrevoiusTabas
  });
};

const changeTab = (params, field) => (dispatch, getState) => {
  dispatch({
    type: "TAB_" + params.code + "_CHANGE_TAB/" + field,
    field
  });
  setTimeout(() => {
    dispatch(nextPreviousTab(params));
  }, 100);
};
const completeTab = (params, field) => (dispatch, getState) => {
  dispatch({
    type: "TAB_" + params.code + "_COMPLETE_TAB/" + field,
    field
  });
};

export default params => ({
  changeTab: field => changeTab(params, field),
  completeTab: field => completeTab(params, field),
  nextPrevious: field => nextPreviousTab(params, field)
});
