import AvailableSpaceUpdateBrandComponent from "../../components/AvailableSpace/AvailableSpaceUpdateBrandComponent";
import { connect } from "react-redux";
import { updateBrandAdsForm } from "../../actions/forms";
import { brandAdvertisementsDetails } from "../../actions/availablespace";
import { platformByBrandList } from "../../actions/host";

const mapDispatchToProps = {
  updateBrandAdsFormSubmit: updateBrandAdsForm.submit,
  updateBrandAdsFormReset: updateBrandAdsForm.reset,
  updateBrandAdsFormResetField: updateBrandAdsForm.resetField,
  updateBrandAdsFormUpdateField: updateBrandAdsForm.updateField,
  updateBrandAdsFormSetError: updateBrandAdsForm.setError,
  updateBrandAdsFormSetSuccess: updateBrandAdsForm.setSuccess,
  updateBrandAdsFormResetError: updateBrandAdsForm.resetError,
  updateBrandAdsFormResetSuccess: updateBrandAdsForm.resetSuccess,

  //brand ads detail
  fetchBrandAdsDetailsIfNeeded: brandAdvertisementsDetails.fetchIfNeeded,
  invalidateBrandAdsDetails: brandAdvertisementsDetails.invalidate,

  //brnad list by platform
  fetchbrandbyPlatformListIfNeeded: platformByBrandList.fetchIfNeeded,
  invalidatebrandbyPlatformList: platformByBrandList.invalidate
};

const mapStateToProps = state => {
  const { forms, host } = state;
  return {
    updateBrandAdsForm: forms.updateBrandAdsForm,
    platformByBrandList: host.platformByBrandList
      ? host.platformByBrandList
      : []
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AvailableSpaceUpdateBrandComponent);
