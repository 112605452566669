import { combineReducers } from "redux";
import { TListReducerCreator } from "./creators";

import { RafflesWinnerList,ChallengesWinnerList } from "../redux-config/tlistActionMap";

const rewards = combineReducers({
  [RafflesWinnerList.stateSubKey]: TListReducerCreator(RafflesWinnerList),
  [ChallengesWinnerList.stateSubKey]: TListReducerCreator(ChallengesWinnerList)
});


export default rewards;
