import React, { Component } from "react";
import SwiperContainer from "../Slider/SwiperContainer";
import TournamentPoolCardComponent from "./TournamentPoolCardComponent";
import { getContainerLeftWidth } from "../../utils/misc";

class TournamentPoolCardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slidesOffsetBefore: 0
    };
    this.calculateWidth = this.calculateWidth.bind(this);
    this.calculateAdIndex = this.calculateAdIndex.bind(this);
    this.adIndex = 4;
    this.calculateAdIndex();
  }

  calculateAdIndex() {
    var conw = document.getElementsByClassName("container")[0]
      ? document.getElementsByClassName("container")[0].getBoundingClientRect()
          .width
      : 0;
    var mleft = Math.floor((window.innerWidth - conw - 30) / 2 + 15);
    if (window.innerWidth < 1200) {
      if (window.innerWidth > 767) {
        this.adIndex = Math.floor((window.innerWidth - mleft - 260) / 260);
      } else {
        this.adIndex = Math.floor((window.innerWidth - mleft - 200) / 200);
      }
    }
  }

  componentDidMount() {
    this.calculateWidth();
  }

  calculateWidth() {
    this.setState((state, props) => {
      return {
        slidesOffsetBefore: getContainerLeftWidth()
      };
    });
  }

  render() {
    const params = {
      fixDrag: true,
      slidesPerView: "auto",
      containerClass: "swiper-container",
      spaceBetween: 25,
      loop: false,
      centeredSlides: false,
      slidesOffsetBefore: this.state.slidesOffsetBefore,
      slidesOffsetAfter: 50,
      breakpoints: {
        1024: {
          initialSlide: 0
        },
        768: {
          initialSlide: 0,
          slidesOffsetBefore: this.state.slidesOffsetBefore + 15
        },
        640: {
          initialSlide: 0,
          spaceBetween: 18,
          slidesOffsetBefore: 15,
          slidesOffsetAfter: 15
        },
        320: {
          initialSlide: 0,
          spaceBetween: 18,
          slidesOffsetBefore: 15,
          slidesOffsetAfter: 15,
          touchRatio: 1
        }
      }
    };
    const { list } = this.props;
    if (this.props.type !== "createChallenge") {
      let test = [];
      if (this.props.showAd) {
        if (list && list.length > 0) {
          test = [
            ...list.slice(0, this.adIndex - 1),
            ...[{ type: "ad" }],
            ...list.slice(this.adIndex - 1)
          ];
        }
      } else {
        test = list;
      }

      return this.state.slidesOffsetBefore && list && list.length > 0 ? (
        <SwiperContainer {...params}>
          {test.map((item, index) => (
            <TournamentPoolCardComponent
              {...item}
              key={index}
              cardLgClass="prize-pool"
            />
          ))}
        </SwiperContainer>
      ) : null;
    } else {
      return this.state.slidesOffsetBefore && list && list.length > 0 ? (
        <SwiperContainer {...params}>
          {list.map((item, index) => (
            <TournamentPoolCardComponent
              rewardData={item}
              key={index}
              cardLgClass="prize-pool"
              forPreview={true}
            />
          ))}
        </SwiperContainer>
      ) : null;
    }
  }
}
export default TournamentPoolCardContainer;
