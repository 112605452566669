import React, { Component } from "react";
import { connect } from "react-redux";
import "./Toastr.scss";
import ToastrItem from "./ToastrItem";

class ToastrContainer extends Component {
  render() {
    const { list } = this.props;
    return list ? (
        <div className="toast-container toast-top-right">
            {list.map((item, index) => (
                <ToastrItem key={index} type={item.type} message={item.message} index={item.uniqueId}/>
            ))}
        </div>
      ):null;
  }
}

const mapStateToProps = state => {
  return {
    list: state.toastr.toastrList,
  };
};

export default connect(mapStateToProps)(ToastrContainer);
