import { VALUE_UPDATE } from "../types/forms";
import { FormActionCreator } from "./creators";
import { onCreateNews, onNewsUpdate, onDisableNews, onEnableNews } from "../services/news.service";
import {
  createDashboardAdvertisementService,
  editDashboardAdvertisementService
} from "../services/dashboard.service";
import {
  brandAdvertisementsAvailable,
  challengeAdvertisementsAvailable
} from "./availablespace";
import {
  publishChallengeService,
  updateChallengeService,
  updateChallengeAdsAvailable,
  onPublishChallenge,
  onRevokeChallengeAds
} from "../services/challenges.service";

import {
  LoginForm,
  UsernameExistsForm,
  ForgotPasswordForm,
  ResetPasswordForm,
  ChangePasswordForm,
  CreateChallengeForm,
  PublishRaffleForm,
  CreateHostForm,
  UpdateHostForm,
  CreateNewsForm,
  UpdateNewsForm,
  UpdateBrandAdsForm,
  UpdateChallengeAdsForm,
  CreateDashboardAdvertisementForm,
  EditDashboardAdvertisementForm,
  AnalyzeForm,
  PublishBrandAdsForm,
  PublishChallengeAdsForm,
  RevokeBrandAdsForm,
  RevokeChallengeAdsForm,
  AddPointsForm,
  BanLiftedForm,
  AddBanUserForm,
  UpdateChallengeForm,
  DeleteTournamentForm,
  DeleteHostForm,
  EnableNewsForm,
  DisableNewsForm,
  DeleteRaffleForm
} from "../redux-config/formActionMap";

import {
  onLogInUser,
  onUsernameExists,
  onForgotPasswordRequest,
  onResetPassword,
  onChangePasswordRequest,
  addPoints,
  banLifted,
  banUser
} from "../services/users.service";

import { publishRaffleService, deleteRaffleService } from "../services/raffles.service";
import { onTournamentDelete } from "../services/tournaments.service";
import { rafflesList } from "../actions/raffles";
import {
  tournamentsList,
  tournamentsListViewAll
} from "../actions/tournaments";
import {
  newsList,
  newsListViewAll
} from "../actions/news";
import { hostList, hostListViewAll } from "../actions/host";
import {
  createHost,
  onHostUpdate,
  updateBrandAdvertisementsAvailable,
  onPublishBrand,
  onRevokeBrandAds,
  onDeleteHost
} from "../services/host.service";
import { getAnalyzeData } from "../services/analyze.service";
import { resetPassword, modalCloseAll, changePassword } from "./modals";

import { loginSuccess } from "./user";
import { axiosInstance } from "../utils/axios";
import { sidebarCloseAll } from "./sidebars";
import { setToastr } from "./toastr";
import { allUserList, banList } from "./bans";

export const deleteRaffleForm = FormActionCreator({
  ...DeleteRaffleForm,
  service: deleteRaffleService,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(extraActions.reset(DeleteRaffleForm));
      dispatch(setToastr("success", "Delete Raffle Successfully"));
      dispatch(rafflesList.invalidate());
      dispatch(rafflesList.fetchData({ page: 1 }));
    } else {
      dispatch(
        setToastr(
          "error",
          response.data.error
            ? response.data.error.message
            : "Oops, something went wrong."
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});
export const enableNewsForm = FormActionCreator({
  ...EnableNewsForm,
  service: onEnableNews,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(extraActions.reset(EnableNewsForm));
      dispatch(setToastr("success", "News Enable Successfully"));
      dispatch(newsList.invalidate());
      dispatch(newsListViewAll.invalidateAll());
      dispatch(newsList.fetchData({ page: 1 }));
      dispatch(newsListViewAll.fetchData({ page: 1 }));
    } else {
      dispatch(
        setToastr(
          "error",
          response.data.error
            ? response.data.error.message
            : "Oops, something went wrong."
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});

export const disableNewsForm = FormActionCreator({
  ...DisableNewsForm,
  service: onDisableNews,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(extraActions.reset(DisableNewsForm));
      dispatch(setToastr("success", "News Disable Successfully"));
      dispatch(newsList.invalidate());
      dispatch(newsListViewAll.invalidateAll());
      dispatch(newsList.fetchData({ page: 1 }));
      dispatch(newsListViewAll.fetchData({ page: 1 }));
    } else {
      dispatch(
        setToastr(
          "error",
          response.data.error
            ? response.data.error.message
            : "Oops, something went wrong."
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});

export const deleteTournamentForm = FormActionCreator({
  ...DeleteTournamentForm,
  service: onTournamentDelete,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(extraActions.reset(DeleteTournamentForm));
      dispatch(setToastr("success", "Tournament Deleted Successfully"));
      dispatch(tournamentsList.invalidate());
      dispatch(tournamentsListViewAll.invalidateAll());
      dispatch(tournamentsList.fetchData({ page: 1, languageId: getState().user.item.defaultLanguage.id }));
      dispatch(tournamentsListViewAll.fetchData({ page: 1, languageId: getState().user.item.defaultLanguage.id }));
    } else {
      dispatch(
        setToastr(
          "error",
          response.data.error
            ? response.data.error.message
            : "Oops, something went wrong."
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});

export const deleteHostForm = FormActionCreator({
  ...DeleteHostForm,
  service: onDeleteHost,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(extraActions.reset(DeleteHostForm));
      dispatch(setToastr("success", "Host Deleted Successfully"));
      dispatch(hostList.invalidate());
      dispatch(hostListViewAll.invalidateAll());
      dispatch(hostListViewAll.fetchData({ page: 1 }));
      dispatch(hostList.fetchData({ page: 1 }));
    } else {
      dispatch(
        setToastr(
          "error",
          response.data.error
            ? response.data.error.message
            : "Oops, something went wrong."
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});

export const onValueUpdate = (scope, field, value) => ({
  type: VALUE_UPDATE,
  payload: { scope: scope, field: field, value: value }
});

export const usernameExistsForm = FormActionCreator({
  ...UsernameExistsForm,
  service: onUsernameExists,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(
        extraActions.setError(UsernameExistsForm, "username", "USERNAME_USE")
      );
    } else {
      response.data.error &&
        response.data.error.code &&
        response.data.error.code === "G001"
        ? dispatch(
          extraActions.setSuccess(
            UsernameExistsForm,
            "username",
            "User not exists"
          )
        )
        : dispatch(
          extraActions.setError(
            UsernameExistsForm,
            "username",
            "CONTACT_SUPPORT"
          )
        );
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});

export const forgotPasswordForm = FormActionCreator({
  ...ForgotPasswordForm,
  service: onForgotPasswordRequest,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      const resendEmail = getState().forms.forgotPasswordForm.fields.resendEmail
        .value;

      dispatch(extraActions.reset(ForgotPasswordForm));
      dispatch(
        extraActions.setSuccess(
          ForgotPasswordForm,
          "email",
          resendEmail
            ? "FORGOT_PASSWORD_RESEND_MAIL"
            : "FORGOT_PASSWORD_SEND_MAIL"
        )
      );
      setTimeout(() => {
        dispatch(extraActions.resetSuccess(ForgotPasswordForm, "email"));
      }, 5000);
    } else {
      dispatch(
        extraActions.setError(
          ForgotPasswordForm,
          "email",
          response.data.error && response.data.error.code
            ? response.data.error.code
            : "CONTACT_SUPPORT"
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});

export const changePasswordForm = FormActionCreator({
  ...ChangePasswordForm,
  service: onChangePasswordRequest,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(
        extraActions.setSuccess(
          ChangePasswordForm,
          "newPassword",
          "RESET_PASSWORD_SUCCESS"
        )
      );
      setTimeout(() => {
        dispatch(extraActions.resetSuccess(ChangePasswordForm, "newPassword"));
        dispatch(changePassword.close());
        dispatch(extraActions.reset(ChangePasswordForm));
      }, 2000);
    } else {
      dispatch(
        extraActions.setError(
          ChangePasswordForm,
          "newPassword",
          response.data.error && response.data.error.code
            ? response.data.error.code
            : "CONTACT_SUPPORT"
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});

export const resetPasswordForm = FormActionCreator({
  ...ResetPasswordForm,
  service: onResetPassword,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(extraActions.reset(ResetPasswordForm));
      dispatch(
        extraActions.setSuccess(
          ResetPasswordForm,
          "newPassword",
          "RESET_PASSWORD_SUCCESS"
        )
      );
      setTimeout(() => {
        dispatch(extraActions.resetSuccess(ResetPasswordForm, "newPassword"));
        dispatch(resetPassword.close());
      }, 5000);
    } else {
      dispatch(
        extraActions.setError(
          ResetPasswordForm,
          "newPassword",
          response.data.error && response.data.error.code
            ? response.data.error.code
            : "NOT_RESET_PASSWORD"
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});

export const loginForm = FormActionCreator({
  ...LoginForm,
  service: onLogInUser,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      if (response.data.data.token)
        axiosInstance.setToken(response.data.data.token);
      // localStorage.setItem("language", response.data.data.defaultLanguage.id);
      let param = {
        data: response.data.data
      };

      dispatch(loginSuccess(param));

      dispatch(
        extraActions.setSuccess(
          LoginForm,
          "email",
          "You are successfully login."
        )
      );
      dispatch(extraActions.reset(LoginForm));
      dispatch(sidebarCloseAll());
      dispatch(modalCloseAll());
    } else {
      dispatch(
        extraActions.setError(
          LoginForm,
          "email",
          response.data.error && response.data.error.code
            ? response.data.error.code
            : "CONTACT_SUPPORT",
          {
            text:
              response.data.info !== undefined ? response.data.info.text : "",
            shortCode:
              response.data.info !== undefined
                ? response.data.info.shortCode
                : "",
            reason:
              response.data.banReason !== undefined
                ? response.data.banReason
                : ""
          }
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});

export const createChallengeForm = FormActionCreator({
  ...CreateChallengeForm,
  service: publishChallengeService,
  onResponse: (response, extraActions, dispatch, getState) => {
    let challengeId = response.data.data.challengeId;
    window.location.href = "/update-challenge/" + challengeId;
  },
  onError: (response, setError, setSuccess, dispatch, getState) => { }
});

export const updateChallengeForm = FormActionCreator({
  ...UpdateChallengeForm,
  service: updateChallengeService,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      window.location.href = "/tournament";
    }
  },
  onError: (response, setError, setSuccess, dispatch, getState) => { }
});

export const publishRaffleForm = FormActionCreator({
  ...PublishRaffleForm,
  service: publishRaffleService,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(
        extraActions.setSuccess(
          PublishRaffleForm,
          "entryFee",
          "PUBLISH_RAFFLE_SUCCESS"
        )
      );
      setTimeout(() => {
        dispatch(extraActions.reset(PublishRaffleForm));
      }, 1000);
      window.location = "/raffles";
    } else {
      dispatch(setToastr("error", "Oops, something went wrong."));
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});

export const createHostForm = FormActionCreator({
  ...CreateHostForm,
  service: createHost,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      window.location = "/host";
    } else {
      dispatch(
        extraActions.setError(
          CreateHostForm,
          "hostName",
          response.data.error.code
        )
      );
      dispatch(setToastr("error", "Host already registered"));
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});

export const updateHostForm = FormActionCreator({
  ...UpdateHostForm,
  service: onHostUpdate,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(extraActions.reset(UpdateHostForm));
      window.location = "/host";
    } else {
      dispatch(
        extraActions.setError(
          UpdateHostForm,
          "hostName",
          response.data.error.code
        )
      );
      dispatch(setToastr("error", "Brand already registered"));
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});
export const createNewsForm = FormActionCreator({
  ...CreateNewsForm,
  service: onCreateNews,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(extraActions.reset(CreateNewsForm));
      window.location = "/news";
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});

export const updateNewsForm = FormActionCreator({
  ...UpdateNewsForm,
  service: onNewsUpdate,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(extraActions.reset(UpdateNewsForm));
      window.location = "/news";
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});
export const updateBrandAdsForm = FormActionCreator({
  ...UpdateBrandAdsForm,
  service: updateBrandAdvertisementsAvailable,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(extraActions.reset(UpdateBrandAdsForm));
      window.location = "/available-space";
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});

export const updateChallengeAdsForm = FormActionCreator({
  ...UpdateChallengeAdsForm,
  service: updateChallengeAdsAvailable,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(extraActions.reset(UpdateChallengeAdsForm));
      window.location = "/available-space";
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});

export const createDashboardAdvertisementForm = FormActionCreator({
  ...CreateDashboardAdvertisementForm,
  service: createDashboardAdvertisementService,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      setTimeout(() => {
        dispatch(extraActions.reset(CreateDashboardAdvertisementForm));
      }, 1000);
      window.location = "/dashboard";
    } else {
      dispatch(
        setToastr(
          "error",
          response.data.error
            ? response.data.error
            : "Oops, something went wrong."
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});

export const editDashboardAdvertisementForm = FormActionCreator({
  ...EditDashboardAdvertisementForm,
  service: editDashboardAdvertisementService,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      setTimeout(() => {
        dispatch(extraActions.reset(EditDashboardAdvertisementForm));
      }, 1000);
      window.location = "/dashboard";
    } else {
      dispatch(
        setToastr(
          "error",
          response.data.error
            ? response.data.error.message
            : "Oops, something went wrong."
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});

export const analyzeForm = FormActionCreator({
  ...AnalyzeForm,
  service: getAnalyzeData,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(allUserList.fetchData);
      dispatch(setToastr("success", "Record Found Successfully"));
      dispatch(extraActions.reset(AnalyzeForm));
    } else {
      dispatch(setToastr("error", "Something Went Wrong"));
      dispatch(
        extraActions.setError(
          AnalyzeForm,
          "profileId",
          response.data.error.code
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});

export const addPointsForm = FormActionCreator({
  ...AddPointsForm,
  service: addPoints,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      let page = Math.ceil(getState().bans.usersList.recordDisplayed / 10);
      let sortBy = getState().bans.usersList.sort.field;
      let sortOrder = getState().bans.usersList.sort.orderBy;
      let searchText = getState().bans.usersList.search.name
        ? getState().bans.usersList.search.name
        : "";
      let searchid = getState().bans.usersList.search.id
        ? getState().bans.usersList.search.id
        : "";
      let searchPoints = getState().bans.usersList.search.points
        ? getState().bans.usersList.search.points
        : "";
      let searchEmail = getState().bans.usersList.search.email
        ? getState().bans.usersList.search.email
        : "";
      let searchNumber = getState().bans.usersList.search.phoneNumber
        ? getState().bans.usersList.search.phoneNumber
        : "";
      let searchMondiaOtpVerified = getState().bans.usersList.search.mondiaOtpVerified 
        ? getState().bans.usersList.search 
        : "";
      let searchMondiaSubscriptionId = getState().bans.usersList.search.mondiaSubscriptionId 
        ? getState().bans.usersList.search 
        : "";
      let searchLastLogin = getState().bans.usersList.search.lastLogin
        ? getState().bans.usersList.search 
        : "";
      let searchSubscriptionEndTime = getState().bans.usersList.search.subscriptionEndTime 
        ? getState().bans.usersList.search 
        : "";
      dispatch(
        allUserList.fetchData({
          page: page,
          sortBy: sortBy,
          sortOrder: sortOrder,
          searchText: searchText,
          searchByPoints: searchPoints,
          searchById: searchid,
          searchByEmail: searchEmail,
          searchByNumber: searchNumber,
          searchByMondiaOtpVerified: searchMondiaOtpVerified,
          searchByMondiaSubscriptionId: searchMondiaSubscriptionId,
          searchByLastLogin: searchLastLogin,
          searchBySubscriptionEndTime: searchSubscriptionEndTime,
        })
      );
      dispatch(setToastr("success", "Points Added Successfully"));
      dispatch(extraActions.reset(AddPointsForm));
    } else {
      dispatch(setToastr("error", "Something Went Wrong"));
      dispatch(
        extraActions.setError(
          AddPointsForm,
          "profileId",
          response.data.error.code
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});

export const banLiftedForm = FormActionCreator({
  ...BanLiftedForm,
  service: banLifted,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      const { recordDisplayed, sort, search } = getState().bans.banUsersList;
      let page =
        recordDisplayed % 10 == 1
          ? Math.ceil(recordDisplayed / 10) - 1
          : Math.ceil(recordDisplayed / 10);
      let sortBy = sort.field;
      let sortOrder = sort.orderBy;
      let searchText = search.name ? search.name : "";

      dispatch(
        banList.fetchData({
          page: page,
          sortBy: sortBy,
          sortOrder: sortOrder,
          searchText: searchText
        })
      );
      dispatch(extraActions.reset(BanLiftedForm));
      dispatch(setToastr("success", "Ban Lifted Successfully"));
    } else {
      dispatch(setToastr("error", "Ban Lifted Failed"));
      dispatch(
        extraActions.setError(BanLiftedForm, "banId", response.data.error.code)
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});

export const addBanUserForm = FormActionCreator({
  ...AddBanUserForm,
  service: banUser,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      dispatch(
        banList.fetchData({
          page: 1,
          sortBy: "",
          sortOrder: "",
          searchText: ""
        })
      );
      dispatch(extraActions.reset(AddBanUserForm));
      dispatch(setToastr("success", "Ban User Successfully"));
    } else {
      dispatch(setToastr("error", "Ban User Failed"));
      dispatch(
        extraActions.setError(
          AddBanUserForm,
          "profileId",
          response.data.error.code
        )
      );
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});

export const publishBrandAdsForm = FormActionCreator({
  ...PublishBrandAdsForm,
  service: onPublishBrand,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      const {
        recordDisplayed,
        sort,
        search
      } = getState().availablespace.brandAvailableSpace;
      let page = Math.ceil(recordDisplayed / 10);
      let sortBy = sort.field;
      let sortOrder = sort.orderBy;
      let searchText = search.name ? search.name : "";
      let searchType = search.type ? search.type : "";
      let searchPlace = search.place ? search.place : "";
      dispatch(
        brandAdvertisementsAvailable.fetchData({
          page: page,
          sortBy: sortBy,
          sortOrder: sortOrder,
          searchText: searchText,
          type: searchType,
          searchByPlace: searchPlace
        })
      );
    } else {
      dispatch(setToastr("error", "Oops, something went wrong."));
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});

export const publishChallengeAdsForm = FormActionCreator({
  ...PublishChallengeAdsForm,
  service: onPublishChallenge,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      const {
        recordDisplayed,
        sort,
        search
      } = getState().availablespace.challengeAvailableSpace;
      let page = Math.ceil(recordDisplayed / 10);
      let sortBy = sort.field;
      let sortOrder = sort.orderBy;
      let searchText = search.name ? search.name : "";
      let searchType = search.type ? search.type : "";
      let searchPlace = search.place ? search.place : "";
      dispatch(
        challengeAdvertisementsAvailable.fetchData({
          page: page,
          sortBy: sortBy,
          sortOrder: sortOrder,
          searchText: searchText,
          type: searchType,
          searchByPlace: searchPlace
        })
      );
    } else {
      dispatch(setToastr("error", "Oops, something went wrong."));
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});
export const revokeBrandAdsForm = FormActionCreator({
  ...RevokeBrandAdsForm,
  service: onRevokeBrandAds,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      const {
        recordDisplayed,
        sort,
        search
      } = getState().availablespace.brandAvailableSpace;
      let page = Math.ceil(recordDisplayed / 10);
      let sortBy = sort.field;
      let sortOrder = sort.orderBy;
      let searchText = search.name ? search.name : "";
      let searchType = search.type ? search.type : "";
      let searchPlace = search.place ? search.place : "";
      dispatch(
        brandAdvertisementsAvailable.fetchData({
          page: page,
          sortBy: sortBy,
          sortOrder: sortOrder,
          searchText: searchText,
          type: searchType,
          searchByPlace: searchPlace
        })
      );
    } else {
      dispatch(setToastr("error", "Oops, something went wrong."));
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});

export const revokeChallengeAdsForm = FormActionCreator({
  ...RevokeChallengeAdsForm,
  service: onRevokeChallengeAds,
  onResponse: (response, extraActions, dispatch, getState) => {
    if (response.data.success) {
      const {
        recordDisplayed,
        sort,
        search
      } = getState().availablespace.challengeAvailableSpace;
      let page = Math.ceil(recordDisplayed / 10);
      let sortBy = sort.field;
      let sortOrder = sort.orderBy;
      let searchText = search.name ? search.name : "";
      let searchType = search.type ? search.type : "";
      let searchPlace = search.place ? search.place : "";
      dispatch(
        challengeAdvertisementsAvailable.fetchData({
          page: page,
          sortBy: sortBy,
          sortOrder: sortOrder,
          searchText: searchText,
          type: searchType,
          searchByPlace: searchPlace
        })
      );
    } else {
      dispatch(setToastr("error", "Oops, something went wrong."));
    }
  },
  onError: (response, extraActions, dispatch, getState) => { }
});
