import React, { Component } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import layouts from "./routes";
import AuthHoc from "./hoc/AuthHoc";
import LoginHoc from "./hoc/LoginHoc";

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          {layouts.map((layout, i) => {

            return layout.routes.map((route, i) => (
              <Route
                key={i}
                path={route.path}
                exact={route.exact}
                render={props => {
                  let Auth = route.auth
                    ? AuthHoc(route.container)
                    : route.allowAfterLogin
                      ? route.container
                      : LoginHoc(route.container);
                  return (
                    <layout.layout {...props}>
                      <Auth {...props} />
                    </layout.layout>
                  );
                }}
              />
            ));
          })}
        </Switch>
      </Router>
    );
  }
}

export default App;
