import { createStore, applyMiddleware, compose } from "redux";

import thunk from "redux-thunk";

import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

import rootReducer from "./reducers";

const persistConfig = {
  key: "root",
  storage,
  blacklist: [
    "modals",
    "sidebars",
    "subscribe",
    "errors",
    "forms",
    "redirect",
    "mytournaments",
    "challenges",
    "host",
    "tournaments",
    "categories",
    "news",
    "raffles",
    "rewards",
    "toggles",
    "loaders",
    "profiles",
    "toastr",
    "messages",
    "notifications",
    "tab",
    "bans",
    "availablespace",
    "dashboard"
  ]
};
const persistedReducer = persistReducer(persistConfig, rootReducer);

const middleware = [thunk];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  persistedReducer,
  composeEnhancers(applyMiddleware(...middleware))
);
export default () => {
  let persistor = persistStore(store, [() => {}]);
  return { store, persistor };
};
