import React, { Component } from "react";
import gameSetupOn from "../../../../assets/images/game-setup-on.png";
import token from "../../../../assets/images/token.png";
import AppInputComponent from "../../../../layouts/components/sidebars/AppInputComponent";

class RewardsCardComponent extends Component {
  render() {
    const rewardItem = this.props;
    let challengeTranslationData = rewardItem && rewardItem['challengePlacementRewardsTranslation'] ? rewardItem : [];
    let rulesExistOrNot = challengeTranslationData['challengePlacementRewardsTranslation'].filter(e => e['languageId'] == this.props.locale)
    return (
      <>
        <div
          className="col-md-12 col-lg-6 mb-5 "
          id={"reward_" + this.props.rewardIndex}
        >
          <div className="bg-rewards h-100">
            <div className="row ">
              <div className="col-sm-12 form-info">
                <h4 className="bold mb-3">
                  {this.props.rewardPlaceData + " place rewards"}
                </h4>
                <ul className=" list-inline d-flex flex-row flex-wrap w-100 mb-5">
                  <li className="list-group-control col-sm-6 mt-2">
                    <div className="select-game-box">
                      <label className="custom-control  fill-checkbox  w-100 mb-0 d-flex justify-content-center align-items-center">
                        <div className="labelc-content d-inline-flex align-items-center">
                          <img src={gameSetupOn} alt="" className="icon" />
                          <p className="mb-0 ml-3">Items</p>
                        </div>
                        <div className="ml-auto">
                          <input
                            className="fill-control-input"
                            type="checkbox"
                            checked={rulesExistOrNot[0]['itemDescription'] ? true : false}
                            id={this.props.rewardIndex}
                            readOnly
                          />
                          <span className="fill-control-indicator backend" />
                        </div>
                      </label>
                    </div>
                  </li>
                  <li className="list-group-control col-sm-6 mt-2 ">
                    <div className="select-game-box">
                      <label className="custom-control  fill-checkbox  w-100 mb-0 d-flex justify-content-center align-items-center">
                        <div className="labelc-content d-inline-flex align-items-center">
                          <img src={token} alt="" className="icon" />
                          <p className="mb-0 ml-3">Points</p>
                        </div>
                        <div className="ml-auto">
                          <input
                            className="fill-control-input"
                            type="checkbox"
                            checked={rewardItem.points ? true : false}
                            id={this.props.rewardIndex}
                            readOnly
                          />
                          <span className="fill-control-indicator backend" />
                        </div>
                      </label>
                    </div>
                  </li>
                </ul>
              </div>

              {rewardItem.itemDescription ? (
                <div className="col-sm-12">
                  <h5 className="bold">Item</h5>
                  <div className="row">
                    <div className="col-md-12 col-lg">
                      <div className="form-group with-lable mt-4 ">
                        <AppInputComponent
                          inputClass="form-control effect-16"
                          divClass="input-group-wrapper"
                          spanClass="focus-border"
                          withLabel={true}
                          labelText="Item Name"
                          value={rulesExistOrNot[0]['itemDescription']}
                          id={this.props.rewardIndex}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-auto upload-image d-flex ">
                      <div className="upload-button d-flex justify-content-center align-items-center">
                        <img
                          src={rulesExistOrNot[0]['itemUrl']}
                          className="img-fluid reward-img"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                  ""
                )}

              {rewardItem.points ? (
                <div className="col-sm-12">
                  <h5 className="bold">Points</h5>
                  <div className="row">
                    <div className="col-md-12 col-lg">
                      <div className="form-group with-lable mt-4 ">
                        <AppInputComponent
                          inputClass="form-control effect-16"
                          divClass="input-group-wrapper"
                          spanClass="focus-border"
                          withLabel={true}
                          labelText="points"
                          value={rewardItem.points}
                          id={this.props.rewardIndex}
                          readOnly
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                  ""
                )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default RewardsCardComponent;
