import React, { Component } from "react";
import camerasvg from "../../assets/images/svg/camera.svg";
import { fileUpload } from "../../services/fileUpload.service";
import IconClose from "../../assets/images/svg/nav-close.svg";
import { connect } from "react-redux";
import { createHostForm } from "../../actions/forms";

class BrandAdsComponent extends Component {
  constructor(props) {
    super(props);
    this.getColClass = this.getColClass.bind(this);
    this.uploadImage = this.uploadImage.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.state = {
      uploadedImage: ""
    };
  }
  componentWillReceiveProps(nextProps) {
    if (nextProps.removePlace) {
      this.setState({ uploadedImage: "" });
    }
  }
  removeImage(imgType) {
    this.props.onRemove(imgType);
    this.setState({ uploadedImage: "" });
  }
  uploadImage(event, imgType) {
    if (event.target.files.length > 0) {
      const fileSize = event.target.files[0].size / 1000000;

      if (fileSize < 2) {
        if (event.target.files[0].type !== "") {
          const fileType = event.target.files[0].type
            ? event.target.files[0].type.split("/")[1].toLowerCase()
            : "";
          if (fileType !== "jpeg" && fileType !== "jpg" && fileType !== "png") {
            this.props.createHostFormSetError(imgType, "FILE_TYPE");
            return false;
          }
        } else {
          this.props.createHostFormSetError(imgType, "FILE_TYPE");
          return false;
        }
      } else {
        this.props.createHostFormSetError(imgType, "FILE_SIZE");
        return false;
      }
      this.props.createHostFormResetError(imgType);
      fileUpload(event.target.files)
        .then(url => {
          this.props.onUploadComplete(imgType, url);
          this.setState({ uploadedImage: url });
        })
        .catch(() => {
          console.log(this);
        });
    } else {
      this.props.onUploadComplete(imgType, "");
    }
  }
  getColClass() {
    const sizes = {
      "950": "col-sm-12",
      "970": "col-sm-12",
      "728": "col-sm-12",
      "300": "col-sm-6"
    };
    return sizes[this.props.width] ? sizes[this.props.width] : "col-sm-12";
  }

  render() {
    const { type, placeCode } = this.props;
    let imgType = type + "-" + placeCode;
    return (
      <div className={"mb-5 mt-4 " + this.getColClass()}>
        <div className="upload-image  d-flex align-items-center ">
          {this.state.uploadedImage ? (
            <div
              style={{ minHeight: this.props.height + "px" }}
              className="image-container col h-100  d-flex flex-column justify-content-center align-items-center"
            >
              <img
                src={this.state.uploadedImage}
                className="img-fluid"
                alt=""
              />
            </div>
          ) : (
            <>
              <input
                type="file"
                name="img[]"
                className="file"
                onChange={e =>
                  this.uploadImage(
                    e,
                    this.props.type + "-" + this.props.placeCode
                  )
                }
                ref={input => (this.inputFeatureImg = input)}
              />
              <div
                onClick={() => this.inputFeatureImg.click()}
                style={{ minHeight: this.props.height + "px" }}
                className={
                  "image-container col h-100 d-flex flex-column justify-content-center align-items-center" +
                  (this.props.createHostForm.fields[imgType].errorMsg ==
                    "FILE_TYPE" ||
                  this.props.createHostForm.fields[imgType].errorMsg ==
                    "FILE_SIZE"
                    ? " add-new-rewards-danger"
                    : "")
                }
              >
                <img src={camerasvg} className="img-fluid" alt="" />
                <p className="choose-photo mb-0 bold">
                  Upload {this.props.label}
                </p>
                <p className="mt-1 small text-center">
                  {`Size ${this.props.width}px width ${
                    this.props.height
                  }px height`}
                  <br />
                  <span
                    className={
                      this.props.createHostForm.fields[imgType].errorMsg ==
                      "FILE_SIZE"
                        ? " file-size-danger"
                        : ""
                    }
                  >
                    * Maximum size 2 MB
                  </span>
                  <br />{" "}
                  <span
                    className={
                      this.props.createHostForm.fields[imgType].errorMsg ==
                      "FILE_TYPE"
                        ? " file-size-danger"
                        : ""
                    }
                  >
                    * Supported file types : jpg, jpeg and png
                  </span>
                </p>
              </div>
            </>
          )}
          <div className="col-auto ">
            {this.state.uploadedImage ? (
              <div
                className="popup-dismiss"
                data-dismiss="modal"
                aria-label="Close"
                onClick={() =>
                  this.removeImage(this.props.type + "-" + this.props.placeCode)
                }
              >
                <img src={IconClose} alt="" />
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    );
  }
}
const defaultProps = {
  width: 200,
  height: 200,
  label: "label",
  placeCode: "RECTANGLE",
  type: "web",
  onUploadComplete: () => {},
  onRemove: () => {}
};

const mapDispatchToProps = {
  createHostFormSetError: createHostForm.setError,
  createHostFormResetError: createHostForm.resetError
};

const mapStateToProps = state => {
  return {
    createHostForm: state.forms.createHostForm
  };
};
BrandAdsComponent.defaultProps = defaultProps;
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BrandAdsComponent);
