import {
  PaginationListActionCreator,
  TListActionCreator,
  ListActionCreator
} from "./creators";
import { getAnalyzeData, getBanUserList } from "../services/analyze.service";
import {
  getUsersList,
  getBanReasons,
  userChallenge,
  getUserSubscriptionHistoryList
} from "../services/users.service";
import {
  AnalyzeList,
  BanUsersList,
  UsersList,
  UserChallengeList
} from "../redux-config/tlistActionMap";
import { BanReasonList, UserSubscriptionHistoryList } from "../redux-config/listActionMap";

export const analyzeListViewAll = TListActionCreator({
  ...AnalyzeList,
  service: getAnalyzeData,
  dataMapper: json => json.data.data.items,
  lastPageMapper: json => json.data.data.isLastPage,
  totalPageMapper: json => json.data.data.totalPage,
  totalCountMapper: json => json.data.data.totalCount,
  recordDisplayedMapper: json => json.data.data.recordDisplayed
});

export const banList = TListActionCreator({
  ...BanUsersList,
  service: getBanUserList,
  dataMapper: json => json.data.data.items,
  lastPageMapper: json => json.data.data.isLastPage,
  totalPageMapper: json => json.data.data.totalPage,
  totalCountMapper: json => json.data.data.totalCount,
  recordDisplayedMapper: json => json.data.data.recordDisplayed
});

export const usersList = PaginationListActionCreator({
  ...BanUsersList,
  service: getBanUserList,
  dataMapper: json => json.data.data.items,
  lastPageMapper: json => json.data.data.isLastPage
});

export const allUserList = TListActionCreator({
  ...UsersList,
  service: getUsersList,
  dataMapper: json => json.data.data.items,
  lastPageMapper: json => json.data.data.isLastPage,
  totalPageMapper: json => json.data.data.totalPage,
  totalCountMapper: json => json.data.data.totalCount,
  recordDisplayedMapper: json => json.data.data.recordDisplayed
});

export const userSubscriptionHistoryList = ListActionCreator({
  ...UserSubscriptionHistoryList,
  service: getUserSubscriptionHistoryList,
  dataMapper: json => json.data.data
});

export const banReasonList = ListActionCreator({
  ...BanReasonList,
  service: getBanReasons,
  dataMapper: json => json.data.data
});

export const userChallengeList = TListActionCreator({
  ...UserChallengeList,
  service: userChallenge,
  dataMapper: json => json.data.data.items,
  lastPageMapper: json => json.data.data.isLastPage,
  totalPageMapper: json => json.data.data.totalPage,
  totalCountMapper: json => json.data.data.totalCount,
  recordDisplayedMapper: json => json.data.data.recordDisplayed
});
