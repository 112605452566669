import {
  ListActionCreator,
  DetailActionCreator,
  TListActionCreator
} from "./creators";
import {
  getDashboardList,
  getDashboardAdvertisementDetail,
  getDashboardDisabledAdvertisements,
  getDashboardAdvertisementPublishService,
  getDashboardAdvertisementRevokeService
} from "../services/dashboard.service";
import {
  DashboardAdvertisementDetails,
  DashboardAdvertisementPublish,
  DashboardAdvertisementRevoke
} from "../redux-config/detailActionMap";
import { editDashboardAdvertisementForm } from "./forms";
import { DashboardList } from "../redux-config/plistActionMap";
import { dashboardDisabledAdvertisementList } from "../redux-config/listActionMap";

export const dashboardListViewAll = TListActionCreator({
  ...DashboardList,
  service: getDashboardList,
  dataMapper: json => json.data.data.items,
  lastPageMapper: json => json.data.data.isLastPage,
  totalPageMapper: json => json.data.data.totalPage,
  totalCountMapper: json => json.data.data.totalCount,
  recordDisplayedMapper: json => json.data.data.recordDisplayed
});

export const dashboardAdvertisementDetails = DetailActionCreator({
  ...DashboardAdvertisementDetails,
  service: getDashboardAdvertisementDetail,
  dataMapper: json => json.data.data,
  onResponse: (data, dispatch) => {
    //set default data into dashboard edit form
    let finalData = data.data.data;
    dispatch(
      editDashboardAdvertisementForm.updateField(
        "id",
        finalData.dashboardAdvertisementPlace.id ? finalData.id : ""
      )
    );

    dispatch(
      editDashboardAdvertisementForm.updateField(
        "dashboardAdvertisementPlaceId",
        finalData.dashboardAdvertisementPlace.id
          ? finalData.dashboardAdvertisementPlace.id
          : ""
      )
    );

    dispatch(
      editDashboardAdvertisementForm.updateField(
        "ownerBrandId",
        finalData.ownerBrand.id ? finalData.ownerBrand.id : ""
      )
    );

    dispatch(
      editDashboardAdvertisementForm.updateField(
        "startAt",
        finalData.startAt ? new Date(finalData.startAt) : ""
      )
    );

    dispatch(
      editDashboardAdvertisementForm.updateField(
        "endAt",
        finalData.endAt ? new Date(finalData.endAt) : ""
      )
    );

    dispatch(
      editDashboardAdvertisementForm.updateField(
        "imageUrl",
        finalData.imageUrl ? finalData.imageUrl : ""
      )
    );
  }
});

export const dashboardAdvertisementPublishDetails = DetailActionCreator({
  ...DashboardAdvertisementPublish,
  service: getDashboardAdvertisementPublishService,
  dataMapper: json => json.data.data,
  onResponse: (data, dispatch, getState) => {
    const {
      recordDisplayed,
      sort,
      search
    } = getState().dashboard.dashboardlist;
    let page = Math.ceil(recordDisplayed / 10);
    let sortBy = sort.field;
    let sortOrder = sort.orderBy;
    let searchText = search.brandName ? search.brandName : "";
    let searchByPlace = search.searchByPlace ? search.searchByPlace : "";
    let searchByType = search.type ? search.type : "";
    dispatch(
      dashboardListViewAll.fetchData({
        page: page,
        sortBy: sortBy,
        sortOrder: sortOrder,
        searchText: searchText,
        searchByPlace: searchByPlace,
        type: searchByType
      })
    );
  }
});

export const dashboardAdvertisementRevokeDetails = DetailActionCreator({
  ...DashboardAdvertisementRevoke,
  service: getDashboardAdvertisementRevokeService,
  dataMapper: json => json.data.data,
  onResponse: (data, dispatch, getState) => {
    const {
      recordDisplayed,
      sort,
      search
    } = getState().dashboard.dashboardlist;
    let page = Math.ceil(recordDisplayed / 10);
    let sortBy = sort.field;
    let sortOrder = sort.orderBy;
    let searchText = search.brandName ? search.brandName : "";
    let searchByPlace = search.searchByPlace ? search.searchByPlace : "";
    let searchByType = search.type ? search.type : "";
    dispatch(
      dashboardListViewAll.fetchData({
        page: page,
        sortBy: sortBy,
        sortOrder: sortOrder,
        searchText: searchText,
        searchByPlace: searchByPlace,
        type: searchByType
      })
    );
  }
});

export const dashboardDisabledAdvertisementsList = ListActionCreator({
  ...dashboardDisabledAdvertisementList,
  service: getDashboardDisabledAdvertisements,
  dataMapper: json => json.data.data
});
