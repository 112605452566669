import React, { Component } from "react";
import { connect } from "react-redux";
import { publishRaffleTranslation } from "../../../../actions/modals";
import { publishRaffleForm } from "../../../../actions/forms";
import "./PublishRaffleModal.scss";
import Modal from "../../Modal";
import camera from "../../../../assets/images/svg/camera.svg";
import AppInputComponent from "../../../../layouts/components/sidebars/AppInputComponent";
import { fileUpload } from "../../../../services/fileUpload.service";

class PublishRaffleModal extends Component {
  constructor(props) {
    super(props);
    this.hidePublishRaffle = this.hidePublishRaffle.bind(this);
    this.translatedValues = this.translatedValues.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.removeUpload = this.removeUpload.bind(this);
    this.state = {
      isLandScape: false,
      currentLang: this.props.defaultLang
    };
  }

  hidePublishRaffle() {
    this.props.publishRaffleTranslationClose();
    this.setState({ currentLang: this.props.defaultLang })
  }

  removeUpload(locale, field) {
    var translationData = this.props.publishRaffleForm.fields.raffleTranslation.value ? this.props.publishRaffleForm.fields.raffleTranslation.value : [];
    var existRecord = translationData.filter(e => e['languageId'] == locale.id)
    existRecord[0][field] = ''
    this.props.publishRaffleFormUpdateField('raffleTranslation', translationData)
  }

  uploadFile(event, locale, field) {
    if (event.target.files.length > 0) {
      const fileSize = event.target.files[0].size / 1000000;
      const fileType = event.target.files[0].type.split("/")[1].toLowerCase();

      if (fileType !== "jpeg" && fileType !== "jpg" && fileType !== "png") {
        this.props.publishRaffleFormSetError("itemUrl", "FILE_TYPE");
        return false;
      } else if (fileSize > 2) {
        this.props.publishRaffleFormSetError("itemUrl", "FILE_SIZE");
        return false;
      }
      this.props.publishRaffleFormResetError("itemUrl");
      fileUpload(event.target.files)
        .then(url => {
          this.translatedValues(locale, field, url);
          if (locale.id == this.props.defaultLang) {
            this.props.publishRaffleFormUpdateField("itemUrl", url);
          }

        })
        .catch(() => { });
    } else {
      this.props.publishRaffleFormUpdateField("itemUrl", "");
    }
  }
  translatedValues(locale, field, value) {
    var translationData = this.props.publishRaffleForm.fields.raffleTranslation.value ? this.props.publishRaffleForm.fields.raffleTranslation.value : [];
    var existRecord = translationData.filter(e => e['languageId'] == locale.id)
    if (existRecord.length > 0) {
      existRecord[0][field] = value
    } else {
      var translationObj = { languageId: locale.id };
      translationObj = { ...translationObj, [field]: value }
      translationData.push(translationObj);
    }
    this.props.publishRaffleFormUpdateField('raffleTranslation', translationData)
  }
  render() {
    let raffleTranslationData = this.props.publishRaffleForm.fields.raffleTranslation.value ? this.props.publishRaffleForm.fields.raffleTranslation.value : [];
    const { languagesList } = this.props;
    return (
      <>
        <Modal
          handleHide={this.hidePublishRaffle}
          isOpen={this.props.isPublishRaffleTranslationddd}
          disableOuterClick={false}
          modalClass="modal modal-translation"
          withFooter={true}
        >
          <div className="card-popup">
            <div className="row">
              <div className="col-sm-12 text-center mb-2 mb-sm-4">
                <h4 className="bold">Reward Item</h4>
              </div>
            </div>
            <section id="rootwizard">
              <section className="container-fluid tab-content-wrapper">
                <div className="col-md-12 col-lg-12  section-info">
                  <div className="row">
                    <div className="col-sm-12 mt-5 mt-lg-0">
                      <ul
                        className="nav nav-pills mb-4 d-flex  justify-content-center align-items-center mt-4"
                      >
                        {languagesList.map((language, index) => {
                          return (
                            <li
                              key={index}
                              className="nav-item col text-center"
                            >
                              <span
                                className={
                                  "a nav-link" +
                                  (this.state.currentLang == language.id ? " active show" : "")
                                }
                                id="pills-today-tab"
                                data-toggle="pill"
                                role="tab"
                                aria-controls="pills-today"
                                aria-selected="true"
                                onClick={() => this.setState({
                                  currentLang: language.id
                                })}
                              >
                                {language.locale}
                              </span>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-sm-12">
                      <div className="form-group with-lable w-100">
                        {languagesList.map((language, index) => {
                          let nameExistOrNot = raffleTranslationData.filter(e => e['languageId'] == language.id)
                          return (
                            this.state.currentLang == language.id ?
                              <AppInputComponent
                                key={index}
                                inputClass={
                                  language.id == this.props.defaultLang ? !this.props.publishRaffleForm.fields.itemUrl
                                    .error || (nameExistOrNot[0] && nameExistOrNot[0]['itemDescription'])
                                    ? "form-control effect-16"
                                    : "form-control effect-17" : "form-control effect-16"
                                }
                                divClass="input-group-wrapper mt-2 mb-5 create-tournament-wrapper"
                                labelText="Enter item name"
                                withLabel="true"
                                maxlength="50"
                                value={nameExistOrNot[0] && nameExistOrNot[0]['itemDescription'] ? nameExistOrNot[0]['itemDescription'] : ""}
                                onChange={event => {
                                  this.translatedValues(language, 'itemDescription', event.target.value);
                                  if (language.id == this.props.defaultLang) {
                                    this.props.publishRaffleFormResetError(
                                      "itemDescription"
                                    );
                                    this.props.publishRaffleFormUpdateField(
                                      "itemDescription",
                                      event.target.value
                                    )
                                  }
                                }}
                              />
                              : <></>
                          )
                        })}
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        {languagesList.map((language, index) => {
                          let imageExistOrNot = raffleTranslationData.filter(e => e['languageId'] == language.id)
                          return (
                            this.state.currentLang == language.id ?
                              (!imageExistOrNot[0] || !imageExistOrNot[0]['itemUrl'] ? (
                                <div className="col-md-12 upload-image d-flex" key={index}>
                                  <input
                                    type="file"
                                    accept="image/*"
                                    name="img[]"
                                    className="file"
                                    onChange={e => {
                                      this.uploadFile(e, language, 'itemUrl')
                                    }}
                                    ref={input => (this.inputElement = input)}
                                  />
                                  <div
                                    className="upload-button d-flex justify-content-center align-items-center"
                                    onClick={() => this.inputElement.click()}
                                  >
                                    <img
                                      src={camera}
                                      className="img-fluid"
                                      alt=""
                                    />
                                    <p
                                      className={
                                        language.id == this.props.defaultLang ? !this.props.publishRaffleForm.fields.itemUrl
                                          .error || (imageExistOrNot[0] && imageExistOrNot[0]['itemUrl'])
                                          ? "choose-photo mb-0 ml-2 effect-16"
                                          : "choose-photo mb-0 ml-2 effect-17" : "choose-photo mb-0 ml-2 effect-16"
                                      }
                                    >
                                      Upload Image
                                    </p>
                                  </div>
                                </div>
                              ) : (
                                  <div className="col-md-12 upload-image d-flex">
                                    <img
                                      src={
                                        imageExistOrNot[0]['itemUrl']
                                      }
                                      className="profile-icon profile-pic-80"
                                      alt=""
                                    />
                                    <div
                                      className="upload-button d-flex justify-content-center align-items-center"
                                      onClick={() => this.removeUpload(language, 'itemUrl')}
                                    >
                                      <a
                                        href="javascript:void(0)"
                                        className="btn btn-link text-primary"
                                      >
                                        Remove Image
                                    </a>
                                    </div>
                                  </div>
                                )
                              ) : <></>
                          )
                        })}
                      </div>
                    </div>

                  </div>
                </div>
              </section>
            </section>
          </div>
        </Modal>
      </>
    );
  }
}
const mapDispatchToProps = {
  publishRaffleTranslationClose: publishRaffleTranslation.close,

  publishRaffleFormSubmit: publishRaffleForm.submit,
  publishRaffleFormUpdateField: publishRaffleForm.updateField,
  publishRaffleFormReset: publishRaffleForm.reset,
  publishRaffleFormResetField: publishRaffleForm.resetField,
  publishRaffleFormSetError: publishRaffleForm.setError,
  publishRaffleFormSetSuccess: publishRaffleForm.setSuccess,
  publishRaffleFormResetError: publishRaffleForm.resetError,
  publishRaffleFormResetSuccess: publishRaffleForm.resetSuccess,
};

const mapStateToProps = state => {
  const { forms, modals, languages, user } = state;
  return {
    isPublishRaffleTranslationddd: modals.isPublishRaffleTranslation,
    publishRaffleForm: forms.publishRaffleForm,
    languagesList: languages.languagesList.items ? languages.languagesList.items : [],
    defaultLang: user.item.defaultLanguage ? user.item.defaultLanguage.id : 0
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PublishRaffleModal);
