import {
  ListActionCreator,
  PaginationListActionCreator,
  DetailActionCreator
} from "./creators";
import {
  getNewsList,
  getNewsCategories,
  onGetNewsDetails
} from "../services/news.service";
import { NewsList, NewsCategoriesList } from "../redux-config/listActionMap";
import { NewsListViewAll } from "../redux-config/plistActionMap";
import { NewsDetails } from "../redux-config/detailActionMap";
import { updateNewsForm } from "./forms";

export const newsList = ListActionCreator({
  ...NewsList,
  service: getNewsList,
  dataMapper: json => json.data.data.items
});
export const newsListViewAll = PaginationListActionCreator({
  ...NewsListViewAll,
  service: getNewsList,
  dataMapper: json => json.data.data.items,
  lastPageMapper: json => json.data.data.isLastPage
});

export const newsCategoriesList = ListActionCreator({
  ...NewsCategoriesList,
  service: getNewsCategories,
  dataMapper: json => json.data.data
});

export const newsDetails = DetailActionCreator({
  ...NewsDetails,
  service: onGetNewsDetails,
  dataMapper: json => {
    return json.data.data;
  },
  onResponse: (json, dispatch) => {
    const { data } = json.data;
    dispatch(updateNewsForm.updateField("title", data.title ? data.title : ""));
    dispatch(updateNewsForm.updateField("source", data.source ? data.source : ""));
    dispatch(updateNewsForm.updateField("envId", data.envId ? data.envId : ""));
    dispatch(updateNewsForm.updateField("envName", data.envName ? data.envName : ""));
    dispatch(
      updateNewsForm.updateField("content", data.content ? data.content : "")
    );
    dispatch(
      updateNewsForm.updateField("imageUrl", data.imageUrl ? data.imageUrl : "")
    );
    dispatch(
      updateNewsForm.updateField(
        "thumbImageUrl",
        data.thumbImageUrl ? data.thumbImageUrl : ""
      )
    );
    dispatch(
      updateNewsForm.updateField(
        "categoryId",
        data.categoryId ? data.categoryId : ""
      )
    );
    dispatch(
      updateNewsForm.updateField(
        "isActive",
        data.active ? true : false
      )
    );
dispatch(
  updateNewsForm.updateField(
    "categoryName",
    data.categoryName ? data.categoryName : ""
  )
);
  }
});
