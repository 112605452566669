import React, { Component } from "react";
import { connect } from "react-redux";
import CardImage from "../../assets/images/card-sm.png";
import Avatar from "../../assets/images/avatar-demo.png";
import { getNoOfDay } from "../../utils/date";
import { refactorTitle } from "./../../utils/misc";
import "./TournamentCardComponent.scss";
import { gameImages } from "../../utils/gameImages";
import adv10 from "../../assets/images/adv/adv-10.png";
import addIcon from "../../assets/images/svg/add.svg";
import { deleteTournamentForm } from "../../actions/forms";

class TournamentCardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
    this.advertisementImg = this.advertisementImg.bind(this);
    this.adsDefualtImg = this.adsDefualtImg.bind(this);
  }
  addDefaultSrc(ev) {
    ev.target.src = CardImage;
  }
  addDefaultSrcBrand(ev) {
    ev.target.src = Avatar;
  }
  adsDefualtImg() {
    return <img src={adv10} alt="Advertisement" className="w-100 disabledAd" />;
  }
  advertisementImg() {
    return (
      <img
        src={this.props.advertisementUrl}
        alt="Advertisement"
        className="w-100"
        onError={ev => (ev.target.src = CardImage)}
      />
    );
  }
  toggleDeleteMenu() {
    this.setState(prevState => ({
      visible: !prevState.visible
    }));
  }
  render() {
    const {
      brandName,
      name,
      timeOfStart,
      timeOfEnd,
      casualGameId,
      showDeleteButton
    } = this.props;
    const { visible } = this.state;
    return (
      <>
        {this.props.id === "add" ? (
          <a href="/create-challenge">
            <div className="card popular-arcadex admin justify-content-center align-items-center">
              <img src={addIcon} className="w-25 " alt="" />
            </div>
          </a>
        ) : this.props.adsType === "ad" ? (
          <div
            className="card popular-arcadex d-flex justify-content-center align-items-center"
            style={{ width: "300px" }}
          >
            {this.props.challengeType == "Live" ? (
              this.props.advertisementPlace == 1 ||
              this.props.advertisementPlace == 4 ? (
                this.advertisementImg()
              ) : (
                this.adsDefualtImg()
              )
            ) : this.props.challengeType == "Upcoming" ? (
              this.props.advertisementPlace == 3 ||
              this.props.advertisementPlace == 5 ? (
                this.advertisementImg()
              ) : (
                this.adsDefualtImg()
              )
            ) : this.props.challengeType == "MoreBy" ? (
              this.props.advertisementPlace == 4 ||
              this.props.advertisementPlace == 6 ? (
                this.advertisementImg()
              ) : (
                this.adsDefualtImg()
              )
            ) : (
              <></>
            )}
          </div>
        ) : (
          <>
            <a
              href={
                this.props.isLoggedIn
                  ? "/update-challenge/" + this.props.id
                  : ""
              }
            >
              <div
                className={
                  this.props.cardClass + (visible ? " car-selected" : "")
                }
              >
                <div className="card-header light">
                  {getNoOfDay(timeOfStart, timeOfEnd)}
                  {showDeleteButton && (
                    <span
                      className={"close-icon " + (visible ? " d-block" : "")}
                      onClick={e => {
                        e.preventDefault();
                        this.toggleDeleteMenu();
                      }}
                    >
                      &times;
                    </span>
                  )}
                </div>
                <img
                  className="card-img"
                  src={this.props.casualFreeGameImageUrl}
                  alt=""
                  onError={this.addDefaultSrc}
                />
                <div className="card-body">
                  <span className="brand-img-parent">
                    <figure>
                      <img
                        src={this.props.brandAvatar}
                        alt="Popular ArcadeX"
                        className={this.props.brandAvatarClass}
                        onError={this.addDefaultSrcBrand}
                      />
                      <figcaption className="semi-bold mt-2">
                        {brandName}
                      </figcaption>
                    </figure>
                  </span>
                  <h4 className="bold">{refactorTitle(name, 30)}</h4>
                </div>
              </div>
            </a>
            {showDeleteButton && visible && (
              <div className="delete-confirmation-wrapper">
                <p className="mb-1 bold ml-2 message-text">Are you sure ?</p>
                <div className="button-wrapper">
                  <button
                    onClick={() => {
                      this.props.deleteTournamentFormUpdateField(
                        "Id",
                        this.props.id
                      );
                      this.props.deleteTournamentFormSubmit({
                        Id: this.props.id
                      });
                      this.toggleDeleteMenu();
                    }}
                    type="button"
                    className="btn btn-pink light py-3 btn-small mt-2 btn-left custom-btn-left"
                  >
                    Yes
                  </button>
                  <button
                    onClick={() => {
                      this.toggleDeleteMenu();
                    }}
                    type="button"
                    className=" btn btn-pink light py-3 btn-small mt-2 ml-4 btn-right custom-btn-right"
                  >
                    No
                  </button>
                </div>
              </div>
            )}
          </>
        )}
      </>
    );
  }
}

const defaultProps = {
  id: 1,
  brandName: "MTN",
  brandAvatar: Avatar,
  name: "test",
  timeOfStart: "2018-10-16 18:30:00+00",
  timeOfEnd: "2018-10-23 06:42:00+00",
  cardClass: "card",
  brandAvatarClass: "profile-pic-72",
  type: "card",
  casualGameId: 15,
  ifCreate: false,
  advertisementPlace: "",
  advertisementUrl: ""
};
TournamentCardComponent.defaultProps = defaultProps;

const mapStateToProps = state => {
  return {
    isLoggedIn: state.user.isLoggedIn
  };
};
const mapDispatchToProps = {
  deleteTournamentFormUpdateField: deleteTournamentForm.updateField,
  deleteTournamentFormSubmit: deleteTournamentForm.submit
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TournamentCardComponent);
