import React, { Component } from "react";
import { connect } from "react-redux";
import { createChallengeRewardsTranslation } from "../../../../actions/modals";
import { createChallengeForm } from "../../../../actions/forms";
import "./CreateChallengeRewardsModal.scss";
import Modal from "../../Modal";
import AppInputComponent from "../../../../layouts/components/sidebars/AppInputComponent";
import camera from "../../../../assets/images/svg/camera.svg";
import { fileUpload } from "../../../../services/fileUpload.service";

class CreateChallengeRewardsModal extends Component {
  constructor(props) {
    super(props);
    this.hideCreateChallengeRewards = this.hideCreateChallengeRewards.bind(this);
    this.translatedValues = this.translatedValues.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.removeUpload = this.removeUpload.bind(this);
    this.state = {
      isLandScape: false,
      currentLang: this.props.defaultLang
    };
  }
  hideCreateChallengeRewards() {
    this.setState({ currentLang: this.props.defaultLang })
    this.props.createChallengeRewardsTranslationClose();
  }
  removeUpload(locale, field, i) {
    let rewardData = this.props.createChallengeForm.fields.rewards.value;
    rewardData[i].rewardsimg = "";
    this.props.createChallengeFormUpdateField("rewards", rewardData);

    var translationData = this.props.publishRaffleForm.fields.raffleTranslation.value ? this.props.publishRaffleForm.fields.raffleTranslation.value : [];
    var existRecord = translationData.filter(e => e['languageId'] == locale.id)
    existRecord[0][field] = ''
    this.props.publishRaffleFormUpdateField('raffleTranslation', translationData)
  }

  uploadFile(event, i, locale, field) {
    let rewardData = this.props.createChallengeForm.fields.rewards.value;

    if (event.target.files.length > 0) {
      const fileSize = event.target.files[0].size / 1000000;
      if (fileSize < 2) {
        if (event.target.files[0].type !== "") {
          const fileType = event.target.files[0].type
            .split("/")[1]
            .toLowerCase();
          if (fileType !== "jpeg" && fileType !== "jpg" && fileType !== "png") {
            this.props.createChallengeFormSetError("rewards", "NOT_EMPTY");
            this.props.createChallengeFormResetError("rewards");
            return false;
          }
        } else {
          return false;
        }
      } else {
        this.props.createChallengeFormSetError("rewards", "NOT_EMPTY");
        this.props.createChallengeFormResetError("rewards");
        return false;
      }

      this.props.createChallengeFormResetError("rewards");
      fileUpload(event.target.files)
        .then(url => {
          this.translatedValues(locale, field, url);
          if (locale.id == this.props.defaultLang.id) {
            rewardData[i].rewardsimg = url;
            this.props.createChallengeFormUpdateField("rewards", rewardData);
          }
        })
        .catch(() => {
          console.log(this);
        });
    } else {
      this.props.onUploadComplete("rewards", "");
    }
  }

  translatedValues(locale, field, value, isImgRemove) {
    let rewardObj;
    var currentRewardIndex = this.props.createChallengeForm.fields.activeRewardIndex.value;
    var currentArr = this.props.createChallengeForm.fields.challengeRewardsTranslation.value ? this.props.createChallengeForm.fields.challengeRewardsTranslation.value : [];
    var existRecord = currentArr.filter(e => e['currentIndex'] == currentRewardIndex).map(e => e)

    if (isImgRemove) {
      let rewardData = this.props.createChallengeForm.fields.rewards.value;
      rewardData[currentRewardIndex].rewardsimg = "";
      this.props.createChallengeFormUpdateField("rewards", rewardData);
    }


    if (existRecord.length > 0 && existRecord[0][locale.locale]) {
      if (currentArr[currentRewardIndex][locale.locale]) {
        currentArr[currentRewardIndex][locale.locale] = { ...currentArr[currentRewardIndex][locale.locale], [field]: value }
      } else {
        currentArr[currentRewardIndex] = { ...currentArr[currentRewardIndex], [locale.locale]: { languageId: locale.id, [field]: value } }
      }
      this.props.createChallengeFormUpdateField('challengeRewardsTranslation', currentArr);
    } else {
      if (existRecord[0]) {
        rewardObj = currentArr[currentRewardIndex]
      } else {
        rewardObj = {}
      }
      rewardObj.currentIndex = currentRewardIndex;
      if (rewardObj[locale.locale]) {
        rewardObj[locale.locale] = { ...rewardObj[locale.locale], [field]: value }
      } else {
        rewardObj = { ...rewardObj, [locale.locale]: { languageId: locale.id, [field]: value } }
      }
      currentArr[currentRewardIndex] = rewardObj
      this.props.createChallengeFormUpdateField('challengeRewardsTranslation', currentArr);
    }
  }

  render() {
    let activeReward = this.props.createChallengeForm.fields.activeRewardIndex.value ? this.props.createChallengeForm.fields.activeRewardIndex.value : 0
    let challengeRewards = this.props.createChallengeForm.fields.challengeRewardsTranslation.value ? this.props.createChallengeForm.fields.challengeRewardsTranslation.value : [];
    let challengeName = challengeRewards.length > 0 && challengeRewards[activeReward] && challengeRewards[activeReward][this.state.currentLang.locale] && challengeRewards[activeReward][this.state.currentLang.locale].itemDescription ? challengeRewards[activeReward][this.state.currentLang.locale].itemDescription : "";
    let rewardsExistOrNot = challengeRewards.filter(e => e['currentIndex'] == activeReward)
    const { languagesList } = this.props;
    return (
      <>
        <Modal
          handleHide={this.hideCreateChallengeRewards}
          isOpen={this.props.isCreateChallengeRewardsTranslation}
          disableOuterClick={false}
          modalClass="modal modal-translation"
          withFooter={true}
        >
          <div className="card-popup">
            <div className="row">
              <div className="col-sm-12 text-center mb-2 mb-sm-4">
                <h4 className="bold">Challenge Rewards</h4>
              </div>
            </div>
            <section id="rootwizard">
              <section className="container-fluid tab-content-wrapper">
                <div className="col-md-12 col-lg-12  section-info">
                  <div className="row">
                    <div className="col-sm-12">
                      <ul
                        className="nav nav-pills mb-4 d-flex  justify-content-center align-items-center mt-4"
                      >
                        {languagesList.map((language, index) => {
                          return (
                            <li
                              key={index}
                              className="nav-item col text-center"
                            >
                              <span
                                className={
                                  "a nav-link" +
                                  (this.state.currentLang.id == language.id ? " active show " + (this.props.createChallengeForm.fields.rewards
                                    .error && (!rewardsExistOrNot[0][this.props.defaultLang.locale]['itemUrl'] || !rewardsExistOrNot[0][this.props.defaultLang.locale]['itemDescription']) ? "nav-danger" : "") : "")
                                }
                                onClick={() => this.setState({
                                  currentLang: language
                                })}
                              >
                                {language.locale}
                              </span>
                            </li>
                          )
                        })}
                      </ul>
                    </div>
                  </div>

                  <div className="row ">
                    <div className="col-sm-12">
                      <div className="form-group with-lable w-100">
                        <div
                          className={"tab-pane fade show active"}
                        >
                          <AppInputComponent
                            inputClass={
                              this.props.createChallengeForm.fields.rewards.error && !rewardsExistOrNot[0][this.props.defaultLang.locale]['itemDescription'] ? "form-control effect-17" : "form-control effect-16"
                            }
                            divClass="input-group-wrapper mt-2 mb-5 create-tournament-wrapper"
                            labelText={"Item Name"}
                            withLabel="true"
                            maxlength="50"
                            value={challengeName}
                            onChange={event => {
                              this.translatedValues(this.state.currentLang, 'itemDescription', event.target.value);
                              if (this.state.currentLang.id == this.props.defaultLang.id) {
                                let rewards = this.props.createChallengeForm.fields.rewards.value;
                                rewards[activeReward].items = event.target.value
                                this.props.createChallengeFormUpdateField(
                                  "rewards",
                                  rewards
                                )
                              }
                            }}
                          />
                        </div>



                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-12">
                        {(rewardsExistOrNot.length > 0 && (!rewardsExistOrNot[0][this.state.currentLang.locale] || !rewardsExistOrNot[0][this.state.currentLang.locale]['itemUrl']) ? (
                          <div className="col-md-12 col-lg-auto upload-image d-flex ">
                            <input
                              type="file"
                              accept="image/*"
                              name={"img[]"}
                              className="file"
                              onChange={e => {
                                this.uploadFile(e, activeReward, this.state.currentLang, 'itemUrl')
                              }
                              }
                              ref={input => (this.inputElement = input)}
                            />

                            <div
                              className="upload-button d-flex justify-content-center align-items-center"
                              onClick={() => this.inputElement.click()}
                            >
                              <img src={camera} className="img-fluid" alt="" />
                              <p
                                className={
                                  this.props.createChallengeForm.fields.rewards.error && !rewardsExistOrNot[0][this.props.defaultLang.locale]['itemUrl']
                                    ? "choose-photo mb-0 ml-2 effect-17"
                                    : "choose-photo mb-0 ml-2 effect-16"
                                }
                              >
                                Upload Image
                              </p>
                            </div>
                          </div>
                        ) : (
                            <div className="col-md-12 upload-image d-flex">
                              <img
                                src={
                                  rewardsExistOrNot.length > 0 && rewardsExistOrNot[0][this.state.currentLang.locale]['itemUrl'] ? rewardsExistOrNot[0][this.state.currentLang.locale]['itemUrl'] : ""
                                }
                                className="profile-icon profile-pic-80"
                                alt=""
                              />
                              <div
                                className="upload-button d-flex justify-content-center align-items-center"
                                onClick={() => this.translatedValues(this.state.currentLang, 'itemUrl', '', this.state.currentLang.id == this.props.defaultLang.id ? 1 : 0)}
                              >
                                <a
                                  href="javascript:void(0)"
                                  className="btn btn-link text-primary"
                                >
                                  Remove Image
                                    </a>
                              </div>
                            </div>
                          )
                        )
                        }

                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </section>
          </div>
        </Modal>
      </>
    );
  }
}
const mapDispatchToProps = {
  createChallengeRewardsTranslationClose: createChallengeRewardsTranslation.close,

  createChallengeFormSubmit: createChallengeForm.submit,
  createChallengeFormUpdateField: createChallengeForm.updateField,
  createChallengeFormReset: createChallengeForm.reset,
  createChallengeFormResetField: createChallengeForm.resetField,
  createChallengeFormSetError: createChallengeForm.setError,
  createChallengeFormSetSuccess: createChallengeForm.setSuccess,
  createChallengeFormResetError: createChallengeForm.resetError,
  createChallengeFormResetSuccess: createChallengeForm.resetSuccess,
};

const mapStateToProps = state => {
  const { forms, modals, languages, user } = state;
  return {
    isCreateChallengeRewardsTranslation: modals.isCreateChallengeRewardsTranslation,
    createChallengeForm: forms.createChallengeForm,
    languagesList: languages.languagesList.items ? languages.languagesList.items : [],
    defaultLang: user.item.defaultLanguage ? user.item.defaultLanguage : {}
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateChallengeRewardsModal);
