
import { axios } from "../utils/axios";
import config from "./config";
import { API_SERVER_URL } from "./config/apiEndpoints";

export const getAnalyzeData = postParams => {
  return axios.request({
    url: API_SERVER_URL + config.getAnalyze,
    method: "post",
    headers: {
      "content-type": "application/json"
    },
    data: postParams
  });
};

export const getBanUserList = postParams => {
  return axios.request({
    url: API_SERVER_URL + config.getBanUser,
    method: "post",
    headers: {
      "content-type": "application/json"
    },
    data: postParams
  });
};