import { axios } from "../utils/axios";
import config from "./config";
import { API_SERVER_URL } from "./config/apiEndpoints";

export const getBrandDetails = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.brandDetails,

      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getHostList = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.getHostList,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const createHost = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.createHost,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const onHostUpdate = (postParams, id) => {
  return axios
    .request({
      url: API_SERVER_URL + config.updateHost + id,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const onGetHostDetails = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.getHostDetails + postParams,
      method: "get",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getBrandCategories = () => {
  return axios
    .request({
      url: API_SERVER_URL + config.getBrandData,
      method: "get",
      headers: {
        "content-type": "application/json"
      }
      // data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getBrandAdvertisementsAvailable = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.brandAdvertisementsAvailable,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const updateBrandAdvertisementsAvailable = (postParams, id) => {
  return axios
    .request({
      url: API_SERVER_URL + config.brandAdvertisementsUpdate + id,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const onGetBrandAdvertisementsDetails = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.brandAdvertisementsDetails + postParams,
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const onPublishBrand = postParams => {
  return axios
    .request({
      url:
        API_SERVER_URL +
        config.publishBrandAds.replace("##id##", postParams ? postParams : 0),
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};
export const onRevokeBrandAds = postParams => {
  return axios
    .request({
      url:
        API_SERVER_URL +
        config.revokeBrandAds.replace("##id##", postParams ? postParams : 0),
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};
export const getPlatformbyBrandList = postParams => {
  return axios
    .request({
      url:
        API_SERVER_URL +
        config.platformByBrandList.replace(
          "##id##",
          postParams ? postParams : 0
        ),
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const onDeleteHost = postParams => {
  return axios
    .request({
      url:
        API_SERVER_URL +
        config.deleteHost.replace("##id##", postParams ? postParams.Id : 0),
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const onResetLeaderboard = postParams => {
  return axios
    .request({
      url:
        API_SERVER_URL +
        config.resetBrandLeaderboard.replace("##id##", postParams ? postParams : 0),
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};