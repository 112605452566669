import {
  invalidate,
  request,
  shouldFetch,
  receiveForPaginationList,
  fetchDataForPaginationList
} from "./functions";
import { incrementFetch } from "../loaders";

const invalidateAll = (params, prefix) => ({
  type: prefix + "_INVALIDATE_ALL_" + params.code
});

export default params => ({
  invalidate: () => invalidate(params, "PLIST"),
  invalidateAll: () => invalidateAll(params, "PLIST"),
  request: () => request(params, "PLIST"),
  receive: (data, isLastPage = false) =>
    receiveForPaginationList(params, data, (isLastPage = false), "PLIST"),
  shouldFetch: state => shouldFetch(state, params),
  fetchIfNeeded: (...extraArgs) => {
    return (dispatch, getState) => {
      if (shouldFetch(getState(), params)) {
        dispatch(request(params, "PLIST"));
        dispatch(incrementFetch());
        return dispatch(fetchDataForPaginationList(params, ...extraArgs));
      }
    };
  },
  fetchData: postParams => fetchDataForPaginationList(params, postParams)
});
