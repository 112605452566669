import React, { Component } from "react";
import "swiper/dist/css/swiper.css";
import "./RafflesComponent.scss";
import RafflesCardContainer from "./RafflesCardContainer";
import addIcon from "../../assets/images/svg/add.svg";
import navArrowRightBlue from "../../assets/images/svg/nav-arrow-right-blue.svg";

class RafflesComponent extends Component {
  componentDidMount() {
    this.props.invalidateRaffles();
    this.props.fetchRafflesIfNeeded({
      page: 1,
      languageId: this.props.defaultLang
    });
  }
  render() {
    const { rafflesList } = this.props;
    return rafflesList.isFetching ? (
      <></>
    ) : (
        <>
          <section className="[ container-fluid ] live-tournament admin-panel-raffle ">
            <div className="padder-main">
            <div className="row">
              <div className="container">
                <div className="row d-flex align-items-center mb-30 ">
                  <div className="col-8 d-flex justify-content-start">
                    <h2 className="bold">Raffles</h2>
                  </div>
                  {rafflesList.items && rafflesList.items.length > 0 ? (
                    <div className="col text-right d-flex justify-content-end">
                      <a
                        href="/raffles/view-all"
                        className="view-all d-flex align-items-center"
                      >
                        View All
                        <span>
                          <img src={navArrowRightBlue} alt="" />
                        </span>
                      </a>
                    </div>
                  ) : (
                      ""
                    )}
                </div>
              </div>
            </div>
              <div className="row livetournament mt-6">
                {rafflesList.items && rafflesList.items.length > 0 ? (
                  <RafflesCardContainer
                    showCreateRaffle={true}
                    list={rafflesList.items.map((raffle, index) => {
                      return {
                        ...raffle,
                        cardClass: "card popular-arcadex",
                        cardHeaderClass: "card-header light"
                      };
                    })}
                  />
                ) : (
                    <a href="/create-raffle" className="text-center">
                      <div className="card enter-raffles admin-raffle defualt-create-card">
                        <div className="card-body">
                          <div className="card-img d-flex justify-content-center align-items-center">
                            <img src={addIcon} className="w-25" alt="Dunkin" />
                          </div>
                        </div>
                        <div className="card-footer text-center">
                          <div className="raffle-timer">
                            <div className="spacer" />
                          </div>
                        </div>
                      </div>
                    </a>
                  )}
              </div>
            </div>
          </section>
        </>
      );
  }
}
export default RafflesComponent;
