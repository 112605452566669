import React, { Component } from "react";
import "swiper/dist/css/swiper.css";
import TournamentCardContainer from "./TournamentCardContainer";
import navArrowRightBlue from "../../assets/images/svg/nav-arrow-right-blue.svg";
import addIcon from "../../assets/images/svg/add.svg";

class TournamentsComponent extends Component {
  componentDidMount() {
    this.props.invalidateTournaments();
    this.props.fetchTournamentsIfNeeded({
      page: 1,
      languageId: this.props.defaultLang
    });
  }

  render() {
    const { tournamentsList } = this.props;
    return (
      <>
        <section className="[ container-fluid ] live-tournament ">
          <div className="padder-main">
            <div className="row">
              <div className="container">
                <div className="row d-flex align-items-center mb-30 ">
                  <div className="col-8 d-flex justify-content-start">
                    <h2 className="bold">Tournaments</h2>
                  </div>
                  {tournamentsList.items && tournamentsList.items.length > 0 ? (
                    <div className="col text-right d-flex justify-content-end">
                      <a
                        href="/tournament/view-all"
                        className="view-all d-flex align-items-center"
                      >
                        View All
                        <span>
                          <img src={navArrowRightBlue} alt="" />
                        </span>
                      </a>
                    </div>
                  ) : (
                      ""
                    )}
                </div>
              </div>
            </div>
            <div className="row livetournament mt-6">
              {tournamentsList.items.length > 0 ? (
                <TournamentCardContainer
                  list={tournamentsList.items.map((tournaments, index) => {
                    return {
                      ...tournaments,
                      cardClass: "card popular-arcadex",
                      cardHeaderClass: "card-header light"
                    };
                  })}
                  ifCreate={true}
                  showAd={false}
                  showDeleteButton={true}
                />
              ) : (
                  <a href="/create-challenge " className="defualt-create-card">
                    <div className="card popular-arcadex admin justify-content-center align-items-center">
                      <img src={addIcon} className="w-25 " alt="" />
                    </div>
                  </a>
                )}
            </div>
          </div>
        </section>
      </>
    );
  }
}
export default TournamentsComponent;
