import { ModalActionCreator, ModalCloseAll } from "./creators";
import {
  Login,
  ForgotPassword,
  ResetPassword,
  ChangePassword,
  RafflesRepeat,
  CreateChallengeRewardsTranslation,
  PublishRaffleTranslation,
  ResetLeaderboard
} from "../redux-config/modalActionMap";

export const login = ModalActionCreator(Login);
export const forgotPassword = ModalActionCreator(ForgotPassword);
export const resetPassword = ModalActionCreator(ResetPassword);
export const changePassword = ModalActionCreator(ChangePassword);
export const rafflesRepeat = ModalActionCreator(RafflesRepeat);
export const createChallengeRewardsTranslation = ModalActionCreator(CreateChallengeRewardsTranslation);
export const publishRaffleTranslation = ModalActionCreator(PublishRaffleTranslation);
export const resetLeaderboard = ModalActionCreator(ResetLeaderboard);

export const modalCloseAll = ModalCloseAll;