import React, { Component } from "react";
import { connect } from "react-redux";
// import { initialiseSocket } from "../actions/socket";

export default ComposedComponent => {
  class SocketHoc extends Component {
    render() {
      return <ComposedComponent {...this.props} />;
    }
  }
  const mapDispatchToProps = {
    // initialiseSocket
  };

  const mapStateToProps = state => {
    return {
      userDetail: state.user.item
    };
  };

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(SocketHoc);
};
