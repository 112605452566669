import React, { Component } from "react";
import PropTypes from "prop-types";
import moment from "moment";

const COUNTDOWN_NOT_STARTED = 1;
const COUNTDOWN_STARTED = 2;
const COUNTDOWN_FINISHED = 3;

export default class Countdown extends Component {
  constructor(props) {
    super(props);
    this.state = {
      remainingTime: 0,
      status: COUNTDOWN_NOT_STARTED,
      intervalId: null
    };

    this.tick = this.tick.bind(this);
  }

  componentDidMount = () => {
    const remaining = this.calculateRemainingTime();

    if (remaining < 0) return;

    const timer = setInterval(() => {
      this.tick();
    }, this.props.interval);
    this.setState({
      status: COUNTDOWN_STARTED,
      intervalId: timer
    });
  };

  componentWillUnmount = () => {
    //clearInterval(this.state.intervalId);
  };

  calculateRemainingTime = () => {
    return -1 * moment().diff(this.props.targetDate);
  };

  addLeadingZero = value => {
    if (value < 10) {
      return "0" + value.toString();
    }
    return value;
  };

  tick = () => {
    const remainingTime = this.calculateRemainingTime();
    let allminute = -1 * moment().diff(this.props.targetDate, "minutes");
    if (allminute < 1) 
    {
      if (this.refs.countdownRemainingTime) {
        this.setState({
          remainingTime: 0,
          status: COUNTDOWN_FINISHED
        });
      }
      if (this.props.action !== false) this.props.action();
    }
    else 
    {
        this.setState({
          remainingTime: remainingTime,
          status: remainingTime <= 0 ? COUNTDOWN_FINISHED : COUNTDOWN_STARTED
        });
      
      if (this.state.remainingTime <= 0) {
        clearInterval(this.state.intervalId);
      }

    }
  };

  renderRemainingTime = () => {
    let html = [];
    let { format, leadingZero } = this.props;
    let { remainingTime } = this.state;

    if (format.day) {
      let daystext = "Day";
      let days = Math.floor(
        moment.duration(remainingTime) / (24 * 60 * 60 * 1000)
      );
      if (leadingZero) {
        days = this.addLeadingZero(days);
      }
      if (days > 1) {
        daystext = "Days";
      }
      html.push(
        <span className="react-cntdwn-day" key="day">
          {days}
          <span>{daystext}, </span>
        </span>
      );
    }

    if (format.hour) {
      let hourstext = "Hr";
      let hours = "";
      if (this.props.totalHours) {
        hours = Math.round(moment.duration(remainingTime).asHours());
      } else {
        hours = moment.duration(remainingTime).get("hours");
      }

      if (leadingZero) {
        hours = this.addLeadingZero(hours);
      }
      if (hours > 1) {
        hourstext = "Hrs";
      }
      html.push(
        <span className="react-cntdwn-hour" key="hour">
          {hours}
          <span>{hourstext}, </span>
        </span>
      );
    }

    if (format.minute) {
      let minutestext = "Min";
      let minutes = moment.duration(remainingTime).get("minutes");
      if (leadingZero) {
        minutes = this.addLeadingZero(minutes);
      }
      html.push(
        <span className="react-cntdwn-minute" key="minute">
          {minutes}
          <span>{minutestext} </span>
        </span>
      );
    }
    // if (format.second) {
    //   let minutestext = "Sec";
    //   let seconds = moment.duration(remainingTime).get("seconds");
    //   html.push(
    //     <span className="react-cntdwn-second" key="second">
    //       {seconds}
    //       <span>{minutestext}</span>
    //     </span>
    //   );
    // }

    return html;
  };

  render = () => {
    if (this.state.status === COUNTDOWN_NOT_STARTED) {
      return <span />;
    }
    if (this.state.status === COUNTDOWN_FINISHED && this.props.showExpired) {
      return <span>Expired</span>;
    }
    return (
      <div className="countDown-timer">
        <span>
          {this.props.challengeStatus === "2" ? "Start:" : "Ends:"}
        </span>
        <span className="react-cntdwn-timer" ref="countdownRemainingTime">
          {this.renderRemainingTime()}
        </span>
      </div>
    );
  };
}

Countdown.propTypes = {
  interval: PropTypes.number,
  startDelay: PropTypes.number,
  onFinished: PropTypes.func,
  format: PropTypes.object,
  timeSeparator: PropTypes.string,
  leadingZero: PropTypes.bool,
  style: PropTypes.object,
  visible: PropTypes.bool
};

Countdown.defaultProps = {
  interval: 1000,
  style: {},
  startDelay: 0,
  format: {
    hour: "HH",
    minute: "MM",
    second: "SS"
  },
  timeSeparator: " ",
  leadingZero: false,
  visible: true,
  totalHours: false
};
