import React, { Component } from "react";

class Pagination extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inputSpage: 1,
      inputpage: 1,
      searchpage: 1,
      page: 1,
      displayPages: 5,
      startPage: 1,
      endPage: 5
    };
  }

  fetchRecords(page) {
    if (this.props.searchSort) {
      this.setState({
        searchpage: page ? page : 1,
        inputSpage: page
      });
    } else {
      this.setState({
        page: page ? page : 1,
        inputpage: page
      });
    }

    let param = {
      ...this.props.param,
      page: page ? page : 1
    };

    this.props.invalidateAllAction();
    this.props.fetchListAllAction(param);
  }

  componentWillReceiveProps(nextProps) {
    let searchValue = this.props.resetSearchPage;
    let nextSearchValue = nextProps.resetSearchPage;

    if (
      nextProps.sortBy != this.props.sortBy ||
      (nextProps.sort == "desc" && this.props.sort == "asc") ||
      (nextProps.sort == "" && this.props.sort == "desc") ||
      (nextProps.sort == "asc" && this.props.sort == "")
    ) {
      this.setState({
        page: 1,
        searchpage: 1,
        inputSpage: 1,
        inputpage: 1
      });
    }

    searchValue.map((field, index) => {
      if (nextSearchValue[index] != field) {
        this.setState({
          searchpage: 1,
          inputSpage: 1
        });
      }
    });

    if (nextProps.searchSort) {
      this.setState({
        page: 1,
        inputpage: 1
      });
    } else {
      this.setState({
        searchpage: 1,
        inputSpage: 1
      });
    }
    if (nextProps.recordDisplayed % 10 == 0 && (this.props.recordDisplayed - nextProps.recordDisplayed) == 1) {
      this.setState({
        inputSpage: this.state.inputSpage - 1 === 0 ? 1 : this.state.inputSpage - 1,
        inputpage: this.state.inputpage - 1 === 0 ? 1 : this.state.inputpage - 1,
        page: this.state.page - 1 === 0 ? 1 : this.state.page - 1,
        searchpage: this.state.searchpage - 1 === 0 ? 1 : this.state.searchpage - 1
      });

    }
  }

  render() {
    let page = this.state.page;
    let searchpage = this.state.searchpage;
    const { totalPage, totalCount, recordDisplayed, isLastPage } = this.props;
    const totalPageTab = [];
    const perPageRecord = 5;
    let lastPageCount = totalPage % perPageRecord;
    let lastPageRecord = totalPage - lastPageCount;
    let abc;
    let seachingpage = this.props.searchSort;
    if (!seachingpage) {
      abc =
        totalPage <= this.state.endPage
          ? totalPage
          : lastPageRecord < page && totalPage >= page
          ? lastPageCount
          : this.state.endPage;
    } else {
      abc =
        totalPage <= this.state.endPage
          ? totalPage
          : lastPageRecord < searchpage && totalPage >= searchpage
          ? lastPageCount
          : this.state.endPage;
    }
    if (totalPage > 1) {
      for (let i = this.state.startPage; i <= abc; i++) {
        let lastFrag = seachingpage
          ? searchpage % this.state.displayPages
          : page % this.state.displayPages;
        let isFragLast = lastFrag === 0;
        let index;
        if (seachingpage) {
          index = isFragLast
            ? (Math.floor(searchpage / this.state.displayPages) - 1) *
                this.state.displayPages +
              i
            : Math.floor(searchpage / this.state.displayPages) *
                this.state.displayPages +
              i;
        } else {
          index = isFragLast
            ? (Math.floor(page / this.state.displayPages) - 1) *
                this.state.displayPages +
              i
            : Math.floor(page / this.state.displayPages) *
                this.state.displayPages +
              i;
        }
        let abc1 = index < totalPage ? index : totalPage;
        totalPageTab.push(
          <li
            className="page-item"
            onClick={() => this.fetchRecords(parseInt(abc1))}
            key={index}
          >
            {seachingpage ? (
              <span
                className={
                  searchpage == abc1
                    ? "a page-link bg-secondary active-page"
                    : "a page-link"
                }
                href="#"
              >
                {abc1}
              </span>
            ) : (
              <span
                className={
                  page == abc1
                    ? "a page-link bg-secondary active-page"
                    : "a page-link"
                }
                href="#"
              >
                {abc1}
              </span>
            )}
          </li>
        );
      }
    }

    return (
      <>
        <div className="row">
          <div className="col-sm-6">
            {seachingpage
              ? "Showing " +
                (searchpage * 10 - 9) +
                " to " +
                recordDisplayed +
                " of " +
                totalCount +
                " entries"
              : "Showing " +
                (page * 10 - 9) +
                " to " +
                recordDisplayed +
                " of " +
                totalCount +
                " entries"}
          </div>

          {totalPage > 1 ? (
            <div className="col-sm-3">
              {"Page "}
              <input
                type="number"
                className="form-control effect-16 d-inline w-25"
                min={1}
                max={totalPage}
                value={parseInt(
                  seachingpage ? this.state.inputSpage : this.state.inputpage
                )}
                onChange={e =>
                  this.fetchRecords(
                    parseInt(
                      e.target.value && e.target.value != 0
                        ? e.target.value > totalPage
                          ? totalPage
                          : e.target.value
                        : e.target.value
                    )
                  )
                }
              />
              {" of " + totalPage}
            </div>
          ) : (
            ""
          )}
          <div className="col-sm-3">
            <div className="pull-right">
              <ul className="pagination justify-content-end">
                {page > 1 ? (
                  <li className="page-item">
                    <span
                      className="a page-link"
                      href="#"
                      aria-label="Previous"
                      onClick={() =>
                        this.fetchRecords(
                          parseInt(seachingpage ? searchpage : page) -
                            parseInt(1)
                        )
                      }
                    >
                      <span aria-hidden="true">&laquo;</span>
                      <span className="sr-only">Previous</span>
                    </span>
                  </li>
                ) : (
                  ""
                )}
                {searchpage > 1 ? (
                  <li className="page-item">
                    <span
                      className="a page-link"
                      href="#"
                      aria-label="Previous"
                      onClick={() =>
                        this.fetchRecords(
                          parseInt(seachingpage ? searchpage : page) -
                            parseInt(1)
                        )
                      }
                    >
                      <span aria-hidden="true">&laquo;</span>
                      <span className="sr-only">Previous</span>
                    </span>
                  </li>
                ) : (
                  ""
                )}
                {totalPageTab}
                {isLastPage ? (
                  ""
                ) : (
                  <li className="page-item">
                    <span
                      className="a page-link"
                      aria-label="Next"
                      onClick={() =>
                        this.fetchRecords(
                          parseInt(seachingpage ? searchpage : page) +
                            parseInt(1)
                        )
                      }
                    >
                      <span aria-hidden="true">&raquo;</span>
                      <span className="sr-only">Next</span>
                    </span>
                  </li>
                )}
              </ul>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Pagination;
