import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "swiper/dist/css/swiper.css";
import navArrowRight from "../../assets/images/svg/nav-arrow-right-blue.svg";
import navCloseSvg from "../../assets/images/svg/nav-close.svg";
import cameraSvg from "../../assets/images/svg/camera.svg";
import DatePicker from "react-datepicker";
import { fileUpload } from "../../services/fileUpload.service";
import { APP_MESSAGES } from "../../services/config/messages";
import DatePickerInput from "../Utils/DatePickerInput";

class EditWebComponent extends Component {
  constructor(props) {
    super(props);

    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.getDateArray = this.getDateArray.bind(this);

    this.uploadLogo = this.uploadLogo.bind(this);
    this.removeImage = this.removeImage.bind(this);
    this.submitForm = this.submitForm.bind(this);
  }

  handleChangeStart(date) {
    if (date) {
      this.props.editDashboardAdvertisementFormResetError("startAt");
      this.props.editDashboardAdvertisementFormUpdateField("startAt", date);
      if (
        date >
        new Date(this.props.editDashboardAdvertisementForm.fields.endAt.value)
      ) {
        this.props.editDashboardAdvertisementFormUpdateField("endAt", date);
      }
    }
  }

  handleChangeEnd(date) {
    if (date) {
      this.props.editDashboardAdvertisementFormResetError("endAt");
      this.props.editDashboardAdvertisementFormUpdateField("endAt", date);
      if (
        date <
        new Date(this.props.editDashboardAdvertisementForm.fields.startAt.value)
      ) {
        this.props.editDashboardAdvertisementFormUpdateField("startAt", date);
      }
    }
  }

  componentDidMount() {
    this.props.invalidateHost();
    this.props.fetchHostIfNeeded({
      page: ""
    });

    this.props.invalidateDashboardAdvertisement();
    this.props.fetchDashboardAdvertisementIfNeeded(this.props.match.params.id);
  }

  removeImage(imgType) {
    this.props.editDashboardAdvertisementFormUpdateField(imgType, "");
  }

  uploadLogo(event, imgType) {
    if (event.target.files.length > 0) {
      let fileSize = event.target.files[0].size / 1000000;
      let fileType = "";
      if (event.target.files[0].type == "") {
        fileType = "nothing";
      } else {
        fileType = event.target.files[0].type.split("/")[1].toLowerCase();
      }

      this.props.editDashboardAdvertisementFormResetError(imgType);
      this.props.editDashboardAdvertisementFormResetError("imageSize");
      this.props.editDashboardAdvertisementFormResetError("imageType");

      //file size
      if (!(fileSize < 2)) {
        this.props.editDashboardAdvertisementFormSetError(imgType, "NOT_EMPTY");
        this.props.editDashboardAdvertisementFormSetError(
          "imageSize",
          "NOT_EMPTY"
        );
        return false;
      } else {
        this.props.editDashboardAdvertisementFormResetError("imageSize");
      }

      //file type
      if (fileType == "jpeg" || fileType == "jpg" || fileType == "png") {
        this.props.editDashboardAdvertisementFormResetError("imageType");
      } else {
        this.props.editDashboardAdvertisementFormSetError(imgType, "NOT_EMPTY");
        this.props.editDashboardAdvertisementFormSetError(
          "imageType",
          "NOT_EMPTY"
        );
        return false;
      }

      fileUpload(event.target.files)
        .then(url => {
          this.props.editDashboardAdvertisementFormUpdateField(imgType, url);
        })
        .catch(() => {
          console.log(this);
        });
    } else {
      this.props.editDashboardAdvertisementFormUpdateField(imgType, "");
    }
  }

  getDateArray(start, end) {
    var arr = new Array();
    var dt = new Date(start);
    while (dt <= end) {
      arr.push(new Date(dt));
      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  }

  ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  submitForm(buttonType) {
    if (!this.props.editDashboardAdvertisementForm.isSubmitting) {
      if (
        !this.props.editDashboardAdvertisementForm.fields
          .dashboardAdvertisementPlaceId.value
      ) {
        this.props.editDashboardAdvertisementFormSetError(
          "dashboardAdvertisementPlaceId",
          "NOT_EMPTY"
        );
      }

      if (
        !this.props.editDashboardAdvertisementForm.fields.ownerBrandId.value
      ) {
        this.props.editDashboardAdvertisementFormSetError(
          "ownerBrandId",
          "NOT_EMPTY"
        );
      }

      if (!this.props.editDashboardAdvertisementForm.fields.startAt.value) {
        this.props.editDashboardAdvertisementFormSetError(
          "startAt",
          "NOT_EMPTY"
        );
      }

      if (!this.props.editDashboardAdvertisementForm.fields.endAt.value) {
        this.props.editDashboardAdvertisementFormSetError("endAt", "NOT_EMPTY");
      }

      if (!this.props.editDashboardAdvertisementForm.fields.imageUrl.value) {
        this.props.editDashboardAdvertisementFormSetError(
          "imageUrl",
          "NOT_EMPTY"
        );
      }

      if (
        this.props.editDashboardAdvertisementForm.fields
          .dashboardAdvertisementPlaceId.value &&
        this.props.editDashboardAdvertisementForm.fields.ownerBrandId.value &&
        this.props.editDashboardAdvertisementForm.fields.startAt.value &&
        this.props.editDashboardAdvertisementForm.fields.endAt.value &&
        this.props.editDashboardAdvertisementForm.fields.imageUrl.value
      ) {
        this.props.editDashboardAdvertisementFormSubmit({
          id: this.props.editDashboardAdvertisementForm.fields.id.value,
          dashboardAdvertisementPlaceId: this.props
            .editDashboardAdvertisementForm.fields.dashboardAdvertisementPlaceId
            .value,
          ownerBrandId: this.props.editDashboardAdvertisementForm.fields
            .ownerBrandId.value,
          imageUrl: this.props.editDashboardAdvertisementForm.fields.imageUrl
            .value,
          impressionCount: 0,
          startAt: this.props.editDashboardAdvertisementForm.fields.startAt
            .value,
          endAt: this.props.editDashboardAdvertisementForm.fields.endAt.value,
          isPublished: buttonType == "Save" ? false : true
        });
      }
    }
  }

  render() {
    const { dashboardAdvertisementDetails } = this.props;

    let Error;
    let Success;

    Error = this.props.editDashboardAdvertisementForm.fields
      .dashboardAdvertisementPlaceId.errorMsg
      ? APP_MESSAGES[
          this.props.editDashboardAdvertisementForm.fields
            .dashboardAdvertisementPlaceId.errorMsg
        ](
          this.props.editDashboardAdvertisementForm.fields
            .dashboardAdvertisementPlaceId.errorPayload,
          () => {
            this.props.editDashboardAdvertisementFormResetError(
              "dashboardAdvertisementPlaceId"
            );
          }
        )
      : props => <>{""}</>;

    Success = this.props.editDashboardAdvertisementForm.fields
      .dashboardAdvertisementPlaceId.successMsg
      ? APP_MESSAGES[
          this.props.editDashboardAdvertisementForm.fields
            .dashboardAdvertisementPlaceId.successMsg
        ](
          this.props.editDashboardAdvertisementForm.fields
            .dashboardAdvertisementPlaceId.successPayload,
          () => {
            this.props.editDashboardAdvertisementFormResetSuccess(
              "dashboardAdvertisementPlaceId"
            );
          }
        )
      : props => <>{""}</>;

    let dashboardAdvertisementOptions = [];
    let dashboardAdvertisementIdValues = [];
    if (this.props.dashboardAdvertisementList.DASHBOARD) {
      let dashboardAdvertisementList = this.props.dashboardAdvertisementList
        .DASHBOARD;
      dashboardAdvertisementList.map(function(data) {
        dashboardAdvertisementOptions.push({ id: data.id, value: data.label });
        dashboardAdvertisementIdValues[data.id] = data.label;
      });
    }

    let brandOptions = [];
    let brandIdValues = [];
    let platform = "";
    if (this.props.hostList.items) {
      let hostList = this.props.hostList.items;
      let ownerBrandId = this.props.editDashboardAdvertisementForm.fields
        .ownerBrandId.value;
      hostList.map(function(data) {
        brandOptions.push({ id: data.id, value: data.name });
        brandIdValues[data.id] = data.name;
        if (ownerBrandId == data.id) {
          platform = data.env.name;
        }
      });
    }
    let finalExcludeDatesArray = [];
    if (dashboardAdvertisementDetails.item.disabledAds) {
      let dashboardDisabledAdvertisementList =
        dashboardAdvertisementDetails.item.disabledAds;
      dashboardDisabledAdvertisementList.map((data, i) => {
        let startDate = new Date(data.startAt); //YYYY-MM-DD
        let endDate = new Date(data.endAt); //YYYY-MM-DD
        let betweenDatesArray = this.getDateArray(startDate, endDate);

        betweenDatesArray.map((data1, i1) => {
          finalExcludeDatesArray.push(data1);
        });
      });
    }

    return !dashboardAdvertisementDetails.isFetching &&
      dashboardAdvertisementDetails &&
      dashboardAdvertisementDetails.item &&
      dashboardAdvertisementDetails.item.dashboardAdvertisementPlace ? (
      <>
        <section className="premium" id="rootwizard">
          <section className="container-fluid tab-content-wrapper">
            <form
              onSubmit={e => {
                e.preventDefault();
              }}
              tabIndex={-1}
            >
              <div className="row">
                <div className="col-sm-12">
                  <div>
                    <div className="container mt-6">
                      <div className="padder-main ">
                        <div className="row">
                          <div className="col-sm-12 text-right d-flex justify-content-start mb-4 back-arrow">
                            <a
                              href="/dashboard"
                              className="view-all d-flex align-items-center"
                            >
                              <span>
                                <img src={navArrowRight} alt="" />
                              </span>
                              <h5 className="bold text-secondary"> Back </h5>
                            </a>
                          </div>
                          <div className="col-sm-12 mb-4">
                            <h3 className="bold">
                              Edit Dashboard Advertisement
                            </h3>
                          </div>

                          <div className="col-sm-12">
                            {/* <Error /> */}
                            <Success />
                          </div>

                          <div className="col-sm-6 zIndex-3 mb-4">
                            <h5 className="bold">Advertisement</h5>
                            <div className=" form-group with-lable mt-4">
                              <div className="input-group-wrapper">
                                <input
                                  type="text"
                                  placeholder="Advertise"
                                  className={
                                    "form-control effect-16 " +
                                    (this.props.editDashboardAdvertisementForm
                                      .fields.dashboardAdvertisementPlaceId
                                      .error
                                      ? "effect-17"
                                      : "")
                                  }
                                  tabIndex="1"
                                  value={
                                    dashboardAdvertisementIdValues[
                                      this.props.editDashboardAdvertisementForm
                                        .fields.dashboardAdvertisementPlaceId
                                        .value
                                    ]
                                      ? dashboardAdvertisementIdValues[
                                          this.props
                                            .editDashboardAdvertisementForm
                                            .fields
                                            .dashboardAdvertisementPlaceId.value
                                        ]
                                      : ""
                                  }
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 zIndex-2 mb-4">
                            <h5 className="bold">Owner</h5>
                            <div className=" form-group with-lable mt-4">
                              <div className="input-group-wrapper">
                                <input
                                  type="text"
                                  placeholder="Brand"
                                  className={
                                    "form-control effect-16 " +
                                    (this.props.editDashboardAdvertisementForm
                                      .fields.ownerBrandId.error
                                      ? "effect-17"
                                      : "")
                                  }
                                  tabIndex="1"
                                  value={
                                    brandIdValues[
                                      this.props.editDashboardAdvertisementForm
                                        .fields.ownerBrandId.value
                                    ]
                                      ? brandIdValues[
                                          this.props
                                            .editDashboardAdvertisementForm
                                            .fields.ownerBrandId.value
                                        ]
                                      : ""
                                  }
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 mb-4">
                            <h5 className="bold">Starting @</h5>
                            <div className="form-group with-lable mt-4 zIndex-4">
                              <div className="input-group-wrapper">
                                <DatePicker
                                  customInput={<DatePickerInput />}
                                  selected={
                                    this.props.editDashboardAdvertisementForm
                                      .fields.startAt.value
                                      ? this.props
                                          .editDashboardAdvertisementForm.fields
                                          .startAt.value
                                      : new Date()
                                  }
                                  selectsStart
                                  minDate={new Date()}
                                  startDate={
                                    this.props.editDashboardAdvertisementForm
                                      .fields.startAt.value
                                      ? this.props
                                          .editDashboardAdvertisementForm.fields
                                          .startAt.value
                                      : new Date()
                                  }
                                  endDate={
                                    this.props.editDashboardAdvertisementForm
                                      .fields.endAt.value
                                      ? this.props
                                          .editDashboardAdvertisementForm.fields
                                          .endAt.value
                                      : new Date()
                                  }
                                  onChange={this.handleChangeStart}
                                  dateFormat="dd-MM-yyyy"
                                  className={
                                    "form-control effect-16 " +
                                    (this.props.editDashboardAdvertisementForm
                                      .fields.startAt.error
                                      ? "effect-17"
                                      : "")
                                  }
                                  placeholderText="Date"
                                  tabIndex={1}
                                  excludeDates={finalExcludeDatesArray}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 mb-4">
                            <h5 className="bold">Ending @</h5>
                            <div className="form-group with-lable mt-4 zIndex-4">
                              <div className="input-group-wrapper">
                                <DatePicker
                                  customInput={<DatePickerInput />}
                                  selected={
                                    this.props.editDashboardAdvertisementForm
                                      .fields.endAt.value
                                      ? this.props
                                          .editDashboardAdvertisementForm.fields
                                          .endAt.value
                                      : new Date()
                                  }
                                  selectsEnd
                                  minDate={new Date()}
                                  startDate={
                                    this.props.editDashboardAdvertisementForm
                                      .fields.startAt.value
                                      ? this.props
                                          .editDashboardAdvertisementForm.fields
                                          .startAt.value
                                      : new Date()
                                  }
                                  endDate={
                                    this.props.editDashboardAdvertisementForm
                                      .fields.endAt.value
                                      ? this.props
                                          .editDashboardAdvertisementForm.fields
                                          .endAt.value
                                      : new Date()
                                  }
                                  onChange={this.handleChangeEnd}
                                  dateFormat="dd-MM-yyyy"
                                  className={
                                    "form-control effect-16 " +
                                    (this.props.editDashboardAdvertisementForm
                                      .fields.endAt.error
                                      ? "effect-17"
                                      : "")
                                  }
                                  placeholderText="Date"
                                  tabIndex={1}
                                  excludeDates={finalExcludeDatesArray}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 mb-4">
                            <h5 className="bold">Type</h5>
                            <div className="form-group with-lable mt-4">
                              <div className="input-group-wrapper">
                                <input
                                  type="text"
                                  placeholder="Advertise"
                                  className={
                                    "form-control effect-16 " +
                                    (this.props.editDashboardAdvertisementForm
                                      .fields.dashboardAdvertisementPlaceId
                                      .error
                                      ? "effect-17"
                                      : "")
                                  }
                                  tabIndex="1"
                                  value={this.ucFirst(
                                    dashboardAdvertisementDetails.item
                                      .dashboardAdvertisementPlace.type
                                  )}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-6 mb-4 ">
                            <h5 className="bold">Platform</h5>
                            <div className="form-group with-lable mt-4 zIndex-11 disable-div">
                              <div className="input-group-wrapper">
                                <input
                                  type="text"
                                  placeholder="Platform"
                                  className="form-control effect-16 "
                                  tabIndex="1"
                                  value={platform}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12  zIndex-1 mb-5 mt-4">
                            <div className="upload-image d-flex align-items-center ">
                              {this.props.editDashboardAdvertisementForm.fields
                                .imageUrl.value ? (
                                <div className="upload-button image-area-2 col h-100  d-flex flex-column justify-content-center align-items-center">
                                  <img
                                    src={
                                      this.props.editDashboardAdvertisementForm
                                        .fields.imageUrl.value
                                    }
                                    className="img-fluid"
                                    alt=""
                                    onError={() => this.removeImage("imageUrl")}
                                  />
                                </div>
                              ) : (
                                <>
                                  <input
                                    type="file"
                                    name="img[]"
                                    className="file"
                                    onChange={e =>
                                      this.uploadLogo(e, "imageUrl")
                                    }
                                    ref={input =>
                                      (this.inputPrizePoolImg = input)
                                    }
                                  />
                                  <div
                                    onClick={() =>
                                      this.inputPrizePoolImg.click()
                                    }
                                    className={
                                      "upload-button image-area-2 col h-100  d-flex flex-column justify-content-center align-items-center" +
                                      (this.props.editDashboardAdvertisementForm
                                        .fields.imageUrl.error
                                        ? " add-new-rewards-danger"
                                        : "")
                                    }
                                  >
                                    <img
                                      src={cameraSvg}
                                      className="img-fluid"
                                      alt=""
                                    />
                                    <p className="choose-photo mb-0 bold">
                                      Upload{" "}
                                      {
                                        dashboardAdvertisementDetails.item
                                          .dashboardAdvertisementPlace.label
                                      }
                                    </p>
                                    <p className="mt-1 small">
                                      {" "}
                                      Size{" "}
                                      {
                                        dashboardAdvertisementDetails.item
                                          .dashboardAdvertisementPlace.width
                                      }
                                      px width{" "}
                                      {
                                        dashboardAdvertisementDetails.item
                                          .dashboardAdvertisementPlace.height
                                      }
                                      px height
                                      <br />{" "}
                                      <span
                                        className={
                                          this.props
                                            .editDashboardAdvertisementForm
                                            .fields.imageSize.error
                                            ? " file-size-danger"
                                            : ""
                                        }
                                      >
                                        * Maximum size 2 MB
                                      </span>
                                      <br />{" "}
                                      <span
                                        className={
                                          this.props
                                            .editDashboardAdvertisementForm
                                            .fields.imageType.error
                                            ? " file-size-danger"
                                            : ""
                                        }
                                      >
                                        * Supported file types : jpg, jpeg and
                                        png
                                      </span>
                                    </p>
                                  </div>
                                </>
                              )}

                              <div className="col-auto ">
                                {this.props.editDashboardAdvertisementForm
                                  .fields.imageUrl.value ? (
                                  <div
                                    className="popup-dismiss"
                                    data-dismiss="modal"
                                    aria-label="Close"
                                    onClick={() => this.removeImage("imageUrl")}
                                  >
                                    {" "}
                                    <img src={navCloseSvg} alt="" />{" "}
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-12">
                            <button
                              type="button"
                              className="btn btn-pink py-3 btn-preview dark mw-150 mt-5"
                              onClick={() => this.submitForm("Save")}
                            >
                              Save
                            </button>
                            <button
                              type="button"
                              className="btn btn-pink py-3 btn-preview dark mw-150 mt-5 ml-2"
                              onClick={() => this.submitForm("SavePublish")}
                            >
                              Save & Publish
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </section>
      </>
    ) : (
      <></>
    );
  }
}

export default EditWebComponent;
