import React, { Component } from "react";
import "./CreateRaffleComponent.scss";
import CameraImage from "../../assets/images/svg/camera.svg";
import GameSetupOnImage from "../../assets/images/game-setup-on.png";
import TokenImage from "../../assets/images/token.png";
import NavRightArrow from "../../assets/images/svg/nav-arrow-right-blue.svg";
import AppInputComponent from "../../layouts/components/sidebars/AppInputComponent";
import { fileUpload } from "../../services/fileUpload.service";
import DatePickerInput from "../Utils/DatePickerInput";
import Select2ComponentId from "../Select2/Select2ComponentId";
import moment from "moment";

//datepicker
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class CreateRaffleComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemsChecked: false,
      pointsChecked: true,
      showUpload: true,
      startDate: undefined
    };

    this.handleItems = this.handleItems.bind(this);
    this.handlePoints = this.handlePoints.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.removeUpload = this.removeUpload.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.convertDateFormat = this.convertDateFormat.bind(this);
    this.resetRewardsItem = this.resetRewardsItem.bind(this);
  }

  componentWillMount() {
    this.props.invalidatebrandList();
    this.props.fetchbrandListIfNeeded();
    var languagesList = this.props.languagesList;
    var localeArr = [];
    languagesList.map((locale, i) => {
      localeArr.push({ languageId: locale.id, itemUrl: "", itemDescription: "" });
    })
    this.props.publishRaffleFormUpdateField("raffleTranslation", localeArr);
  }

  resetRewardsItem() {
    var languagesRecord = this.props.languagesList;
    var localeArr = [];
    languagesRecord.map((locale, i) => {
      localeArr.push({ languageId: locale.id, itemUrl: "", itemDescription: "" });
    })
    this.props.publishRaffleFormUpdateField("itemDescription", "");
    this.props.publishRaffleFormUpdateField("itemUrl", "");
    this.props.publishRaffleFormUpdateField("raffleTranslation", localeArr);
  }

  convertDateFormat(date1) {
    let date = moment(date1).format("YYYY-MM-DD");
    return date;
  }

  handleChange(date) {
    this.setState({
      startDate: date
    });

    this.props.publishRaffleFormResetError("timeOfStart");
    this.props.publishRaffleFormUpdateField(
      "timeOfStart",
      this.convertDateFormat(date)
    );
  }

  handleItems() {
    this.setState({ itemsChecked: !this.state.itemsChecked });
    this.props.publishRaffleFormResetError("itemDescription");
    this.props.publishRaffleFormResetError("itemUrl");
  }

  handlePoints() {
    this.setState({ pointsChecked: !this.state.pointsChecked });
    this.props.publishRaffleFormResetError("points");
  }

  uploadFile(event) {
    if (event.target.files.length > 0) {
      const fileSize = event.target.files[0].size / 1000000;
      const fileType = event.target.files[0].type.split("/")[1].toLowerCase();

      if (fileType !== "jpeg" && fileType !== "jpg" && fileType !== "png") {
        this.props.publishRaffleFormSetError("itemUrl", "FILE_TYPE");
        return false;
      } else if (fileSize > 2) {
        this.props.publishRaffleFormSetError("itemUrl", "FILE_SIZE");
        return false;
      }
      this.props.publishRaffleFormResetError("itemUrl");
      fileUpload(event.target.files)
        .then(url => {
          this.props.publishRaffleFormUpdateField("itemUrl", url);
          this.setState({ showUpload: !this.state.showUpload });
        })
        .catch(() => { });
    } else {
      this.props.publishRaffleFormUpdateField("itemUrl", "");
    }
  }

  removeUpload() {
    this.setState({ showUpload: !this.state.showUpload });
    this.props.publishRaffleFormUpdateField('itemUrl', translationData)
    var translationData = this.props.publishRaffleForm.fields.raffleTranslation.value ? this.props.publishRaffleForm.fields.raffleTranslation.value : [];
    var existRecord = translationData.filter(e => e['languageId'] == this.props.defaultLang)
    existRecord[0]['itemUrl'] = ''
    this.props.publishRaffleFormUpdateField('raffleTranslation', translationData)
  }

  render() {
    const { hostList } = this.props;
    const category = {};
    let platform = "";
    const hostDropDown = hostList.map((data, index) => {
      let ownerBrandId = this.props.publishRaffleForm.fields.selectHost.value;
      if (ownerBrandId == data.id) {
        platform = data.env.name;
      }
      return {
        ...category,
        id: data.id,
        value: data.name
      };
    });
    const durationObj = {};
    const durationDropDown = this.props.durationList.map((data, index) => {
      return {
        ...durationObj,
        id: data.id,
        value: data.description
      };
    });

    if (!this.state.itemsChecked && !this.state.pointsChecked) {
      this.handleItems();
    }
    return (
      <>
        <section id="rootwizard">
          <section className="container-fluid tab-content-wrapper">
            <div className="row">
              <div className="col-sm-12">
                <div>
                  <div className="container">
                    <div className="padder-main">
                      <div className="row">
                        <div className="col-sm-12  mt-6 text-right d-flex justify-content-start mb-2 back-arrow">
                          <a
                            href="/raffles"
                            className="view-all d-flex align-items-center"
                          >
                            <span>
                              <img src={NavRightArrow} alt="" />
                            </span>
                            <h5 className="bold text-secondary"> Back </h5>
                          </a>
                        </div>
                        <div className="col-sm-12  mt-4 mb-6">
                          <h1>Create New Raffle</h1>
                        </div>
                      </div>
                      <form
                        onSubmit={e => {
                          e.preventDefault();
                          if (!this.props.publishRaffleForm.isSubmitting) {
                            if (
                              !this.props.publishRaffleForm.fields.selectHost
                                .value
                            ) {
                              this.props.publishRaffleFormSetError(
                                "selectHost",
                                "NOT_EMPTY"
                              );
                            }
                            if (
                              !this.props.publishRaffleForm.fields.timeOfStart
                                .value
                            ) {
                              this.props.publishRaffleFormSetError(
                                "timeOfStart",
                                "NOT_EMPTY"
                              );
                            }
                            if (!this.props.publishRaffleForm.fields.HH.value) {
                              this.props.publishRaffleFormSetError(
                                "HH",
                                "NOT_EMPTY"
                              );
                            }
                            if (!this.props.publishRaffleForm.fields.MM.value) {
                              this.props.publishRaffleFormSetError(
                                "MM",
                                "NOT_EMPTY"
                              );
                            }
                            if (
                              !this.props.publishRaffleForm.fields.duration
                                .value
                            ) {
                              this.props.publishRaffleFormSetError(
                                "duration",
                                "NOT_EMPTY"
                              );
                            }
                            if (
                              !this.props.publishRaffleForm.fields.entryFee
                                .value ||
                              this.props.publishRaffleForm.fields.entryFee
                                .value < 0
                            ) {
                              this.props.publishRaffleFormSetError(
                                "entryFee",
                                "NOT_EMPTY"
                              );
                            }
                            if (this.state.itemsChecked) {
                              if (
                                !this.props.publishRaffleForm.fields
                                  .itemDescription.value
                              ) {
                                this.props.publishRaffleFormSetError(
                                  "itemDescription",
                                  "NOT_EMPTY"
                                );
                              }
                              if (
                                !this.props.publishRaffleForm.fields.itemUrl
                                  .value
                              ) {
                                this.props.publishRaffleFormSetError(
                                  "itemUrl",
                                  "NOT_EMPTY"
                                );
                              }
                            }
                            if (
                              this.state.pointsChecked &&
                              !this.props.publishRaffleForm.fields.points.value
                            ) {
                              this.props.publishRaffleFormSetError(
                                "points",
                                "NOT_EMPTY"
                              );
                            }

                            if (
                              !this.props.publishRaffleForm.fields.timeOfStart
                                .value ||
                              !this.props.publishRaffleForm.fields.HH.value ||
                              !this.props.publishRaffleForm.fields.MM.value ||
                              !this.props.publishRaffleForm.fields.duration
                                .value ||
                              !this.props.publishRaffleForm.fields.entryFee
                                .value ||
                              this.props.publishRaffleForm.fields.entryFee
                                .value < 0
                            ) {
                              return false;
                            }
                            if (this.state.itemsChecked) {
                              if (
                                !this.props.publishRaffleForm.fields
                                  .itemDescription.value
                              ) {
                                return false;
                              }
                              if (
                                !this.props.publishRaffleForm.fields.itemUrl
                                  .value
                              ) {
                                return false;
                              }
                            }
                            if (
                              this.state.pointsChecked &&
                              (!this.props.publishRaffleForm.fields.points
                                .value ||
                                this.props.publishRaffleForm.fields.points
                                  .value < 0)
                            ) {
                              return false;
                            }

                            this.props.publishRaffleFormSubmit({
                              brandId: this.props.publishRaffleForm.fields
                                .selectHost.value,
                              timeOfStart:
                                this.props.publishRaffleForm.fields.timeOfStart
                                  .value +
                                " " +
                                this.props.publishRaffleForm.fields.HH.value +
                                ":" +
                                this.props.publishRaffleForm.fields.MM.value +
                                ":00+00",
                              entryFee: this.props.publishRaffleForm.fields
                                .entryFee.value,
                              itemDescription: this.props.publishRaffleForm
                                .fields.itemDescription.value,
                              itemUrl: this.props.publishRaffleForm.fields
                                .itemUrl.value,
                              durationId: this.props.publishRaffleForm.fields
                                .duration.value,
                              points: this.props.publishRaffleForm.fields.points
                                .value
                                ? this.props.publishRaffleForm.fields.points
                                  .value
                                : 0,
                              raffleTranslation: this.props.publishRaffleForm.fields.raffleTranslation.value
                            });
                          }
                        }}
                        tabIndex={-1}
                      >
                        <div className="row">
                          <div className="col-sm-12 zIndex-3 mb-4">
                            <h5 className="bold">Brand</h5>
                            <div className=" form-group with-lable mt-4">
                              <div className="input-group-wrapper">
                                <Select2ComponentId
                                  selectClass={
                                    this.props.publishRaffleForm.fields
                                      .selectHost.error
                                      ? "form-control effect-17 select-2-input"
                                      : "form-control effect-16 select-2-input"
                                  }
                                  onChange={event => {
                                    this.props.publishRaffleFormResetError(
                                      "selectHost"
                                    );
                                    this.props.publishRaffleFormUpdateField(
                                      "selectHost",
                                      event.id
                                    );
                                  }}
                                  labelText="Select Brand"
                                  selectOptions={hostDropDown}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-12  mb-4">
                            <h5 className="bold">Platform</h5>
                            <div className=" form-group with-lable mt-4">
                              <div className="input-group-wrapper">
                                <input
                                  type="text"
                                  placeholder="Platform"
                                  className="form-control effect-16 "
                                  tabIndex="1"
                                  value={platform}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-sm-4 mb-4 zIndex-1">
                            <h5 className="bold">Starting @</h5>
                            <div className="form-group with-lable mt-4 ">
                              <div className="input-group-wrapper">
                                <DatePicker
                                  selected={this.state.startDate}
                                  onChange={this.handleChange}
                                  minDate={new Date()}
                                  className={
                                    this.props.publishRaffleForm.fields
                                      .timeOfStart.error
                                      ? "form-control effect-17"
                                      : "form-control effect-16"
                                  }
                                  placeholderText="Date"
                                  dateFormat="yyyy-MM-dd"
                                  tabIndex={1}
                                  customInput={<DatePickerInput />}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 mb-4">
                            <h5 className="bold">Time</h5>
                            <div className="row">
                              <div className="col-sm-6">
                                <AppInputComponent
                                  labelText="HH"
                                  inputClass={
                                    this.props.publishRaffleForm.fields.HH.error
                                      ? "form-control effect-17"
                                      : "form-control effect-16"
                                  }
                                  divClass="form-group with-lable mt-4"
                                  value={
                                    this.props.publishRaffleForm.fields.HH.value
                                  }
                                  onChange={event => {
                                    this.props.publishRaffleFormResetError(
                                      "HH"
                                    );
                                    if (event.target.value <= 23) {
                                      var HHValue = event.target.value;
                                    } else {
                                      var HHValue = 23;
                                    }
                                    this.props.publishRaffleFormUpdateField(
                                      "HH",
                                      HHValue
                                    );
                                  }}
                                  placeholder="Hours"
                                  type="number"
                                  min="0"
                                  max="23"
                                  minLength="1"
                                  maxLength="2"
                                  tabIndex={-1}
                                />
                              </div>
                              <div className="col-sm-6">
                                <AppInputComponent
                                  labelText="MM"
                                  divClass="form-group with-lable mt-4"
                                  inputClass={
                                    this.props.publishRaffleForm.fields.MM.error
                                      ? "form-control effect-17"
                                      : "form-control effect-16"
                                  }
                                  value={
                                    this.props.publishRaffleForm.fields.MM.value
                                  }
                                  onChange={event => {
                                    this.props.publishRaffleFormResetError(
                                      "MM"
                                    );
                                    if (event.target.value <= 59) {
                                      var MMValue = event.target.value;
                                    } else {
                                      var MMValue = 59;
                                    }
                                    this.props.publishRaffleFormUpdateField(
                                      "MM",
                                      MMValue
                                    );
                                  }}
                                  placeholder="Minutes"
                                  type="number"
                                  min="0"
                                  max="59"
                                  minLength="1"
                                  maxLength="2"
                                  tabIndex={-1}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-sm-4 zIndex-1 mb-4">
                            <h5 className="bold">Duration</h5>
                            <div className=" form-group with-lable mt-4">
                              <div className="input-group-wrapper">
                                <Select2ComponentId
                                  selectClass={
                                    this.props.publishRaffleForm.fields.duration
                                      .error
                                      ? "form-control effect-17 select-2-input"
                                      : "form-control effect-16 select-2-input"
                                  }
                                  onChange={event => {
                                    this.props.publishRaffleFormResetError(
                                      "duration"
                                    );
                                    this.props.publishRaffleFormUpdateField(
                                      "duration",
                                      event.id
                                    );
                                  }}
                                  labelText="Select Duration"
                                  selectOptions={durationDropDown}
                                  selectedValue={
                                    this.props.publishRaffleForm.fields.duration
                                      .value
                                  }
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-sm-12 mb-4">
                            <h5 className="bold">Entry fee</h5>
                            <AppInputComponent
                              labelText="Enter points"
                              divClass="form-group with-lable mt-4"
                              inputClass={
                                this.props.publishRaffleForm.fields.entryFee
                                  .error
                                  ? "form-control effect-17"
                                  : "form-control effect-16"
                              }
                              value={
                                this.props.publishRaffleForm.fields.entryFee
                                  .value
                              }
                              onChange={event => {
                                this.props.publishRaffleFormResetError(
                                  "entryFee"
                                );
                                this.props.publishRaffleFormUpdateField(
                                  "entryFee",
                                  event.target.value
                                );
                              }}
                              placeholder="Entry Fees"
                              type="number"
                              min="0"
                              tabIndex={-1}
                            />
                          </div>
                          <div className="col-sm-12 mb-4 form-info">
                            <h5 className="bold">Reward</h5>
                            <ul className=" list-inline d-flex flex-row flex-wrap w-100 mt-3 mb-3">
                              <li className="list-group-control col-sm-6 col-md-3 mt-2 pl-0">
                                <div className="select-game-box">
                                  <label className="custom-control  fill-checkbox  w-100 mb-0 d-flex justify-content-center align-items-center">
                                    <div className="labelc-content d-inline-flex align-items-center">
                                      <img
                                        src={GameSetupOnImage}
                                        alt=""
                                        className="icon"
                                      />
                                      <p className="mb-0 ml-3">Item</p>
                                    </div>
                                    <div className="ml-auto">
                                      <input
                                        type="checkbox"
                                        className="fill-control-input"
                                        value=""
                                        checked={
                                          this.state.itemsChecked
                                            ? "checked"
                                            : ""
                                        }
                                        onChange={() => {
                                          this.resetRewardsItem()
                                        }}
                                        readOnly
                                      />
                                      <span
                                        className="fill-control-indicator backend"
                                        onClick={this.handleItems}
                                      />
                                    </div>
                                  </label>
                                </div>
                              </li>
                              <li className="list-group-control col-sm-6 col-md-3 mt-2 ">
                                <div className="select-game-box">
                                  <label className="custom-control  fill-checkbox  w-100 mb-0 d-flex justify-content-center align-items-center">
                                    <div className="labelc-content d-inline-flex align-items-center">
                                      <img
                                        src={TokenImage}
                                        alt=""
                                        className="icon"
                                      />
                                      <p className="mb-0 ml-3">Points</p>
                                    </div>
                                    <div className="ml-auto">
                                      <input
                                        type="checkbox"
                                        className="fill-control-input"
                                        value=""
                                        name="secondPrize"
                                        id="secondPrize"
                                        checked={
                                          this.state.pointsChecked
                                            ? "checked"
                                            : ""
                                        }
                                        onChange={() => {
                                          this.props.publishRaffleFormUpdateField(
                                            "points",
                                            ""
                                          );
                                        }}
                                        readOnly
                                      />
                                      <span
                                        className="fill-control-indicator backend"
                                        onClick={this.handlePoints}
                                      />
                                    </div>
                                  </label>
                                </div>
                              </li>
                            </ul>
                          </div>
                          {this.state.itemsChecked ? (
                            <div className="col-sm-12">
                              <div className="row">
                                <div className="col-md-8">
                                  <AppInputComponent
                                    labelText="Enter item name"
                                    divClass="form-group with-lable mt-4"
                                    inputClass={
                                      this.props.publishRaffleForm.fields.itemDescription.error
                                        ? "form-control effect-17"
                                        : "form-control effect-16"
                                    }
                                    value={
                                      this.props.publishRaffleForm.fields
                                        .itemDescription.value
                                    }
                                    placeholder="Enter Item Name"
                                    onClick={() => {
                                      this.props.publishRaffleTranslationOpen()
                                    }}
                                    tabIndex={-1}
                                  />
                                </div>
                                {!this.props.publishRaffleForm.fields
                                  .itemUrl.value ? (
                                    <div className="col-md-4 upload-image d-flex">
                                      <div
                                        className="upload-button d-flex justify-content-center align-items-center"
                                        onClick={() => this.props.publishRaffleTranslationOpen()}
                                      >
                                        <img
                                          src={CameraImage}
                                          className="img-fluid"
                                          alt=""
                                        />
                                        <p
                                          className={
                                            this.props.publishRaffleForm.fields
                                              .itemUrl.error
                                              ? "choose-photo mb-0 ml-2 effect-17"
                                              : "choose-photo mb-0 ml-2 effect-16"
                                          }
                                        >
                                          Upload Image
                                      </p>
                                      </div>
                                    </div>
                                  ) : (
                                    <div className="col-md-4 upload-image d-flex">
                                      <img
                                        src={
                                          this.props.publishRaffleForm.fields
                                            .itemUrl.value
                                        }
                                        className="profile-icon profile-pic-80"
                                        alt=""
                                      />
                                      <div
                                        className="upload-button d-flex justify-content-center align-items-center"
                                        onClick={this.removeUpload}
                                      >
                                        <a
                                          href="javascript:void(0)"
                                          className="btn btn-link text-primary"
                                        >
                                          Remove Image
                                      </a>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          ) : (
                              ""
                            )}
                          {this.state.pointsChecked ? (
                            <div className="col-sm-12">
                              <div className="row">
                                <div className="col-md-8">
                                  <AppInputComponent
                                    labelText="Enter points"
                                    divClass="form-group with-lable mt-4"
                                    inputClass={
                                      this.props.publishRaffleForm.fields.points
                                        .error
                                        ? "form-control effect-17"
                                        : "form-control effect-16"
                                    }
                                    value={
                                      this.props.publishRaffleForm.fields.points
                                        .value
                                    }
                                    placeholder="Enter Points"
                                    onChange={event => {
                                      this.props.publishRaffleFormResetError(
                                        "points"
                                      );
                                      if (event.target.value <= 100000) {
                                        this.props.publishRaffleFormUpdateField(
                                          "points",
                                          event.target.value
                                        );
                                      } else {
                                        this.props.publishRaffleFormUpdateField(
                                          "points",
                                          100000
                                        );
                                      }
                                    }}
                                    min="0"
                                    max="100000"
                                    maxLength="6"
                                    type="number"
                                    tabIndex={-1}
                                  />
                                </div>
                              </div>
                            </div>
                          ) : (
                              ""
                            )}
                          <div className="col-sm-12 mb-6">
                            <button
                              type="submit"
                              className="btn btn-pink py-3 btn-preview dark mw-150 mt-5 create-raffle-btn"
                            >
                              Create Raffle
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </>
    );
  }
}

export default CreateRaffleComponent;
