import { combineReducers } from "redux";
import { SidebarReducerCreator } from "./creators";
import {
  LeftSidebar,
} from "../redux-config/sidebarActionMap";
import { SB_OPEN_ANY, SB_CLOSE_ALL } from "../types/sidebars";

const sidebars = combineReducers({
  [LeftSidebar.stateModalKey]: SidebarReducerCreator(LeftSidebar),
  isAnySidebarOpen: (state = false, action) => {
    switch (action.type) {
      case SB_OPEN_ANY:
        return true;
      case SB_CLOSE_ALL:
        return false;
      default:
        return state;
    }
  }
});

export default sidebars;
