import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";

export default function(ComposedComponent) {
  class LoginHoc extends Component {
    render() {
      return (
        <>
          {!this.props.isAuthenticated ? (
            <ComposedComponent {...this.props} />
          ) : (
            <Redirect to="/dashboard" />
          )}
        </>
      );
    }
  }

  const mapStateToProps = state => {
    return {
      isAuthenticated: state.user.isLoggedIn
    };
  };
  return connect(mapStateToProps)(LoginHoc);
}
