import React, { Component } from "react";
import SwiperContainer from "../../../components/Slider/SwiperContainer";
import DashboardNewsCardComponent from "./DashboardNewsCardComponent";
import { getContainerLeftWidth } from "../../../utils/misc";

class DashboardNewsComponents extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slidesOffsetBefore: 0,
      calculating: true
    };
    this.calculateWidth = this.calculateWidth.bind(this);
  }

  componentDidMount() {
    this.calculateWidth();
  }
  calculateWidth() {
    this.setState((state, props) => {
      return {
        slidesOffsetBefore: getContainerLeftWidth(),
        calculating: false
      };
    });
  }

  render() {
    const { list } = this.props;
    const params = {
      fixDrag: true,
      slidesPerView: "auto",
      containerClass: "swiper-container",
      spaceBetween: 25,
      loop: false,
      centeredSlides: false,
      slidesOffsetBefore: this.state.slidesOffsetBefore + 20,
      slidesOffsetAfter: 50,
      breakpoints: {
        1024: {
          initialSlide: 0
        },
        768: {
          initialSlide: 0,
          slidesOffsetBefore: this.state.slidesOffsetBefore + 15
        },
        640: {
          initialSlide: 0,
          spaceBetween: 18,
          slidesOffsetBefore: 15,
          slidesOffsetAfter: 15
        },
        320: {
          initialSlide: 0,
          spaceBetween: 18,
          slidesOffsetBefore: 15,
          slidesOffsetAfter: 15,
          touchRatio: 1
        }
      }
    };

    return !this.state.calculating && list !== undefined && list.length > 0 ? (
      !this.props.newsCategory ? (
        <SwiperContainer {...params}>
          {list.map((item, index) => (
            <DashboardNewsCardComponent {...item} key={index} />
          ))}
        </SwiperContainer>
      ) : (
        <SwiperContainer {...params}>
          {list
            .filter(data => data.categoryName === this.props.newsCategory)
            .map((item, index) => (
              <DashboardNewsCardComponent
                {...item}
                key={index}
                displayContent={true}
              />
            ))}
        </SwiperContainer>
      )
    ) : (
      ""
    );
  }
}

export default DashboardNewsComponents;
