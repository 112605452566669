import React, { Component } from "react";
import { connect } from "react-redux";
import { createChallengeForm } from "../../../actions/forms";
import Select2Component from "../../Select2/Select2Component";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select2ComponentId from "../../Select2/Select2ComponentId";
import moment from "moment";

class TimeAndDateComponent extends Component {
  constructor(props) {
    super(props);
    this.handleChange = this.handleChange.bind(this);
    this.convertDateFormat = this.convertDateFormat.bind(this);
    this.state = {
      itemsChecked: true,
      pointsChecked: false,
      showUpload: true,
      startDate: undefined,
      endDate: undefined,
      zIndex: "zIndex-11"
    };
  }

  convertDateFormat(date1) {
    let date = moment(date1).format("YYYY-MM-DD");
    return date;
  }

  handleChange(date, dateType) {
    if (dateType === "start") {
      this.setState({
        startDate: date
      });

      this.props.createChallengeFormResetError("tournamentStartDate");
      this.props.createChallengeFormUpdateField(
        "tournamentStartDate",
        this.convertDateFormat(date)
      );
    }
    if (dateType === "end") {
      this.setState({
        endDate: date
      });

      this.props.createChallengeFormResetError("tournamentEndingDate");
      this.props.createChallengeFormUpdateField(
        "tournamentEndingDate",
        this.convertDateFormat(date)
      );
    }
  }
  render() {
    const selectTournamentStartOption = [
      { id: 1, value: "1 Day before start" },
      { id: 2, value: "2 Day before start" },
      { id: 3, value: "3 Day before start" },
      { id: 4, value: "4 Day before start" },
      { id: 5, value: "5 Day before start" },
      { id: 6, value: "6 Day before start" },
      { id: 7, value: "7 Day before start" },
      { id: 8, value: "8 Day before start" },
      { id: 9, value: "9 Day before start" },
      { id: 10, value: "10 Day before start" },
      { id: 11, value: "11 Day before start" },
      { id: 12, value: "12 Day before start" },
      { id: 13, value: "13 Day before start" },
      { id: 14, value: "14 Day before start" }
    ];
    const selectHourOption = [
      { id: 0, value: "0" },
      { id: 1, value: "1" },
      { id: 2, value: "2" },
      { id: 3, value: "3" },
      { id: 4, value: "4" },
      { id: 5, value: "5" },
      { id: 6, value: "6" },
      { id: 7, value: "7" },
      { id: 8, value: "8" },
      { id: 9, value: "9" },
      { id: 10, value: "10" },
      { id: 11, value: "11" },
      { id: 12, value: "12" },
      { id: 13, value: "13" },
      { id: 14, value: "14" },
      { id: 15, value: "15" },
      { id: 16, value: "16" },
      { id: 17, value: "17" },
      { id: 18, value: "18" },
      { id: 19, value: "19" },
      { id: 20, value: "20" },
      { id: 21, value: "21" },
      { id: 22, value: "22" },
      { id: 23, value: "23" }
    ];
    const selectMinOption = [
      { id: 0, value: "0" },
      { id: 1, value: "1" },
      { id: 2, value: "2" },
      { id: 3, value: "3" },
      { id: 4, value: "4" },
      { id: 5, value: "5" },
      { id: 6, value: "6" },
      { id: 7, value: "7" },
      { id: 8, value: "8" },
      { id: 9, value: "9" },
      { id: 10, value: "10" },
      { id: 11, value: "11" },
      { id: 12, value: "12" },
      { id: 13, value: "13" },
      { id: 14, value: "14" },
      { id: 15, value: "15" },
      { id: 16, value: "16" },
      { id: 17, value: "17" },
      { id: 18, value: "18" },
      { id: 19, value: "19" },
      { id: 20, value: "20" },
      { id: 21, value: "21" },
      { id: 22, value: "22" },
      { id: 23, value: "23" },
      { id: 24, value: "24" },
      { id: 25, value: "25" },
      { id: 26, value: "26" },
      { id: 27, value: "27" },
      { id: 28, value: "28" },
      { id: 29, value: "29" },
      { id: 30, value: "30" },
      { id: 31, value: "31" },
      { id: 32, value: "32" },
      { id: 33, value: "33" },
      { id: 34, value: "34" },
      { id: 35, value: "35" },
      { id: 36, value: "36" },
      { id: 37, value: "37" },
      { id: 38, value: "38" },
      { id: 39, value: "39" },
      { id: 40, value: "40" },
      { id: 41, value: "41" },
      { id: 42, value: "42" },
      { id: 43, value: "43" },
      { id: 44, value: "44" },
      { id: 45, value: "45" },
      { id: 46, value: "46" },
      { id: 47, value: "47" },
      { id: 48, value: "48" },
      { id: 49, value: "49" },
      { id: 50, value: "40" },
      { id: 51, value: "51" },
      { id: 52, value: "52" },
      { id: 53, value: "53" },
      { id: 54, value: "54" },
      { id: 55, value: "55" },
      { id: 56, value: "56" },
      { id: 57, value: "57" },
      { id: 58, value: "58" },
      { id: 59, value: "59" }
    ];
    return (
      <>
        <div className="container">
          <div className="padder-main">
            <div className="row">
              <div className="col-sm-12  mt-2 mb-5">
                <h1>
                  Challenge{" "}
                  {this.props.createChallengeForm.fields.challengeName.value}
                </h1>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12 col-lg-6  ">
                <div className="row ">
                  <div className="col-sm-12">
                    <h5 className="bold">Tournament starting</h5>
                    <div className="form-group with-lable mt-4 zIndex-15">
                      <div className="input-group-wrapper">
                        <DatePicker
                          selected={this.state.startDate}
                          onChange={e => this.handleChange(e, "start")}
                          minDate={new Date()}
                          className={
                            this.props.createChallengeForm.fields
                              .tournamentStartDate.error
                              ? "form-control effect-17"
                              : "form-control effect-16"
                          }
                          placeholderText="Date"
                          dateFormat="yyyy-MM-dd"
                          tabIndex={1}
                          // calendarClassName="zIndex-3"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 mt-4">
                    <h5 className="bold">Time</h5>
                    <div className="row">
                      <div className="col-sm-6">
                        <div className="form-group with-lable mt-4 zIndex-14">
                          <div className="input-group-wrapper">
                            <Select2Component
                              selectClass={
                                this.props.createChallengeForm.fields
                                  .tournamentHours.error
                                  ? "form-control effect-17 select-2-input"
                                  : "form-control effect-16 select-2-input"
                              }
                              selectOptions={selectHourOption}
                              labelText={"Select hours"}
                              selectedValue={
                                this.props.createChallengeForm.fields
                                  .tournamentHours.value
                              }
                              onChange={event => {
                                this.props.createChallengeFormResetError(
                                  "tournamentHours"
                                );

                                this.props.createChallengeFormUpdateField(
                                  "tournamentHours",
                                  event
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group with-lable mt-4 zIndex-13">
                          <div className="input-group-wrapper">
                            <Select2Component
                              selectClass={
                                this.props.createChallengeForm.fields
                                  .tournamentMinutes.error
                                  ? "form-control effect-17 select-2-input"
                                  : "form-control effect-16 select-2-input"
                              }
                              selectOptions={selectMinOption}
                              labelText={"Select minutes"}
                              selectedValue={
                                this.props.createChallengeForm.fields
                                  .tournamentMinutes.value
                              }
                              onChange={event => {
                                this.props.createChallengeFormResetError(
                                  "tournamentMinutes"
                                );

                                this.props.createChallengeFormUpdateField(
                                  "tournamentMinutes",
                                  event
                                );
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 mt-4">
                    <h5 className="bold">Tournament visible</h5>
                    <div className=" form-group with-lable mt-4 zIndex-12">
                      <div className="input-group-wrapper">
                        <Select2ComponentId
                          selectClass={
                            this.props.createChallengeForm.fields
                              .tournamentVisible.error
                              ? "form-control effect-17 select-2-input"
                              : "form-control effect-16 select-2-input"
                          }
                          selectOptions={selectTournamentStartOption}
                          onChange={event => {
                            this.props.createChallengeFormResetError(
                              "tournamentVisible"
                            );

                            this.props.createChallengeFormUpdateField(
                              "tournamentVisible",
                              event.id
                            );
                          }}
                          labelText={"Please Select"}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 mt-4">
                    <h5 className="bold">Tournament ending</h5>
                    <div
                      className={
                        "form-group with-lable mt-4 " + this.state.zIndex
                      }
                      onClick={() => {
                        this.setState({
                          zIndex: "zIndex-14"
                        });
                      }}
                    >
                      <div className="input-group-wrapper">
                        <DatePicker
                          selected={this.state.endDate}
                          onChange={e => this.handleChange(e, "end")}
                          minDate={new Date()}
                          className={
                            this.props.createChallengeForm.fields
                              .tournamentEndingDate.error
                              ? "form-control effect-17"
                              : "form-control effect-16"
                          }
                          placeholderText="Date"
                          dateFormat="yyyy-MM-dd"
                          tabIndex={1}
                          calendarClassName="zIndex-15"
                          onBlur={() => {
                            this.setState({
                              zIndex: "zIndex-11"
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-lg-6 section-info mt-5 mt-lg-0">
                <h5 className="bold">Overview</h5>
                <table className="table table-md mt-4">
                  <tbody>
                    <tr>
                      <td scope="row" className="col-7">
                        Tournament type
                      </td>
                      <th className="col">arcadex</th>
                    </tr>
                    <tr>
                      <td scope="row" className="col-7">
                        Game
                      </td>
                      <th className="col">
                        {this.props.createChallengeForm.fields.gamesName.value}
                      </th>
                    </tr>
                    <tr>
                      <td scope="row" className="col-7">
                        Win Criteria
                      </td>
                      <th className="col">Top score</th>
                    </tr>
                    <tr>
                      <td scope="row" className="col-7">
                        Subscription
                      </td>
                      <th className="col">
                        {this.props.createChallengeForm.fields
                          .selectSubscription.value === 1
                          ? "None"
                          : ""}
                      </th>
                    </tr>
                    <tr>
                      <td scope="row" className="col-7">
                        Players
                      </td>
                      <th className="col">
                        {" "}
                        {
                          this.props.createChallengeForm.fields.selectPlayer
                            .value
                        }
                      </th>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {
  createChallengeFormSubmit: createChallengeForm.submit,
  createChallengeFormUpdateField: createChallengeForm.updateField,
  createChallengeFormReset: createChallengeForm.reset,
  createChallengeFormResetField: createChallengeForm.resetField,
  createChallengeFormSetError: createChallengeForm.setError,
  createChallengeFormSetSuccess: createChallengeForm.setSuccess,
  createChallengeFormResetError: createChallengeForm.resetError,
  createChallengeFormResetSuccess: createChallengeForm.resetSuccess
};

const mapStateToProps = state => {
  const { forms } = state;
  return {
    createChallengeForm: forms.createChallengeForm
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimeAndDateComponent);
