import React, { Component } from "react";
import "./LeaderBoard.scss";
import adv7 from "../../../assets/images/adv/adv-2.png";
import { ordinalSuffix } from "../../../utils/misc";
import Profileimg from "../../../assets/images/avatar-demo.png";
import Rank_1 from "../../../assets/images/svg/rank-no-1.svg";
import Rank_2 from "../../../assets/images/svg/rank-no-2.svg";

class LeaderBoard extends Component {
  render() {
    let positionIcon1 = 1;
    let positionIcon2 = 2;
    let positionText1 = 1;
    let positionText2 = 2;
    return (
      <section className="container-fluid leader-board">
        <div className="row padder leaderboard">
          <div className="container">
            <div className="row d-flex justify-content-center">
              <div className="col-lg-12 text-center">
                <h2 className="mb-5-half bold">Leaderboard</h2>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-lg-12 text-center">
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-today"
                    role="tabpanel"
                    aria-labelledby="pills-today-tab"
                  >
                    <div className="row d-flex justify-content-center score-info">
                      <div className="col-lg-12 col-xl-9 text-center score-info-wrapper">
                        <div className="row d-flex align-items-center justify-content-center mobile-panel">
                          <div className="col br-1 order-1 order-lg-1">
                            <p className="small semi-bold">Your Rank</p>
                            <h4
                              className="bold text-secondary"
                              dangerouslySetInnerHTML={{
                                __html: 0 === "-" ? "-" : ordinalSuffix(0, true)
                              }}
                            />
                          </div>

                          <div className="col order-3 order-lg-2">
                            <p className="small semi-bold">Your Score</p>
                            <h4 className="bold text-secondary">0</h4>
                          </div>
                          <div className="col-auto profile-pic order-2 order-lg-3">
                            <figure>
                              <img
                                src={Profileimg}
                                alt=""
                                className="profile-pic-96"
                              />
                              <figcaption className="online-96" />
                            </figure>
                          </div>
                          <div className="col br-1 d-none d-lg-block order-lg-4">
                            <p className="small semi-bold">Games Played</p>
                            <h4 className="bold text-secondary">0</h4>
                          </div>
                          <div className="col d-none d-lg-block order-lg-5">
                            <p className="small semi-bold d-none d-lg-block">
                              Tokens Earned
                            </p>
                            <h4 className="bold text-secondary">0</h4>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row d-lg-flex justify-content-center scoreboard-header mt-5">
                      <div className="col-lg-12 col-xl-9 text-center scoreboard-header-wrapper">
                        <div className="row d-flex align-items-center justify-content-center p-3">
                          <div className="col-auto text-left text-white small bold">
                            Rank
                          </div>
                          <div className="col text-left text-white small bold">
                            Player
                          </div>
                          <div className="col text-right text-white small bold">
                            Score
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row d-flex justify-content-center scoreboard mt-2 mt-lg-1">
                      <div className="col-lg-12 col-xl-9 text-center scoreboard-wrapper">
                        <div className="d-flex align-items-center justify-content-center">
                          <ul className="list-unstyled w-100 mt-2">
                            <li className="scoreboard-row bb-1 py-2">
                              <ul className="list-inline w-100 d-flex align-items-center justify-content-center">
                                <li className="list-inline-item d-flex align-items-center justify-content-center text-left">
                                  {positionIcon1 ? (
                                    <img src={Rank_1} alt="" className="rank" />
                                  ) : (
                                    <h6 className="bold">{positionText1}</h6>
                                  )}
                                </li>
                                <li className="list-inline-item flex-fill text-left ml-0 ml-lg-4 fixed-name">
                                  <div className="d-flex justify-content-center align-items-center">
                                    <figure>
                                      <img
                                        src={Profileimg}
                                        className="profile-pic-56"
                                        alt=""
                                      />
                                    </figure>
                                    <p className="bold ml-3 text-truncate player-name">
                                      Sonal
                                    </p>
                                  </div>
                                </li>
                                <li className="list-inline-item flex-fill text-right fixed-score">
                                  <span className="h6 bold text-truncate text-secondary">
                                    0
                                  </span>
                                </li>
                              </ul>
                            </li>
                            <li className="scoreboard-row bb-1 py-2">
                              <ul className="list-inline w-100 d-flex align-items-center justify-content-center">
                                <li className="list-inline-item d-flex align-items-center justify-content-center text-left">
                                  {positionIcon2 ? (
                                    <img src={Rank_2} alt="" className="rank" />
                                  ) : (
                                    <h6 className="bold">{positionText2}</h6>
                                  )}
                                </li>
                                <li className="list-inline-item flex-fill text-left ml-0 ml-lg-4 fixed-name">
                                  <div className="d-flex justify-content-center align-items-center">
                                    <figure>
                                      <img
                                        src={Profileimg}
                                        className="profile-pic-56"
                                        alt=""
                                      />
                                    </figure>
                                    <p className="bold ml-3 text-truncate player-name">
                                      Ravina
                                    </p>
                                  </div>
                                </li>
                                <li className="list-inline-item flex-fill text-right fixed-score">
                                  <span className="h6 bold text-truncate text-secondary">
                                    0
                                  </span>
                                </li>
                              </ul>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="pills-overall"
                    role="tabpanel"
                    aria-labelledby="pills-overall-tab"
                  >
                    OverAll
                  </div>
                </div>
              </div>
            </div>

            <div className="container">
              <div className="row">
                <div className="col-sm-12 d-flex justify-content-center ">
                  <div
                    className="banner-advertising sm d-none d-lg-block mt-6"
                    id="adv-3"
                  >
                    {this.props.advertisementPlace == 3 ? (
                      <img
                        src={this.props.advertisementUrl}
                        alt=""
                        className="d-none d-lg-block"
                      />
                    ) : (
                      <img
                        src={adv7}
                        alt=""
                        className="d-none d-lg-block disabledAd"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}
export default LeaderBoard;
