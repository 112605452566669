import { connect } from "react-redux";
import UpdateNewsComponent from "../../components/News/UpdateNewsComponent";
import { newsCategoriesList, newsDetails } from "../../actions/news";
import { updateNewsForm } from "../../actions/forms";

const mapDispatchToProps = {
  fetchNewsCategoriesIfNeeded: newsCategoriesList.fetchIfNeeded,
  invalidateNewsCategories: newsCategoriesList.invalidate,

  //news details
  fetchNewsDetailsIfNeeded: newsDetails.fetchIfNeeded,
  invalidateNewsDetails: newsDetails.invalidate,

  //News Form
  updateNewsFormSubmit: updateNewsForm.submit,
  updateNewsFormReset: updateNewsForm.reset,
  updateNewsFormResetField: updateNewsForm.resetField,
  updateNewsFormUpdateField: updateNewsForm.updateField,
  updateNewsFormSetError: updateNewsForm.setError,
  updateNewsFormSetSuccess: updateNewsForm.setSuccess,
  updateNewsFormResetError: updateNewsForm.resetError,
  updateNewsFormResetSuccess: updateNewsForm.resetSuccess
};

const mapStateToProps = state => {
  const { news, forms } = state;
  return {
    newsCategoriesList: news.newsCategoriesList ? news.newsCategoriesList : [],
    updateNewsForm: forms.updateNewsForm,
    newsDetails: news.newsDetails
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateNewsComponent);
