import React, { Component } from "react";
import { connect } from "react-redux";
import AppInputComponent from "../../../layouts/components/sidebars/AppInputComponent";
import PaginationComponent from "../../Pagination/Pagination";
import { analyzeForm } from "../../../actions/forms";
import { analyzeListViewAll } from "../../../actions/bans";

class AnalyzeComponent extends Component {
  constructor(props) {
    super(props);
    this.fetchAnalyze = this.fetchAnalyze.bind(this);
    this.state = {
      page: 1,
      isSearch: false
    };
  }

  fetchAnalyze(page) {
    if (!this.props.analyzeForm.fields.profileId.value) {
      this.props.analyzeFormSetError("profileId", "NOT_EMPTY");
      return false;
    }
    if (!this.props.analyzeForm.fields.challengeId.value) {
      this.props.analyzeFormSetError("challengeId", "NOT_EMPTY");
      return false;
    }

    this.setState({
      page: page
    });
    this.props.invalidateAnalyzeListViewAll();
    this.props.fetchAnalyzeListViewAllIfNeeded({
      page: page,
      challengeId: this.props.analyzeForm.fields.challengeId.value,
      profileId: this.props.analyzeForm.fields.profileId.value,
      searchByScore: "",
      sortBy: "",
      sortOrder: ""
    });

    setTimeout(() => {
      this.setState({
        isSearch: true
      });
    }, 200);
  }

  getAllUserData() {
    this.props.invalidateAnalyzeListViewAll();
    this.props.fetchAnalyzeListViewAllIfNeeded({
      page: "1",
      challengeId: this.props.analyzeForm.fields.challengeId.value,
      profileId: this.props.analyzeForm.fields.profileId.value,
      searchByScore: this.props.analyzelistSearch.score
        ? this.props.analyzelistSearch.score
        : "",
      sortBy: this.props.analyzelistSort.field,
      sortOrder: this.props.analyzelistSort.orderBy
    });
  }

  render() {
    const {
      analyzelist,
      analyzeTotalCount,
      analyzeTotalPage,
      isAnalyzeRecordDisplayed,
      isAnalyzeLastPage
    } = this.props;
    return (
      <>
        <div className="row justify-content-center align-items-center">
          <div className="col-sm-3 mb-4">
            <div className="form-group with-lable mt-4 ">
              <AppInputComponent
                min="0"
                type="number"
                placeholder="Profile Id"
                inputClass={
                  this.props.analyzeForm.fields.profileId.error
                    ? "form-control effect-17"
                    : "form-control effect-16"
                }
                onChange={e => {
                  this.props.analyzeFormResetError("profileId");
                  this.props.analyzeFormUpdateField(
                    "profileId",
                    e.target.value
                  );
                }}
              />
            </div>
            <div className="form-group with-lable mt-4 ">
              <AppInputComponent
                min="0"
                type="number"
                placeholder="Challenge Id"
                inputClass={
                  this.props.analyzeForm.fields.challengeId.error
                    ? "form-control effect-17"
                    : "form-control effect-16"
                }
                onChange={e => {
                  this.props.analyzeFormResetError("challengeId");
                  this.props.analyzeFormUpdateField(
                    "challengeId",
                    e.target.value
                  );
                }}
              />
            </div>
            <div className="row justify-content-center align-items-center">
              <div className="form-group with-lable mt-4 ">
                <button
                  onClick={() => {
                    this.fetchAnalyze(this.state.page);
                  }}
                  type="button"
                  className="[ btn btn-pink light  py-3 btn-preview dark mt-2 ml-4]"
                >
                  Search
                </button>
              </div>
            </div>
          </div>
        </div>

        {this.state.isSearch ? (
          <>
            <div className="row ">
              <div className="col-sm-12 table-responsive admin-table">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th
                        width="20%"
                        scope="col"
                        className={
                          "text-center sortable " +
                          (this.props.analyzelistSort.field === "score"
                            ? this.props.analyzelistSort.orderBy
                            : "")
                        }
                        onClick={e => {
                          this.props.setSortByAnalyzeListViewAll("score");
                          setTimeout(() => {
                            this.getAllUserData();
                          }, 100);
                        }}
                      >
                        Score
                      </th>
                      <th width="30%" scope="col " className="text-center">
                        Time Of Start
                      </th>
                      <th width="30%" scope="col" className="text-center">
                        Time Of End
                      </th>
                      <th width="20%" scope="col" className="text-center">
                        Time Difference
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        <div className="form-group with-lable">
                          <div className="input-group-wrapper">
                            <input
                              type="number"
                              placeholder="Search by Score"
                              className="form-control effect-16"
                              min="0"
                              onChange={e => {
                                this.props.setSearchByAnalyzeListViewAll(
                                  "score",
                                  e.target.value
                                );
                                setTimeout(() => {
                                  this.getAllUserData();
                                }, 100);
                              }}
                            />
                            <span className="focus-border" />
                          </div>
                        </div>
                      </td>
                      <td className="text-center" />
                      <td className="text-center" />
                      <td className="text-center" />
                    </tr>
                    {analyzelist.length > 0 ? (
                      analyzelist.map((analyze, index) => {
                        return (
                          <tr key={index}>
                            <td>{analyze.score ? analyze.score : 0}</td>
                            <td className="text-center">
                              {analyze.timeOfStart}
                            </td>
                            <td className="text-center">{analyze.timeOfEnd}</td>
                            <td className="text-center">
                              {analyze.timeDifference}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr className="text-center">
                        <td colSpan="5">No Records Found</td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
              {analyzelist.length > 0 ? (
                <div className="col-sm-12">
                  <PaginationComponent
                    totalPage={analyzeTotalPage}
                    totalCount={analyzeTotalCount}
                    recordDisplayed={isAnalyzeRecordDisplayed}
                    isLastPage={isAnalyzeLastPage}
                    invalidateAllAction={
                      this.props.invalidateAnalyzeListViewAll
                    }
                    fetchListAllAction={
                      this.props.fetchAnalyzeListViewAllIfNeeded
                    }
                    sortBy={this.props.analyzelistSort.field}
                    sort={this.props.analyzelistSort.orderBy}
                    searchSort={
                      this.props.analyzelistSearch.score ? true : false
                    }
                    param={{
                      profileId: this.props.analyzeForm.fields.profileId.value,
                      challengeId: this.props.analyzeForm.fields.challengeId
                        .value,
                      searchByScore: this.props.analyzelistSearch.score
                        ? this.props.analyzelistSearch.score
                        : "",
                      sortBy: this.props.analyzelistSort.field,
                      sortOrder: this.props.analyzelistSort.orderBy
                    }}
                    resetSearchPage={[
                      this.props.analyzelistSearch.score
                        ? this.props.analyzelistSearch.score
                        : ""
                    ]}
                  />
                </div>
              ) : (
                ""
              )}
            </div>
          </>
        ) : (
          ""
        )}
      </>
    );
  }
}

const mapDispatchToProps = {
  fetchAnalyzeListViewAllIfNeeded: analyzeListViewAll.fetchIfNeeded,
  invalidateAnalyzeListViewAll: analyzeListViewAll.invalidate,
  setSearchByAnalyzeListViewAll: analyzeListViewAll.setSearchBy,
  setSortByAnalyzeListViewAll: analyzeListViewAll.setSortBy,

  analyzeFormReset: analyzeForm.reset,
  analyzeFormResetField: analyzeForm.resetField,
  analyzeFormUpdateField: analyzeForm.updateField,
  analyzeFormSetError: analyzeForm.setError,
  analyzeFormResetError: analyzeForm.resetError
};

const mapStateToProps = state => {
  const { forms, bans } = state;
  return {
    analyzeForm: forms.analyzeForm,
    analyzelist: bans.analyzelist ? bans.analyzelist.items : [],
    analyzelistSort: bans.analyzelist.sort,
    analyzelistSearch: bans.analyzelist.search,
    isAnalyzeLastPage: bans.analyzelist.isLastPage,
    analyzeTotalPage: bans.analyzelist.totalPage
      ? bans.analyzelist.totalPage
      : 0,
    analyzeTotalCount: bans.analyzelist.totalCount
      ? bans.analyzelist.totalCount
      : 0,
    isAnalyzeRecordDisplayed: bans.analyzelist.recordDisplayed
      ? bans.analyzelist.recordDisplayed
      : 0
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AnalyzeComponent);
