import { connect } from "react-redux";
import DashboardComponent from "../components/Dashboard/DashboardComponent";
import {
  dashboardListViewAll,
  dashboardAdvertisementPublishDetails,
  dashboardAdvertisementRevokeDetails
} from "../actions/dashboard";

const mapDispatchToProps = {
  fetchDashboardListViewAllData: dashboardListViewAll.fetchData,

  fetchDashboardListViewAllIfNeeded: dashboardListViewAll.fetchIfNeeded,
  invalidateDashboardListViewAll: dashboardListViewAll.invalidate,

  setSortByDashboardListViewAll: dashboardListViewAll.setSortBy,
  setSearchByDashboardListViewAll: dashboardListViewAll.setSearchBy,
  resetSearchSortDashboardListViewAll: dashboardListViewAll.resetSearchAndSort,

  fetchDashboardAdvertisementPublishDetailsIfNeeded:
    dashboardAdvertisementPublishDetails.fetchIfNeeded,
  invalidateDashboardAdvertisementPublishDetails:
    dashboardAdvertisementPublishDetails.invalidate,

  fetchDashboardAdvertisementRevokeDetailsIfNeeded:
    dashboardAdvertisementRevokeDetails.fetchIfNeeded,
  invalidateDashboardAdvertisementRevokeDetails:
    dashboardAdvertisementRevokeDetails.invalidate
};

const mapStateToProps = state => {
  const { dashboard, user } = state;
  return {
    userId: user.item ? user.item.id : null,
    dashboardlist: dashboard.dashboardlist ? dashboard.dashboardlist.items : [],
    isLastPage: dashboard.dashboardlist
      ? dashboard.dashboardlist.isLastPage
      : 0,
    dashboardTotalPage: dashboard.dashboardlist.totalPage
      ? dashboard.dashboardlist.totalPage
      : 0,
    dashboardTotalCount: dashboard.dashboardlist.totalCount
      ? dashboard.dashboardlist.totalCount
      : 0,
    isRecordDisplayed: dashboard.dashboardlist.recordDisplayed
      ? dashboard.dashboardlist.recordDisplayed
      : 0,
    searchDashboardAds: dashboard.dashboardlist.search,
    sortDashboardAds: dashboard.dashboardlist.sort,
    dashboardAdvertisementList: user.item ? user.item.adminStats : []
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(DashboardComponent);
