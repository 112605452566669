export const Login = {
  code: "LOGIN",
  stateKey: "modals",
  stateSubKey: "isLoginOpen"
};
export const ForgotPassword = {
  code: "FORGOT_PASSWORD",
  stateKey: "modals",
  stateSubKey: "isForgotPasswordOpen"
};
export const ResetPassword = {
  code: "RESET_PASSWORD",
  stateKey: "modals",
  stateSubKey: "isResetPasswordOpen"
};
export const ChangePassword = {
  code: "CHANGE_PASSWORD",
  stateKey: "modals",
  stateSubKey: "isChangePasswordOpen"
};
export const RafflesRepeat = {
  code: "RAFFLES_REPEAT",
  stateKey: "modals",
  stateSubKey: "isRafflesRepeat"
};

export const CreateChallengeRewardsTranslation = {
  code: "CREATE_CHALLENGE_REWARDS_TRANSLATION",
  stateKey: "modals",
  stateSubKey: "isCreateChallengeRewardsTranslation"
};

export const PublishRaffleTranslation = {
  code: "PUBLISH_RAFFLE_TRANSLATION",
  stateKey: "modals",
  stateSubKey: "isPublishRaffleTranslation"
};

export const ResetLeaderboard = {
  code: "RESET_BRAND_LEADERBOARD",
  stateKey: "modals",
  stateSubKey: "isResetLeaderboard"
};