import { combineReducers } from "redux";
import { ToggleReducerCreator } from "./creators";
import {
  PasswordEyeLogin,
  PasswordEyeResetPass,
  PasswordEyeChangeOldPass,
  PasswordEyeChangePass,
  // GameFullScreen
} from "../redux-config/toggleActionMap";

const toggles = combineReducers({
  [PasswordEyeLogin.stateSubKey]: ToggleReducerCreator(PasswordEyeLogin),
  [PasswordEyeResetPass.stateSubKey]: ToggleReducerCreator(
    PasswordEyeResetPass
  ),
  [PasswordEyeChangePass.stateSubKey]: ToggleReducerCreator(
    PasswordEyeChangePass
  ),
  [PasswordEyeChangeOldPass.stateSubKey]: ToggleReducerCreator(
    PasswordEyeChangeOldPass
  ),
  // [GameFullScreen.stateSubKey]: ToggleReducerCreator(GameFullScreen)
});
export default toggles;
