import { FormReducerCreator } from "./creators";
import {
  LoginForm,
  UsernameExistsForm,
  ForgotPasswordForm,
  ResetPasswordForm,
  ChangePasswordForm,
  CreateChallengeForm,
  UpdateChallengeForm,
  CreateHostForm,
  UpdateHostForm,
  CreateNewsForm,
  PublishRaffleForm,
  UpdateNewsForm,
  UpdateBrandAdsForm,
  UpdateChallengeAdsForm,
  CreateDashboardAdvertisementForm,
  EditDashboardAdvertisementForm,
  AnalyzeForm,
  PublishBrandAdsForm,
  PublishChallengeAdsForm,
  RevokeBrandAdsForm,
  RevokeChallengeAdsForm,
  AddPointsForm,
  BanLiftedForm,
  AddBanUserForm,
  DeleteTournamentForm
} from "../redux-config/formActionMap";
import { combineReducers } from "redux";

const forms = combineReducers({
  [LoginForm.stateSubKey]: FormReducerCreator(LoginForm),
  [UsernameExistsForm.stateSubKey]: FormReducerCreator(UsernameExistsForm),
  [ForgotPasswordForm.stateSubKey]: FormReducerCreator(ForgotPasswordForm),
  [ResetPasswordForm.stateSubKey]: FormReducerCreator(ResetPasswordForm),
  [ChangePasswordForm.stateSubKey]: FormReducerCreator(ChangePasswordForm),
  [CreateChallengeForm.stateSubKey]: FormReducerCreator(CreateChallengeForm),
  [UpdateChallengeForm.stateSubKey]: FormReducerCreator(UpdateChallengeForm),
  [CreateHostForm.stateSubKey]: FormReducerCreator(CreateHostForm),
  [UpdateHostForm.stateSubKey]: FormReducerCreator(UpdateHostForm),
  [CreateNewsForm.stateSubKey]: FormReducerCreator(CreateNewsForm),
  [PublishRaffleForm.stateSubKey]: FormReducerCreator(PublishRaffleForm),
  [UpdateNewsForm.stateSubKey]: FormReducerCreator(UpdateNewsForm),
  [UpdateBrandAdsForm.stateSubKey]: FormReducerCreator(UpdateBrandAdsForm),
  [UpdateChallengeAdsForm.stateSubKey]: FormReducerCreator(
    UpdateChallengeAdsForm
  ),
  [PublishRaffleForm.stateSubKey]: FormReducerCreator(PublishRaffleForm),

  [PublishBrandAdsForm.stateSubKey]: FormReducerCreator(PublishBrandAdsForm),
  [PublishChallengeAdsForm.stateSubKey]: FormReducerCreator(
    PublishChallengeAdsForm
  ),
  [RevokeBrandAdsForm.stateSubKey]: FormReducerCreator(RevokeBrandAdsForm),
  [RevokeChallengeAdsForm.stateSubKey]: FormReducerCreator(
    RevokeChallengeAdsForm
  ),
  [CreateDashboardAdvertisementForm.stateSubKey]: FormReducerCreator(
    CreateDashboardAdvertisementForm
  ),
  [EditDashboardAdvertisementForm.stateSubKey]: FormReducerCreator(
    EditDashboardAdvertisementForm
  ),
  [AnalyzeForm.stateSubKey]: FormReducerCreator(AnalyzeForm),
  [AddPointsForm.stateSubKey]: FormReducerCreator(AddPointsForm),
  [BanLiftedForm.stateSubKey]: FormReducerCreator(BanLiftedForm),
  [AddBanUserForm.stateSubKey]: FormReducerCreator(AddBanUserForm),
  [DeleteTournamentForm.stateSubKey]: FormReducerCreator(DeleteTournamentForm),
});
export default forms;
