import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import "swiper/dist/css/swiper.css";
import navArrowRight from "../../assets/images/svg/nav-arrow-right-blue.svg";
import navCloseSvg from "../../assets/images/svg/nav-close.svg";
import cameraSvg from "../../assets/images/svg/camera.svg";
import Select2ComponentId from "../Select2/Select2ComponentId";
import DatePicker from "react-datepicker";
import { fileUpload } from "../../services/fileUpload.service";
import { APP_MESSAGES } from "../../services/config/messages";
import DatePickerInput from "../Utils/DatePickerInput";

class CreateWebComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      label: "Price Pool Medium Rectangle",
      height: "300",
      width: "250",
      type: ""
    };
    this.handleChangeStart = this.handleChangeStart.bind(this);
    this.handleChangeEnd = this.handleChangeEnd.bind(this);
    this.callDatedashboardDisabledAdvertisementsList = this.callDatedashboardDisabledAdvertisementsList.bind(
      this
    );
    this.getDateArray = this.getDateArray.bind(this);

    this.uploadLogo = this.uploadLogo.bind(this);
    this.removeImage = this.removeImage.bind(this);
  }

  handleChangeStart(date) {
    this.props.createDashboardAdvertisementFormResetError("startAt");
    this.props.createDashboardAdvertisementFormUpdateField("startAt", date);
    if (
      date >
      new Date(this.props.createDashboardAdvertisementForm.fields.endAt.value)
    ) {
      this.props.createDashboardAdvertisementFormUpdateField("endAt", date);
    }
  }

  handleChangeEnd(date) {
    this.props.createDashboardAdvertisementFormResetError("endAt");
    this.props.createDashboardAdvertisementFormUpdateField("endAt", date);
    if (
      date <
      new Date(this.props.createDashboardAdvertisementForm.fields.startAt.value)
    ) {
      this.props.createDashboardAdvertisementFormUpdateField("startAt", date);
    }
  }

  componentDidMount() {
    this.props.invalidateHost();
    this.props.fetchHostIfNeeded({
      page: ""
    });
  }

  callDatedashboardDisabledAdvertisementsList(placeId) {
    this.props.invaliDatedashboardDisabledAdvertisementsList();
    this.props.fetchDashboardDisabledAdvertisementsListIfNeeded({
      page: "",
      placeId: placeId
    });
  }

  removeImage(imgType) {
    this.props.createDashboardAdvertisementFormUpdateField(imgType, "");
  }

  ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  uploadLogo(event, imgType) {
    if (event.target.files.length > 0) {
      let fileSize = event.target.files[0].size / 1000000;
      let fileType = "";
      if (event.target.files[0].type == "") {
        fileType = "nothing";
      } else {
        fileType = event.target.files[0].type.split("/")[1].toLowerCase();
      }

      this.props.createDashboardAdvertisementFormResetError(imgType);
      this.props.createDashboardAdvertisementFormResetError("imageSize");
      this.props.createDashboardAdvertisementFormResetError("imageType");

      //file size
      if (!(fileSize < 2)) {
        this.props.createDashboardAdvertisementFormSetError(
          imgType,
          "NOT_EMPTY"
        );
        this.props.createDashboardAdvertisementFormSetError(
          "imageSize",
          "NOT_EMPTY"
        );
        return false;
      } else {
        this.props.createDashboardAdvertisementFormResetError("imageSize");
      }

      //file type
      if (fileType == "jpeg" || fileType == "jpg" || fileType == "png") {
        this.props.createDashboardAdvertisementFormResetError("imageType");
      } else {
        this.props.createDashboardAdvertisementFormSetError(
          imgType,
          "NOT_EMPTY"
        );
        this.props.createDashboardAdvertisementFormSetError(
          "imageType",
          "NOT_EMPTY"
        );
        return false;
      }

      fileUpload(event.target.files)
        .then(url => {
          this.props.createDashboardAdvertisementFormUpdateField(imgType, url);
        })
        .catch(() => {
          console.log(this);
        });
    } else {
      this.props.createDashboardAdvertisementFormUpdateField(imgType, "");
    }
  }

  getDateArray(start, end) {
    var arr = new Array();
    var dt = new Date(start);
    while (dt <= end) {
      arr.push(new Date(dt));
      dt.setDate(dt.getDate() + 1);
    }
    return arr;
  }

  render() {
    let Error;
    let Success;

    Error = this.props.createDashboardAdvertisementForm.fields
      .dashboardAdvertisementPlaceId.errorMsg
      ? APP_MESSAGES[
          this.props.createDashboardAdvertisementForm.fields
            .dashboardAdvertisementPlaceId.errorMsg
        ](
          this.props.createDashboardAdvertisementForm.fields
            .dashboardAdvertisementPlaceId.errorPayload,
          () => {
            this.props.createDashboardAdvertisementFormResetError(
              "dashboardAdvertisementPlaceId"
            );
          }
        )
      : props => <>{""}</>;

    Success = this.props.createDashboardAdvertisementForm.fields
      .dashboardAdvertisementPlaceId.successMsg
      ? APP_MESSAGES[
          this.props.createDashboardAdvertisementForm.fields
            .dashboardAdvertisementPlaceId.successMsg
        ](
          this.props.createDashboardAdvertisementForm.fields
            .dashboardAdvertisementPlaceId.successPayload,
          () => {
            this.props.createDashboardAdvertisementFormResetSuccess(
              "dashboardAdvertisementPlaceId"
            );
          }
        )
      : props => <>{""}</>;

    let dashboardAdvertisementOptions = [];
    let labelHeightWidthById = [];
    let typesById = [];
    if (this.props.dashboardAdvertisementList.DASHBOARD) {
      let dashboardAdvertisementList = this.props.dashboardAdvertisementList
        .DASHBOARD;
      dashboardAdvertisementList.map(function(data) {
        dashboardAdvertisementOptions.push({ id: data.id, value: data.label });
        labelHeightWidthById[data.id] = {
          height: data.height,
          width: data.width,
          label: data.label
        };
        typesById[data.id] = { type: data.type };
      });
    }

    let brandOptions = [];
    let platform = "";
    if (this.props.hostList.items) {
      let hostList = this.props.hostList.items;
      let ownerBrandId = this.props.createDashboardAdvertisementForm.fields
        .ownerBrandId.value;
      hostList.map(function(data) {
        brandOptions.push({
          id: data.id,
          value: data.name
        });
        if (ownerBrandId == data.id) {
          platform = data.env.name;
        }
      });
    }

    let finalExcludeDatesArray = [];
    if (this.props.dashboardDisabledAdvertisementList.items) {
      let dashboardDisabledAdvertisementList = this.props
        .dashboardDisabledAdvertisementList.items;
      dashboardDisabledAdvertisementList.map((data, i) => {
        let startDate = new Date(data.startAt); //YYYY-MM-DD
        let endDate = new Date(data.endAt); //YYYY-MM-DD
        let betweenDatesArray = this.getDateArray(startDate, endDate);

        betweenDatesArray.map((data1, i1) => {
          finalExcludeDatesArray.push(data1);
        });
      });
    }

    return (
      <section className="premium" id="rootwizard">
        <section className="container-fluid tab-content-wrapper">
          <form
            onSubmit={e => {
              e.preventDefault();
              if (!this.props.createDashboardAdvertisementForm.isSubmitting) {
                if (
                  !this.props.createDashboardAdvertisementForm.fields
                    .dashboardAdvertisementPlaceId.value
                ) {
                  this.props.createDashboardAdvertisementFormSetError(
                    "dashboardAdvertisementPlaceId",
                    "NOT_EMPTY"
                  );
                }

                if (
                  !this.props.createDashboardAdvertisementForm.fields
                    .ownerBrandId.value
                ) {
                  this.props.createDashboardAdvertisementFormSetError(
                    "ownerBrandId",
                    "NOT_EMPTY"
                  );
                }

                if (
                  !this.props.createDashboardAdvertisementForm.fields.startAt
                    .value
                ) {
                  this.props.createDashboardAdvertisementFormSetError(
                    "startAt",
                    "NOT_EMPTY"
                  );
                }

                if (
                  !this.props.createDashboardAdvertisementForm.fields.endAt
                    .value
                ) {
                  this.props.createDashboardAdvertisementFormSetError(
                    "endAt",
                    "NOT_EMPTY"
                  );
                }

                if (
                  !this.props.createDashboardAdvertisementForm.fields.imageUrl
                    .value
                ) {
                  this.props.createDashboardAdvertisementFormSetError(
                    "imageUrl",
                    "NOT_EMPTY"
                  );
                }

                if (
                  this.props.createDashboardAdvertisementForm.fields
                    .dashboardAdvertisementPlaceId.value &&
                  this.props.createDashboardAdvertisementForm.fields
                    .ownerBrandId.value &&
                  this.props.createDashboardAdvertisementForm.fields.startAt
                    .value &&
                  this.props.createDashboardAdvertisementForm.fields.endAt
                    .value &&
                  this.props.createDashboardAdvertisementForm.fields.imageUrl
                    .value
                ) {
                  this.props.createDashboardAdvertisementFormSubmit({
                    dashboardAdvertisementPlaceId: this.props
                      .createDashboardAdvertisementForm.fields
                      .dashboardAdvertisementPlaceId.value,
                    ownerBrandId: this.props.createDashboardAdvertisementForm
                      .fields.ownerBrandId.value,
                    startAt: this.props.createDashboardAdvertisementForm.fields
                      .startAt.value,
                    endAt: this.props.createDashboardAdvertisementForm.fields
                      .endAt.value,
                    imageUrl: this.props.createDashboardAdvertisementForm.fields
                      .imageUrl.value,
                    impressionCount: 0
                  });
                }
              }
            }}
            tabIndex={-1}
          >
            <div className="row">
              <div className="col-sm-12">
                <div>
                  <div className="container mt-6">
                    <div className="padder-main ">
                      <div className="row">
                        <div className="col-sm-12 text-right d-flex justify-content-start mb-4 back-arrow">
                          <a
                            href="/dashboard"
                            className="view-all d-flex align-items-center"
                          >
                            <span>
                              <img src={navArrowRight} alt="" />
                            </span>
                            <h5 className="bold text-secondary"> Back </h5>
                          </a>
                        </div>
                        <div className="col-sm-12 mb-4">
                          <h3 className="bold">Add Dashboard Advertisement</h3>
                        </div>

                        <div className="col-sm-12">
                          {/* <Error /> */}
                          <Success />
                        </div>

                        <div className="col-sm-6 zIndex-15 mb-4">
                          <h5 className="bold">Advertisement</h5>
                          <div className=" form-group with-lable mt-4">
                            <div className="input-group-wrapper">
                              <Select2ComponentId
                                labelText="Select Advertisement"
                                selectOptions={dashboardAdvertisementOptions}
                                onChange={event => {
                                  this.setState({
                                    height:
                                      labelHeightWidthById[event.id].height,
                                    width: labelHeightWidthById[event.id].width,
                                    label: labelHeightWidthById[event.id].label,
                                    type: typesById[event.id].type
                                  });
                                  this.props.createDashboardAdvertisementFormResetError(
                                    "dashboardAdvertisementPlaceId"
                                  );
                                  this.props.createDashboardAdvertisementFormUpdateField(
                                    "dashboardAdvertisementPlaceId",
                                    event.id
                                  );
                                  this.callDatedashboardDisabledAdvertisementsList(
                                    event.id
                                  );
                                }}
                                selectClass={
                                  "form-control effect-16 select-2-input " +
                                  (this.props.createDashboardAdvertisementForm
                                    .fields.dashboardAdvertisementPlaceId.error
                                    ? "effect-17"
                                    : "")
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-4">
                          <h5 className="bold">Owner</h5>
                          <div className=" form-group with-lable mt-4 zIndex-14">
                            <div className="input-group-wrapper">
                              <Select2ComponentId
                                labelText="Select Owner"
                                selectOptions={brandOptions}
                                onChange={event => {
                                  this.props.createDashboardAdvertisementFormResetError(
                                    "ownerBrandId"
                                  );
                                  this.props.createDashboardAdvertisementFormUpdateField(
                                    "ownerBrandId",
                                    event.id
                                  );
                                }}
                                selectClass={
                                  "form-control effect-16 select-2-input " +
                                  (this.props.createDashboardAdvertisementForm
                                    .fields.ownerBrandId.error
                                    ? "effect-17"
                                    : "")
                                }
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-4">
                          <h5 className="bold">Starting @</h5>
                          <div className="form-group with-lable mt-4 zIndex-13">
                            <div className="input-group-wrapper">
                              <DatePicker
                                customInput={<DatePickerInput />}
                                selected={
                                  this.props.createDashboardAdvertisementForm
                                    .fields.startAt.value
                                    ? this.props
                                        .createDashboardAdvertisementForm.fields
                                        .startAt.value
                                    : undefined
                                }
                                selectsStart
                                minDate={new Date()}
                                startDate={
                                  this.props.createDashboardAdvertisementForm
                                    .fields.startAt.value
                                    ? this.props
                                        .createDashboardAdvertisementForm.fields
                                        .startAt.value
                                    : new Date()
                                }
                                endDate={
                                  this.props.createDashboardAdvertisementForm
                                    .fields.endAt.value
                                    ? this.props
                                        .createDashboardAdvertisementForm.fields
                                        .endAt.value
                                    : new Date()
                                }
                                onChange={this.handleChangeStart}
                                dateFormat="dd-MM-yyyy"
                                className={
                                  "form-control effect-16 " +
                                  (this.props.createDashboardAdvertisementForm
                                    .fields.startAt.error
                                    ? "effect-17"
                                    : "")
                                }
                                placeholderText="Date"
                                tabIndex={1}
                                excludeDates={finalExcludeDatesArray}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-4">
                          <h5 className="bold">Ending @</h5>
                          <div className="form-group with-lable mt-4 zIndex-12">
                            <div className="input-group-wrapper">
                              <DatePicker
                                customInput={<DatePickerInput />}
                                selected={
                                  this.props.createDashboardAdvertisementForm
                                    .fields.endAt.value
                                    ? this.props
                                        .createDashboardAdvertisementForm.fields
                                        .endAt.value
                                    : undefined
                                }
                                selectsEnd
                                minDate={new Date()}
                                startDate={
                                  this.props.createDashboardAdvertisementForm
                                    .fields.startAt.value
                                    ? this.props
                                        .createDashboardAdvertisementForm.fields
                                        .startAt.value
                                    : new Date()
                                }
                                endDate={
                                  this.props.createDashboardAdvertisementForm
                                    .fields.endAt.value
                                    ? this.props
                                        .createDashboardAdvertisementForm.fields
                                        .endAt.value
                                    : new Date()
                                }
                                onChange={this.handleChangeEnd}
                                dateFormat="dd-MM-yyyy"
                                className={
                                  "form-control effect-16 " +
                                  (this.props.createDashboardAdvertisementForm
                                    .fields.endAt.error
                                    ? "effect-17"
                                    : "")
                                }
                                placeholderText="Date"
                                tabIndex={1}
                                excludeDates={finalExcludeDatesArray}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-4">
                          <h5 className="bold">Type</h5>
                          <div className="form-group with-lable mt-4 zIndex-11">
                            <div className="input-group-wrapper">
                              <input
                                type="text"
                                placeholder="Type"
                                className="form-control effect-16 "
                                tabIndex="1"
                                value={this.ucFirst(this.state.type)}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-6 mb-4 ">
                          <h5 className="bold">Platform</h5>
                          <div className="form-group with-lable mt-4 zIndex-11 disable-div">
                            <div className="input-group-wrapper">
                              <input
                                type="text"
                                placeholder="Platform"
                                className="form-control effect-16 "
                                tabIndex="1"
                                value={platform}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12  mb-5 mt-4">
                          <div className="upload-image d-flex align-items-center ">
                            {this.props.createDashboardAdvertisementForm.fields
                              .imageUrl.value ? (
                              <div className="upload-button image-area-2 col h-100  d-flex flex-column justify-content-center align-items-center">
                                <img
                                  src={
                                    this.props.createDashboardAdvertisementForm
                                      .fields.imageUrl.value
                                  }
                                  className="img-fluid"
                                  alt=""
                                />
                              </div>
                            ) : (
                              <>
                                <input
                                  type="file"
                                  name="img[]"
                                  className="file"
                                  onChange={e => this.uploadLogo(e, "imageUrl")}
                                  ref={input =>
                                    (this.inputPrizePoolImg = input)
                                  }
                                />
                                <div
                                  onClick={() => this.inputPrizePoolImg.click()}
                                  className={
                                    "upload-button image-area-2 col h-100  d-flex flex-column justify-content-center align-items-center " +
                                    (this.props.createDashboardAdvertisementForm
                                      .fields.imageUrl.error
                                      ? "add-new-rewards-danger"
                                      : "")
                                  }
                                >
                                  <img
                                    src={cameraSvg}
                                    className="img-fluid"
                                    alt=""
                                  />
                                  <p className="choose-photo mb-0 bold">
                                    Upload {this.state.label}
                                  </p>
                                  <p className="mt-1 small">
                                    {" "}
                                    Size {this.state.width}px width{" "}
                                    {this.state.height}px height
                                    <br />{" "}
                                    <span
                                      className={
                                        this.props
                                          .createDashboardAdvertisementForm
                                          .fields.imageSize.error
                                          ? " file-size-danger"
                                          : ""
                                      }
                                    >
                                      * Maximum size 2 MB
                                    </span>
                                    <br />{" "}
                                    <span
                                      className={
                                        this.props
                                          .createDashboardAdvertisementForm
                                          .fields.imageType.error
                                          ? " file-size-danger"
                                          : ""
                                      }
                                    >
                                      * Supported file types : jpg, jpeg and png
                                    </span>
                                  </p>
                                </div>
                              </>
                            )}

                            <div className="col-auto ">
                              {this.props.createDashboardAdvertisementForm
                                .fields.imageUrl.value ? (
                                <div
                                  className="popup-dismiss"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                  onClick={() => this.removeImage("imageUrl")}
                                >
                                  {" "}
                                  <img src={navCloseSvg} alt="" />{" "}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>

                        <div className="col-sm-12">
                          <button
                            type="submit"
                            className="btn btn-pink py-3 btn-preview dark mw-150 mt-5"
                          >
                            Add
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </section>
      </section>
    );
  }
}

export default CreateWebComponent;
