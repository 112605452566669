export const AnalyzeList = {
  code: "ANALYZE_LIST",
  stateKey: "bans",
  stateSubKey: "analyzelist"
};

export const BanUsersList = {
  code: "BAN_USERS_LIST",
  stateKey: "bans",
  stateSubKey: "banUsersList"
};

export const UsersList = {
  code: "USERS_LIST",
  stateKey: "user",
  stateSubKey: "usersList"
};
export const BrandAdvertisementsAvailable = {
  code: "BRAND_ADVERTISEMENT_AVAILABLE",
  stateKey: "availablespace",
  stateSubKey: "brandAvailableSpace"
};
export const ChallengeAdvertisementsAvailable = {
  code: "CHALLENGE_ADVERTISEMENT_AVAILABLE",
  stateKey: "availablespace",
  stateSubKey: "challengeAvailableSpace"
};
export const UserChallengeList = {
  code: "USER_CHALLENGE",
  stateKey: "bans",
  stateSubKey: "userChallengeList"
};

export const RafflesWinnerList = {
  code: "RAFFLES_WINNER_LIST",
  stateKey: "rewards",
  stateSubKey: "rafflesWinner"
};

export const ChallengesWinnerList = {
  code: "CHALLENGES_WINNER_LIST",
  stateKey: "rewards",
  stateSubKey: "challengesWinner"
};