import React, { Component } from "react";
import { connect } from "react-redux";
import "swiper/dist/css/swiper.css";
import { createChallengeForm } from "../../../actions/forms";
import Editor from "../../../components/Editor/Editor";
import {
  exec,
  queryCommandState,
  queryCommandValue
} from "../../../components/Editor/editor-pell";
import { stripHtml } from "../../../utils/stripHtml";
import rulesEn from './AutofillTranslate/rules-en.json';
class RulesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentLang: this.props.defaultLang,
    };
    this.translatedValues = this.translatedValues.bind(this);
  }
  translatedValues(locale, field, value) {
    if (this.state.currentLang == this.props.defaultContent) {
      this.props.createChallengeFormResetError("rules");
      if (stripHtml(value) === "") {
        this.props.createChallengeFormSetError("rules", "NOT_EMPTY");
        return false;
      } else {
        this.props.createChallengeFormUpdateField("rules", value);
      }
    }

    var translationData = this.props.createChallengeForm.fields.challengeTranslation.value ? this.props.createChallengeForm.fields.challengeTranslation.value : [];
    var existRecord = translationData.filter(e => e['languageId'] == locale)
    if (existRecord.length > 0) {
      existRecord[0][field] = value
    } else {
      var translationObj = { languageId: locale };
      translationObj = { ...translationObj, [field]: value }
      translationData.push(translationObj);
    }
    if (this.state.currentLang == this.props.defaultLang) {
      this.props.createChallengeFormResetError("rules");
      this.props.createChallengeFormUpdateField(
        "rules",
        value
      );
    }
    this.props.createChallengeFormUpdateField('challengeTranslation', translationData)
  }
  componentDidMount(){
    if(this.props.firstpage === 0)
    {
      this.editor.setContent(rulesEn[this.props.createChallengeForm.fields.gamesName.value]);
      this.translatedValues(this.state.currentLang, 'rules', rulesEn[this.props.createChallengeForm.fields.gamesName.value] )
    }
  }
  render() {
    const actions = [
      {
        name: "heading1",
        icon: "H1",
        result: () => {
          if (queryCommandValue("formatBlock") !== "h1") {
            exec("formatBlock", "<h1>");
          } else {
            exec("formatBlock", "<div>");
          }
        },
        state: () => {
          return queryCommandValue("formatBlock") === "h1";
        }
      },
      {
        name: "heading2",
        icon: "H2",
        result: () => {
          if (queryCommandValue("formatBlock") !== "h2") {
            exec("formatBlock", "<h2>");
          } else {
            exec("formatBlock", "<div>");
          }
        },
        state: () => {
          return queryCommandValue("formatBlock") === "h2";
        }
      },
      "bold",
      {
        name: "olist",
        icon: '<span class="icon-ol"></span>',
        state: () => {
          return queryCommandState("insertOrderedList");
        }
      }
    ];

    const { languagesList } = this.props;
    let challengeTranslationData = this.props.createChallengeForm.fields.challengeTranslation.value ? this.props.createChallengeForm.fields.challengeTranslation.value : ["<div>hii</div>"];
    let rulesExistOrNot = challengeTranslationData.filter(e => e['languageId'] == this.state.currentLang)
    return (
      <>
        <div className="container">
          <div className="row">
            <div className="col-sm-12">
              <div className="col-sm-12 mt-5 mt-lg-0">
                <ul className="nav nav-pills mb-4 d-flex  justify-content-center align-items-center mt-4">
                  {languagesList.map((language, index) => {
                    return (
                      <li
                        key={index}
                        className="nav-item col text-center"
                      >
                        <span
                          className={
                            "a nav-link" +
                            (this.state.currentLang == language.id ? " active show " + ((this.props.defaultLang == language.id) && (this.props.createChallengeForm.fields.rules
                              .error)
                              ? "nav-danger"
                              : "") : "")
                          }
                          onClick={() => this.setState({
                            currentLang: language.id
                          })}
                        >
                          {language.locale}
                        </span>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>
          </div>

          <div className="padder-main">
            {languagesList.map((language, index) => {
              return (
                this.state.currentLang == language.id ?
                  <>
                    <div className="row">
                      <div className="col-sm-8  mt-2 mb-5">
                        <h1>
                          Challange{" "}
                          {rulesExistOrNot[0]['name']}
                        </h1>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-sm-12  ">
                        <div className="bg-light">
                          <Editor
                            onChange={() => {
                              this.translatedValues(this.state.currentLang, 'rules', this.editor.getContent())
                            }
                            }
                            actions={actions}
                            contentClass={
                              "pell-content" +
                              (this.props.createChallengeForm.fields.rules.error
                                ? " rules-danger"
                                : "")
                            }
                            ref={editor => {
                              this.editor = editor;
                            }}
                            defaultContent={rulesExistOrNot[0]['rules']}
                          />
                        </div>
                      </div>
                    </div>
                  </> : <></>
              )
            })}
          </div>
        </div>
      </>
    );
  }
}
const mapDispatchToProps = {
  createChallengeFormSubmit: createChallengeForm.submit,
  createChallengeFormUpdateField: createChallengeForm.updateField,
  createChallengeFormReset: createChallengeForm.reset,
  createChallengeFormResetField: createChallengeForm.resetField,
  createChallengeFormSetError: createChallengeForm.setError,
  createChallengeFormSetSuccess: createChallengeForm.setSuccess,
  createChallengeFormResetError: createChallengeForm.resetError,
  createChallengeFormResetSuccess: createChallengeForm.resetSuccess
};

const mapStateToProps = state => {
  const { forms, user, languages } = state;
  return {
    createChallengeForm: forms.createChallengeForm,
    languagesList: languages.languagesList.items ? languages.languagesList.items : [],
    defaultLang: user.item.defaultLanguage ? user.item.defaultLanguage.id : 0
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RulesComponent);
