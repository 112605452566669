import {
  ListActionCreator
} from "./creators";
import {
  getLanguages
} from "../services/news.service";

import { LanguagesList } from "../redux-config/listActionMap";

export const languagesList = ListActionCreator({
  ...LanguagesList,
  service: getLanguages,
  dataMapper: json => json.data.data
});