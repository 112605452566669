import React, { Component } from "react";
import SwiperContainer from "../../../components/Slider/SwiperContainer";
import DashboardPopularHostCardComponent from "./DashboardPopularHostCardComponent";
import { getContainerLeftWidth } from "../../../utils/misc";

class DashboardPopularHostComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slidesOffsetBefore: 0,
      calculating: true
    };
    this.calculateWidth = this.calculateWidth.bind(this);
  }

  componentDidMount() {
    this.calculateWidth();
  }

  calculateWidth() {
    this.setState((state, props) => {
      return {
        slidesOffsetBefore: getContainerLeftWidth() + 15,
        calculating: false
      };
    });
  }

  render() {
    const { list } = this.props;
    const params = {
      fixDrag: true,
      speed: 1000,
      slidesPerView: "auto",
      loop: false,
      spaceBetween: 15,
      slidesOffsetBefore: this.state.slidesOffsetBefore,
      slidesOffsetAfter: 15,
      initialSlide: 0
    };

    return !this.state.calculating && list !== undefined && list.length > 0 ? (
      <SwiperContainer {...params}>
        {list.map((item, index) => (
          <DashboardPopularHostCardComponent {...item} key={index} order={index+1} />
        ))}
      </SwiperContainer>
    ) : (
      ""
    );
  }
}

export default DashboardPopularHostComponent;
