import { combineReducers } from "redux";
import {
  ListReducerCreator,
  DetailReducerCreator,
  PaginationListReducerCreator
} from "./creators";

import {
  HostList,
  BrandList,
  PlatformByBrandList
} from "../redux-config/listActionMap";
import { HostListViewAll } from "../redux-config/plistActionMap";
import { HostDetails } from "../redux-config/detailActionMap";

const host = combineReducers({
  [HostList.stateSubKey]: ListReducerCreator(HostList),
  [BrandList.stateSubKey]: ListReducerCreator(BrandList),
  [HostDetails.stateSubKey]: DetailReducerCreator(HostDetails),
  [HostListViewAll.stateSubKey]: PaginationListReducerCreator(HostListViewAll),
  [PlatformByBrandList.stateSubKey]: ListReducerCreator(PlatformByBrandList)
});

export default host;
