import { DetailActionCreator, TListActionCreator } from "./creators";
import {
  getBrandAdvertisementsAvailable,
  onGetBrandAdvertisementsDetails
} from "../services/host.service";
import {
  getChallengeAdvertisementsAvailable,
  onGetChallengeAdsDetails
} from "../services/challenges.service";
import {
  BrandAdvertisementsAvailable,
  ChallengeAdvertisementsAvailable
} from "../redux-config/tlistActionMap";
import {
  BrandAdvertisementsDetails,
  ChallengeAdvertisementsDetails
} from "../redux-config/detailActionMap";
import { updateBrandAdsForm, updateChallengeAdsForm } from "./forms";

export const brandAdvertisementsAvailable = TListActionCreator({
  ...BrandAdvertisementsAvailable,
  service: getBrandAdvertisementsAvailable,
  dataMapper: json => json.data.data.items,
  lastPageMapper: json => json.data.data.isLastPage,
  totalPageMapper: json => json.data.data.totalPage,
  totalCountMapper: json => json.data.data.totalCount,
  recordDisplayedMapper: json => json.data.data.recordDisplayed
});
export const challengeAdvertisementsAvailable = TListActionCreator({
  ...ChallengeAdvertisementsAvailable,
  service: getChallengeAdvertisementsAvailable,
  dataMapper: json => json.data.data.items,
  lastPageMapper: json => json.data.data.isLastPage,
  totalPageMapper: json => json.data.data.totalPage,
  totalCountMapper: json => json.data.data.totalCount,
  recordDisplayedMapper: json => json.data.data.recordDisplayed
});

export const brandAdvertisementsDetails = DetailActionCreator({
  ...BrandAdvertisementsDetails,
  service: onGetBrandAdvertisementsDetails,
  dataMapper: json => {
    return json.data.data;
  },
  onResponse: (json, dispatch) => {
    const { data } = json.data;
    dispatch(
      updateBrandAdsForm.updateField(
        "imageUrl",
        data.imageUrl ? data.imageUrl : ""
      )
    );
    dispatch(updateBrandAdsForm.updateField("envId", data.brand.envId));
    dispatch(
      updateBrandAdsForm.updateField(
        "brandName",
        data.brand.name ? data.brand.name : ""
      )
    );
    dispatch(
      updateBrandAdsForm.updateField(
        "brandAdvertisementPlace",
        data.brandAdvertisementPlace.label
      )
    );
    dispatch(
      updateBrandAdsForm.updateField(
        "brandAdvertisementType",
        data.brandAdvertisementPlace.type
      )
    );
    dispatch(
      updateBrandAdsForm.updateField(
        "brandAdsWidth",
        data.brandAdvertisementPlace.width
      )
    );
    dispatch(
      updateBrandAdsForm.updateField(
        "brandAdsHeight",
        data.brandAdvertisementPlace.height
      )
    );
    dispatch(
      updateBrandAdsForm.updateField(
        "ownerBrandName",
        data.ownerBrand.name ? data.ownerBrand.name : ""
      )
    );
    dispatch(
      updateBrandAdsForm.updateField(
        "ownerBrandId",
        data.ownerBrand.id ? data.ownerBrand.id : ""
      )
    );
  }
});

export const challengeAdvertisementsDetails = DetailActionCreator({
  ...ChallengeAdvertisementsDetails,
  service: onGetChallengeAdsDetails,
  dataMapper: json => {
    return json.data.data;
  },
  onResponse: (json, dispatch) => {
    const { data } = json.data;
    dispatch(
      updateChallengeAdsForm.updateField("url", data.url ? data.url : "")
    );
    dispatch(
      updateChallengeAdsForm.updateField(
        "challengeName",
        data.challenge.name ? data.challenge.name : ""
      )
    );
    dispatch(
      updateChallengeAdsForm.updateField(
        "envId",
        data.challenge.envId ? data.challenge.envId : ""
      )
    );
    dispatch(
      updateChallengeAdsForm.updateField(
        "challengeAdvertisementPlace",
        data.challengeAdvertisementPlace.label
      )
    );
    dispatch(
      updateChallengeAdsForm.updateField(
        "challengeAdvertisementType",
        data.challengeAdvertisementPlace.type
      )
    );
    dispatch(
      updateChallengeAdsForm.updateField(
        "challengeAdsWidth",
        data.challengeAdvertisementPlace.width
      )
    );
    dispatch(
      updateChallengeAdsForm.updateField(
        "challengeAdsHeight",
        data.challengeAdvertisementPlace.height
      )
    );
    dispatch(
      updateChallengeAdsForm.updateField(
        "ownerBrandName",
        data.ownerBrand.name ? data.ownerBrand.name : ""
      )
    );
    dispatch(
      updateChallengeAdsForm.updateField(
        "ownerBrandId",
        data.ownerBrand.id ? data.ownerBrand.id : ""
      )
    );
  }
});
