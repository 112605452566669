import React, { Component } from "react";
import { connect } from "react-redux";
import { login, forgotPassword } from "../../../actions/modals";
import { togglePasswordEyeLogin } from "../../../actions/toggles";
import { loginForm } from "../../../actions/forms";
import "./LoginModal.scss";
import ModalV1 from "../Modal";
import { validateEmail } from "../../../utils/validators";
import { APP_MESSAGES } from "../../../services/config/messages";
import AppInputComponent from "../../../layouts/components/sidebars/AppInputComponent";

class LoginModal extends Component {
  constructor(props) {
    super(props);
    this.showLogin = this.showLogin.bind(this);
    this.hideLogin = this.hideLogin.bind(this);
    this.state = {
      isLandScape: false
    };
  }
  showLogin() {
    this.props.loginOpen();
  }
  hideLogin() {
    this.props.loginClose();
  }
  render() {
    let Error;
    let Success;
    Success = this.props.loginForm.fields.email.successMsg
      ? APP_MESSAGES[this.props.loginForm.fields.email.successMsg](
          this.props.loginForm.fields.email.successPayload,
          () => {
            this.props.loginFormResetSuccess("email");
          }
        )
      : props => <>{""}</>;
    Error = this.props.loginForm.fields.email.errorMsg
      ? APP_MESSAGES[this.props.loginForm.fields.email.errorMsg](
          this.props.loginForm.fields.email.errorPayload,
          () => {
            this.props.loginFormResetError("email");
          }
        )
      : props => <>{""}</>;
    return (
      <>
        <ModalV1
          handleHide={this.hideLogin}
          isOpen={this.props.isLoginOpen}
          disableOuterClick={false}
          withError={
            this.props.loginForm.fields.email.error ||
            this.props.loginForm.fields.email.success
          }
        >
          <div className="card-popup login-form">
            <div className="row">
              <div className="col-sm-12 text-center mb-2 mb-sm-4">
                <h4 className="bold">Sign In</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <Error />
                <Success />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <form
                  onSubmit={e => {
                    e.preventDefault();

                    if (!this.props.loginForm.isSubmitting) {
                      if (!this.props.loginForm.fields.email.value) {
                        this.props.loginFormSetError("email", "NOT_EMPTY");
                        return false;
                      }
                      if (
                        !validateEmail(this.props.loginForm.fields.email.value)
                      ) {
                        this.props.loginFormSetError("email", "EMAIL");
                        return false;
                      }

                      this.props.loginFormSubmit({
                        email: this.props.loginForm.fields.email.value,
                        password: this.props.loginForm.fields.password.value
                      });
                    }
                  }}
                >
                  <AppInputComponent
                    labelText="Email Address"
                    divClass="form-group with-lable mt-3"
                    value={this.props.loginForm.fields.email.value}
                    onChange={event => {
                      this.props.loginFormSetError("email", "");
                      this.props.loginFormUpdateField(
                        "email",
                        event.target.value
                      );
                    }}
                    placeholder="Email Address"
                  />

                  <AppInputComponent
                    type={this.props.isPasswordVisible ? "text" : "password"}
                    autoComplete="true"
                    labelText="Password"
                    divClass="form-group with-lable mt-5"
                    value={this.props.loginForm.fields.password.value}
                    onChange={event => {
                      this.props.loginFormSetError("email", "");
                      this.props.loginFormUpdateField(
                        "password",
                        event.target.value
                      );
                    }}
                    inputAddon={() => (
                      <span
                        toggle="#password-field"
                        className={
                          "field-icon toggle-password" +
                          (this.props.isPasswordVisible
                            ? " eye-close"
                            : " eye-open")
                        }
                        onClick={this.props.passwordEyeToggle}
                      />
                    )}
                    placeholder="Password"
                  />
                  <button
                    type="button"
                    onClick={() => {
                      this.props.forgotPasswordOpen();
                    }}
                    className="btn btn-link forgot-password mt-1"
                  >
                    Forgot Password
                  </button>
                  <button
                    type="submit"
                    className={
                      "btn btn-pink dark btn-block mt-2 mt-sm-5" +
                      (this.props.loginForm.isSubmitting ? " disabled" : "")
                    }
                  >
                    {this.props.loginForm.isSubmitting
                      ? "Signing In..."
                      : "Sign In"}
                  </button>
                </form>
              </div>
            </div>
          </div>
        </ModalV1>
      </>
    );
  }
}
const mapDispatchToProps = {
  passwordEyeToggle: togglePasswordEyeLogin.toggle,
  loginOpen: login.open,
  loginClose: login.close,
  forgotPasswordOpen: forgotPassword.open,

  loginFormSubmit: loginForm.submit,
  loginFormReset: loginForm.reset,
  loginFormResetField: loginForm.resetField,
  loginFormUpdateField: loginForm.updateField,
  loginFormSetError: loginForm.setError,
  loginFormSetSuccess: loginForm.setSuccess,
  loginFormResetError: loginForm.resetError,
  loginFormResetSuccess: loginForm.resetSuccess
};

const mapStateToProps = state => {
  return {
    isLoginOpen: state.modals.isLoginOpen,
    isPasswordVisible: state.toggles.pwEyeLogin,
    loginForm: state.forms.loginForm
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginModal);
