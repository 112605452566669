import React, { Component } from "react";
import moment from "moment";
import gameSetupOff from "../../assets/images/svg/game-setup-off.svg";
import gameSetupOn from "../../assets/images/svg/game-setup-on.svg";
import timeOn from "../../assets/images/svg/time-on.svg";
import timeOff from "../../assets/images/svg/time-off.svg";
import rewardOn from "../../assets/images/svg/reward-on.svg";
import rewardOff from "../../assets/images/svg/reward-off.svg";
import brandingOn from "../../assets/images/svg/branding-on.svg";
import brandingOff from "../../assets/images/svg/branding-off.svg";
import rulesOn from "../../assets/images/svg/rules-on.svg";
import rulesOff from "../../assets/images/svg/rules-off.svg";
import publishOn from "../../assets/images/svg/publish-on.svg";
import publishOff from "../../assets/images/svg/publish-off.svg";
import previewOn from "../../assets/images/svg/preview-on.svg";
import previewOff from "../../assets/images/svg/preview-off.svg";
import GameSetupComponent from "./CreateChallenge/GameSetupComponent";
import BrandingComponent from "./CreateChallenge/BrandingComponent";
import RulesComponent from "./CreateChallenge/RulesComponent";
import RewardsComponent from "./CreateChallenge/RewardsComponent";
import TimeAndDateComponent from "./CreateChallenge/TimeAndDateComponent";
import PublishComponent from "./CreateChallenge/PublishComponent";
import PreviewComponent from "./CreateChallenge/PreviewComponent";
import { validatePassword } from "../../utils/validators";

class CreateChallengeComponent extends Component {
  constructor(props) {
    super(props);
    this.state={
      firstpage:0
    }
    this.formValidationCheck = this.formValidationCheck.bind(this);
  }
  componentWillMount() {
    this.props.stats.map((placement, index) => {
      this.props.createChallengeFormUpdateField(
        placement.type + "-" + placement.placeCode,
        ""
      );
      this.props.createChallengeFormResetError(
        placement.type + "-" + placement.placeCode
      );
      this.props.createChallengeFormUpdateField(
        placement.type + "-" + placement.placeCode + "-" + "imgFormat",
        ""
      );
    });


    var languagesList = this.props.languagesList;
    var rewardObj = { currentIndex: 0 };
    var rewardArr = [];
    var challengeArr = [];
    languagesList.map((locale) => {
      challengeArr.push({
        languageId: locale.id, name: "", challengeImageUrl: "", logoUrl: "", promoImage: "", promoImageMobile: "", rules: "", promoTitle: "",
        promoSubTitle: "",
        promoDescription: "",
        promoLogo: "",
        promoBackgroundImage: "",
        promoBackgroundOverlayImage: "",
        promoTextDirection: "",
        promoHasButton: "",
        promoButtonText: ""
      });
      
      rewardObj = { ...rewardObj, [locale.locale]: { languageId: locale.id, itemUrl: "", itemDescription: "" } }
    })
    rewardArr.push(rewardObj);
    this.props.createChallengeFormUpdateField("challengeTranslation", challengeArr);
    this.props.createChallengeFormUpdateField("challengeRewardsTranslation", rewardArr);
  }

  formValidationCheck() {
    const { activeTab } = this.props.createChallengeTabList;
    let error = true;
    if (activeTab === "gameSetupTab") {
      if (!this.props.createChallengeForm.fields.challengeName.value) {
        this.props.createChallengeFormSetError("challengeName", "NOT_EMPTY");
        error = false;
      }
      if (!this.props.createChallengeForm.fields.selectHost.value) {
        this.props.createChallengeFormSetError("selectHost", "NOT_EMPTY");
        error = false;
      }
      if (!this.props.createChallengeForm.fields.selectPlayer.value) {
        this.props.createChallengeFormSetError("selectPlayer", "NOT_EMPTY");
        error = false;
      }
      if (!this.props.createChallengeForm.fields.selectSubscription.value) {
        this.props.createChallengeFormSetError(
          "selectSubscription",
          "NOT_EMPTY"
        );
        error = false;
      }
      if (!error) {
        return false;
      }
      if (activeTab) {
        setTimeout(() => {
          this.props.createCompleteTab(activeTab);
        }, 500);
      }
    } else if (activeTab === "timeDateTab") {
      if (!this.props.createChallengeForm.fields.tournamentStartDate.value) {
        this.props.createChallengeFormSetError(
          "tournamentStartDate",
          "NOT_EMPTY"
        );
        error = false;
      }
      if (!this.props.createChallengeForm.fields.tournamentHours.value) {
        this.props.createChallengeFormSetError("tournamentHours", "NOT_EMPTY");
        error = false;
      }
      if (!this.props.createChallengeForm.fields.tournamentMinutes.value) {
        this.props.createChallengeFormSetError(
          "tournamentMinutes",
          "NOT_EMPTY"
        );
        error = false;
      }
      if (!this.props.createChallengeForm.fields.tournamentVisible.value) {
        this.props.createChallengeFormSetError(
          "tournamentVisible",
          "NOT_EMPTY"
        );
        error = false;
      }
      if (!this.props.createChallengeForm.fields.tournamentEndingDate.value) {
        this.props.createChallengeFormSetError(
          "tournamentEndingDate",
          "NOT_EMPTY"
        );
        error = false;
      } else if (
        this.props.createChallengeForm.fields.tournamentStartDate.value &&
        this.props.createChallengeForm.fields.tournamentEndingDate.value
      ) {
        var start = moment(
          this.props.createChallengeForm.fields.tournamentStartDate.value
        );
        var end = moment(
          this.props.createChallengeForm.fields.tournamentEndingDate.value
        );
        const sd = end.diff(start, "days");
        if (sd < 0) {
          this.props.createChallengeFormSetError(
            "tournamentEndingDate",
            "DATE_DIFF"
          );
          error = false;
        }
      }
      if (!error) {
        return false;
      }

      if (activeTab) {
        setTimeout(() => {
          this.props.createCompleteTab(activeTab);
        }, 500);
      }
    } else if (activeTab === "rewardsTab") {
      let rewardData = this.props.createChallengeForm.fields.rewards.value;
      let noPrizes = this.props.createChallengeForm.fields.noPrizes.value;

      if (!noPrizes && rewardData.length > 0) {
        this.props.createChallengeFormUpdateField("rewards", rewardData);
        let validChk = true;
        rewardData.filter(data => {
          if (!data.itemsChk && !data.pointsChk) {
            validChk = false;
          }
        });

        let itemsEmpty = rewardData.filter(data =>
          data.itemsChk === true ? data.items === "" : ""
        );

        let pointsEmpty = rewardData.filter(data =>
          data.pointsChk ? data.points === "" : ""
        );

        let itemsImage = rewardData.filter(data =>
          data.itemsChk ? data.rewardsimg === "" : ""
        );

        if (!validChk) {
          return false;
        }

        if (itemsEmpty.length > 0) {
          this.props.createChallengeFormSetError("rewards", "NOT_EMPTY");
          return false;
        }
        if (pointsEmpty.length > 0) {
          this.props.createChallengeFormSetError("rewards", "NOT_EMPTY");
          return false;
        }
        if (itemsImage.length > 0) {
          this.props.createChallengeFormSetError("rewards", "NOT_EMPTY");
          return false;
        }
      }
      if (activeTab) {
        setTimeout(() => {
          this.props.createCompleteTab(activeTab);
        }, 500);
      }
    } else if (activeTab === "brandingTab") {
      if (!this.props.createChallengeForm.fields.challengeImageUrl.value) {
        this.props.createChallengeFormSetError(
          "challengeImageUrl",
          "NOT_EMPTY"
        );
        error = false;
      }
      if (!this.props.createChallengeForm.fields.logoUrl.value) {
        this.props.createChallengeFormSetError("logoUrl", "NOT_EMPTY");
        error = false;
      }
      if(this.props.createChallengeForm.fields.isFeatured.value){
        if(!this.props.createChallengeForm.fields.promoTitle.value){
          this.props.createChallengeFormSetError("promoTitle", "NOT_EMPTY");
          error = false;
        }
        if(!this.props.createChallengeForm.fields.promoSubTitle.value){
          this.props.createChallengeFormSetError("promoSubTitle", "NOT_EMPTY");
          error = false;
        }
        // if(!this.props.createChallengeForm.fields.promoDescription.value){
        //   this.props.createChallengeFormSetError("promoDescription", "NOT_EMPTY");
        //   error = false;
        // }
        if(!this.props.createChallengeForm.fields.promoLogo.value){
          this.props.createChallengeFormSetError("promoLogo", "NOT_EMPTY");
          error = false;
        }
        if(!this.props.createChallengeForm.fields.promoBackgroundImage.value){
          this.props.createChallengeFormSetError("promoBackgroundImage", "NOT_EMPTY");
          error = false;
        }
        if(!this.props.createChallengeForm.fields.promoBackgroundOverlayImage.value){
          this.props.createChallengeFormSetError("promoBackgroundOverlayImage", "NOT_EMPTY");
          error = false;
        }
        if(!this.props.createChallengeForm.fields.promoBackgroundColor.value){
          this.props.createChallengeFormSetError("promoBackgroundColor", "NOT_EMPTY");
          error = false;
        }
        if(!this.props.createChallengeForm.fields.promoBackgroundColor2.value){
          this.props.createChallengeFormSetError("promoBackgroundColor2", "NOT_EMPTY");
          error = false;
        }
        if(!this.props.createChallengeForm.fields.promoTextDirection.value){
          this.props.createChallengeFormSetError("promoTextDirection", "NOT_EMPTY");
          error = false;
        }
        // if(this.props.createChallengeForm.fields.promoHasButton.value){
        //   if(!this.props.createChallengeForm.fields.promoButtonText.value){
        //     this.props.createChallengeFormSetError("promoButtonText", "NOT_EMPTY");
        //     error = false;
        //   }
        // }
      }

      if (!error) {
        return false;
      }

      if (activeTab) {
        setTimeout(() => {
          this.props.createCompleteTab(activeTab);
        }, 500);
      }
    } else if (activeTab === "rulesTab") {
      if (!this.props.createChallengeForm.fields.rules.value) {
        this.props.createChallengeFormSetError("rules", "NOT_EMPTY");
        return false;
      }
      if (activeTab) {
        this.setState(prevstate => ({ firstpage: prevstate.firstpage + 1}));
        setTimeout(() => {
          this.props.createCompleteTab(activeTab);
        }, 500);
      }
    } else if (activeTab === "publishTab") {
      if (this.props.createChallengeForm.fields.additionalPassword.value) {
        if (
          !validatePassword(
            this.props.createChallengeForm.fields.additionalPassword.value
          )
        ) {
          this.props.createChallengeFormSetError(
            "additionalPassword",
            "PASSWORD_LENGTH"
          );
          return false;
        }
      }
      if (activeTab) {
        setTimeout(() => {
          this.props.createCompleteTab(activeTab);
        }, 500);
      }
    } else if (activeTab === "previewTab") {    
      let rewards = {};
      if (!this.props.createChallengeForm.fields.noPrizes.value) {
        let rewardArray = this.props.createChallengeForm.fields.rewards.value
          ? this.props.createChallengeForm.fields.rewards.value
          : [];

        rewards = rewardArray.map((rewardItem, i) => {
          let tmpList = [];
          tmpList.push({
            place: rewardItem.rewardPlace,
            points: parseInt(rewardItem.points),
            itemUrl: rewardItem.rewardimg,
            itemDescription: rewardItem.items
          });

          return tmpList;
        });
      }

      let adsPlace = {};
      let userStat = this.props.stats;
      let adsPlces = userStat.map((us, i) => {
        return {
          ...adsPlace,
          id: us.id,
          url: this.props.createChallengeForm.fields[
            us.type + "-" + us.placeCode
          ].value
        };
      });

      moment().diff(
        this.props.createChallengeForm.fields.tournamentStartDate.value,
        "minutes"
      );

      let startTime = this.props.createChallengeForm.fields.tournamentStartDate
        .value
        ? this.props.createChallengeForm.fields.tournamentStartDate.value +
        " " +
        this.props.createChallengeForm.fields.tournamentHours.value +
        ":" +
        this.props.createChallengeForm.fields.tournamentMinutes.value
        : "" + ":00";

      let visibleBeforeDay = this.props.createChallengeForm.fields
        .tournamentVisible.value;

      let endTime = this.props.createChallengeForm.fields.tournamentEndingDate
        .value
        ? this.props.createChallengeForm.fields.tournamentEndingDate.value
        : "";
      var startDate = moment(startTime);
      var endDate = moment(endTime);
      var visibleBefore = startDate.clone().add(-visibleBeforeDay, "day");

      var translatedRecord = [];
      // var translatedRecordName = this.props.createChallengeForm.fields.challengeNameTranslation.value
      // var translatedRecordRules = this.props.createChallengeForm.fields.challengeRulesTranslation.value
      // translatedRecord = this.props.languagesList.map((rec, i) => {
      //   var translatedObj = {};
      //   var names = translatedRecordName.filter(tNames => tNames['languageId'] == rec.id)
      //   var rules = translatedRecordRules.filter(tRules => tRules['languageId'] == rec.id)
      //   console.log(names, 'names')
      //   console.log(rules, 'rules')
      // })

      let param = {
        challengeTitle: this.props.createChallengeForm.fields.challengeName
          .value
          ? this.props.createChallengeForm.fields.challengeName.value.trim()
          : "",
        brandId: this.props.createChallengeForm.fields.selectHost.value
          ? this.props.createChallengeForm.fields.selectHost.value
          : "",
        gameId: this.props.createChallengeForm.fields.gamesId.value
          ? this.props.createChallengeForm.fields.gamesId.value
          : "",
        winCriterionId: this.props.createChallengeForm.fields.winCriterionId
          .value
          ? this.props.createChallengeForm.fields.winCriterionId.value
          : 1,
        maxParticipants: this.props.createChallengeForm.fields.selectPlayer
          .value
          ? this.props.createChallengeForm.fields.selectPlayer.value
          : "",
        timeOfStart: startDate.format(),
        timeOfEnd: endDate.format(),
        visibleFrom: visibleBefore.format(),
        passwordForEntry: this.props.createChallengeForm.fields
          .additionalPassword.value
          ? this.props.createChallengeForm.fields.additionalPassword.value
          : "",
        rules: this.props.createChallengeForm.fields.rules.value
          ? this.props.createChallengeForm.fields.rules.value
          : "",
        entryFeeAmount: 0,
        logoUrl: this.props.createChallengeForm.fields.logoUrl.value
          ? this.props.createChallengeForm.fields.logoUrl.value
          : "",
        challengeImageUrl: this.props.createChallengeForm.fields
          .challengeImageUrl.value
          ? this.props.createChallengeForm.fields.challengeImageUrl.value
          : "",
        promoImage: this.props.createChallengeForm.fields.promoImage.value
          ? this.props.createChallengeForm.fields.promoImage.value
          : "",
        promoImageMobile: this.props.createChallengeForm.fields.promoImageMobile.value
          ? this.props.createChallengeForm.fields.promoImageMobile.value
          : "",
        subscriptionTypes: this.props.createChallengeForm.fields
          .selectSubscription.value
          ? 0
          : 0,
        isFeatured: this.props.createChallengeForm.fields.isFeatured.value,
        noPrizes: this.props.createChallengeForm.fields.noPrizes.value,
        about: null,
        aboutImage: null,
        rewards: rewards,
        challengeAdPlaces: adsPlces,
        facebook: null,
        twitter: null,
        instagram: null,
        challengeTranslation: translatedRecord
      };
      this.props.createChallengeFormSubmit(param);
      if (activeTab) {
        setTimeout(() => {
          this.props.createCompleteTab(activeTab);
        }, 500);
      }
    }

    if (
      this.props.createChallengeTabList.nextTab &&
      activeTab !== "previewTab"
    ) {
      this.props.createChallengeTab(this.props.createChallengeTabList.nextTab);
    }
  }
  componentDidMount() {
    // this.props.fetchGamesListIfNeeded();
    // this.props.invalidateGameList();
  }
  render() {
    const flagClass1 = this.props.createChallengeTabList.steps.gameSetupTab
      .isCompleted;
    const flagClass2 = this.props.createChallengeTabList.steps.timeDateTab
      .isCompleted;
    const flagClass3 = this.props.createChallengeTabList.steps.rewardsTab
      .isCompleted;
    const flagClass4 = this.props.createChallengeTabList.steps.brandingTab
      .isCompleted;
    const flagClass5 = this.props.createChallengeTabList.steps.rulesTab
      .isCompleted;
    const flagClass6 = this.props.createChallengeTabList.steps.publishTab
      .isCompleted;
    const flagClass7 = this.props.createChallengeTabList.steps.previewTab
      .isActive;

    const aflagClass1 = this.props.createChallengeTabList.steps["gameSetupTab"]
      .isActive;
    const aflagClass2 = this.props.createChallengeTabList.steps["timeDateTab"]
      .isActive;
    const aflagClass3 = this.props.createChallengeTabList.steps["rewardsTab"]
      .isActive;
    const aflagClass4 = this.props.createChallengeTabList.steps["brandingTab"]
      .isActive;
    const aflagClass5 = this.props.createChallengeTabList.steps["rulesTab"]
      .isActive;
    const aflagClass6 = this.props.createChallengeTabList.steps["publishTab"]
      .isActive;
    const aflagClass7 = this.props.createChallengeTabList.steps["previewTab"]
      .isActive;

    return (
      <div>
        <section id="rootwizard">
          <section className="container-fluid banner-wrapper">
            <div className="row">
              <div className="banner backend d-flex flex-column align-items-center">
                <div className="container">
                  <div className="padder-main">
                    <div className="row">
                      <div className="col-sm-12">
                        <ul
                          className="nav nav-pills mb-3 d-flex backend-pill"
                          id="backend-pill"
                          role="tablist"
                        >
                          <li
                            className={
                              "nav-item text-center flex-fill transparentDiv " +
                              (flagClass1 ? "mobile-active" : "")
                            }
                          >
                            <a
                              className={
                                "nav-link " +
                                (flagClass1 ? "active active-fixed" : "")
                              }
                              id="gameSetupTab"
                              datatoggle="pill"
                              role="tab"
                              // onClick={e => {
                              //   this.props.createChallengeTab(e.target.id);
                              // }}
                              aria-controls="game-setup"
                              aria-selected="true"
                            >
                              <div className="circle">
                                <div className="inner">
                                  <img
                                    src={gameSetupOn}
                                    className="on"
                                    alt=""
                                  />
                                  <img
                                    src={gameSetupOff}
                                    className="off"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </a>
                            <p>Game Setup</p>
                          </li>
                          <li
                            className={
                              "nav-item text-center flex-fill " +
                              (flagClass2 ? "transparentDiv mobile-active" : "")
                            }
                          >
                            <a
                              className={
                                "nav-link " +
                                (flagClass2 ? "active active-fixed" : "")
                              }
                              id="timeDateTab"
                              data-toggle="pill"
                              // href="#timeDate"
                              role="tab"
                              // onClick={e => {
                              //   this.props.createChallengeTab(e.target.id);
                              // }}
                              aria-controls="timeDate"
                              aria-selected="true"
                            >
                              <div className="circle">
                                <div className="inner">
                                  <img src={timeOn} className="on" alt="" />
                                  <img src={timeOff} className="off" alt="" />
                                </div>
                              </div>
                            </a>
                            <p>Time & Date</p>
                          </li>
                          <li
                            className={
                              "nav-item text-center flex-fill " +
                              (flagClass3 ? "transparentDiv mobile-active" : "")
                            }
                          >
                            <a
                              className={
                                "nav-link " +
                                (flagClass3 ? "active active-fixed" : "")
                              }
                              id="rewardsTab"
                              data-toggle="pill"
                              // href="#rewards"
                              role="tab"
                              aria-controls="rewards"
                              aria-selected="true"
                            >
                              <div className="circle">
                                <div className="inner">
                                  <img src={rewardOn} className="on" alt="" />
                                  <img src={rewardOff} className="off" alt="" />
                                </div>
                              </div>
                            </a>
                            <p>Rewards</p>
                          </li>
                          <li
                            className={
                              "nav-item text-center flex-fill " +
                              (flagClass4 ? "transparentDiv mobile-active" : "")
                            }
                          >
                            <a
                              className={
                                "nav-link " +
                                (flagClass4 ? "active active-fixed" : "")
                              }
                              id="brandingTab"
                              data-toggle="pill"
                              // onClick={e =>
                              //   this.props.createChallengeTab(e.target.id)
                              // }
                              role="tab"
                              aria-controls="branding"
                              aria-selected="true"
                            >
                              <div className="circle">
                                <div className="inner">
                                  <img src={brandingOn} className="on" alt="" />
                                  <img
                                    src={brandingOff}
                                    className="off"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </a>
                            <p>Branding</p>
                          </li>
                          <li
                            className={
                              "nav-item text-center flex-fill " +
                              (flagClass5 ? "transparentDiv mobile-active" : "")
                            }
                          >
                            <a
                              className={
                                "nav-link " +
                                (flagClass5 ? "active active-fixed" : "")
                              }
                              id="rulesTab"
                              data-toggle="pill"
                              role="tab"
                              aria-controls="rules"
                              aria-selected="true"
                            >
                              <div className="circle">
                                <div className="inner">
                                  <img src={rulesOn} className="on" alt="" />
                                  <img src={rulesOff} className="off" alt="" />
                                </div>
                              </div>
                            </a>
                            <p>Rules</p>
                          </li>
                          <li
                            className={
                              "nav-item text-center flex-fill " +
                              (flagClass6 ? "transparentDiv mobile-active" : "")
                            }
                          >
                            <a
                              className={
                                "nav-link " +
                                (flagClass6 ? "active active-fixed" : "")
                              }
                              id="publishTab"
                              data-toggle="pill"
                              role="tab"
                              aria-controls="publish"
                              aria-selected="true"
                            >
                              <div className="circle">
                                <div className="inner">
                                  <img src={publishOn} className="on" alt="" />
                                  <img
                                    src={publishOff}
                                    className="off"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </a>
                            <p>Publish</p>
                          </li>
                          <li
                            className={
                              "nav-item text-center flex-fil " +
                              (flagClass7 ? "transparentDiv mobile-active" : "")
                            }
                          >
                            <a
                              className={
                                "nav-link " +
                                (flagClass7 ? "active active-fixed" : "")
                              }
                              id="previewTab"
                              data-toggle="pill"
                              role="tab"
                              aria-controls="preview"
                              aria-selected="true"
                              style={{ width: 163, marginLeft: -6 }}
                            >
                              <div className="circle">
                                <div className="inner">
                                  <img src={previewOn} className="on" alt="" />
                                  <img
                                    src={previewOff}
                                    className="off"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </a>
                            <p>Preview</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="container-fluid tab-content-wrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="tab-content" id="backendpills-Content">
                  <div
                    className={
                      "tab-pane fade " +
                      (!aflagClass2 &&
                        !aflagClass3 &&
                        !aflagClass4 &&
                        !aflagClass5 &&
                        !aflagClass6 &&
                        !aflagClass7
                        ? "show active"
                        : "") +
                      " tab-1"
                    }
                    id="game-setup"
                    role="tabpanel"
                    aria-labelledby="game-setup-tab"
                  >
                    <GameSetupComponent gamesData={this.props.gameLists} />
                  </div>
                  <div
                    className={
                      "tab-pane fade " +
                      (aflagClass2 ? "show active" : "") +
                      " tab-2"
                    }
                    id="timeDate"
                    role="tabpanel"
                    aria-labelledby="timeDate-tab"
                  >
                    <TimeAndDateComponent />
                  </div>
                  <div
                    className={
                      "tab-pane fade " +
                      (aflagClass3 ? "show active" : "") +
                      " tab-3"
                    }
                    id="rewards"
                    role="tabpanel"
                    aria-labelledby="rewards-tab"
                  >
                    <RewardsComponent />
                  </div>
                  <div
                    className={
                      "tab-pane fade " +
                      (aflagClass4 ? "show active" : "") +
                      " tab-4"
                    }
                    id="branding"
                    role="tabpanel"
                    aria-labelledby="branding-tab"
                  >
                    <BrandingComponent gamesData={this.props.gameLists}/>
                  </div>
                  <div
                    className={
                      "tab-pane fade " +
                      (aflagClass5 ? "show active" : "") +
                      " tab-5"
                    }
                    id="rules"
                    role="tabpanel"
                    aria-labelledby="rules-tab"
                  >
                   { this.props.createChallengeTabList.steps["rulesTab"].isActive?<RulesComponent firstpage={this.state.firstpage} />:''}
                  </div>
                  <div
                    className={
                      "tab-pane fade " +
                      (aflagClass6 ? "show active" : "") +
                      " tab-6"
                    }
                    id="publish"
                    role="tabpanel"
                    aria-labelledby="publish-tab"
                  >
                    <PublishComponent />
                  </div>
                  <div
                    className={
                      "tab-pane fade " +
                      (aflagClass7 ? "show active" : "") +
                      " tab-7"
                    }
                    id="preview"
                    role="tabpanel"
                    aria-labelledby="preview-tab"
                  >
                    <PreviewComponent />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="container-fluid tab-indication-wrapper ">
            <div className="row justify-content-end align-items-center">
              <div className="col-sm-3 d-flex justify-content-end align-items-center">
                {this.props.createChallengeTabList.previousTab ? (
                  <button
                    onClick={e => {
                      this.props.createChallengeTabList.steps["rulesTab"].isActive&&this.setState(prevstate => ({ firstpage: prevstate.firstpage + 1}))
                      this.props.createChallengeTab(
                        this.props.createChallengeTabList.previousTab
                      );
                    }}
                    id="btnTabPrv"
                    className="btn btn-gray   py-3 unlock dark  mw-100 mx-1"
                  >
                    Previous
                  </button>
                ) : (
                    ""
                  )}
                {this.props.createChallengeTabList.nextTab ? (
                  <button
                    onClick={e => {
                      this.formValidationCheck();
                    }}
                    id="btnTabnext"
                    className="[ btn btn-pink light  py-3 unlock dark  mw-100 mx-1]"
                  >
                    Next
                  </button>
                ) : (
                    ""
                  )}
              </div>
            </div>
          </section>
          <section className="container-fluid">
            <div className="row">
              <div className="blank-space d-block d-lg-none" />
            </div>
          </section>
        </section>
      </div>
    );
  }
}

export default CreateChallengeComponent;
