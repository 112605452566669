import { combineReducers } from "redux";
import { ListReducerCreator, PaginationListReducerCreator, DetailReducerCreator } from "./creators";
import { RafflesListViewAll } from "../redux-config/plistActionMap";

import { RafflesList } from "../redux-config/listActionMap";
import { RaffleDetails } from "../redux-config/detailActionMap";

const raffles = combineReducers({
  [RafflesList.stateSubKey]: ListReducerCreator(RafflesList),
  [RaffleDetails.stateSubKey]: DetailReducerCreator(RaffleDetails),
  [RafflesListViewAll.stateSubKey]: PaginationListReducerCreator(
    RafflesListViewAll
  ),
});


export default raffles;
