const initialStateForList = {
  isFetching: false,
  items: [],
  didInvalidate: true,
  receiveError: false,
  isLastPage: false,
  totalPage: 0,
  totalCount: 0,
  recordDisplayed: 0,
  sort: { field: "", orderBy: "" },
  search: {}
};

export default params => (state = initialStateForList, action) => {
  switch (action.type) {
    case "TLIST_INVALIDATE_" + params.code:
      return {
        ...state,
        didInvalidate: true
      };
    case "TLIST_REQUEST_" + params.code:
      return {
        ...state,
        isFetching: true
      };
    case "TLIST_RECEIVE_" + params.code:
      return {
        ...state,
        isFetching: false,
        items: action.data,
        didInvalidate: false,
        isLastPage: action.isLastPage,
        totalPage: action.totalPage,
        totalCount: action.totalCount,
        recordDisplayed: action.recordDisplayed,
        receiveError: false
      };
    case "TLIST_RECEIVE_ERROR_" + params.code:
      return {
        ...initialStateForList,
        receiveError: true
      };
    case "TLIST_SET_SORT_" + params.code + "/" + action.field:
      let sortBy = { field: action.field, orderBy: "" };
      if (state.sort.field) {
        if (state.sort.field == action.field) {
          if (state.sort.orderBy === "") {
            sortBy.orderBy = "asc";
          } else if (state.sort.orderBy === "asc") {
            sortBy.orderBy = "desc";
          } else if (state.sort.orderBy === "desc") {
            sortBy.field = "";
            sortBy.orderBy = "";
          }
        } else {
          sortBy.orderBy = "asc";
        }
      } else if (state.sort.field == "") {
        if (state.sort.orderBy === "") {
          sortBy.orderBy = "asc";
        } else if (state.sort.orderBy === "asc") {
          sortBy.orderBy = "desc";
        } else if (state.sort.orderBy === "desc") {
          sortBy.field = "";
          sortBy.orderBy = "";
        }
      }
      return {
        ...state,
        sort: sortBy
      };
    case "TLIST_SET_SEARCH_" + params.code + "/" + action.field:
      return {
        ...state,
        search: {
          ...state.search,
          [action.field]: action.value
        }
      };
    case "TLIST_" + params.code + "_SEARCH_AND_SORT_RESET":
      return {
        ...state,
        search: initialStateForList.search,
        sort: initialStateForList.sort
      };
    default:
      return state;
  }
};
