import { LOGOUT_REQUEST, LOGOUT_SUCCESS, LOGIN_USER } from "../types/user";
import { modalCloseAll } from "./modals";
import { languagesList } from "./languages";

import { axiosInstance } from "../utils/axios";
import { sidebarCloseAll } from "./sidebars";

export const loginSuccess = userdata => dispatch => {
  dispatch({
    type: LOGIN_USER,
    userdata
  });
  dispatch(languagesList.fetchData());
};

export const logoutRequest = () => ({
  type: LOGOUT_REQUEST
});
export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS
});

const logoutUser = () => dispatch => {
  dispatch(logoutRequest());
  return setTimeout(() => {
    dispatch(logoutSuccess());
    dispatch(modalCloseAll());
    dispatch(sidebarCloseAll());
  }, 500);
};

const shouldLogout = () => {
  return true;
};

export const logoutUserIfNeeded = () => (dispatch, getState) => {
  if (shouldLogout(getState())) {
    axiosInstance.removeToken();
    return dispatch(logoutUser());
  }
};
