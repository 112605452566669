import { axios } from "../utils/axios";
import config from "./config";
import { API_SERVER_URL } from "./config/apiEndpoints";

export const getNewsList = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.getNews,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};
export const getNewsCategories = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.getNewsCategoriesList,
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const onCreateNews = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.createNews,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};
export const onGetNewsDetails = postParams => {
  return axios
    .request({
      url:
        API_SERVER_URL +
        config.getNewsDetails +
        postParams,
      method: "get",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};
export const onNewsUpdate = (postParams, id) => {
  return axios
    .request({
      url: API_SERVER_URL + config.updateNews + id,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};
export const getLanguages = () => {
  return axios
    .request({
      url: API_SERVER_URL + config.getLanguagesList,
      method: "get",
      headers: {
        "content-type": "application/json"
      }
    })
    .catch(err => {
      console.log(err);
    });
};

export const onEnableNews = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.newsEnable.replace("##id##", postParams ? postParams : 0),
      method: "get",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const onDisableNews = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.newsDisable.replace("##id##", postParams ? postParams : 0),
      method: "get",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};