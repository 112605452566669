import React, { Component } from "react";
import { connect } from "react-redux";
import AppInputComponent from "../../../layouts/components/sidebars/AppInputComponent";
import { updateChallengeForm } from "../../../actions/forms";
import Select2ComponentId from "../../Select2/Select2ComponentId";

class GameSetupStepComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { currentLang: this.props.defaultLang };
    this.translatedValues = this.translatedValues.bind(this);
  }

  translatedValues(locale, field, value) {
    var translationData = this.props.updateChallengeForm.fields.challengeTranslation.value ? this.props.updateChallengeForm.fields.challengeTranslation.value : [];
    var existRecord = translationData.filter(e => e['languageId'] == locale)
    if (existRecord.length > 0) {
      existRecord[0][field] = value
    } else {
      var translationObj = { languageId: locale };
      translationObj = { ...translationObj, [field]: value }
      translationData.push(translationObj);
    }
    this.props.updateChallengeFormUpdateField('challengeTranslation', translationData)
  }
  render() {
    const { challengeDetails, languagesList } = this.props;
    let challengeTranslationData = this.props.updateChallengeForm.fields.challengeTranslation.value ? this.props.updateChallengeForm.fields.challengeTranslation.value : [];
    let nameExistOrNot = challengeTranslationData.filter(e => e['languageId'] == this.state.currentLang)
    return (
      <>
        {challengeDetails ? (
          <div className="container">
            <div className="row">
              <div className="col-sm-12 mt-5 mt-lg-0">
                <ul className="nav nav-pills mb-4 d-flex  justify-content-center align-items-center mt-4">
                  {languagesList.map((language, index) => {
                    return (
                      <li
                        key={index}
                        className="nav-item col text-center"
                      >
                        <span
                          className={
                            "a nav-link" +
                            (this.state.currentLang == language.id ? " active show " + ((this.props.defaultLang == language.id) && (this.props.updateChallengeForm.fields.challengeName
                              .error)
                              ? "nav-danger"
                              : "") : "")
                          }
                          id="pills-today-tab"
                          data-toggle="pill"
                          role="tab"
                          aria-controls="pills-today"
                          aria-selected="true"
                          onClick={() => this.setState({
                            currentLang: language.id
                          })}
                        >
                          {language.locale}
                        </span>
                      </li>
                    )
                  })}
                </ul>
              </div>
            </div>

            <div className="padder-main">
              <div className="row ">
                <div className="form-group with-lable w-100">
                  <div className="col-sm-12">
                    <AppInputComponent
                      onChange={event => {
                        if (this.state.currentLang == this.props.defaultLang) {
                          this.props.updateChallengeFormResetError(
                            "challengeName"
                          );
                          this.props.updateChallengeFormUpdateField(
                            "challengeName",
                            event.target.value
                          );
                        }
                        this.translatedValues(this.state.currentLang, 'name', event.target.value);
                      }
                      }
                      inputClass={
                        this.state.currentLang == this.props.defaultLang ? !this.props.updateChallengeForm.fields.challengeName
                          .error || (nameExistOrNot[0] && nameExistOrNot[0]['name'])
                          ? "form-control effect-16"
                          : "form-control effect-17" : "form-control effect-16"
                      }
                      divClass="input-group-wrapper mt-2 mb-5 create-tournament-wrapper"
                      labelText="Enter challenge name"
                      withLabel="true"
                      maxlength="50"
                      value={nameExistOrNot[0] && nameExistOrNot[0]['name'] ? nameExistOrNot[0]['name'] : ""}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12 col-lg-6  form-info">
                <div className="row">
                  <div className="col-sm-12">
                    <h5 className="bold">Game</h5>
                  </div>
                  <ul className=" list-inline d-flex flex-row flex-wrap w-100 mt-3">
                    <li className="list-group-control col-sm-6 mt-2">
                      <div className="select-game-box">
                        <label className="custom-control custom-radio w-100 mb-0 d-flex justify-content-center align-items-center">
                          <div className="labelc-content d-inline-flex align-items-center">
                            <img
                              src={
                                this.props.updateChallengeForm.fields.gamesUrl
                                  .value
                              }
                              alt=""
                            />
                            <p className="mb-0 ml-3">
                              {
                                this.props.updateChallengeForm.fields.gamesName
                                  .value
                              }
                            </p>
                          </div>
                          <div className="label-new ml-auto">
                            {/* <span className="custom-control-indicator" /> */}
                          </div>
                        </label>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-12 col-lg-6  form-info">
                <div className="row">
                  <div className="col-sm-12 mt-5 mt-lg-0">
                    <h5 className="bold">Win Criteria</h5>
                    <ul className="nav nav-pills mb-4 d-flex  justify-content-center align-items-center mt-4">
                      <li className="nav-item col text-center">
                        <span
                          className={"a nav-link active show"}
                          id="pills-today-tab"
                          data-toggle="pill"
                          role="tab"
                          aria-controls="pills-today"
                          aria-selected="true"
                        >
                          {"Top score"}
                        </span>
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-12 mt-4 zIndex-2">
                    <h5 className="bold">Host</h5>
                    <div className="form-group with-lable my-3">
                      <div className="input-group-wrapper disable-div">
                        <Select2ComponentId
                          selectClass={
                            this.props.updateChallengeForm.fields.selectHost
                              .error
                              ? "form-control effect-17 select-2-input"
                              : "form-control effect-16 select-2-input disable-div"
                          }
                          selectOptions={[]}
                          value={
                            this.props.updateChallengeForm.fields.selectHostName
                              .value
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12  mt-4">
                    <h5 className="bold">Platform</h5>
                    <div className=" form-group with-lable my-3">
                      <div className="input-group-wrapper disable-div">
                        <input
                          type="text"
                          placeholder="Platform"
                          className="form-control effect-16 "
                          tabIndex="1"
                          value={
                            this.props.updateChallengeForm.fields.envName.value
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 mt-4 zIndex-1">
                    <h5 className="bold">Number of players</h5>
                    <div className="form-group with-lable my-3">
                      <div className="input-group-wrapper disable-div">
                        <Select2ComponentId
                          selectClass={
                            this.props.updateChallengeForm.fields.selectHost
                              .error
                              ? "form-control effect-17 select-2-input"
                              : "form-control effect-16 select-2-input disable-div"
                          }
                          selectOptions={[]}
                          value={
                            this.props.updateChallengeForm.fields.selectPlayer
                              .value +
                            " " +
                            "players"
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-12 mt-4">
                    <h5 className="bold">Subscription</h5>
                    <div className="form-group with-lable my-3">
                      <div className="input-group-wrapper disable-div">
                        <Select2ComponentId
                          selectClass={
                            this.props.updateChallengeForm.fields.selectHost
                              .error
                              ? "form-control effect-17 select-2-input"
                              : "form-control effect-16 select-2-input disable-div"
                          }
                          selectOptions={[]}
                          value={"None"}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* </div> */}
            </div>
          </div>
        ) : (
            ""
          )}
      </>
    );
  }
}

const mapDispatchToProps = {
  updateChallengeFormSubmit: updateChallengeForm.submit,
  updateChallengeFormUpdateField: updateChallengeForm.updateField,
  updateChallengeFormReset: updateChallengeForm.reset,
  updateChallengeFormResetField: updateChallengeForm.resetField,
  updateChallengeFormSetError: updateChallengeForm.setError,
  updateChallengeFormSetSuccess: updateChallengeForm.setSuccess,
  updateChallengeFormResetError: updateChallengeForm.resetError,
  updateChallengeFormResetSuccess: updateChallengeForm.resetSuccess
};

const mapStateToProps = state => {
  const { forms, user, languages } = state;
  return {
    updateChallengeForm: forms.updateChallengeForm,
    languagesList: languages.languagesList.items ? languages.languagesList.items : [],
    defaultLang: user.item.defaultLanguage ? user.item.defaultLanguage.id : 0
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GameSetupStepComponent);
