import React, { Component } from "react";
import moment from "moment";
import gameSetupOff from "../../assets/images/svg/game-setup-off.svg";
import gameSetupOn from "../../assets/images/svg/game-setup-on.svg";
import timeOn from "../../assets/images/svg/time-on.svg";
import timeOff from "../../assets/images/svg/time-off.svg";
import rewardOn from "../../assets/images/svg/reward-on.svg";
import rewardOff from "../../assets/images/svg/reward-off.svg";
import brandingOn from "../../assets/images/svg/branding-on.svg";
import brandingOff from "../../assets/images/svg/branding-off.svg";
import rulesOn from "../../assets/images/svg/rules-on.svg";
import rulesOff from "../../assets/images/svg/rules-off.svg";
import publishOn from "../../assets/images/svg/publish-on.svg";
import publishOff from "../../assets/images/svg/publish-off.svg";
import previewOn from "../../assets/images/svg/preview-on.svg";
import previewOff from "../../assets/images/svg/preview-off.svg";
import GameSetupComponent from "./UpdateChallenge/GameSetupComponent";
import BrandingComponent from "./UpdateChallenge/BrandingComponent";
import RulesComponent from "./UpdateChallenge/RulesComponent";
import RewardsComponent from "./UpdateChallenge/RewardsComponent";
import TimeAndDateComponent from "./UpdateChallenge/TimeAndDateComponent";
import PublishComponent from "./UpdateChallenge/PublishComponent";
import PreviewComponent from "./UpdateChallenge/PreviewComponent";
import { validatePassword } from "../../utils/validators";

class UpdateChallengeComponent extends Component {
  constructor(props) {
    super(props);
    this.formValidationCheck = this.formValidationCheck.bind(this);
  }
  componentWillMount() {
    this.props.invalidateChallengeDetails();
    this.props.fetchChallengeDetailsIfNeeded(this.props.match.params.id);
   
    this.props.invalidatebrandList();
    this.props.fetchbrandListIfNeeded();

    this.props.stats.map((placement, index) => {
      this.props.updateChallengeFormUpdateField(
        placement.type + "-" + placement.placeCode,
        ""
      );
      this.props.updateChallengeFormResetError(
        placement.type + "-" + placement.placeCode
      );
      this.props.updateChallengeFormUpdateField(
        placement.type + "-" + placement.placeCode + "-" + "imgFormat",
        ""
      );
    });
  }
  // componentDidMount(){
  //   this.setState((props, state) => {
  //     return{
  //       game: this.props.gameLists.filter(data => data.id == this.props.updateChallengeForm.fields.gamesId.value),
  //     }
  //   });
  // }
  formValidationCheck() {
    const { activeTab } = this.props.createChallengeTabList;
    let error = true;
    if (activeTab === "gameSetupTab") {
      if (!this.props.updateChallengeForm.fields.challengeName.value) {
        this.props.updateChallengeFormSetError("challengeName", "NOT_EMPTY");
        return false;
      }
      if (activeTab) {
        setTimeout(() => {
          this.props.createCompleteTab(activeTab);
        }, 500);
      }
    } else if (activeTab === "timeDateTab") {
      if (activeTab) {
        setTimeout(() => {
          this.props.createCompleteTab(activeTab);
        }, 500);
      }
    } else if (activeTab === "rewardsTab") {
      if (activeTab) {
        setTimeout(() => {
          this.props.createCompleteTab(activeTab);
        }, 500);
      }
    } else if (activeTab === "brandingTab") {
      if (!this.props.updateChallengeForm.fields.challengeImageUrl.value) {
        this.props.updateChallengeFormSetError(
          "challengeImageUrl",
          "NOT_EMPTY"
        );
        error = false;
      }
      if (!this.props.updateChallengeForm.fields.logoUrl.value) {
        this.props.updateChallengeFormSetError("logoUrl", "NOT_EMPTY");
        error = false;
      }
      if(this.props.updateChallengeForm.fields.isFeatured.value){
        if(!this.props.updateChallengeForm.fields.promoTitle.value){
          this.props.updateChallengeFormSetError("promoTitle", "NOT_EMPTY");
          error = false;
        }
        if(!this.props.updateChallengeForm.fields.promoSubTitle.value){
          this.props.updateChallengeFormSetError("promoSubTitle", "NOT_EMPTY");
          error = false;
        }
        // if(!this.props.updateChallengeForm.fields.promoDescription.value){
        //   this.props.updateChallengeFormSetError("promoDescription", "NOT_EMPTY");
        //   error = false;
        // }
        if(!this.props.updateChallengeForm.fields.promoBackgroundColor.value){
          this.props.updateChallengeFormSetError("promoBackgroundColor", "NOT_EMPTY");
          error = false;
        }
        if(!this.props.updateChallengeForm.fields.promoBackgroundColor2.value){
          this.props.updateChallengeFormSetError("promoBackgroundColor2", "NOT_EMPTY");
          error = false;
        }
        if(!this.props.updateChallengeForm.fields.promoLogo.value){
          this.props.updateChallengeFormSetError("promoLogo", "NOT_EMPTY");
          error = false;
        }
        if(!this.props.updateChallengeForm.fields.promoBackgroundImage.value){
          this.props.updateChallengeFormSetError("promoBackgroundImage", "NOT_EMPTY");
          error = false;
        }
        if(!this.props.updateChallengeForm.fields.promoBackgroundOverlayImage.value){
          this.props.updateChallengeFormSetError("promoBackgroundOverlayImage", "NOT_EMPTY");
          error = false;
        }
        if(!this.props.updateChallengeForm.fields.promoTextDirection.value){
          this.props.updateChallengeFormSetError("promoTextDirection", "NOT_EMPTY");
          error = false;
        }
        // if(this.props.updateChallengeForm.fields.promoHasButton.value){
        //   if(!this.props.updateChallengeForm.fields.promoButtonText.value){
        //     this.props.updateChallengeFormSetError("promoButtonText", "NOT_EMPTY");
        //     error = false;
        //   }
        // }
      }

      if (!error) {
        return false;
      }

      if (activeTab) {
        setTimeout(() => {
          this.props.createCompleteTab(activeTab);
        }, 500);
      }
    }
    if (activeTab === "rulesTab") {
      if (!this.props.updateChallengeForm.fields.rules.value) {
        this.props.updateChallengeFormSetError("rules", "NOT_EMPTY");
        return false;
      }
      if (activeTab) {
        setTimeout(() => {
          this.props.createCompleteTab(activeTab);
        }, 500);
      }
    } else if (activeTab === "publishTab") {
      if (this.props.updateChallengeForm.fields.additionalPassword.value) {
        if (
          !validatePassword(
            this.props.updateChallengeForm.fields.additionalPassword.value
          )
        ) {
          this.props.updateChallengeFormSetError(
            "additionalPassword",
            "PASSWORD_LENGTH"
          );
          return false;
        }
      }
      if (activeTab) {
        setTimeout(() => {
          this.props.createCompleteTab(activeTab);
        }, 500);
      }
    } else if (activeTab === "previewTab") {
      let rewardArray = this.props.updateChallengeForm.fields.rewards.value
        ? this.props.updateChallengeForm.fields.rewards.value
        : [];
      let test = rewardArray.map((rewardItem, i) => {
        let tmpList = [];
        tmpList.push({
          place: rewardItem.rewardPlace,
          points: parseInt(rewardItem.points),
          itemUrl: rewardItem.rewardimg,
          itemDescription: rewardItem.items
        });

        return tmpList;
      });

      let adsPlace = {};
      let userStat = this.props.stats;
      let adsPlces = userStat.map((us, i) => {
        return {
          ...adsPlace,
          id: us.id,
          url: this.props.updateChallengeForm.fields[
            us.type + "-" + us.placeCode
          ].value
        };
      });

      moment().diff(
        this.props.updateChallengeForm.fields.tournamentStartDate.value,
        "minutes"
      );

      let startTime = this.props.updateChallengeForm.fields.tournamentStartDate
        .value
        ? this.props.updateChallengeForm.fields.tournamentStartDate.value +
          " " +
          this.props.updateChallengeForm.fields.tournamentHours.value +
          ":" +
          this.props.updateChallengeForm.fields.tournamentMinutes.value
        : "" + ":00";

      let visibleBeforeDay = this.props.updateChallengeForm.fields
        .tournamentVisible.value;

      let endTime = this.props.updateChallengeForm.fields.tournamentEndingDate
        .value
        ? this.props.updateChallengeForm.fields.tournamentEndingDate.value
        : "";
      var startDate = moment(startTime);
      var endDate = moment(endTime);
      var visibleBefore = startDate.clone().add(-visibleBeforeDay, "day");

      let param = {
        challengeTitle: this.props.updateChallengeForm.fields.challengeName
          .value
          ? this.props.updateChallengeForm.fields.challengeName.value.trim()
          : "",
        brandId: this.props.updateChallengeForm.fields.selectHost.value
          ? this.props.updateChallengeForm.fields.selectHost.value
          : "",
        gameId: this.props.updateChallengeForm.fields.gamesId.value
          ? this.props.updateChallengeForm.fields.gamesId.value
          : "",
        winCriterionId: this.props.updateChallengeForm.fields.winCriterionId
          .value
          ? this.props.updateChallengeForm.fields.winCriterionId.value
          : 1,
        maxParticipants: this.props.updateChallengeForm.fields.selectPlayer
          .value
          ? this.props.updateChallengeForm.fields.selectPlayer.value
          : "",
        timeOfStart: startDate.format(),
        timeOfEnd: endDate.format(),
        visibleFrom: visibleBefore.format(),
        passwordForEntry: this.props.updateChallengeForm.fields
          .additionalPassword.value
          ? this.props.updateChallengeForm.fields.additionalPassword.value
          : "",
        rules: this.props.updateChallengeForm.fields.rules.value
          ? this.props.updateChallengeForm.fields.rules.value
          : "",
        entryFeeAmount: 0,
        logoUrl: this.props.updateChallengeForm.fields.logoUrl.value
          ? this.props.updateChallengeForm.fields.logoUrl.value
          : "",
        challengeImageUrl: this.props.updateChallengeForm.fields
          .challengeImageUrl.value
          ? this.props.updateChallengeForm.fields.challengeImageUrl.value
          : "",
        promoImage: this.props.updateChallengeForm.fields.promoImage.value
          ? this.props.updateChallengeForm.fields.promoImage.value
          : "",
        subscriptionTypes: 0,
        isFeatured: this.props.updateChallengeForm.fields.isFeatured.value,
        promoTitle: this.props.updateChallengeForm.fields.promoTitle.value ? this.props.updateChallengeForm.fields.promoTitle.value : "",
        promoSubTitle: this.props.updateChallengeForm.fields.promoSubTitle.value ? this.props.updateChallengeForm.fields.promoSubTitle.value : "",
        promoDescription: this.props.updateChallengeForm.fields.promoDescription.value ? this.props.updateChallengeForm.fields.promoDescription.value : "",
        promoLogo: this.props.updateChallengeForm.fields.promoLogo.value ? this.props.updateChallengeForm.fields.promoLogo.value : "",
        
        isCustomLogoUrl: this.props.updateChallengeForm.fields.isCustomLogoUrl.value && this.props.updateChallengeForm.fields.isCustomLogoUrl.value ? this.props.updateChallengeForm.fields.isCustomLogoUrl.value : "",
        isCustomImageUrl: this.props.updateChallengeForm.fields.isCustomImageUrl.value && this.props.updateChallengeForm.fields.isCustomImageUrl.value ? this.props.updateChallengeForm.fields.isCustomImageUrl.value : "",

        isCustomPromoLogo: this.props.updateChallengeForm.fields.isCustomPromoLogo.value ? this.props.updateChallengeForm.fields.isCustomPromoLogo.value : "",
        isCustomPromoBackgroundImage: this.props.updateChallengeForm.fields.isCustomPromoBackgroundImage.value ? this.props.updateChallengeForm.fields.isCustomPromoBackgroundImage.value : "",
        isCustomPromoBackgroundOverlayImage: this.props.updateChallengeForm.fields.isCustomPromoBackgroundOverlayImage.value ? this.props.updateChallengeForm.fields.isCustomPromoBackgroundOverlayImage.value : "",
        promoBackgroundImage: this.props.updateChallengeForm.fields.promoBackgroundImage.value ? this.props.updateChallengeForm.fields.promoBackgroundImage.value : "",
        promoBackgroundOverlayImage: this.props.updateChallengeForm.fields.promoBackgroundOverlayImage.value ? this.props.updateChallengeForm.fields.promoBackgroundOverlayImage.value : "",
        promoBackgroundColor: this.props.updateChallengeForm.fields.promoBackgroundColor.value ? this.props.updateChallengeForm.fields.promoBackgroundColor.value : "",
        promoBackgroundColor2: this.props.updateChallengeForm.fields.promoBackgroundColor2.value ? this.props.updateChallengeForm.fields.promoBackgroundColor2.value : "",
        promoTextDirection: this.props.updateChallengeForm.fields.promoTextDirection.value ? this.props.updateChallengeForm.fields.promoTextDirection.value : "",
        promoHasButton: this.props.updateChallengeForm.fields.promoHasButton.value ? this.props.updateChallengeForm.fields.promoHasButton.value : "",
        promoButtonText: this.props.updateChallengeForm.fields.promoButtonText.value ? this.props.updateChallengeForm.fields.promoButtonText.value : "",
        about: null,
        aboutImage: null,
        rewards: test,
        challengeAdPlaces: adsPlces,
        facebook: null,
        twitter: null,
        instagram: null
      };
      this.props.createChallengeFormSubmit(param);
      if (activeTab) {
        setTimeout(() => {
          this.props.createCompleteTab(activeTab);
        }, 500);
      }
    }

    if (
      this.props.createChallengeTabList.nextTab &&
      activeTab !== "previewTab"
    ) {
      this.props.createChallengeTab(this.props.createChallengeTabList.nextTab);
    }
  }

  render() {
    const flagClass1 = this.props.createChallengeTabList.steps.gameSetupTab
      .isCompleted;
    const flagClass2 = this.props.createChallengeTabList.steps.timeDateTab
      .isCompleted;
      const flagClass3 = this.props.createChallengeTabList.steps.rewardsTab
      .isCompleted;
    const flagClass4 = this.props.createChallengeTabList.steps.brandingTab
      .isCompleted;
    const flagClass5 = this.props.createChallengeTabList.steps.rulesTab
      .isCompleted;
    const flagClass6 = this.props.createChallengeTabList.steps.publishTab
      .isCompleted;
    const flagClass7 = this.props.createChallengeTabList.steps.previewTab
      .isActive;

    const aflagClass1 = this.props.createChallengeTabList.steps["gameSetupTab"]
      .isActive;
    const aflagClass2 = this.props.createChallengeTabList.steps["timeDateTab"]
      .isActive;
    const aflagClass3 = this.props.createChallengeTabList.steps["rewardsTab"]
      .isActive;
    const aflagClass4 = this.props.createChallengeTabList.steps["brandingTab"]
      .isActive;
    const aflagClass5 = this.props.createChallengeTabList.steps["rulesTab"]
      .isActive;
    const aflagClass6 = this.props.createChallengeTabList.steps["publishTab"]
      .isActive;
    const aflagClass7 = this.props.createChallengeTabList.steps["previewTab"]
      .isActive;

    return this.props.challengeDetails ? (
      <div>
        <section id="rootwizard">
          <section className="container-fluid banner-wrapper">
            <div className="row">
              <div className="banner backend d-flex flex-column align-items-center">
                <div className="container">
                  <div className="padder-main">
                    <div className="row">
                      <div className="col-sm-12">
                        <ul
                          className="nav nav-pills mb-3 d-flex backend-pill"
                          id="backend-pill"
                          role="tablist"
                        >
                          <li
                            className={
                              "nav-item text-center flex-fill transparentDiv " +
                              (flagClass1 ? "mobile-active" : "")
                            }
                          >
                            <a
                              className={
                                "nav-link " +
                                (flagClass1 ? "active active-fixed" : "")
                              }
                              id="gameSetupTab"
                              datatoggle="pill"
                              role="tab"
                              // onClick={e => {
                              //   this.props.createChallengeTab(e.target.id);
                              // }}
                              aria-controls="game-setup"
                              aria-selected="true"
                            >
                              <div className="circle">
                                <div className="inner">
                                  <img
                                    src={gameSetupOn}
                                    className="on"
                                    alt=""
                                  />
                                  <img
                                    src={gameSetupOff}
                                    className="off"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </a>
                            <p>Game Setup</p>
                          </li>
                          <li
                            className={
                              "nav-item text-center flex-fill " +
                              (flagClass2 ? "transparentDiv mobile-active" : "")
                            }
                          >
                            <a
                              className={
                                "nav-link " +
                                (flagClass2 ? "active active-fixed" : "")
                              }
                              id="timeDateTab"
                              data-toggle="pill"
                              role="tab"
                              aria-controls="timeDate"
                              aria-selected="true"
                            >
                              <div className="circle">
                                <div className="inner">
                                  <img src={timeOn} className="on" alt="" />
                                  <img src={timeOff} className="off" alt="" />
                                </div>
                              </div>
                            </a>
                            <p>Time & Date</p>
                          </li>
                          <li
                            className={
                              "nav-item text-center flex-fill " +
                              (flagClass3 ? "transparentDiv mobile-active" : "")
                            }
                          >
                            <a
                              className={
                                "nav-link " +
                                (flagClass3 ? "active active-fixed" : "")
                              }
                              id="rewardsTab"
                              data-toggle="pill"
                              role="tab"
                              aria-controls="rewards"
                              aria-selected="true"
                            >
                              <div className="circle">
                                <div className="inner">
                                  <img src={rewardOn} className="on" alt="" />
                                  <img src={rewardOff} className="off" alt="" />
                                </div>
                              </div>
                            </a>
                            <p>Rewards</p>
                          </li>
                          <li
                            className={
                              "nav-item text-center flex-fill " +
                              (flagClass4 ? "transparentDiv mobile-active" : "")
                            }
                          >
                            <a
                              className={
                                "nav-link " +
                                (flagClass4 ? "active active-fixed" : "")
                              }
                              id="brandingTab"
                              data-toggle="pill"
                              role="tab"
                              aria-controls="branding"
                              aria-selected="true"
                            >
                              <div className="circle">
                                <div className="inner">
                                  <img src={brandingOn} className="on" alt="" />
                                  <img
                                    src={brandingOff}
                                    className="off"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </a>
                            <p>Branding</p>
                          </li>
                          <li
                            className={
                              "nav-item text-center flex-fill " +
                              (flagClass5 ? "transparentDiv mobile-active" : "")
                            }
                          >
                            <a
                              className={
                                "nav-link " +
                                (flagClass5 ? "active active-fixed" : "")
                              }
                              id="rulesTab"
                              data-toggle="pill"
                              role="tab"
                              aria-controls="rules"
                              aria-selected="true"
                            >
                              <div className="circle">
                                <div className="inner">
                                  <img src={rulesOn} className="on" alt="" />
                                  <img src={rulesOff} className="off" alt="" />
                                </div>
                              </div>
                            </a>
                            <p>Rules</p>
                          </li>
                          <li
                            className={
                              "nav-item text-center flex-fill " +
                              (flagClass6 ? "transparentDiv mobile-active" : "")
                            }
                          >
                            <a
                              className={
                                "nav-link " +
                                (flagClass6 ? "active active-fixed" : "")
                              }
                              id="publishTab"
                              data-toggle="pill"
                              role="tab"
                              aria-controls="publish"
                              aria-selected="true"
                            >
                              <div className="circle">
                                <div className="inner">
                                  <img src={publishOn} className="on" alt="" />
                                  <img
                                    src={publishOff}
                                    className="off"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </a>
                            <p>Publish</p>
                          </li>
                          <li
                            className={
                              "nav-item text-center flex-fil " +
                              (flagClass7 ? "transparentDiv mobile-active" : "")
                            }
                          >
                            <a
                              className={
                                "nav-link " +
                                (flagClass7 ? "active active-fixed" : "")
                              }
                              id="previewTab"
                              data-toggle="pill"
                              role="tab"
                              aria-controls="preview"
                              aria-selected="true"
                              style={{ width: 163, marginLeft: -6 }}
                            >
                              <div className="circle">
                                <div className="inner">
                                  <img src={previewOn} className="on" alt="" />
                                  <img
                                    src={previewOff}
                                    className="off"
                                    alt=""
                                  />
                                </div>
                              </div>
                            </a>
                            <p>Preview</p>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="container-fluid tab-content-wrapper">
            <div className="row">
              <div className="col-sm-12">
                <div className="tab-content" id="backendpills-Content">
                  <div
                    className={
                      "tab-pane fade " +
                      (!aflagClass2 &&
                      !aflagClass3 &&
                      !aflagClass4 &&
                      !aflagClass5 &&
                      !aflagClass6 &&
                      !aflagClass7
                        ? "show active"
                        : "") +
                      " tab-1"
                    }
                    id="game-setup"
                    role="tabpanel"
                    aria-labelledby="game-setup-tab"
                  >
                    <GameSetupComponent
                      gamesData={this.props.gameLists}
                      challengeDetails={this.props.challengeDetails}
                    />
                  </div>
                  <div
                    className={
                      "tab-pane fade " +
                      (aflagClass2 ? "show active" : "") +
                      " tab-2"
                    }
                    id="timeDate"
                    role="tabpanel"
                    aria-labelledby="timeDate-tab"
                  >
                    <TimeAndDateComponent
                      challengeDetails={this.props.challengeDetails}
                    />
                  </div>
                  <div
                    className={
                      "tab-pane fade " +
                      (aflagClass3 ? "show active" : "") +
                      " tab-3"
                    }
                    id="rewards"
                    role="tabpanel"
                    aria-labelledby="rewards-tab"
                  >
                    <RewardsComponent />
                  </div>
                  <div
                    className={
                      "tab-pane fade " +
                      (aflagClass4 ? "show active" : "") +
                      " tab-4"
                    }
                    id="branding"
                    role="tabpanel"
                    aria-labelledby="branding-tab"
                  >
                    <BrandingComponent
                      challengeDetails={this.props.challengeDetails}
                    />
                  </div>
                  <div
                    className={
                      "tab-pane fade " +
                      (aflagClass5 ? "show active" : "") +
                      " tab-5"
                    }
                    id="rules"
                    role="tabpanel"
                    aria-labelledby="rules-tab"
                  >
                    <RulesComponent
                      challengeDetails={this.props.challengeDetails}
                    />
                  </div>
                  <div
                    className={
                      "tab-pane fade " +
                      (aflagClass6 ? "show active" : "") +
                      " tab-6"
                    }
                    id="publish"
                    role="tabpanel"
                    aria-labelledby="publish-tab"
                  >
                    <PublishComponent
                      challengeDetails={this.props.challengeDetails}
                      challengeDetailId={this.props.match.params.id}
                    />
                  </div>
                  <div
                    className={
                      "tab-pane fade " +
                      (aflagClass7 ? "show active" : "") +
                      " tab-7"
                    }
                    id="preview"
                    role="tabpanel"
                    aria-labelledby="preview-tab"
                  >
                    <PreviewComponent
                      challengeDetails={this.props.challengeDetails}
                    />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="container-fluid tab-indication-wrapper ">
            <div className="row justify-content-end align-items-center">
              <div className="col-sm-3 d-flex justify-content-end align-items-center">
                {this.props.createChallengeTabList.previousTab ? (
                  <button
                    onClick={e => {
                      this.props.createChallengeTab(
                        this.props.createChallengeTabList.previousTab
                      );
                    }}
                    id="btnTabPrv"
                    className=" btn btn-gray   py-3 unlock dark  mw-100 mx-1"
                  >
                    Previous
                  </button>
                ) : (
                  ""
                )}
                {this.props.createChallengeTabList.nextTab ? (
                  <button
                    onClick={e => {
                      this.formValidationCheck();
                    }}
                    id="btnTabnext"
                    className="[ btn btn-pink light  py-3 unlock dark  mw-100 mx-1]"
                  >
                    Next
                  </button>
                ) : (
                  ""
                )}
              </div>
            </div>
          </section>
          <section className="container-fluid">
            <div className="row">
              <div className="blank-space d-block d-lg-none" />
            </div>
          </section>
        </section>
      </div>
    ) : (
      ""
    );
  }
}

export default UpdateChallengeComponent;
