import React, { Component } from "react";
import { connect } from "react-redux";
import ActionGame from "../../../assets/images/topic-action@3x.png";

class DashboardFreeToPlayGamesCardComponent extends Component {
  addDefaultSrc(ev) {
    ev.target.src = ActionGame;
  }

  render() {
    return (
      <a href={"#"}>
        <div className="card free-to-play-game">
          <img
            className="card-img"
            src={this.props.imageUrl ? this.props.imageUrl : ActionGame}
            alt={this.props.description}
            onError={this.addDefaultSrc}
          />
          <h4 className="mt-3 bold">{this.props.description}</h4>
        </div>
      </a>
    );
  }
}

const defaultProps = {
  id: 1,
  imageUrl: ActionGame,
  description: "Action"
};

const mapStateToProps = state => {
  return {
    isLoggedIn: true
  };
};

DashboardFreeToPlayGamesCardComponent.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  defaultProps
)(DashboardFreeToPlayGamesCardComponent);
