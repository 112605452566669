import React, { Component } from "react";
import SwiperContainer from "../../Slider/SwiperContainer";
import TournamentCardComponent from "./TournamentCardComponent";
import { getContainerLeftWidth } from "../../../utils/misc";

class TournamentCardContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slidesOffsetBefore: 0,
      calculating: true
    };
    this.calculateWidth = this.calculateWidth.bind(this);
    this.calculateAdIndex = this.calculateAdIndex.bind(this);
    this.adIndex = 4;
    this.calculateAdIndex();
  }

  calculateAdIndex() {
    if (window.innerWidth < 1200) {
      if (window.innerWidth > 767) {
        this.adIndex = Math.floor(
          (window.innerWidth - getContainerLeftWidth()) / 260
        );
      } else {
        this.adIndex = Math.floor(
          (window.innerWidth - getContainerLeftWidth()) / 200
        );
      }
    }
  }

  componentDidMount() {
    this.calculateWidth();
  }
  calculateWidth() {
    this.setState((state, props) => {
      return {
        slidesOffsetBefore: getContainerLeftWidth(),
        calculating: false
      };
    });
  }
  render() {
    const params = {
      fixDrag: true,
      slidesPerView: "auto",
      containerClass: "swiper-container",
      spaceBetween: 25,
      loop: false,
      centeredSlides: false,
      slidesOffsetBefore: this.state.slidesOffsetBefore + 20,
      slidesOffsetAfter: 50,
      breakpoints: {
        1024: {
          initialSlide: 0
        },
        768: {
          initialSlide: 0,
          slidesOffsetBefore: this.state.slidesOffsetBefore + 15
        },
        640: {
          initialSlide: 0,
          spaceBetween: 18,
          slidesOffsetBefore: 15,
          slidesOffsetAfter: 15
        },
        320: {
          initialSlide: 0,
          spaceBetween: 18,
          slidesOffsetBefore: 15,
          slidesOffsetAfter: 15,
          touchRatio: 1
        }
      }
    };
    const { list, challengeType } = this.props;
    let test = [];
    if (this.props.showAd) {
      if (list && list.length > 0) {
        const adIndex = this.adIndex > 1 ? this.adIndex : 2;
        test = [
          ...list.slice(0, adIndex - 1),
          ...[{ type: "ad" }],
          ...list.slice(adIndex - 1)
        ];
      }
    } else {
      test = list;
    }
    return !this.state.calculating && list && list.length > 0 ? (
      <SwiperContainer {...params}>
        {test.map((item, index) => (
          <TournamentCardComponent
            {...item}
            key={index}
            cardLgClass="popular-arcadex"
            challengeType={challengeType}
            order={index + 1}
            advertisementUrl={this.props.advertisementUrl}
            advertisementPlace={this.props.advertisementPlace}
          />
        ))}
      </SwiperContainer>
    ) : (
      ""
    );
  }
}
TournamentCardContainer.defaultProps = {
  showAd: false
};

export default TournamentCardContainer;
