import React, { Component } from "react";
import { connect } from "react-redux";
import ProfileImage from "../../../assets/images/avatar-demo.png";
import addIcon from "../../../assets/images/svg/add.svg";
import { deleteHostForm } from "../../../actions/forms";

class HostPlayerCardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false
    };
  }
  addDefaultSrc(ev) {
    ev.target.src = ProfileImage;
  }
  toggleDeleteMenu() {
    this.setState(prevState => ({
      visible: !prevState.visible
    }));
  }
  render() {
    const { showDeleteButton } = this.props;
    const { visible } = this.state;
    return (
      <>
        <div className="swiper-slide d-flex justify-content-end">
          <div className="card popular-host text-center">
            {this.props.id === "add" ? (
              <a href={"/create-host"} rel="noopener noreferrer">
                <figure className="mr-2 mr-md-4 profile-pic-120 bg-host-backend d-flex justify-content-center align-items-center">
                  <img src={addIcon} className="w-25" alt="Dunkin" />
                </figure>
              </a>
            ) : (
              <>
                {showDeleteButton && (
                  <span
                    className={"close-icon " + (visible ? " d-block" : "")}
                    onClick={e => {
                      e.preventDefault();
                      this.toggleDeleteMenu();
                    }}
                  >
                    &times;
                  </span>
                )}
                <a
                  href={"/update-host/" + this.props.id}
                  rel="noopener noreferrer"
                  target="_blank"
                  className={"link " + (visible ? "selected" : "")}
                >
                  <figure className="mr-1 ml-1 ml-md-2 mr-md-2">
                    <img
                      src={this.props.avatar ? this.props.avatar : ProfileImage}
                      alt={this.props.name}
                      onError={this.addDefaultSrc}
                      className="profile-pic-120"
                    />
                    <figcaption className="text-secondary mt-3">
                      {this.props.name}
                    </figcaption>
                  </figure>
                </a>
                {showDeleteButton && visible && (
                  <div className="delete-confirmation-wrapper">
                    <p className="mb-1 bold ml-2 message-text">
                      Are you sure ?
                    </p>
                    <div className="button-wrapper">
                      <button
                        onClick={() => {
                          this.props.deleteHostFormUpdateField(
                            "Id",
                            this.props.id
                          );
                          this.props.deleteHostFormSubmit({
                            Id: this.props.id
                          });
                          this.toggleDeleteMenu();
                        }}
                        type="button"
                        className="btn btn-pink light py-3 btn-small mt-2 btn-left custom-btn-left"
                      >
                        Yes
                      </button>
                      <button
                        onClick={() => {
                          this.toggleDeleteMenu();
                        }}
                        type="button"
                        className=" btn btn-pink light py-3 btn-small mt-2 ml-4 btn-right custom-btn-right"
                      >
                        No
                      </button>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}

const defaultProps = {
  id: 1,
  avatar: ProfileImage,
  name: "Telkom Esports",
  cardClass: "card popular-arcadex",
  cardHeaderClass: "card-header light",
  showDeleteButton: false
};

const mapDispatchToProps = {
  deleteHostFormUpdateField: deleteHostForm.updateField,
  deleteHostFormSubmit: deleteHostForm.submit
};

HostPlayerCardComponent.defaultProps = defaultProps;
export default connect(
  null,
  mapDispatchToProps
)(HostPlayerCardComponent);
