const initialStateForList = {
  isFetching: false,
  items: [],
  didInvalidate: true,
  receiveError: false
};

export default params => (state = initialStateForList, action) => {
  switch (action.type) {
    case "LIST_INVALIDATE_" + params.code:
      return {
        ...state,
        didInvalidate: true
      };
    case "LIST_REQUEST_" + params.code:
      return {
        ...state,
        isFetching: true
      };
    case "LIST_RECEIVE_" + params.code:
      return {
        ...state,
        isFetching: false,
        items: action.data,
        didInvalidate: false,
        receiveError: false
      };
    case "LIST_RECEIVE_ERROR_" + params.code:
      return {
        ...initialStateForList,
        receiveError: true
      };
    default:
      return state;
  }
};
