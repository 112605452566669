import { combineReducers } from "redux";
import {
  ListReducerCreator, TListReducerCreator,
} from "./creators";

import { AnalyzeList, BanUsersList, UsersList, UserChallengeList  } from "../redux-config/tlistActionMap";
import { BanReasonList, UserSubscriptionHistoryList } from "../redux-config/listActionMap";

const bans = combineReducers({
  [AnalyzeList.stateSubKey]: TListReducerCreator(AnalyzeList),
  [UsersList.stateSubKey]: TListReducerCreator(UsersList),
  [BanUsersList.stateSubKey]: TListReducerCreator(BanUsersList),
  [BanReasonList.stateSubKey]: ListReducerCreator(BanReasonList),
  [UserChallengeList.stateSubKey]: TListReducerCreator(UserChallengeList),
  [UserSubscriptionHistoryList.stateSubKey]: ListReducerCreator(UserSubscriptionHistoryList)
});


export default bans;
