import { combineReducers } from "redux";
import { ListReducerCreator, PaginationListReducerCreator,DetailReducerCreator } from "./creators";
import { TournamentsList } from "../redux-config/listActionMap";
import { TournamentsListViewAll } from "../redux-config/plistActionMap";
import { ChallengeFormDetails } from "../redux-config/detailActionMap";

const tournaments = combineReducers({
  [TournamentsList.stateSubKey]: ListReducerCreator(TournamentsList),
  [TournamentsListViewAll.stateSubKey]: PaginationListReducerCreator(
    TournamentsListViewAll
  ),
  [ChallengeFormDetails.stateSubKey]: DetailReducerCreator(ChallengeFormDetails)
});

export default tournaments;
