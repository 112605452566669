import {
  ListActionCreator,
  PaginationListActionCreator,
  DetailActionCreator
} from "./creators";
import {
  getHostList,
  onGetHostDetails,
  getBrandCategories,
  getPlatformbyBrandList
} from "../services/host.service";
import { HostListViewAll } from "../redux-config/plistActionMap";
import { HostDetails } from "../redux-config/detailActionMap";
import { updateHostForm } from "./forms";
import {
  HostList,
  BrandList,
  PlatformByBrandList
} from "../redux-config/listActionMap";

export const hostList = ListActionCreator({
  ...HostList,
  service: getHostList,
  dataMapper: json => json.data.data.items
});
export const hostListViewAll = PaginationListActionCreator({
  ...HostListViewAll,
  service: getHostList,
  dataMapper: json => json.data.data.items,
  lastPageMapper: json => json.data.data.isLastPage
});

export const hostDetails = DetailActionCreator({
  ...HostDetails,
  service: onGetHostDetails,
  dataMapper: json => {
    return json.data.data;
  },
  onResponse: (json, dispatch) => {
    const { data } = json.data;
    let userStat = data.profile ? data.profile : [];
    let profileIdsValue = userStat.map((us, i) => {
      return us.id;
    });
    let brand = data.brandAdvertisements
      ? data.brandAdvertisements.map((item, index) => {
        dispatch(
          updateHostForm.updateField(
            item.brandAdvertisementPlace.type +
            "-" +
            item.brandAdvertisementPlace.placeCode,
            item.imageUrl
          )
        );
      })
      : [];
    let ownerBrandId = data.brandAdvertisements
      ? data.brandAdvertisements.map((item, index) => {
        dispatch(
          updateHostForm.updateField(
            item.brandAdvertisementPlace.type +
            "-" +
            item.brandAdvertisementPlace.placeCode +
            "-" +
            "ownerBrandID",
            item.ownerBrandId
          )
        );
      })
      : [];
    dispatch(
      updateHostForm.updateField("prizeImage", data.prizeImage ? data.prizeImage : "")
    );
    dispatch(
      updateHostForm.updateField("avatarUrl", data.avatar ? data.avatar : "")
    );
    dispatch(
      updateHostForm.updateField("hostName", data.name ? data.name : "")
    );
    dispatch(
      updateHostForm.updateField(
        "description",
        data.description ? data.description : ""
      )
    );
    dispatch(updateHostForm.updateField("website", data.url ? data.url : ""));
    dispatch(
      updateHostForm.updateField(
        "webHeaderImage",
        data.brandPageHeader ? data.brandPageHeader : ""
      )
    );
    dispatch(
      updateHostForm.updateField(
        "mobileHeaderImage",
        data.brandPageMobileHeader ? data.brandPageMobileHeader : ""
      )
    );
    dispatch(
      updateHostForm.updateField(
        "instagram",
        data.socialLinks.instagram ? data.socialLinks.instagram : ""
      )
    );
    dispatch(
      updateHostForm.updateField(
        "twitter",
        data.socialLinks.twitter ? data.socialLinks.twitter : ""
      )
    );
    dispatch(
      updateHostForm.updateField(
        "facebook",
        data.socialLinks.facebook ? data.socialLinks.facebook : ""
      )
    );
    dispatch(
      updateHostForm.updateField(
        "youtube",
        data.socialLinks.youtube ? data.socialLinks.youtube : ""
      )
    );
    dispatch(
      updateHostForm.updateField(
        "twitch",
        data.socialLinks.twitch ? data.socialLinks.twitch : ""
      )
    );
    dispatch(
      updateHostForm.updateField(
        "smashcast",
        data.socialLinks.smashcast ? data.socialLinks.smashcast : ""
      )
    );
    dispatch(
      updateHostForm.updateField(
        "countryName",
        data.countryName ? data.countryName : ""
      )
    );
    dispatch(
      updateHostForm.updateField(
        "countryId",
        data.countryId ? data.countryId : ""
      )
    );
    dispatch(updateHostForm.updateField("profileIds", profileIdsValue));
    dispatch(updateHostForm.updateField("envId", data.env.id));
    dispatch(updateHostForm.updateField("envName", data.env.name));
  }
});

export const brandList = ListActionCreator({
  ...BrandList,
  service: getBrandCategories,
  dataMapper: json => (json.data.success ? json.data.data : [])
});

export const platformByBrandList = ListActionCreator({
  ...PlatformByBrandList,
  service: getPlatformbyBrandList,
  dataMapper: json => (json.data.success ? json.data.data : [])
});
