export const HostListViewAll = {
  code: "HOST_VIEW_ALL",
  stateKey: "host",
  stateSubKey: "hostListViewAll"
};

export const TournamentsListViewAll = {
  code: "TOURNAMENTS_VIEW_ALL",
  stateKey: "tournaments",
  stateSubKey: "tournamentsListViewAll"
};

export const RafflesListViewAll = {
  code: "RAFFLES_VIEW_ALL",
  stateKey: "raffles",
  stateSubKey: "rafflesListViewAll"
};

export const NewsListViewAll = {
  code: "NEWS_VIEW_ALL",
  stateKey: "news",
  stateSubKey: "newsListViewAll"
};
export const AnalyzeList = {
  code: "ANALYZE_LIST",
  stateKey: "bans",
  stateSubKey: "analyzelist"
};
export const DashboardList = {
  code: "DASHOBOARD_LIST",
  stateKey: "dashboard",
  stateSubKey: "dashboardlist"
};

export const BanUsersList = {
  code: "BAN_USERS_LIST",
  stateKey: "bans",
  stateSubKey: "banUsersList"
};
