import AvailableSpaceUpdateChallengeComponent from "../../components/AvailableSpace/AvailableSpaceUpdateChallengeComponent";
import { connect } from "react-redux";
import { updateChallengeAdsForm } from "../../actions/forms";
import { platformByBrandList } from "../../actions/host";
import { challengeAdvertisementsDetails } from "../../actions/availablespace";

const mapDispatchToProps = {
  updateChallengeAdsFormSubmit: updateChallengeAdsForm.submit,
  updateChallengeAdsFormReset: updateChallengeAdsForm.reset,
  updateChallengeAdsFormResetField: updateChallengeAdsForm.resetField,
  updateChallengeAdsFormUpdateField: updateChallengeAdsForm.updateField,
  updateChallengeAdsFormSetError: updateChallengeAdsForm.setError,
  updateChallengeAdsFormSetSuccess: updateChallengeAdsForm.setSuccess,
  updateChallengeAdsFormResetError: updateChallengeAdsForm.resetError,
  updateChallengeAdsFormResetSuccess: updateChallengeAdsForm.resetSuccess,

  //brand ads detail
  fetchChallengeAdsDetailsIfNeeded:
    challengeAdvertisementsDetails.fetchIfNeeded,
  invalidateChallengeAdsDetails: challengeAdvertisementsDetails.invalidate,

  //brnad list by platform
  fetchbrandbyPlatformListIfNeeded: platformByBrandList.fetchIfNeeded,
  invalidatebrandbyPlatformList: platformByBrandList.invalidate
};

const mapStateToProps = state => {
  const { forms, host } = state;
  return {
    updateChallengeAdsForm: forms.updateChallengeAdsForm,
    platformByBrandList: host.platformByBrandList
      ? host.platformByBrandList
      : []
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AvailableSpaceUpdateChallengeComponent);
