import React, { Component } from "react";
import PaginationComponent from "../Pagination/Pagination";
import Select2ComponentId from "../Select2/Select2ComponentId";

class AvailableSpaceComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adsPlacements: false
    };
    this.changeAdsPlace = this.changeAdsPlace.bind(this);
    this.getAllBrandAdsData = this.getAllBrandAdsData.bind(this);
    this.getAllchallengeAdsData = this.getAllchallengeAdsData.bind(this);
  }
  changeAdsPlace() {
    this.setState({
      adsPlacements: !this.state.adsPlacements
    });
    if (this.state.adsPlacements) {
      this.props.invalidatebrandAdAvailable();
      this.props.fetchbrandAdAvailabletIfNeeded({
        page: 1,
        sortBy: "",
        sortOrder: "",
        searchText: "",
        type: "",
        searchByPlace: ""
      });
      this.props.resetSearchAndSortchallengeAdAvailable();
    } else {
      this.props.invalidatechallengeAdAvailable();
      this.props.fetchchallengeAdAvailabletIfNeeded({
        page: 1,
        sortBy: "",
        sortOrder: "",
        searchText: "",
        type: "",
        searchByPlace: ""
      });
      this.props.resetSearchAndSortbrandAdAvailable();
    }
  }
  componentDidMount() {
    this.props.resetSearchAndSortchallengeAdAvailable();
    this.props.resetSearchAndSortbrandAdAvailable();
    this.props.invalidatebrandAdAvailable();
    this.props.fetchbrandAdAvailabletIfNeeded({
      page: 1,
      sortBy: "",
      sortOrder: "",
      searchText: "",
      type: "",
      searchByPlace: ""
    });
    this.props.invalidatechallengeAdAvailable();
    this.props.fetchchallengeAdAvailabletIfNeeded({
      page: 1,
      sortBy: "",
      sortOrder: "",
      searchText: "",
      type: "",
      searchByPlace: ""
    });
  }

  getAllBrandAdsData() {
    const {
      brandlistSort,
      brandlistSearch,
      invalidatebrandAdAvailable,
      fetchbrandAdAvailabletIfNeeded
    } = this.props;
    invalidatebrandAdAvailable();
    fetchbrandAdAvailabletIfNeeded({
      page: "1",
      sortBy: brandlistSort.field,
      sortOrder: brandlistSort.orderBy,
      searchText: brandlistSearch.name ? brandlistSearch.name : "",
      type: brandlistSearch.type ? brandlistSearch.type : "",
      searchByPlace: brandlistSearch.place ? brandlistSearch.place : ""
    });
  }
  getAllchallengeAdsData() {
    const {
      challengelistSort,
      challengelistSearch,
      invalidatechallengeAdAvailable,
      fetchchallengeAdAvailabletIfNeeded
    } = this.props;
    invalidatechallengeAdAvailable();
    fetchchallengeAdAvailabletIfNeeded({
      page: "1",
      sortBy: challengelistSort.field,
      sortOrder: challengelistSort.orderBy,
      searchText: challengelistSearch.name ? challengelistSearch.name : "",
      type: challengelistSearch.type ? challengelistSearch.type : "",
      searchByPlace: challengelistSearch.place ? challengelistSearch.place : ""
    });
  }
  render() {
    const {
      brandAvailableSpace,
      brandTotalPage,
      brandTotalCount,
      isBrandrecordDisplayed,
      isBrandLastPage,
      challengeAvailableSpace,
      ischallengeLastPage,
      challengeTotalPage,
      challengeTotalCount,
      ischallengerecordDisplayed,
      challengelistSort,
      challengelistSearch,
      brandlistSort,
      brandlistSearch,
      challengeState,
      brandState
    } = this.props;
    const selectType = [
      { id: "", value: "All" },
      { id: "web", value: "Web" },
      { id: "mobile", value: "Mobile" }
    ];
    const adsChallengeSelect = challengeState.map((data, index) => {
      return {
        id: data.id,
        value: data.label
      };
    });
    const adsBrandSelect = brandState.map((data, index) => {
      return {
        id: data.id,
        value: data.label
      };
    });
    return (
      <>
        <section className="container-fluid tab-content-wrapper">
          <div className="row">
            <div className="col-sm-12">
              <div className="container">
                <div className="padder-main">
                  <div className="row">
                    <div className="col-sm-12 mt-6">
                      <section className="tab-content">
                        <div className="row ">
                          <div className="col-sm-12">
                            <div className="col-sm-12">
                              <ul className="nav nav-pills  mb-4 d-flex justify-content-center align-items-center w-25">
                                <li className="nav-item col text-center">
                                  <span
                                    className={
                                      "a nav-link" +
                                      (this.state.adsPlacements
                                        ? ""
                                        : " active show")
                                    }
                                    onClick={this.changeAdsPlace}
                                  >
                                    Tournament
                                  </span>
                                </li>
                                <li className="nav-item col text-center">
                                  <span
                                    className={
                                      "a nav-link" +
                                      (!this.state.adsPlacements
                                        ? ""
                                        : " active show")
                                    }
                                    onClick={this.changeAdsPlace}
                                  >
                                    Host
                                  </span>
                                </li>
                              </ul>
                              <section className="tab-content">
                                <div
                                  id="webadv"
                                  className={
                                    "tab-pane fade" +
                                    (this.state.adsPlacements
                                      ? ""
                                      : " active show")
                                  }
                                >
                                  <div className="row">
                                    <>
                                      <div className="col-sm-12 table-responsive admin-table">
                                        <table className="table table-bordered">
                                          <thead>
                                            <tr>
                                              <th
                                                width="18%"
                                                scope="col"
                                                className={
                                                  "text-center sortable " +
                                                  (challengelistSort.field ===
                                                  "name"
                                                    ? challengelistSort.orderBy
                                                    : "")
                                                }
                                                onClick={e => {
                                                  this.props.setSortBychallengeAdAvailable(
                                                    "name"
                                                  );
                                                  setTimeout(() => {
                                                    this.getAllchallengeAdsData();
                                                  }, 100);
                                                }}
                                              >
                                                Tournament
                                              </th>
                                              <th scope="col" width="39%">
                                                Advertisement Place
                                              </th>
                                              <th scope="col">Owner</th>
                                              <th scope="col" width="20%">
                                                Type
                                              </th>
                                              <th
                                                scope="col"
                                                className="text-center"
                                              >
                                                Manage
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>
                                                <div className="form-group with-lable">
                                                  <div className="input-group-wrapper">
                                                    <input
                                                      type="text"
                                                      placeholder="Search by Tournament"
                                                      className="form-control effect-16"
                                                      value={
                                                        challengelistSearch.name
                                                          ? challengelistSearch.name
                                                          : ""
                                                      }
                                                      onChange={e => {
                                                        this.props.setSearchBychallengeAdAvailable(
                                                          "name",
                                                          e.target.value.trimLeft()
                                                        );
                                                        setTimeout(() => {
                                                          this.getAllchallengeAdsData();
                                                        }, 100);
                                                      }}
                                                    />
                                                    <span className="focus-border" />
                                                  </div>
                                                </div>
                                              </td>
                                              <td className="text-center">
                                                <div className="form-group with-lable">
                                                  <div className="input-group-wrapper">
                                                    <div className="select">
                                                      <Select2ComponentId
                                                        selectClass={
                                                          " select-2-input form-control effect-16"
                                                        }
                                                        onChange={event => {
                                                          this.props.setSearchBychallengeAdAvailable(
                                                            "place",
                                                            event.id
                                                          );
                                                          setTimeout(() => {
                                                            this.getAllchallengeAdsData();
                                                          }, 100);
                                                        }}
                                                        onSubmitRemove={
                                                          this.state
                                                            .adsPlacements
                                                        }
                                                        selectOptions={
                                                          adsChallengeSelect
                                                        }
                                                        labelText="Select Place"
                                                      />
                                                    </div>
                                                    <span className="focus-border" />
                                                  </div>
                                                </div>
                                              </td>
                                              <td className="text-center" />
                                              <td className="text-center ">
                                                <div className="form-group with-lable">
                                                  <div className="input-group-wrapper">
                                                    <div className="select">
                                                      <Select2ComponentId
                                                        selectClass={
                                                          " select-2-input form-control effect-16"
                                                        }
                                                        onChange={event => {
                                                          this.props.setSearchBychallengeAdAvailable(
                                                            "type",
                                                            event.id
                                                          );
                                                          setTimeout(() => {
                                                            this.getAllchallengeAdsData();
                                                          }, 100);
                                                        }}
                                                        onSubmitRemove={
                                                          this.state
                                                            .adsPlacements
                                                        }
                                                        selectOptions={
                                                          selectType
                                                        }
                                                        labelText="Select Type"
                                                      />
                                                    </div>
                                                    <span className="focus-border" />
                                                  </div>
                                                </div>
                                              </td>
                                              <td className="text-center" />
                                            </tr>
                                            {challengeAvailableSpace.items &&
                                            challengeAvailableSpace.items
                                              .length > 0 ? (
                                              challengeAvailableSpace.items.map(
                                                (item, index) => {
                                                  return (
                                                    <tr key={index}>
                                                      <td>
                                                        {item.challenge.name
                                                          ? item.challenge.name
                                                          : "-"}
                                                      </td>
                                                      <td>
                                                        {item
                                                          .challengeAdvertisementPlace
                                                          .label
                                                          ? item
                                                              .challengeAdvertisementPlace
                                                              .label
                                                          : ""}
                                                      </td>
                                                      <td>
                                                        {item.ownerBrand
                                                          ? item.ownerBrand.name
                                                          : "-"}
                                                      </td>
                                                      <td>
                                                        <span
                                                          className={
                                                            "badge badge-pill " +
                                                            (item
                                                              .challengeAdvertisementPlace
                                                              .type == "web"
                                                              ? "badge-secondary"
                                                              : "badge-danger")
                                                          }
                                                        >
                                                          {item
                                                            .challengeAdvertisementPlace
                                                            .type
                                                            ? item
                                                                .challengeAdvertisementPlace
                                                                .type
                                                            : ""}
                                                        </span>
                                                      </td>
                                                      <td
                                                        className="text-center"
                                                        width="20%"
                                                      >
                                                        <a
                                                          href={
                                                            "/available-space-update-challenge/" +
                                                            item.id
                                                          }
                                                          className="text-secondary semi-bold"
                                                        >
                                                          Edit{" "}
                                                        </a>
                                                        <span className="mx-2">
                                                          |
                                                        </span>{" "}
                                                        <a
                                                          href={
                                                            "/challenge-ads-preview?advertisementPlace=" +
                                                            item
                                                              .challengeAdvertisementPlace
                                                              .id +
                                                            "&advertisementType=" +
                                                            item
                                                              .challengeAdvertisementPlace
                                                              .type +
                                                            "&advertisementUrl=" +
                                                            item.url
                                                          }
                                                          className="text-secondary semi-bold"
                                                          target="_blank"
                                                        >
                                                          Preview
                                                        </a>
                                                        <span className="mx-2">
                                                          |
                                                        </span>{" "}
                                                        {item.isPublished ? (
                                                          <a
                                                            href={
                                                              "javascript:void(0)"
                                                            }
                                                            onClick={() => {
                                                              this.props.revokeChallengeAdsFormSubmit(
                                                                item.id
                                                              );
                                                            }}
                                                            className="text-secondary semi-bold"
                                                          >
                                                            Revoke
                                                          </a>
                                                        ) : (
                                                          <a
                                                            href={
                                                              "javascript:void(0)"
                                                            }
                                                            onClick={() => {
                                                              this.props.publishChallengeAdsFormSubmit(
                                                                item.id
                                                              );
                                                            }}
                                                            className="text-secondary semi-bold"
                                                          >
                                                            Publish
                                                          </a>
                                                        )}
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )
                                            ) : (
                                              <tr className="text-center">
                                                <td colSpan="5">
                                                  No Record Found
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                      {challengeAvailableSpace.items.length >
                                      0 ? (
                                        <div className="col-sm-12">
                                          <PaginationComponent
                                            totalPage={challengeTotalPage}
                                            totalCount={challengeTotalCount}
                                            recordDisplayed={
                                              ischallengerecordDisplayed
                                            }
                                            isLastPage={ischallengeLastPage}
                                            invalidateAllAction={
                                              this.props
                                                .invalidatechallengeAdAvailable
                                            }
                                            fetchListAllAction={
                                              this.props
                                                .fetchchallengeAdAvailabletIfNeeded
                                            }
                                            sortBy={challengelistSort.field}
                                            sort={challengelistSort.orderBy}
                                            resetSearchPage={[
                                              challengelistSearch.name
                                                ? challengelistSearch.name
                                                : "",
                                              challengelistSearch.type
                                                ? challengelistSearch.type
                                                : "",
                                              challengelistSearch.place
                                                ? challengelistSearch.place
                                                : ""
                                            ]}
                                            searchSort={
                                              challengelistSearch.name ||
                                              challengelistSearch.type ||
                                              challengelistSearch.place
                                                ? true
                                                : false
                                            }
                                            param={{
                                              sortBy: challengelistSort.field,
                                              sortOrder:
                                                challengelistSort.orderBy,
                                              searchText: challengelistSearch.name
                                                ? challengelistSearch.name
                                                : "",
                                              type: challengelistSearch.type
                                                ? challengelistSearch.type
                                                : "",
                                              searchByPlace: challengelistSearch.place
                                                ? challengelistSearch.place
                                                : ""
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  </div>
                                </div>
                                <div
                                  id="mobile-adv"
                                  className={
                                    "tab-pane fade" +
                                    (!this.state.adsPlacements
                                      ? ""
                                      : " active show")
                                  }
                                >
                                  <div className="row">
                                    <>
                                      <div className="col-sm-12 table-responsive admin-table ">
                                        <table className="table table-bordered">
                                          <thead>
                                            <tr>
                                              <th
                                                width="25%"
                                                scope="col"
                                                className={
                                                  "text-center sortable " +
                                                  (brandlistSort.field ===
                                                  "name"
                                                    ? brandlistSort.orderBy
                                                    : "")
                                                }
                                                onClick={e => {
                                                  this.props.setSortBybrandAdAvailable(
                                                    "name"
                                                  );
                                                  setTimeout(() => {
                                                    this.getAllBrandAdsData();
                                                  }, 100);
                                                }}
                                              >
                                                Host
                                              </th>
                                              <th scope="col" width="20%">
                                                Advertisement Place
                                              </th>
                                              <th scope="col">Owner</th>
                                              <th scope="col" width="20%">
                                                Type
                                              </th>
                                              <th
                                                scope="col"
                                                className="text-center"
                                              >
                                                Manage
                                              </th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <td>
                                                <div className="form-group with-lable">
                                                  <div className="input-group-wrapper">
                                                    <input
                                                      type="text"
                                                      placeholder="Search by Host"
                                                      className="form-control effect-16"
                                                      value={
                                                        brandlistSearch.name
                                                          ? brandlistSearch.name
                                                          : ""
                                                      }
                                                      onChange={e => {
                                                        this.props.setSearchBybrandAdAvailable(
                                                          "name",
                                                          e.target.value.trimLeft()
                                                        );
                                                        setTimeout(() => {
                                                          this.getAllBrandAdsData();
                                                        }, 100);
                                                      }}
                                                    />
                                                    <span className="focus-border" />
                                                  </div>
                                                </div>
                                              </td>
                                              <td className="text-center">
                                                <div className="form-group with-lable">
                                                  <div className="input-group-wrapper">
                                                    <div className="select">
                                                      <Select2ComponentId
                                                        selectClass={
                                                          " select-2-input form-control effect-16"
                                                        }
                                                        onChange={event => {
                                                          this.props.setSearchBybrandAdAvailable(
                                                            "place",
                                                            event.id
                                                          );

                                                          setTimeout(() => {
                                                            this.getAllBrandAdsData();
                                                          }, 100);
                                                        }}
                                                        onSubmitRemove={
                                                          !this.state
                                                            .adsPlacements
                                                        }
                                                        selectOptions={
                                                          adsBrandSelect
                                                        }
                                                        labelText="Select Place"
                                                      />
                                                    </div>
                                                    <span className="focus-border" />
                                                  </div>
                                                </div>
                                              </td>
                                              <td className="text-center" />
                                              <td className="text-center">
                                                <div className="form-group with-lable">
                                                  <div className="input-group-wrapper">
                                                    <div className="select">
                                                      <Select2ComponentId
                                                        selectClass={
                                                          "select-2-input form-control effect-16 "
                                                        }
                                                        onChange={event => {
                                                          this.props.setSearchBybrandAdAvailable(
                                                            "type",
                                                            event.id
                                                          );

                                                          setTimeout(() => {
                                                            this.getAllBrandAdsData();
                                                          }, 100);
                                                        }}
                                                        selectOptions={
                                                          selectType
                                                        }
                                                        onSubmitRemove={
                                                          !this.state
                                                            .adsPlacements
                                                        }
                                                        labelText="Select Type"
                                                      />
                                                    </div>
                                                    <span className="focus-border" />
                                                  </div>
                                                </div>
                                              </td>
                                              <td className="text-center" />
                                            </tr>
                                            {brandAvailableSpace.items &&
                                            brandAvailableSpace.items.length >
                                              0 ? (
                                              brandAvailableSpace.items.map(
                                                (item, index) => {
                                                  return (
                                                    <tr key={index}>
                                                      <td>{item.brand.name}</td>
                                                      <td>
                                                        {
                                                          item
                                                            .brandAdvertisementPlace
                                                            .label
                                                        }
                                                      </td>
                                                      <td>
                                                        {item.ownerBrand
                                                          ? item.ownerBrand.name
                                                          : "-"}
                                                      </td>

                                                      <td>
                                                        <span
                                                          className={
                                                            "badge badge-pill " +
                                                            (item
                                                              .brandAdvertisementPlace
                                                              .type == "web"
                                                              ? "badge-secondary"
                                                              : "badge-danger")
                                                          }
                                                        >
                                                          {
                                                            item
                                                              .brandAdvertisementPlace
                                                              .type
                                                          }
                                                        </span>
                                                      </td>
                                                      <td
                                                        className="text-center"
                                                        width="20%"
                                                      >
                                                        <a
                                                          href={
                                                            "/available-space-update-brand/" +
                                                            item.id
                                                          }
                                                          className="text-secondary semi-bold"
                                                        >
                                                          Edit{" "}
                                                        </a>
                                                        <span className="mx-2">
                                                          |
                                                        </span>{" "}
                                                        <a
                                                          href={
                                                            "/host-preview?advertisementPlace=" +
                                                            item
                                                              .brandAdvertisementPlace
                                                              .id +
                                                            "&advertisementType=" +
                                                            item
                                                              .brandAdvertisementPlace
                                                              .type +
                                                            "&advertisementUrl=" +
                                                            item.imageUrl
                                                          }
                                                          target="_blank"
                                                          className="text-secondary semi-bold"
                                                        >
                                                          Preview
                                                        </a>
                                                        <span className="mx-2">
                                                          |
                                                        </span>{" "}
                                                        {item.isPublished ? (
                                                          <a
                                                            href={
                                                              "javascript:void(0)"
                                                            }
                                                            onClick={() => {
                                                              this.props.revokeBrandAdsFormSubmit(
                                                                item.id
                                                              );
                                                            }}
                                                            className="text-secondary semi-bold"
                                                          >
                                                            Revoke
                                                          </a>
                                                        ) : (
                                                          <a
                                                            href={
                                                              "javascript:void(0)"
                                                            }
                                                            onClick={() => {
                                                              this.props.publishBrandAdsFormSubmit(
                                                                item.id
                                                              );
                                                            }}
                                                            className="text-secondary semi-bold"
                                                          >
                                                            Publish
                                                          </a>
                                                        )}
                                                      </td>
                                                    </tr>
                                                  );
                                                }
                                              )
                                            ) : (
                                              <tr className="text-center">
                                                <td colSpan="5">
                                                  No Record Found
                                                </td>
                                              </tr>
                                            )}
                                          </tbody>
                                        </table>
                                      </div>
                                      {brandAvailableSpace.items.length > 0 ? (
                                        <div className="col-sm-12">
                                          <PaginationComponent
                                            totalPage={brandTotalPage}
                                            totalCount={brandTotalCount}
                                            recordDisplayed={
                                              isBrandrecordDisplayed
                                            }
                                            isLastPage={isBrandLastPage}
                                            invalidateAllAction={
                                              this.props
                                                .invalidatebrandAdAvailable
                                            }
                                            fetchListAllAction={
                                              this.props
                                                .fetchbrandAdAvailabletIfNeeded
                                            }
                                            sortBy={brandlistSort.field}
                                            sort={brandlistSort.orderBy}
                                            searchSort={
                                              brandlistSearch.name ||
                                              brandlistSearch.type ||
                                              brandlistSearch.place
                                                ? true
                                                : false
                                            }
                                            resetSearchPage={[
                                              brandlistSearch.name
                                                ? brandlistSearch.name
                                                : "",
                                              brandlistSearch.type
                                                ? brandlistSearch.type
                                                : "",
                                              brandlistSearch.place
                                                ? brandlistSearch.place
                                                : ""
                                            ]}
                                            param={{
                                              sortBy: brandlistSort.field,
                                              sortOrder: brandlistSort.orderBy,
                                              searchText: brandlistSearch.name
                                                ? brandlistSearch.name
                                                : "",
                                              type: brandlistSearch.type
                                                ? brandlistSearch.type
                                                : "",
                                              searchByPlace: brandlistSearch.place
                                                ? brandlistSearch.place
                                                : ""
                                            }}
                                          />
                                        </div>
                                      ) : (
                                        ""
                                      )}
                                    </>
                                  </div>
                                </div>
                              </section>
                            </div>
                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default AvailableSpaceComponent;
