export const LoginForm = {
  code: "LOGIN",
  stateKey: "forms",
  stateSubKey: "loginForm",
  fields: ["email", "password"]
};
export const UsernameExistsForm = {
  code: "USERNAME_EXISTS",
  stateKey: "forms",
  stateSubKey: "usernameExistsForm",
  fields: ["username"]
};
export const ForgotPasswordForm = {
  code: "FORGOT_PASSWORD",
  stateKey: "forms",
  stateSubKey: "forgotPasswordForm",
  fields: ["email", "resendEmail"]
};
export const EnableNewsForm = {
  code: "ENABLE_NEWS",
  stateKey: "forms",
  stateSubKey: "enableNewsForm",
  fields: ["newsId"]
};
export const DisableNewsForm = {
  code: "DISABLE_NEWS",
  stateKey: "forms",
  stateSubKey: "disableNewsForm",
  fields: ["newsId"]
};
export const ResetPasswordForm = {
  code: "RESET_PASSWORD",
  stateKey: "forms",
  stateSubKey: "resetPasswordForm",
  fields: ["newPassword", "renewPassword"]
};
export const ChangePasswordForm = {
  code: "CHANGE_PASSWORD",
  stateKey: "forms",
  stateSubKey: "changePasswordForm",
  fields: ["currentPassword", "newPassword", "renewPassword"]
};
export const CreateChallengeForm = {
  code: "CREATE_CHALLENGE",
  stateKey: "forms",
  stateSubKey: "createChallengeForm",
  fields: [
    "challengeName",
    "challengeTranslation",
    "challengeRewardsTranslation",
    "activeRewardIndex",
    "selectHost",
    "selectHostName",
    "hostAvatar",
    "gamesId",
    "gamesName",
    "winCriterionId",
    "selectPlayer",
    "selectSubscription",
    "tournamentStartDate",
    "tournamentHours",
    "tournamentMinutes",
    "tournamentVisible",
    "tournamentEndingDate",
    "noPrizes",
    "rewards",
    "rules",
    "additionalPassword",
    "logoUrl",
    "challengeImageUrl",
    "promoImage",
    "promoImageMobile",
    "isFeatured",
    "previewLogo",
    "previewBanner",
    "challengeImageUrlType",
    "logoUrlType",
    "promoImageType",
    "promoImageMobileType",
    "promoTitle",
    "promoSubTitle",
    "promoDescription",
    "promoLogo",
    "promoLogoType",
    "promoBackgroundImage",
    "promoBackgroundImageType",
    "promoBackgroundOverlayImage",
    "promoBackgroundOverlayImageType",
    "promoBackgroundColor",
    "promoBackgroundColor2",
    "promoTextDirection",
    "promoHasButton",
    "promoButtonText",
    "isCustomPromoLogo",
    "isCustomLogoUrl",
    "isCustomImageUrl",
    "isCustomPromoBackgroundImage",
    "isCustomPromoBackgroundOverlayImage"
  ]
};
export const UpdateChallengeForm = {
  code: "UPDATE_CHALLENGE",
  stateKey: "forms",
  stateSubKey: "updateChallengeForm",
  fields: [
    "challengeName",
    "challengeTranslation",
    "selectHost",
    "selectHostName",
    "hostAvatar",
    "gamesId",
    "gamesName",
    "gamesUrl",
    "winCriterionId",
    "selectPlayer",
    "selectSubscription",
    "tournamentStartDate",
    "tournamentVisible",
    "tournamentEndingDate",
    "rewards",
    "noPrizes",
    "rules",
    "additionalPassword",
    "challengeImageUrl",
    "logoUrl",
    "isFeatured",
    "promoImage",
    "promoImageMobile",
    "previewLogo",
    "previewBanner",
    "challengeImageUrlType",
    "logoUrlType",
    "promoImageType",
    "envName",
    "promoImageMobileType",
    "promoTitle",
    "promoSubTitle",
    "promoDescription",
    "promoLogo",
    "promoLogoType",
    "promoBackgroundImage",
    "promoBackgroundOverlayImage",
    "promoBackgroundOverlayImageType",
    "promoBackgroundImageType",
    "promoBackgroundColor",
    "promoBackgroundColor2",
    "promoTextDirection",
    "promoHasButton",
    "promoButtonText",
    "isCustomPromoLogo",
    "isCustomPromoBackgroundImage",
    "isCustomPromoBackgroundOverlayImage",
    "isCustomLogoUrl",
    "isCustomImageUrl",
  ]
};

export const CreateHostForm = {
  code: "CREATE_HOST",
  stateKey: "forms",
  stateSubKey: "createHostForm",
  fields: [
    "avatarUrl",
    "hostName",
    "description",
    "website",
    "webHeaderImage",
    "mobileHeaderImage",
    "facebook",
    "twitter",
    "instagram",
    "twitch",
    "youtube",
    "smashcast",
    "profileIds",
    "countryId",
    "envId",
    "prizeImage"
  ]
};

export const UpdateHostForm = {
  code: "UPDATE_HOST",
  stateKey: "forms",
  stateSubKey: "updateHostForm",
  fields: [
    "avatarUrl",
    "hostName",
    "description",
    "website",
    "webHeaderImage",
    "mobileHeaderImage",
    "facebook",
    "twitter",
    "instagram",
    "twitch",
    "youtube",
    "smashcast",
    "profileIds",
    "countryId",
    "countryName",
    "envId",
    "envName",
    "prizeImage",
    "profileIds"
  ]
};

export const CreateNewsForm = {
  code: "CREATE_NEWS",
  stateKey: "forms",
  stateSubKey: "createNewsForm",
  fields: [
    "categoryId",
    "title",
    "content",
    "source",
    "imageUrl",
    "isActive",
    "thumbImageUrl",
    "envId"
  ]
};

export const UpdateNewsForm = {
  code: "UPDATE_NEWS",
  stateKey: "forms",
  stateSubKey: "updateNewsForm",
  fields: [
    "categoryId",
    "categoryName",
    "title",
    "content",
    "source",
    "imageUrl",
    "isActive",
    "thumbImageUrl",
    "envId",
    "envName"
  ]
};

export const PublishRaffleForm = {
  code: "CREATE_RAFFLE",
  stateKey: "forms",
  stateSubKey: "publishRaffleForm",
  fields: [
    "raffleTranslation",
    "selectHost",
    "timeOfStart",
    "entryFee",
    "itemDescription",
    "itemUrl",
    "duration",
    "points",
    "HH",
    "MM"
  ]
};

export const UpdateBrandAdsForm = {
  code: "UPDATE_BRAND_ADS",
  stateKey: "forms",
  stateSubKey: "updateBrandAdsForm",
  fields: [
    "brandName",
    "imageUrl",
    "ownerBrandId",
    "ownerBrandName",
    "brandAdvertisementPlace",
    "brandAdvertisementType",
    "brandAdsWidth",
    "brandAdsHeight",
    "isPublished",
    "envId"
  ]
};
export const UpdateChallengeAdsForm = {
  code: "UPDATE_CHALLENGE_ADS",
  stateKey: "forms",
  stateSubKey: "updateChallengeAdsForm",
  fields: [
    "challengeName",
    "url",
    "ownerBrandId",
    "ownerBrandName",
    "challengeAdvertisementPlace",
    "challengeAdvertisementType",
    "challengeAdsWidth",
    "challengeAdsHeight",
    "isPublished",
    "envId"
  ]
};
export const CreateDashboardAdvertisementForm = {
  code: "CREATE_DASHBOARD_ADVERTISEMENT",
  stateKey: "forms",
  stateSubKey: "createDashboardAdvertisementForm",
  fields: [
    "dashboardAdvertisementPlaceId",
    "ownerBrandId",
    "imageUrl",
    "impressionCount",
    "startAt",
    "endAt",
    "type",
    "imageType",
    "imageSize"
  ]
};

export const EditDashboardAdvertisementForm = {
  code: "EDIT_DASHBOARD_ADVERTISEMENT",
  stateKey: "forms",
  stateSubKey: "editDashboardAdvertisementForm",
  fields: [
    "id",
    "dashboardAdvertisementPlaceId",
    "ownerBrandId",
    "imageUrl",
    "impressionCount",
    "startAt",
    "endAt",
    "isPublished",
    "imageType",
    "imageSize"
  ]
};
export const AnalyzeForm = {
  code: "CREATE_ANALYZE_LIST",
  stateKey: "forms",
  stateSubKey: "analyzeForm",
  fields: ["profileId", "challengeId"]
};
export const PublishBrandAdsForm = {
  code: "PUBLISH_BRAND_ADS",
  stateKey: "forms",
  stateSubKey: "publishBrandAdsForm",
  fields: ["Id"]
};
export const DeleteTournamentForm = {
  code: "DELETE_TOURNAMENT",
  stateKey: "forms",
  stateSubKey: "deleteTournamentForm",
  fields: ["Id"]
};
export const DeleteHostForm = {
  code: "DELETE_HOST",
  stateKey: "forms",
  stateSubKey: "deleteHostForm",
  fields: ["Id"]
};
export const PublishChallengeAdsForm = {
  code: "PUBLISH_CHALLENGE_ADS",
  stateKey: "forms",
  stateSubKey: "publishChallengeAdsForm",
  fields: ["Id"]
};
export const RevokeBrandAdsForm = {
  code: "REVOKE_BRAND_ADS",
  stateKey: "forms",
  stateSubKey: "revokBrandAdsForm",
  fields: ["Id"]
};
export const RevokeChallengeAdsForm = {
  code: "REVOKE_CHALLENGE_ADS",
  stateKey: "forms",
  stateSubKey: "revokChallengeAdsForm",
  fields: ["Id"]
};

export const AddPointsForm = {
  code: "ADD_POINTS",
  stateKey: "forms",
  stateSubKey: "addPointsForm",
  fields: ["profileId", "points"]
};
export const BanLiftedForm = {
  code: "BAN_LIFTED",
  stateKey: "forms",
  stateSubKey: "banLiftedForm",
  fields: ["banId"]
};

export const AddBanUserForm = {
  code: "ADD_BAN_USER",
  stateKey: "forms",
  stateSubKey: "addBanUserForm",
  fields: ["profileId", "reasonId", "comment"]
};

export const DeleteRaffleForm = {
  code: "DELETE_RAFFLE",
  stateKey: "forms",
  stateSubKey: "deleteRaffleForm",
  fields: ["raffleId"]
};