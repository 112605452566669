import "babel-polyfill";
import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import createStore from "./store";
import App from "./App";
import SocketHoc from "./hoc/SocketHoc";
import "./styles/master.scss";
import { axios } from "./utils/axios";
import { logoutUserIfNeeded } from "./actions/user";

const { store, persistor } = createStore();

axios.interceptors.response.use(response => {
  if (
    response.data &&
    response.data.error &&
    response.data.error.code &&
    (response.data.error.code === "JWT1" || response.data.error.code === "JWT2" || response.data.error.code === "JWT3")
   // && axios.defaults.headers.common["x-bearer-token"]
  ) {        
    store.dispatch(logoutUserIfNeeded());
  }
return response;
});

const AppWithSocket = SocketHoc(App);
render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <AppWithSocket />
    </PersistGate>
  </Provider>,
  document.getElementById("root")
);
