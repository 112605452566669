import { connect } from "react-redux";
import CreateNewsComponent from "../../components/News/CreateNewsComponent";
import { newsCategoriesList } from "../../actions/news";
import { createNewsForm } from "../../actions/forms";

const mapDispatchToProps = {
  fetchNewsCategoriesIfNeeded: newsCategoriesList.fetchIfNeeded,
  invalidateNewsCategories: newsCategoriesList.invalidate,

  //News Form
  createNewsFormSubmit: createNewsForm.submit,
  createNewsFormReset: createNewsForm.reset,
  createNewsFormResetField: createNewsForm.resetField,
  createNewsFormUpdateField: createNewsForm.updateField,
  createNewsFormSetError: createNewsForm.setError,
  createNewsFormSetSuccess: createNewsForm.setSuccess,
  createNewsFormResetError: createNewsForm.resetError,
  createNewsFormResetSuccess: createNewsForm.resetSuccess
};

const mapStateToProps = state => {
  const { news, forms } = state;
  return {
    newsCategoriesList: news.newsCategoriesList ? news.newsCategoriesList : [],
    createNewsForm: forms.createNewsForm,
    statsPlatform: state.user.item.adminStats.ENVS,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateNewsComponent);
