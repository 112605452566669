export const ChallengeDetails = {
  code: "CHALLENGE_DETAIL",
  stateKey: "challenges",
  stateSubKey: "challengeDetails",
  type: "detail"
};
export const HostDetails = {
  code: "HOST_DETAIL",
  stateKey: "host",
  stateSubKey: "hostDetails",
  type: "detail"
};

export const RaffleDetails = {
  code: "RAFFLE_DETAIL",
  stateKey: "raffles",
  stateSubKey: "raffleDetails",
  type: "detail"
};

export const NewsDetails = {
  code: "NEWS_DETAIL",
  stateKey: "news",
  stateSubKey: "newsDetails",
  type: "detail"
};

export const BrandAdvertisementsDetails = {
  code: "BRAND_ADVERTISEMENTS",
  stateKey: "availablespace",
  stateSubKey: "brandAdvertisementsDetails",
  type: "detail"
};

export const ChallengeAdvertisementsDetails = {
  code: "CHALLENGE_ADVERTISEMENTS",
  stateKey: "availablespace",
  stateSubKey: "challengeAdvertisementsDetails",
  type: "detail"
};
export const DashboardAdvertisementDetails = {
  code: "DASHBOARD_ADVERTISEMENT_DETAIL",
  stateKey: "dashboard",
  stateSubKey: "dashboardAdvertisementDetails",
  type: "detail"
};

export const DashboardAdvertisementPublish = {
  code: "DASHBOARD_ADVERTISEMENT_PUBLISH_DETAIL",
  stateKey: "dashboard",
  stateSubKey: "dashboardAdvertisementPublishDetails",
  type: "detail"
};

export const DashboardAdvertisementRevoke = {
  code: "DASHBOARD_ADVERTISEMENT_REVOKE_DETAIL",
  stateKey: "dashboard",
  stateSubKey: "dashboardAdvertisementRevokeDetails",
  type: "detail"
};
export const ChallengeFormDetails = {
  code: "CHALLENGE_DETAIL",
  stateKey: "tournaments",
  stateSubKey: "challengeFormDetails",
  type: "detail"
};
