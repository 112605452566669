import React, { Component } from "react";
import Swiper from "./Swiper";
import "swiper/dist/css/swiper.css";
import UpdateDimension from "../Utils/UpdateDimension";

class SwiperContainer extends Component {
  constructor(props) {
    super(props);
    this.updateDimensions = this.updateDimensions.bind(this);
  }
  updateDimensions() {
    this.swiper.updateSwiper();
    this.swiper.swiper.slideTo(0);
  }
  render() {
    const { children, ...rest } = this.props;
    return (
      <>
        <UpdateDimension onUpdate={this.updateDimensions}>
          <Swiper
            {...rest}
            ref={swiper => {
              this.swiper = swiper;
            }}
          >
            {children}
          </Swiper>
        </UpdateDimension>
      </>
    );
  }
}

export default SwiperContainer;
