import React, { Component } from "react";
import CardImage from "../../assets/images/poo_default.png";
import PrizeIconFirst from "../../assets/images/svg/x-1.svg";
import PrizeIconSecond from "../../assets/images/svg/x-2.svg";
import PrizeIconThird from "../../assets/images/svg/x-3.svg";
import PrizeIconFourth from "../../assets/images/svg/x-4.svg";
import "./TournamentPoolCardComponent.scss";
import { ordinalSuffix } from "../../utils/misc";
import adv10 from "../../assets/images/adv/adv-10.png";

class TournamentPoolCardComponent extends Component {
  getPlace(no) {
    switch (no) {
      case "1":
        return {
          image: PrizeIconFirst,
          name: "First Prize",
          barClass: "first"
        };
      case "2":
        return {
          image: PrizeIconSecond,
          name: "Second Prize",
          barClass: "second"
        };
      case "3":
        return {
          image: PrizeIconThird,
          name: "Third Prize",
          barClass: "third"
        };
      case "4":
        return {
          image: PrizeIconFourth,
          name: "Fourth Prize",
          barClass: "fourth"
        };
      case "8":
        return {
          image: PrizeIconFourth,
          name: ordinalSuffix(5) + " - " + ordinalSuffix(8),
          barClass: "fourth"
        };
      case "16":
        return {
          image: PrizeIconFourth,
          name: ordinalSuffix(9) + " - " + ordinalSuffix(16),
          barClass: "fourth"
        };
      case "32":
        return {
          image: PrizeIconFourth,
          name: ordinalSuffix(17) + " - " + ordinalSuffix(32),
          barClass: "fourth"
        };
      case "64":
        return {
          image: PrizeIconFourth,
          name: ordinalSuffix(33) + " - " + ordinalSuffix(64),
          barClass: "fourth"
        };
      case "128":
        return {
          image: PrizeIconFourth,
          name: ordinalSuffix(65) + " - " + ordinalSuffix(128),
          barClass: "fourth"
        };
      case "256":
        return {
          image: PrizeIconFourth,
          name: ordinalSuffix(129) + " - " + ordinalSuffix(256),
          barClass: "fourth"
        };
      case "512":
        return {
          image: PrizeIconFourth,
          name: ordinalSuffix(257) + " - " + ordinalSuffix(512),
          barClass: "fourth"
        };
      case "1024":
        return {
          image: PrizeIconFourth,
          name: ordinalSuffix(513) + " - " + ordinalSuffix(1024),
          barClass: "fourth"
        };
      default:
        return {
          image: PrizeIconFourth,
          name: "no",
          barClass: "fourth"
        };
    }
  }

  getRewardTitle() {
    let itemsTitle = "";
    let pointsTitle = "";
    this.props.map(reward => {
      if (reward.type.toLowerCase() === "items") itemsTitle = reward.value;

      if (reward.type.toLowerCase() === "points")
        pointsTitle = reward.value + " Tokens";
    });

    if (itemsTitle !== "" && pointsTitle !== "") {
      return (
        <h4 className="bold">
          {itemsTitle}
          <span> + {pointsTitle}</span>
        </h4>
      );
    } else if (itemsTitle !== "") {
      return <h4 className="bold">{itemsTitle}</h4>;
    } else {
      return (
        <h4 className="bold">
          {pointsTitle}
          <br />
        </h4>
      );
    }
  }
  getRewardTitle2() {
    let itemsTitle = "";
    let pointsTitle = "";
    // if (this.props.rewardData.items.toLowerCase())
    itemsTitle = this.props.rewardData.items;

    // if (this.props.rewardData.points.toLowerCase())
    pointsTitle = this.props.rewardData.points + " Tokens";

    if (itemsTitle !== "" && pointsTitle !== "") {
      return (
        <h4 className="bold">
          {itemsTitle}
          <span> + {pointsTitle}</span>
        </h4>
      );
    } else if (itemsTitle !== "") {
      return <h4 className="bold">{itemsTitle}</h4>;
    } else {
      return (
        <h4 className="bold">
          {pointsTitle}
          <br />
        </h4>
      );
    }
  }

  render() {
    const place = this.props.forPreview
      ? this.props.rewardData.rewardPlace.toString()
      : this.props.place;
    const { image, name, barClass } = this.getPlace(place);
    const { rewardsimg } = this.props.rewardData;
    return (
      <>
        {this.props.type && this.props.type === "ad" ? (
          <div
            className="card popular-arcadex d-flex justify-content-center align-items-center"
            style={{ width: "300px" }}
          >
            <img className="w-100" src={adv10} alt="Advertisement" />
          </div>
        ) : (
          <div className={"card " + this.props.cardLgClass}>
            <img className="card-img" src={rewardsimg ? rewardsimg : CardImage} alt="" />
            <div
              className={
                "card-body " +
                barClass +
                " d-flex flex-column align-items-center justify-content-center"
              }
            >
              <figure>
                <div className="oval">
                  <div className="mask">
                    <img src={image} alt="Popular ArcadeX" />
                  </div>
                </div>
                <figcaption className="text-center">
                  <h6 className="bold">{name}</h6>
                </figcaption>
              </figure>

              {!this.props.forPreview
                ? this.getRewardTitle()
                : this.getRewardTitle2()}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default TournamentPoolCardComponent;
