export const PasswordEyeLogin = {
  code: "PW_EYE_LOGIN",
  stateKey: "toggles",
  stateSubKey: "pwEyeLogin"
};
export const PasswordEyeResetPass = {
  code: "PW_EYE_RESET_PASS",
  stateKey: "toggles",
  stateSubKey: "pwEyeResetPw"
};
export const PasswordEyeChangeOldPass = {
  code: "PW_EYE_CHANGE_OLD_PASS",
  stateKey: "toggles",
  stateSubKey: "pwEyeChangeOldPw"
};
export const PasswordEyeChangePass = {
  code: "PW_EYE_CHANGE_PASS",
  stateKey: "toggles",
  stateSubKey: "pwEyeChangePw"
};
