import CreateHostComponent from "../../components/Host/CreateHostComponent";
import { connect } from "react-redux";
import { createHostForm } from "../../actions/forms";

const mapDispatchToProps = {
  createHostFormSubmit: createHostForm.submit,
  createHostFormReset: createHostForm.reset,
  createHostFormResetField: createHostForm.resetField,
  createHostFormUpdateField: createHostForm.updateField,
  createHostFormSetError: createHostForm.setError,
  createHostFormSetSuccess: createHostForm.setSuccess,
  createHostFormResetError: createHostForm.resetError,
  createHostFormResetSuccess: createHostForm.resetSuccess
};

const mapStateToProps = state => {
  return {
    createHostForm: state.forms.createHostForm,
    loggedUserId: state.user.item.id,
    loggedUserIsBrand: state.user.item.isBrand,
    stats: state.user.item.adminStats.BRANDS,
    statsCountry: state.user.item.adminStats.COUNTRIES,
    statsPlatform: state.user.item.adminStats.ENVS,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateHostComponent);
