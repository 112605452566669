import React, { Component } from "react";
import PaginationComponent from "../Pagination/Pagination";
import Select2ComponentId from "../Select2/Select2ComponentId";

class DashboardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      page: 1,
      sortBy: "",
      sortOrder: "",
      searchText: "",
      searchByPlace: "",
      type: ""
    };

    this.publishAd = this.publishAd.bind(this);
    this.revokeAd = this.revokeAd.bind(this);
    this.searchByBrand = this.searchByBrand.bind(this);
    this.setParentPage = this.setParentPage.bind(this);
  }

  componentDidMount() {
    this.searchByBrand();
  }

  getFormattedDate(date) {
    var year = date.getFullYear();
    var month = (1 + date.getMonth()).toString();
    month = month.length > 1 ? month : "0" + month;
    var day = date.getDate().toString();
    day = day.length > 1 ? day : "0" + day;
    return day + "-" + month + "-" + year;
  }

  publishAd(id) {
    this.props.invalidateDashboardAdvertisementPublishDetails();
    this.props.fetchDashboardAdvertisementPublishDetailsIfNeeded(id);
  }

  revokeAd(id) {
    this.props.invalidateDashboardAdvertisementRevokeDetails();
    this.props.fetchDashboardAdvertisementRevokeDetailsIfNeeded(id);
  }

  searchByBrand(e) {
    this.props.invalidateDashboardListViewAll();
    this.props.fetchDashboardListViewAllIfNeeded({
      page: 1,
      sortBy: this.props.sortDashboardAds.field
        ? this.props.sortDashboardAds.field
        : "",
      sortOrder: this.props.sortDashboardAds.orderBy
        ? this.props.sortDashboardAds.orderBy.toUpperCase()
        : "",
      searchText: this.props.searchDashboardAds.brandName
        ? this.props.searchDashboardAds.brandName
        : "",
      searchByPlace: this.props.searchDashboardAds.searchByPlace
        ? this.props.searchDashboardAds.searchByPlace
        : "",
      type: this.props.searchDashboardAds.type
        ? this.props.searchDashboardAds.type
        : ""
    });
  }

  setParentPage(pageNumber) {
    this.setState({ page: pageNumber });
  }

  ucFirst(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  render() {
    const { dashboardlist } = this.props;
    const {
      dashboardTotalCount,
      dashboardTotalPage,
      isRecordDisplayed,
      isLastPage
    } = this.props;

    let dashboardAdvertisementOptions = [{ id: "", value: "All" }];
    let dashboardAdvertisementIdValues = [];
    if (this.props.dashboardAdvertisementList.DASHBOARD) {
      let dashboardAdvertisementList = this.props.dashboardAdvertisementList
        .DASHBOARD;
      dashboardAdvertisementList.map(function(data) {
        dashboardAdvertisementOptions.push({ id: data.id, value: data.label });
        dashboardAdvertisementIdValues[data.id] = data.label;
      });
    }

    const selectType = [
      { id: "", value: "All" },
      { id: "web", value: "Web" },
      { id: "mobile", value: "Mobile" }
    ];
    return (
      <>
        <section className="container-fluid tab-content-wrapper">
          <div className="row">
            <div className="col-sm-12">
              <div>
                <div className="container">
                  <div className="padder-main">
                    <div className="row">
                      <div className="col-sm-12 mt-6">
                        <section className="tab-content">
                          <div className="row ">
                            <div className="col-sm-12 d-flex justify-content-end mt-5 mb-3 text-right">
                              <a
                                href="/create-web"
                                className="btn btn-outline-danger social-login align-items-center justify-content-center  add-adv-admin semi-bold"
                              >
                                Add
                              </a>
                            </div>
                            <div className="col-sm-12 table-responsive admin-table">
                              <table className="table table-bordered">
                                <thead>
                                  <tr>
                                    <th scope="col" width="23%">
                                      Advertisement
                                    </th>
                                    <th
                                      scope="col"
                                      width="15%"
                                      className={
                                        "text-center sortable " +
                                        (this.props.sortDashboardAds.field ===
                                        "ownerBrand"
                                          ? this.props.sortDashboardAds.orderBy
                                          : "")
                                      }
                                      onClick={() => {
                                        this.props.setSortByDashboardListViewAll(
                                          "ownerBrand"
                                        );
                                        setTimeout(() => {
                                          this.searchByBrand();
                                        }, 100);
                                      }}
                                    >
                                      Owner
                                    </th>
                                    <th>Platform</th>
                                    <th
                                      scope="col"
                                      width="10%"
                                      className={
                                        "text-center sortable " +
                                        (this.props.sortDashboardAds.field ===
                                        "startAt"
                                          ? this.props.sortDashboardAds.orderBy
                                          : "")
                                      }
                                      onClick={() => {
                                        this.props.setSortByDashboardListViewAll(
                                          "startAt"
                                        );
                                        setTimeout(() => {
                                          this.searchByBrand();
                                        }, 100);
                                      }}
                                    >
                                      Starting @
                                    </th>
                                    <th
                                      scope="col"
                                      width="10%"
                                      className={
                                        "text-center sortable " +
                                        (this.props.sortDashboardAds.field ===
                                        "endAt"
                                          ? this.props.sortDashboardAds.orderBy
                                          : "")
                                      }
                                      onClick={() => {
                                        this.props.setSortByDashboardListViewAll(
                                          "endAt"
                                        );
                                        setTimeout(() => {
                                          this.searchByBrand();
                                        }, 100);
                                      }}
                                    >
                                      Ending @
                                    </th>
                                    <th
                                      scope="col"
                                      width="17%"
                                      className="text-center"
                                    >
                                      Type
                                    </th>
                                    <th scope="col" className="text-center">
                                      Manage
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr>
                                    <td>
                                      <div className="form-group with-lable">
                                        <div className="input-group-wrapper">
                                          <div className="select">
                                            <Select2ComponentId
                                              selectClass={
                                                "select-2-input form-control effect-16"
                                              }
                                              onChange={event => {
                                                this.props.setSearchByDashboardListViewAll(
                                                  "searchByPlace",
                                                  event.id
                                                );
                                                setTimeout(() => {
                                                  this.searchByBrand();
                                                }, 100);
                                              }}
                                              selectOptions={
                                                dashboardAdvertisementOptions
                                              }
                                              labelText="Search by advertisement"
                                            />
                                          </div>
                                          <span className="focus-border" />
                                        </div>
                                      </div>
                                    </td>
                                    <td>
                                      <div className="form-group with-lable">
                                        <div className="input-group-wrapper">
                                          <input
                                            type="text"
                                            placeholder="Search by owner"
                                            className="form-control effect-16"
                                            tabIndex="1"
                                            onChange={e => {
                                              this.props.setSearchByDashboardListViewAll(
                                                "brandName",
                                                e.target.value
                                              );
                                              setTimeout(() => {
                                                this.searchByBrand();
                                              }, 100);
                                            }}
                                          />
                                          <span className="focus-border" />
                                        </div>
                                      </div>
                                    </td>
                                    <td />
                                    <td />
                                    <td />
                                    <td>
                                      <div className="form-group with-lable">
                                        <div className="input-group-wrapper">
                                          <div className="select">
                                            <Select2ComponentId
                                              selectClass={
                                                "select-2-input form-control effect-16"
                                              }
                                              onChange={event => {
                                                this.props.setSearchByDashboardListViewAll(
                                                  "type",
                                                  event.id
                                                );
                                                setTimeout(() => {
                                                  this.searchByBrand();
                                                }, 100);
                                              }}
                                              selectOptions={selectType}
                                              labelText="Search by type"
                                            />
                                          </div>{" "}
                                          <span className="focus-border" />
                                        </div>
                                      </div>
                                    </td>
                                    <td />
                                  </tr>
                                  {dashboardlist.length > 0 ? (
                                    dashboardlist.map((dashboard, i) => (
                                      <tr key={dashboard.id}>
                                        <th scope="row">
                                          {dashboard.dashboardAdvertisementPlace
                                            ? dashboard
                                                .dashboardAdvertisementPlace
                                                .label
                                            : ""}
                                        </th>
                                        <td> {dashboard.ownerBrand.name}</td>
                                        <td>
                                          <span
                                            className={
                                              "badge badge-pill " +
                                              (dashboard.ownerBrand.env.name ==
                                              "arcadex"
                                                ? "platform-arcadex"
                                                : "platform-nickx")
                                            }
                                          >
                                            {this.ucFirst(
                                              dashboard.ownerBrand.env.name
                                            )}
                                          </span>
                                        </td>
                                        <td className="text-center">
                                          {this.getFormattedDate(
                                            new Date(dashboard.startAt)
                                          )}
                                        </td>
                                        <td className="text-center">
                                          {this.getFormattedDate(
                                            new Date(dashboard.endAt)
                                          )}
                                        </td>
                                        <td className="text-center">
                                          <span
                                            className={
                                              "badge badge-pill " +
                                              (dashboard
                                                .dashboardAdvertisementPlace
                                                .type == "web"
                                                ? "badge-secondary"
                                                : "badge-danger")
                                            }
                                          >
                                            {this.ucFirst(
                                              dashboard
                                                .dashboardAdvertisementPlace
                                                .type
                                            )}
                                          </span>
                                        </td>
                                        <td className="text-center">
                                          <a
                                            href={"edit-web/" + dashboard.id}
                                            className="text-secondary semi-bold"
                                          >
                                            Edit
                                          </a>
                                          &nbsp; | &nbsp;
                                          <a
                                            href={
                                              "dashboard/preview/?type=" +
                                              dashboard
                                                .dashboardAdvertisementPlace
                                                .type +
                                              "&advertisementPlace=" +
                                              dashboard
                                                .dashboardAdvertisementPlace
                                                .id +
                                              "&advertisementUrl=" +
                                              dashboard.imageUrl
                                            }
                                            className="text-secondary semi-bold"
                                            target="_blank"
                                          >
                                            Preview
                                          </a>
                                          &nbsp; | &nbsp;
                                          {dashboard.isPublished == false ? (
                                            <a
                                              href={"javascript:void(0)"}
                                              className="text-secondary semi-bold"
                                              onClick={() =>
                                                this.publishAd(dashboard.id)
                                              }
                                            >
                                              Publish
                                            </a>
                                          ) : (
                                            <a
                                              href={"javascript:void(0)"}
                                              className="text-secondary semi-bold"
                                              onClick={() =>
                                                this.revokeAd(dashboard.id)
                                              }
                                            >
                                              Revoke
                                            </a>
                                          )}
                                        </td>
                                      </tr>
                                    ))
                                  ) : (
                                    <tr>
                                      <td className="text-center" colSpan="7">
                                        No records found
                                      </td>
                                    </tr>
                                  )}
                                </tbody>
                              </table>

                              {dashboardlist.length != 0 ? (
                                <div className="">
                                  <PaginationComponent
                                    totalPage={dashboardTotalPage}
                                    totalCount={dashboardTotalCount}
                                    recordDisplayed={isRecordDisplayed}
                                    isLastPage={isLastPage}
                                    invalidateAllAction={
                                      this.props.invalidateDashboardListViewAll
                                    }
                                    fetchListAllAction={
                                      this.props
                                        .fetchDashboardListViewAllIfNeeded
                                    }
                                    sortBy={this.props.sortDashboardAds.field}
                                    sort={this.props.sortDashboardAds.orderBy}
                                    searchSort={
                                      this.props.searchDashboardAds.brandName
                                        ? true
                                        : false
                                    }
                                    resetSearchPage={[
                                      this.props.searchDashboardAds.brandName
                                        ? this.props.searchDashboardAds
                                            .brandName
                                        : ""
                                    ]}
                                    param={{
                                      page: 1,
                                      sortBy: this.props.sortDashboardAds.field
                                        ? this.props.sortDashboardAds.field
                                        : "",
                                      sortOrder: this.props.sortDashboardAds
                                        .orderBy
                                        ? this.props.sortDashboardAds.orderBy.toUpperCase()
                                        : "",
                                      searchText: this.props.searchDashboardAds
                                        .brandName
                                        ? this.props.searchDashboardAds
                                            .brandName
                                        : "",
                                      searchByPlace: this.props
                                        .searchDashboardAds.searchByPlace
                                        ? this.props.searchDashboardAds
                                            .searchByPlace
                                        : "",
                                      type: this.props.searchDashboardAds.type
                                        ? this.props.searchDashboardAds.type
                                        : ""
                                    }}
                                  />
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default DashboardComponent;
