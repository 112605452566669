import React, { Component } from "react";
import PaginationComponent from "../Pagination/Pagination";

class RewardsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adsPlacements: false
    };
    this.getRaffleWinnerList = this.getRaffleWinnerList.bind(this);
    this.getChallangeWinnerList = this.getChallangeWinnerList.bind(this);
    this.changeAdsPlace = this.changeAdsPlace.bind(this);
    this.getEnvName = this.getEnvName.bind(this);
  }
  componentDidMount() {
    this.props.resetSearchAndSortRafflesWinners();
    this.props.resetSearchAndSortchallengesWinners();
    this.props.invalidateRafflesWinner();
    this.props.fetchRafflesWinnerFetchData({
      page: 1,
      languageId: this.props.defaultLang,
      sortBy: "",
      sortOrder: "",
      searchByRaffle: "",
      searchByWinner: "",
      searchByNumber: "",
      searchByName: "",
      searchByOpe: "",
      searchByCoun: ""
    });

    this.props.invalidateChallengesWinner();
    this.props.fetchChallengesWinnerFetchData({
      page: 1,
      sortBy: "",
      sortOrder: "",
      searchByChallenge: "",
      searchByChallengeName: "",
      searchByRank: "",
      searchByWinner: "",
      searchByName: "",
      searchByNumber: "",
      searchByOpe: "",
      searchByCoun: ""
    });
  }
  getRaffleWinnerList() {
    const {
      rafflesWinnersSort,
      rafflesWinnersSearch,
      invalidateRafflesWinner,
      fetchRafflesWinnerFetchData
    } = this.props;
    invalidateRafflesWinner();
    fetchRafflesWinnerFetchData({
      page: 1,
      languageId: this.props.defaultLang,
      sortBy: rafflesWinnersSort.field,
      sortOrder: rafflesWinnersSort.orderBy,
      searchByRaffle: rafflesWinnersSearch.id ? rafflesWinnersSearch.id : "",
      searchByWinner: rafflesWinnersSearch.winnerId ? rafflesWinnersSearch.winnerId : "",
      searchByNumber: rafflesWinnersSearch.winnerNumber ? rafflesWinnersSearch.winnerNumber : "",
      searchByName: rafflesWinnersSearch.winnerName ? rafflesWinnersSearch.winnerName : "",
      searchByCoun: rafflesWinnersSearch.winnerCountry ? rafflesWinnersSearch.winnerCountry : "",
      searchByOpe: rafflesWinnersSearch.winnerOperator ? rafflesWinnersSearch.winnerOperator : ""
    });
  }
  getChallangeWinnerList() {
    const {
      challengesWinnersSort,
      challengesWinnersSearch,
      invalidateChallengesWinner,
      fetchChallengesWinnerFetchData
    } = this.props;
    invalidateChallengesWinner();
    fetchChallengesWinnerFetchData({
      page: 1,
      sortBy: challengesWinnersSort.field,
      sortOrder: challengesWinnersSort.orderBy,
      searchByChallenge: challengesWinnersSearch.challengeId ? challengesWinnersSearch.challengeId : "",
      searchByChallengeName: challengesWinnersSearch.challengeName ? challengesWinnersSearch.challengeName : "",
      searchByRank: challengesWinnersSearch.rank ? challengesWinnersSearch.rank : "",
      searchByWinner: challengesWinnersSearch.winnerId ? challengesWinnersSearch.winnerId : "",
      searchByNumber: challengesWinnersSearch.winnerNumber ? challengesWinnersSearch.winnerNumber : "",
      searchByName: challengesWinnersSearch.winnerName ? challengesWinnersSearch.winnerName : "",
      searchByCoun: challengesWinnersSearch.winnerCountry ? challengesWinnersSearch.winnerCountry : "",
      searchByOpe: challengesWinnersSearch.winnerOperator ? challengesWinnersSearch.winnerOperator : "",
    });
  }
  changeAdsPlace() {
    this.setState({
      adsPlacements: !this.state.adsPlacements
    });
    if (this.state.adsPlacements) {
      this.props.invalidateChallengesWinner();
      this.props.fetchChallengesWinnerFetchData({
        page: 1,
        sortBy: "",
        sortOrder: "",
        searchByChallenge: "",
        searchByChallengeName: "",
        searchByRank: "",
        searchByWinner: "",
        searchByName: "",
        searchByNumber: "",
        searchByOpe: "",
        searchByCoun: ""
      });
      this.props.resetSearchAndSortchallengesWinners();
    } else {
      this.props.invalidateRafflesWinner();
      this.props.fetchRafflesWinnerFetchData({
        page: 1,
        languageId: this.props.defaultLang,
        sortBy: "",
        sortOrder: "",
        searchByRaffle: "",
        searchByWinner: "",
        searchByNumber: "",
        searchByName: "",
        searchByOpe: "",
        searchByCoun: ""
      });
      this.props.resetSearchAndSortRafflesWinners();
    }
  }
  getEnvName(envId){
    let env = this.props.envs.filter((item, index) => {
        return item.id == envId;
    })
    return typeof(env[0]) != "undefined" ? env[0]['name'] : '';
  }
  render() {
    const { rafflesWinnersList, rafflesWinnersTotalCount, rafflesWinnersTotalPage, israfflesWinnersLastPage, israfflesWinnersRecordDisplayed, rafflesWinnersSort, rafflesWinnersSearch,
      challengeWinnerList, challengesWinnersTotalCount, challengesWinnersTotalPage, ischallengesWinnersLastPage, ischallengesWinnersRecordDisplayed, challengesWinnersSort, challengesWinnersSearch
    } = this.props;
    
    return (
      <>
        <section id="rootwizard">
          <section className="container-fluid tab-content-wrapper">
            <div className="row">
              <div className="col-sm-12">
                <div>
                  <div className="container">
                    <div className="padder-main">
                      <div className="row">
                        <div className="col-sm-12  mt-4 mb-6">
                          <h1>Rewards</h1>
                        </div>
                      </div>

                      <section className="tab-content">
                        <div className="row ">
                          <div className="col-sm-12">
                            <ul className="nav nav-pills  mb-4 d-flex justify-content-center align-items-center w-25">
                              <li className="nav-item col text-center">
                                <span
                                  className={
                                    "a nav-link" +
                                    (this.state.adsPlacements
                                      ? ""
                                      : " active show")
                                  }
                                  onClick={this.changeAdsPlace}
                                >
                                  Tournament
                                  </span>
                              </li>
                              <li className="nav-item col text-center">
                                <span
                                  className={
                                    "a nav-link" +
                                    (!this.state.adsPlacements
                                      ? ""
                                      : " active show")
                                  }
                                  onClick={this.changeAdsPlace}
                                >
                                  Raffles
                                  </span>
                              </li>
                            </ul>

                            <>
                              <section className="tab-content">
                                <div
                                  id="webadv"
                                  className={
                                    "tab-pane fade" +
                                    (!this.state.adsPlacements
                                      ? ""
                                      : " active show")
                                  }
                                >
                                  <div className="row">
                                    <div className="col-sm-12 table-responsive admin-table">
                                      <table className="table table-bordered">
                                        <thead>
                                          <tr>
                                            <th width="15%" className="text-center">
                                              Raffle Id
                                </th>
                                            <th
                                              width="15%" className="text-center">
                                              Winner Profile Id
                                </th>
                                            <th scope="col"
                                              className={
                                                "text-center sortable " +
                                                (rafflesWinnersSort.field ===
                                                  "entryFee"
                                                  ? rafflesWinnersSort.orderBy
                                                  : "")
                                              }
                                              onClick={() => {
                                                this.props.setSortByrafflesWinners(
                                                  "entryFee"
                                                );
                                                setTimeout(() => {
                                                  this.getRaffleWinnerList();
                                                }, 100);
                                              }}>
                                              Entry Fee
                                </th>
                                            <th
                                              width="15%" className="text-center">
                                              Item Name
                                </th>
                                            <th scope="col"
                                              className={
                                                "text-center sortable " +
                                                (rafflesWinnersSort.field ===
                                                  "points"
                                                  ? rafflesWinnersSort.orderBy
                                                  : "")
                                              }
                                              onClick={() => {
                                                this.props.setSortByrafflesWinners(
                                                  "points"
                                                );
                                                setTimeout(() => {
                                                  this.getRaffleWinnerList();
                                                }, 100);
                                              }}>
                                              Points
                                </th>
                                            <th scope="col" className="text-center">
                                              Plateform
                                </th>
                                            <th scope="col"
                                              className={
                                                "text-center sortable " +
                                                (rafflesWinnersSort.field ===
                                                  "winnerName"
                                                  ? rafflesWinnersSort.orderBy
                                                  : "")
                                              }
                                              onClick={() => {
                                                this.props.setSortByrafflesWinners(
                                                  "winnerName"
                                                );
                                                setTimeout(() => {
                                                  this.getRaffleWinnerList();
                                                }, 100);
                                              }}>
                                              Username
                                </th>
                                            <th scope="col" className="text-center">
                                              Number
                                </th>
                                            <th scope="col" className="text-center">
                                              Country
                                </th>
                                            <th scope="col" className="text-center">
                                              Operator
                                </th>
                                            <th scope="col"
                                              className={
                                                "text-center sortable " +
                                                (rafflesWinnersSort.field ===
                                                  "timeOfEnd"
                                                  ? rafflesWinnersSort.orderBy
                                                  : "")
                                              }
                                              onClick={() => {
                                                this.props.setSortByrafflesWinners(
                                                  "timeOfEnd"
                                                );
                                                setTimeout(() => {
                                                  this.getRaffleWinnerList();
                                                }, 100);
                                              }}>
                                              Completed On
                                </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td className="text-center">
                                              <div className="form-group with-lable">
                                                <div className="input-group-wrapper">
                                                  <input
                                                    type="text"
                                                    placeholder="Search by Raffle Id"
                                                    className="form-control effect-16"
                                                    value={
                                                      rafflesWinnersSearch.id
                                                        ? rafflesWinnersSearch.id
                                                        : ""
                                                    }
                                                    onChange={e => {
                                                      this.props.setSearchByrafflesWinners(
                                                        "id",
                                                        e.target.value.trimLeft()
                                                      );
                                                      setTimeout(() => {
                                                        this.getRaffleWinnerList();
                                                      }, 100);
                                                    }}
                                                  />
                                                  <span className="focus-border" />
                                                </div>
                                              </div>
                                            </td>
                                            <td className="text-center">
                                              <div className="form-group with-lable">
                                                <div className="input-group-wrapper">
                                                  <input
                                                    type="text"
                                                    placeholder="Search by Winner Profile Id"
                                                    className="form-control effect-16"
                                                    value={
                                                      rafflesWinnersSearch.winnerId
                                                        ? rafflesWinnersSearch.winnerId
                                                        : ""
                                                    }
                                                    onChange={e => {
                                                      this.props.setSearchByrafflesWinners(
                                                        "winnerId",
                                                        e.target.value.trimLeft()
                                                      );
                                                      setTimeout(() => {
                                                        this.getRaffleWinnerList();
                                                      }, 100);
                                                    }}
                                                  />
                                                  <span className="focus-border" />
                                                </div>
                                              </div>
                                            </td>
                                            <td className="text-center" />
                                            <td className="text-center" />
                                            <td className="text-center" />
                                            <td className="text-center" />
                                            <td className="text-center">
                                              <div className="form-group with-lable">
                                                <div className="input-group-wrapper">
                                                  <input
                                                    type="text"
                                                    placeholder="Search by Username"
                                                    className="form-control effect-16"
                                                    value={
                                                      rafflesWinnersSearch.winnerName
                                                        ? rafflesWinnersSearch.winnerName
                                                        : ""
                                                    }
                                                    onChange={e => {
                                                      this.props.setSearchByrafflesWinners(
                                                        "winnerName",
                                                        e.target.value.trimLeft()
                                                      );
                                                      setTimeout(() => {
                                                        this.getRaffleWinnerList();
                                                      }, 100);
                                                    }}
                                                  />
                                                  <span className="focus-border" />
                                                </div>
                                              </div>
                                            </td>
                                            <td className="text-center">
                                              <div className="form-group with-lable">
                                                <div className="input-group-wrapper">
                                                  <input
                                                    type="number"
                                                    placeholder="Search by Number"
                                                    className="form-control effect-16"
                                                    value={
                                                      rafflesWinnersSearch.winnerNumber
                                                        ? rafflesWinnersSearch.winnerNumber
                                                        : ""
                                                    }
                                                    onChange={e => {
                                                      this.props.setSearchByrafflesWinners(
                                                        "winnerNumber",
                                                        e.target.value.trimLeft()
                                                      );
                                                      setTimeout(() => {
                                                        this.getRaffleWinnerList();
                                                      }, 100);
                                                    }}
                                                  />
                                                  <span className="focus-border" />
                                                </div>
                                              </div>
                                            </td>
                                            <td className="text-center">
                                              <div className="form-group with-lable">
                                                <div className="input-group-wrapper">
                                                  <input
                                                    type="text"
                                                    placeholder="Search by Country"
                                                    className="form-control effect-16"
                                                    value={
                                                      rafflesWinnersSearch.winnerCountry
                                                        ? rafflesWinnersSearch.winnerCountry
                                                        : ""
                                                    }
                                                    onChange={e => {
                                                      this.props.setSearchByrafflesWinners(
                                                        "winnerCountry",
                                                        e.target.value.trimLeft()
                                                      );
                                                      setTimeout(() => {
                                                        this.getRaffleWinnerList();
                                                      }, 100);
                                                    }}
                                                  />
                                                  <span className="focus-border" />
                                                </div>
                                              </div>
                                            </td>
                                            <td className="text-center">
                                              <div className="form-group with-lable">
                                                <div className="input-group-wrapper">
                                                  <input
                                                    type="text"
                                                    placeholder="Search by Operator"
                                                    className="form-control effect-16"
                                                    value={
                                                      rafflesWinnersSearch.winnerOperator
                                                        ? rafflesWinnersSearch.winnerOperator
                                                        : ""
                                                    }
                                                    onChange={e => {
                                                      this.props.setSearchByrafflesWinners(
                                                        "winnerOperator",
                                                        e.target.value.trimLeft()
                                                      );
                                                      setTimeout(() => {
                                                        this.getRaffleWinnerList();
                                                      }, 100);
                                                    }}
                                                  />
                                                  <span className="focus-border" />
                                                </div>
                                              </div>
                                            </td>
                                            <td className="text-center" />
                                          </tr>
                                          {rafflesWinnersList && rafflesWinnersList.items.length > 0 ? (
                                            rafflesWinnersList.items.map(
                                              (item, index) => {
                                                return (
                                                  <tr key={index}>
                                                    <td className="text-center">
                                                      {item.id}
                                                    </td>
                                                    <td className="text-center">
                                                      {item.winnerId}
                                                    </td>
                                                    <td className="text-center">
                                                      {item.entryFee ? item.entryFee : '-'}
                                                    </td>
                                                    <td className="text-center">
                                                      {item.itemDescription ? item.itemDescription : '-'}
                                                    </td>
                                                    <td className="text-center">
                                                      {item.points ? item.points : '-'}
                                                    </td>
                                                    <td className="text-center">
                                                      { this.getEnvName(item.env_id) }
                                                    </td>
                                                    <td className="text-center">
                                                      {item.winnerName ? item.winnerName : '-'}
                                                    </td>
                                                    <td className="text-center">
                                                      {item.winnerNumber ? item.winnerNumber : '-'}
                                                    </td>
                                                    <td className="text-center">
                                                      {item.winnerCountry ? item.winnerCountry : '-'}
                                                    </td>
                                                    <td className="text-center">
                                                      {item.winnerOperator ? item.winnerOperator : '-'}
                                                    </td>
                                                    <td className="text-center">
                                                      {item.timeOfEnd ? item.timeOfEnd : '-'}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )
                                          ) : (
                                              <tr className="text-center">
                                                <td colSpan="11">No Records Found</td>
                                              </tr>
                                            )}
                                        </tbody>
                                      </table>

                                      {rafflesWinnersList.items.length > 0 ? (
                                        <div className="">
                                          <PaginationComponent
                                            totalPage={rafflesWinnersTotalPage}
                                            totalCount={rafflesWinnersTotalCount}
                                            recordDisplayed={
                                              israfflesWinnersRecordDisplayed
                                            }
                                            isLastPage={israfflesWinnersLastPage}
                                            invalidateAllAction={this.props.invalidateRafflesWinner}
                                            fetchListAllAction={this.props.fetchRafflesWinnerFetchData}
                                            sortBy={rafflesWinnersSort.field}
                                            sort={rafflesWinnersSort.orderBy}
                                            resetSearchPage={[
                                              rafflesWinnersSearch.id
                                                ? rafflesWinnersSearch.id
                                                : "",
                                              rafflesWinnersSearch.winnerId
                                                ? rafflesWinnersSearch.winnerId
                                                : "",
                                              rafflesWinnersSearch.winnerName
                                                ? rafflesWinnersSearch.winnerName
                                                : ""
                                            ]}
                                            searchSort={
                                              rafflesWinnersSearch.id ||
                                                rafflesWinnersSearch.winnerId ||
                                                rafflesWinnersSearch.winnerName
                                                ? true
                                                : false
                                            }
                                            param={{
                                              languageId: this.props.defaultLang,
                                              sortBy: rafflesWinnersSort.field,
                                              sortOrder: rafflesWinnersSort.orderBy,
                                              searchByRaffle: rafflesWinnersSearch.id ? rafflesWinnersSearch.id : "",
                                              searchByWinner: rafflesWinnersSearch.winnerId ? rafflesWinnersSearch.winnerId : "",
                                              searchByNumber: rafflesWinnersSearch.winnerNumber ? rafflesWinnersSearch.winnerNumber : "",
                                              searchByName: rafflesWinnersSearch.winnerName ? rafflesWinnersSearch.winnerName : "",
                                              searchByCoun: rafflesWinnersSearch.winnerCountry ? rafflesWinnersSearch.winnerCountry : "",
                                              searchByOpe: rafflesWinnersSearch.winnerOperator ? rafflesWinnersSearch.winnerOperator : ""
                                            }}
                                          />
                                        </div>
                                      ) : (
                                          ""
                                        )}
                                    </div>
                                  </div>
                                </div>

                                <div
                                  id="webadv"
                                  className={
                                    "tab-pane fade" +
                                    (this.state.adsPlacements
                                      ? ""
                                      : " active show")
                                  }
                                >
                                  <div className="row">
                                    <div className="col-sm-12 table-responsive admin-table">
                                      <table className="table table-bordered">
                                        <thead>
                                          <tr>
                                            <th width="15%" className="text-center">
                                              Challenge Id
                                </th>
                                            <th
                                              width="15%" className="text-center">
                                              Challenge Name
                                </th>
                                            <th scope="col"
                                              className={
                                                "text-center sortable " +
                                                (challengesWinnersSort.field ===
                                                  "rank"
                                                  ? challengesWinnersSort.orderBy
                                                  : "")
                                              }
                                              onClick={() => {
                                                this.props.setSortBychallengesWinners(
                                                  "rank"
                                                );
                                                setTimeout(() => {
                                                  this.getChallangeWinnerList();
                                                }, 100);
                                              }}>
                                              Place
                                </th>
                                            <th
                                              width="15%" className="text-center">
                                              Item Name
                                </th>
                                            <th
                                              width="15%" className="text-center">
                                              Points
                                </th>
                                            <th
                                              width="15%" className="text-center">
                                              Plateform
                                </th>
                                            <th scope="col"
                                              className={
                                                "text-center sortable " +
                                                (challengesWinnersSort.field ===
                                                  "winnerId"
                                                  ? challengesWinnersSort.orderBy
                                                  : "")
                                              }
                                              onClick={() => {
                                                this.props.setSortBychallengesWinners(
                                                  "winnerId"
                                                );
                                                setTimeout(() => {
                                                  this.getChallangeWinnerList();
                                                }, 100);
                                              }}>
                                              Winner Profile Id
                                </th>
                                            <th scope="col"
                                              className={
                                                "text-center sortable " +
                                                (challengesWinnersSort.field ===
                                                  "winnerUsername"
                                                  ? challengesWinnersSort.orderBy
                                                  : "")
                                              }
                                              onClick={() => {
                                                this.props.setSortBychallengesWinners(
                                                  "winnerUsername"
                                                );
                                                setTimeout(() => {
                                                  this.getChallangeWinnerList();
                                                }, 100);
                                              }}>
                                              Username
                                </th>
                                            <th scope="col" className="text-center">
                                              Number
                                </th>
                                            <th scope="col" className="text-center">
                                              Country
                                </th>
                                            <th scope="col" className="text-center">
                                              Operator
                                </th>
                                            <th scope="col"
                                              className={
                                                "text-center sortable " +
                                                (challengesWinnersSort.field ===
                                                  "timeOfEnd"
                                                  ? challengesWinnersSort.orderBy
                                                  : "")
                                              }
                                              onClick={() => {
                                                this.props.setSortBychallengesWinners(
                                                  "timeOfEnd"
                                                );
                                                setTimeout(() => {
                                                  this.getChallangeWinnerList();
                                                }, 100);
                                              }}>
                                              Completed On
                                </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          <tr>
                                            <td className="text-center">
                                              <div className="form-group with-lable">
                                                <div className="input-group-wrapper">
                                                  <input
                                                    type="text"
                                                    placeholder="Search by Challenge Id"
                                                    className="form-control effect-16"
                                                    value={
                                                      challengesWinnersSearch.challengeId
                                                        ? challengesWinnersSearch.challengeId
                                                        : ""
                                                    }
                                                    onChange={e => {
                                                      this.props.setSearchBychallengesWinners(
                                                        "challengeId",
                                                        e.target.value.trimLeft()
                                                      );
                                                      setTimeout(() => {
                                                        this.getChallangeWinnerList();
                                                      }, 100);
                                                    }}
                                                  />
                                                  <span className="focus-border" />
                                                </div>
                                              </div>
                                            </td>
                                            <td className="text-center">

                                              <div className="form-group with-lable">
                                                <div className="input-group-wrapper">
                                                  <input
                                                    type="text"
                                                    placeholder="Search by Challenge Name"
                                                    className="form-control effect-16"
                                                    value={
                                                      challengesWinnersSearch.challengeName
                                                        ? challengesWinnersSearch.challengeName
                                                        : ""
                                                    }
                                                    onChange={e => {
                                                      this.props.setSearchBychallengesWinners(
                                                        "challengeName",
                                                        e.target.value.trimLeft()
                                                      );
                                                      setTimeout(() => {
                                                        this.getChallangeWinnerList();
                                                      }, 100);
                                                    }}
                                                  />
                                                  <span className="focus-border" />
                                                </div>
                                              </div>
                                            </td>
                                            <td className="text-center">
                                              <div className="form-group with-lable">
                                                <div className="input-group-wrapper">
                                                  <input
                                                    type="text"
                                                    placeholder="Search by Place"
                                                    className="form-control effect-16"
                                                    value={
                                                      challengesWinnersSearch.rank
                                                        ? challengesWinnersSearch.rank
                                                        : ""
                                                    }
                                                    onChange={e => {
                                                      this.props.setSearchBychallengesWinners(
                                                        "rank",
                                                        e.target.value.trimLeft()
                                                      );
                                                      setTimeout(() => {
                                                        this.getChallangeWinnerList();
                                                      }, 100);
                                                    }}
                                                  />
                                                  <span className="focus-border" />
                                                </div>
                                              </div>
                                            </td>
                                            <td className="text-center">
                                            </td>

                                            <td className="text-center" />
                                            <td className="text-center" />
                                            <td className="text-center">
                                              <div className="form-group with-lable">
                                                <div className="input-group-wrapper">
                                                  <input
                                                    type="text"
                                                    placeholder="Search by Profile Id"
                                                    className="form-control effect-16"
                                                    value={
                                                      challengesWinnersSearch.winnerId
                                                        ? challengesWinnersSearch.winnerId
                                                        : ""
                                                    }
                                                    onChange={e => {
                                                      this.props.setSearchBychallengesWinners(
                                                        "winnerId",
                                                        e.target.value.trimLeft()
                                                      );
                                                      setTimeout(() => {
                                                        this.getChallangeWinnerList();
                                                      }, 100);
                                                    }}
                                                  />
                                                  <span className="focus-border" />
                                                </div>
                                              </div>
                                            </td>
                                            <td className="text-center">
                                              <div className="form-group with-lable">
                                                <div className="input-group-wrapper">
                                                  <input
                                                    type="text"
                                                    placeholder="Search by Username"
                                                    className="form-control effect-16"
                                                    value={
                                                      challengesWinnersSearch.winnerName
                                                        ? challengesWinnersSearch.winnerName
                                                        : ""
                                                    }
                                                    onChange={e => {
                                                      this.props.setSearchBychallengesWinners(
                                                        "winnerName",
                                                        e.target.value.trimLeft()
                                                      );
                                                      setTimeout(() => {
                                                        this.getChallangeWinnerList();
                                                      }, 100);
                                                    }}
                                                  />
                                                  <span className="focus-border" />
                                                </div>
                                              </div>
                                            </td>
                                            <td className="text-center">
                                              <div className="form-group with-lable">
                                                <div className="input-group-wrapper">
                                                  <input
                                                    type="number"
                                                    placeholder="Search by Number"
                                                    className="form-control effect-16"
                                                    value={
                                                      challengesWinnersSearch.winnerNumber
                                                        ? challengesWinnersSearch.winnerNumber
                                                        : ""
                                                    }
                                                    onChange={e => {
                                                      this.props.setSearchBychallengesWinners(
                                                        "winnerNumber",
                                                        e.target.value.trimLeft()
                                                      );
                                                      setTimeout(() => {
                                                        this.getChallangeWinnerList();
                                                      }, 100);
                                                    }}
                                                  />
                                                  <span className="focus-border" />
                                                </div>
                                              </div>
                                            </td>
                                            <td className="text-center">
                                              <div className="form-group with-lable">
                                                <div className="input-group-wrapper">
                                                  <input
                                                    type="text"
                                                    placeholder="Search by Country"
                                                    className="form-control effect-16"
                                                    value={
                                                      challengesWinnersSearch.winnerCountry
                                                        ? challengesWinnersSearch.winnerCountry
                                                        : ""
                                                    }
                                                    onChange={e => {
                                                      this.props.setSearchBychallengesWinners(
                                                        "winnerCountry",
                                                        e.target.value.trimLeft()
                                                      );
                                                      setTimeout(() => {
                                                        this.getChallangeWinnerList();
                                                      }, 100);
                                                    }}
                                                  />
                                                  <span className="focus-border" />
                                                </div>
                                              </div>
                                            </td>
                                            <td className="text-center">
                                              <div className="form-group with-lable">
                                                <div className="input-group-wrapper">
                                                  <input
                                                    type="text"
                                                    placeholder="Search by Operator"
                                                    className="form-control effect-16"
                                                    value={
                                                      challengesWinnersSearch.winnerOperator
                                                        ? challengesWinnersSearch.winnerOperator
                                                        : ""
                                                    }
                                                    onChange={e => {
                                                      this.props.setSearchBychallengesWinners(
                                                        "winnerOperator",
                                                        e.target.value.trimLeft()
                                                      );
                                                      setTimeout(() => {
                                                        this.getChallangeWinnerList();
                                                      }, 100);
                                                    }}
                                                  />
                                                  <span className="focus-border" />
                                                </div>
                                              </div>
                                            </td>
                                            <td className="text-center" />
                                          </tr>
                                          {challengeWinnerList && challengeWinnerList.items.length > 0 ? (
                                            challengeWinnerList.items.map(
                                              (item, index) => {
                                                return (
                                                  <tr key={index}>
                                                    <td className="text-center">
                                                      {item.challengeId}
                                                    </td>
                                                    <td className="text-center">
                                                      {item.challangeName ? item.challangeName : "-"}
                                                    </td>
                                                    <td className="text-center">
                                                      {item.rank ? item.rank : '-'}
                                                    </td>
                                                    <td className="text-center">
                                                      {item.itemDescription ? item.itemDescription : '-'}
                                                    </td>
                                                    <td className="text-center">
                                                      {item.points ? item.points : '-'}
                                                    </td>
                                                    <td className="text-center">
                                                    { this.getEnvName(item.platform) }
                                                    </td>
                                                    <td className="text-center">
                                                      {item.winnerId}
                                                    </td>
                                                    <td className="text-center">
                                                      {item.winnerUsername ? item.winnerUsername : '-'}
                                                    </td>
                                                    <td className="text-center">
                                                      {item.winnerNumber ? item.winnerNumber : '-'}
                                                    </td>
                                                    <td className="text-center">
                                                      {item.winnerCountry ? item.winnerCountry : '-'}
                                                    </td>
                                                    <td className="text-center">
                                                      {item.winnerOperator ? item.winnerOperator : '-'}
                                                    </td>
                                                    <td className="text-center">
                                                      {item.timeOfEnd ? item.timeOfEnd : '-'}
                                                    </td>
                                                  </tr>
                                                );
                                              }
                                            )
                                          ) : (
                                              <tr className="text-center">
                                                <td colSpan="12">No Records Found</td>
                                              </tr>
                                            )}
                                        </tbody>
                                      </table>

                                      {challengeWinnerList.items.length > 0 ? (
                                        <div className="">
                                          <PaginationComponent
                                            totalPage={challengesWinnersTotalPage}
                                            totalCount={challengesWinnersTotalCount}
                                            recordDisplayed={
                                              ischallengesWinnersRecordDisplayed
                                            }
                                            isLastPage={ischallengesWinnersLastPage}
                                            invalidateAllAction={this.props.invalidateChallengesWinner}
                                            fetchListAllAction={this.props.fetchChallengesWinnerFetchData}
                                            sortBy={challengesWinnersSort.field}
                                            sort={challengesWinnersSort.orderBy}
                                            resetSearchPage={[
                                              challengesWinnersSearch.challengeId
                                                ? challengesWinnersSearch.challengeId
                                                : "",
                                              challengesWinnersSearch.challengeName
                                                ? challengesWinnersSearch.challengeName
                                                : "",
                                              challengesWinnersSearch.rank
                                                ? challengesWinnersSearch.rank
                                                : "",
                                              challengesWinnersSearch.winnerId
                                                ? challengesWinnersSearch.winnerId
                                                : "",
                                              challengesWinnersSearch.winnerName
                                                ? challengesWinnersSearch.winnerName
                                                : "",
                                              challengesWinnersSearch.winnerNumber
                                                ? challengesWinnersSearch.winnerNumber
                                                : "",
                                              challengesWinnersSearch.winnerCountry
                                                ? challengesWinnersSearch.winnerCountry
                                                : "",
                                              challengesWinnersSearch.winnerOperator
                                                ? challengesWinnersSearch.winnerOperator
                                                : ""
                                            ]}
                                            searchSort={
                                              challengesWinnersSearch.challengeId ||
                                                challengesWinnersSearch.challengeName ||
                                                challengesWinnersSearch.rank ||
                                                challengesWinnersSearch.winnerId ||
                                                challengesWinnersSearch.winnerName ||
                                                challengesWinnersSearch.winnerNumber ||
                                                challengesWinnersSearch.winnerCountry ||
                                                challengesWinnersSearch.winnerOperator
                                                ? true
                                                : false
                                            }
                                            param={{
                                              sortBy: challengesWinnersSort.field,
                                              sortOrder: challengesWinnersSort.orderBy,
                                              searchByChallenge: challengesWinnersSearch.challengeId ? challengesWinnersSearch.challengeId : "",
                                              searchByChallengeName: challengesWinnersSearch.challengeName ? challengesWinnersSearch.challengeName : "",
                                              searchByRank: challengesWinnersSearch.rank ? challengesWinnersSearch.rank : "",
                                              searchByWinner: challengesWinnersSearch.winnerId ? challengesWinnersSearch.winnerId : "",
                                              searchByNumber: challengesWinnersSearch.winnerNumber ? challengesWinnersSearch.winnerNumber : "",
                                              searchByName: challengesWinnersSearch.winnerName ? challengesWinnersSearch.winnerName : "",
                                              searchByCoun: challengesWinnersSearch.winnerCountry ? challengesWinnersSearch.winnerCountry : "",
                                              searchByOpe: challengesWinnersSearch.winnerOperator ? challengesWinnersSearch.winnerOperator : ""
                                            }}
                                          />
                                        </div>
                                      ) : (
                                          ""
                                        )}
                                    </div>
                                  </div>
                                </div>



                              </section>
                            </>

                          </div>
                        </div>
                      </section>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </section>
      </>
    );
  }
}
export default RewardsComponent;
