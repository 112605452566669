import { axios } from "../utils/axios";
import config from "./config";
import { API_SERVER_URL } from "./config/apiEndpoints";

export const getDashboardList = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.getDashboard,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const createDashboardAdvertisementService = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.createDashboardAdvertisement,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const editDashboardAdvertisementService = postParams => {
  const editDashAdUrl = config.editDashboardAdvertisement.replace("##id##", postParams.id ? postParams.id : 0);
  delete postParams.id;    
  return axios
    .request({      
      url: API_SERVER_URL + editDashAdUrl,
      method: "post",
      headers: {
        "content-type": "application/json"
      },
      data: postParams
    })
    .catch(err => {
      console.log(err);
    });
};

export const getDashboardAdvertisementDetail = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.dashboardAdvertisementDetail.replace("##id##", postParams ? postParams : 0),
      method: "get",
      headers: {
        "content-type": "application/json"
      },
    })
    .catch(err => {
      console.log(err);
    });
};

export const getDashboardDisabledAdvertisements = postParams => {  
  return axios
    .request({
      url: API_SERVER_URL + config.dashboardDisabledAdvertisements.replace("##id##", postParams ? postParams.placeId : 0),
      method: "get",
      headers: {
        "content-type": "application/json"
      },
    })
    .catch(err => {
      console.log(err);
    });
};

export const getDashboardAdvertisementPublishService = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.dashboardAdPublish.replace("##id##", postParams ? postParams : 0),
      method: "get",
      headers: {
        "content-type": "application/json"
      },
    })
    .catch(err => {
      console.log(err);
    });
};

export const getDashboardAdvertisementRevokeService = postParams => {
  return axios
    .request({
      url: API_SERVER_URL + config.dashboardAdRevoke.replace("##id##", postParams ? postParams : 0),
      method: "get",
      headers: {
        "content-type": "application/json"
      },
    })
    .catch(err => {
      console.log(err);
    });
};