import { incrementFetch, decrementFetch } from "../../loaders";

export const invalidate = (params, prefix) => ({
  type: prefix + "_INVALIDATE_" + params.code
});
export const request = (params, prefix) => ({
  type: prefix + "_REQUEST_" + params.code
});
export const receive = (params, data, prefix) => ({
  type: prefix + "_RECEIVE_" + params.code,
  data: data
});
export const receiveForTable = (
  params,
  data,
  isLastPage = false,
  totalPage = 0,
  totalCount = 0,
  recordDisplayed = 0,
  prefix
) => ({
  type: prefix + "_RECEIVE_" + params.code,
  data: data,
  isLastPage: isLastPage,
  totalPage: totalPage,
  totalCount: totalCount,
  recordDisplayed: recordDisplayed,
});
export const receiveError = (params, prefix) => ({
  type: prefix + "_RECEIVE_ERROR_" + params.code
});

const setSortBy = (params, prefix, field) => dispatch => {

  return dispatch({
    type: prefix + "_SET_SORT_" + params.code + "/" + field,
    field
  });
};
const setSearchBy = (params, prefix, field, value) => dispatch => {
  return dispatch({
    type: prefix + "_SET_SEARCH_" + params.code + "/" + field,
    field,
    value
  });
};
const resetSearchAndSort = (params, prefix) => dispatch => {
  return dispatch({
    type: prefix + '_' + params.code + "_SEARCH_AND_SORT_RESET"
  });
};

export const shouldFetch = (state, params) => {
  const data = state[params.stateKey][params.stateSubKey];
  if (!data) {
    return true;
  }
  if (data.isFetching) {
    return false;
  }
  return data.didInvalidate;
};

export const fetchData = (params, prefix, ...extraArgs) => {
  return (dispatch, getState) => {
    return params
      .service(...extraArgs, dispatch, getState)
      .then(json => {
        dispatch(receive(params, params.dataMapper(json), prefix));
        dispatch(decrementFetch());
        if (params.onResponse) {
          params.onResponse(json, dispatch, getState);
        }
      })
      .catch(response => {
        dispatch(receiveError(params, prefix));
        dispatch(decrementFetch());
        if (params.onError) {
          params.onError(response, dispatch, getState);
        }
      });
  };
};
export const fetchDataForTable = (params, prefix, ...extraArgs) => {
  return (dispatch, getState) => {
    return params
      .service(...extraArgs, dispatch, getState)
      .then(json => {
        dispatch(receiveForTable(
          params,
          params.dataMapper(json),
          params.lastPageMapper ? params.lastPageMapper(json) : false,
          params.totalPageMapper ? params.totalPageMapper(json) : 0,
          params.totalCountMapper ? params.totalCountMapper(json) : 0,
          params.recordDisplayedMapper ? params.recordDisplayedMapper(json) : 0,
          prefix
        ));
        if (params.onResponse) {
          params.onResponse(json, dispatch, getState);
        }
      })
      .catch(response => {
        dispatch(receiveError(params, prefix));
        dispatch(decrementFetch());
        if (params.onError) {
          params.onError(response, dispatch, getState);
        }
      });
  };
};
export const ActionCreator = prefix => params => ({
  invalidate: () => invalidate(params, prefix),
  request: () => request(params, prefix),
  receive: data => receive(params, data, prefix),
  shouldFetch: state => shouldFetch(state, params),
  fetchIfNeeded: (...extraArgs) => {
    return (dispatch, getState) => {
      if (shouldFetch(getState(), params)) {
        dispatch(request(params, prefix));
        dispatch(incrementFetch());
        return dispatch(fetchData(params, prefix, ...extraArgs));
      }
    };
  },
  fetchData: postParams => fetchData(params, prefix, postParams)
});
export const ActionCreatorForTable = prefix => params => ({
  invalidate: () => invalidate(params, prefix),
  request: () => request(params, prefix),
  receive: (data, isLastPage = false, totalPage = 0, totalCount = 0, recordDisplayed = 0) => receiveForTable(params, data, (isLastPage = false), (totalPage = 0), (totalCount = 0), (recordDisplayed = 0), prefix),
  shouldFetch: state => shouldFetch(state, params),
  fetchIfNeeded: (...extraArgs) => {
    return (dispatch, getState) => {
      if (shouldFetch(getState(), params)) {
        dispatch(request(params, prefix));
        return dispatch(fetchDataForTable(params, prefix, ...extraArgs));
      }
    };
  },
  fetchData: postParams => fetchDataForTable(params, prefix, postParams),
  setSortBy: (field) => setSortBy(params, prefix, field),
  setSearchBy: (field, value) => setSearchBy(params, prefix, field, value),
  resetSearchAndSort: () => resetSearchAndSort(params, prefix),
});
export const receiveForPaginationList = (
  params,
  data,
  isLastPage = false,
  prefix
) => ({
  type: prefix + "_RECEIVE_" + params.code,
  data: data,
  isLastPage: isLastPage
});

export const fetchDataForPaginationList = (params, ...extraArgs) => {
  return (dispatch, getState) => {
    return params
      .service(...extraArgs, dispatch, getState)
      .then(json => {
        dispatch(
          receiveForPaginationList(
            params,
            params.dataMapper(json),
            params.lastPageMapper ? params.lastPageMapper(json) : false,
            "PLIST"
          )
        );
        dispatch(decrementFetch());
        if (params.onResponse) {
          params.onResponse(json, dispatch, getState);
        }
      })
      .catch(response => {
        dispatch(receiveError(params, "PLIST"));
        dispatch(decrementFetch());
        if (params.onError) {
          params.onError(response, dispatch, getState);
        }
      });
  };
};
