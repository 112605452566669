export const gameImages = {
  "1":
    "https://img.cdn.famobi.com/portal/html5games/images/tmp/JewelishBlitzTeaser.jpg",
  "2":
    "https://img.cdn.famobi.com/portal/html5games/images/tmp/CandyBubble_Teaser.jpg",
  "5":
    "https://img.cdn.famobi.com/portal/html5games/images/tmp/JungleRunTeaser.jpg",
  "6":
    "https://img.cdn.famobi.com/portal/html5games/images/tmp/Hex_Blitz_Teaser.jpg",
  "7":
    "https://img.cdn.famobi.com/portal/html5games/images/tmp/TowerRushTeaser.jpg",
  "8":
    "https://img.cdn.famobi.com/portal/html5games/images/tmp/YetiSensationTeaser.jpg",
  "9":
    "https://img.cdn.famobi.com/portal/html5games/images/tmp/AngryFlappyWingsTeaserB.jpg",
  "10":
    "https://img.cdn.famobi.com/portal/html5games/images/tmp/RunningJackTeaser.jpg",
  "11":
    "https://img.cdn.famobi.com/portal/html5games/images/tmp/99BallsTeaser.jpg",
  "12":
    "https://img.cdn.famobi.com/portal/html5games/images/tmp/ColorShapeTeaser.jpg",
  "13":
    "https://img.cdn.famobi.com/portal/html5games/images/tmp/AllezHopTeaser.jpg",
  "14":
    "https://img.cdn.famobi.com/portal/html5games/images/tmp/BurninRubberTeaser.jpg",
  "15":
    "https://img.cdn.famobi.com/portal/html5games/images/tmp/CityDunkTeaser.jpg",
  "16":
    "https://img.cdn.famobi.com/portal/html5games/images/tmp/EuroSoccerSprintTeaser.jpg",
  "17":
    "https://img.cdn.famobi.com/portal/html5games/images/tmp/GoldMinerTomTeaser.jpg",
  "18":
    "https://img.cdn.famobi.com/portal/html5games/images/tmp/SuperPlumberRunTeaser.jpg",
  "19":
    "https://img.cdn.famobi.com/portal/html5games/images/tmp/2048Teaser.jpg",
  "20":
    "https://img.cdn.famobi.com/portal/html5games/images/tmp/ColorpopTeaser.jpg",
  "21":
    "https://img.cdn.famobi.com/portal/html5games/images/tmp/SolitaireClassicTeaser.jpg",
  "22":
    "https://img.cdn.famobi.com/portal/html5games/images/tmp/SurferArchersTeaserB.jpg",
  "23":
    "https://img.cdn.famobi.com/portal/html5games/images/tmp/BlazeKickTeaser.jpg",
  "24": "http://gbg-api-dev.ap-southeast-2.elasticbeanstalk.com/images/10986",
  "25": "http://gbg-api-dev.ap-southeast-2.elasticbeanstalk.com/images/10989",
  "26": "http://gbg-api-dev.ap-southeast-2.elasticbeanstalk.com/images/10990",
  "27": "http://gbg-api-dev.ap-southeast-2.elasticbeanstalk.com/images/10991"
};
