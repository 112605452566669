const formInitialStateGenerator = params => {
  let form = {
    isUploading: false,
    isSubmitting: false,
    fields: {}
  };
  params.fields.map((field, index) => {
    return (form.fields[field] = {
      value: "",
      error: false,
      errorMsg: "",
      errorPayload: null,
      success: false,
      successMsg: "",
      successPayload: null
    });
  });
  return form;
};

export default params => (
  state = formInitialStateGenerator(params),
  action
) => {
  switch (action.type) {
    case "FORM_" + params.code + "_REQUEST":
      return {
        ...state,
        isSubmitting: true
      };
    case "FORM_" + params.code + "_RESPONSE":
      return {
        ...state,
        isSubmitting: false
      };
    case "FORM_" + params.code + "_SET_ERROR/" + action.field:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.field]: {
            ...state.fields[action.field],
            error: true,
            errorMsg: action.msg,
            errorPayload: action.payload ? action.payload : null,
            success: false,
            successMsg: "",
            successPayload: null
          }
        }
      };
    case "FORM_" + params.code + "_SET_SUCCESS/" + action.field:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.field]: {
            ...state.fields[action.field],
            error: false,
            errorMsg: "",
            errorPayload: null,
            success: true,
            successMsg: action.msg,
            successPayload: action.payload ? action.payload : null
          }
        }
      };
    case "FORM_" + params.code + "_RESET_ERROR/" + action.field:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.field]: {
            ...state.fields[action.field],
            error: false,
            errorMsg: "",
            errorPayload: null
          }
        }
      };
    case "FORM_" + params.code + "_RESET_SUCCESS/" + action.field:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.field]: {
            ...state.fields[action.field],
            success: false,
            successMsg: "",
            successPayload: null
          }
        }
      };
    case "FORM_" + params.code + "_UPDATE_FIELD/" + action.field:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.field]: {
            ...state.fields[action.field],
            value: action.value
          }
        }
      };
    case "FORM_" + params.code + "_RESET/" + action.field:
      return {
        ...state,
        fields: {
          ...state.fields,
          [action.field]: {
            value: "",
            error: false,
            errorMsg: "",
            errorPayload: null,
            success: false,
            successMsg: "",
            successPayload: null
          }
        }
      };
    case "FORM_" + params.code + "_RESET":
      return formInitialStateGenerator(params);
    default:
      return state;
  }
};
