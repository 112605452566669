import { combineReducers } from "redux";
import user from "./user";
import sidebars from "./sidebars";
import modals from "./modals";
import forms from "./forms";
import alerts from "./alerts";
import toggles from "./toggles";
import games from "./games";
import loaders from "./loaders";
import toastr from "./toastr";
import tab from "./tab";
import host from "./host";
import tournaments from "./tournaments";
import news from "./news";
import raffles from "./raffles";
import availablespace from "./availablespace";
import dashboard from "./dashboard";
import bans from "./bans";
import languages from "./languages";
import rewards from "./rewards";

const rootReducer = combineReducers({
  user,
  sidebars,
  modals,
  forms,
  alerts,
  toggles,
  loaders,
  toastr,
  games,
  tab,
  host,
  tournaments,
  news,
  raffles,
  availablespace,
  dashboard,
  bans,
  languages,
  rewards
});
export default rootReducer;
