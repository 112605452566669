import React, { Component } from "react";
import { connect } from "react-redux";
import gameSetupOn from "../../../../assets/images/game-setup-on.png";
import token from "../../../../assets/images/token.png";
import camera from "../../../../assets/images/svg/camera.svg";
import { createChallengeForm } from "../../../../actions/forms";
import { createChallengeRewardsTranslation } from "../../../../actions/modals";
import AppInputComponent from "../../../../layouts/components/sidebars/AppInputComponent";
import IconClose from "../../../../assets/images/svg/nav-close.svg";
import { fileUpload } from "../../../../services/fileUpload.service";

class RewardsCardComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      itemField: false,
      pointField: true
    };
    this.addText = this.addText.bind(this);
    this.removeRewardPlace = this.removeRewardPlace.bind(this);
    this.showItemInputField = this.showItemInputField.bind(this);
    this.showPointInputField = this.showPointInputField.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.removeRewardImg = this.removeRewardImg.bind(this);
    this.getRewardPlace = this.getRewardPlace.bind(this);
  }

  showItemInputField(e, i, fieldName) {
    if (this.state.pointField) {
      this.setState({ itemField: !this.state.itemField });
      this.addText("", i, "items");
      this.removeRewardImg(i);
    }
  }
  showPointInputField(e, i, fieldName) {
    if (!this.state.itemField) {
      this.setState({ itemField: !this.state.itemField });
      this.addText(true, i, fieldName);
    }
    this.addText("", i, "points");
    this.setState({ pointField: !this.state.pointField });
  }
  removeRewardImg(i) {
    let rewardData = this.props.createChallengeForm.fields.rewards.value;
    rewardData[i].rewardsimg = "";
    this.props.createChallengeFormUpdateField("rewards", rewardData);

    var translationData = this.props.createChallengeForm.fields.challengeRewardsTranslation.value ? this.props.createChallengeForm.fields.challengeRewardsTranslation.value : [];
    translationData[i][this.props.defaultLang.locale]['itemUrl'] = ""
    this.props.createChallengeFormUpdateField('challengeRewardsTranslation', translationData)
  }
  getRewardPlace(no) {
    switch (no) {
      case 5:
        return "8";
        break;
      case 6:
        return "16";
        break;
      case 7:
        return "32";
        break;
      case 8:
        return "64";
        break;
      case 9:
        return "65-128th";
        break;
      case 10:
        return "129-256th";
        break;
      case 11:
        return "257-512nd";
        break;
      case 12:
        return "513-1024th";
        break;
      case 13:
        return "1025-2048th";
        break;
      case 14:
        return "2049-4096th";
        break;
      default:
        return no;
        break;
    }
  }
  removeRewardPlace(index) {
    let activeReward = this.props.createChallengeForm.fields.activeRewardIndex.value ? this.props.createChallengeForm.fields.activeRewardIndex.value : 0
    this.props.createChallengeFormUpdateField("activeRewardIndex", activeReward - 1);

    let translatedRewardData = this.props.createChallengeForm.fields.challengeRewardsTranslation.value;
    translatedRewardData.splice(index, 1);
    let rewardData = this.props.createChallengeForm.fields.rewards.value;
    rewardData.splice(index, 1);
    rewardData.map((item, i) => {
      item.rewardPlace = i + 1;
      translatedRewardData[i].currentIndex = i;
    });
    this.props.createChallengeFormUpdateField("challengeRewardsTranslation", translatedRewardData);
    this.props.createChallengeFormUpdateField("rewards", rewardData);
  }

  addText(e, i, fieldName) {
    let stateData = this.props.createChallengeForm.fields.rewards.value;
    stateData[i][fieldName] = e;

    this.props.createChallengeFormUpdateField("rewards", stateData);
    this.props.createChallengeFormResetError("rewards");

    var translationData = this.props.createChallengeForm.fields.challengeRewardsTranslation.value ? this.props.createChallengeForm.fields.challengeRewardsTranslation.value : [];
    this.props.createChallengeFormUpdateField('challengeRewardsTranslation', translationData)
  }

  uploadFile(event, i) {
    let rewardData = this.props.createChallengeForm.fields.rewards.value;

    if (event.target.files.length > 0) {
      const fileSize = event.target.files[0].size / 1000000;
      if (fileSize < 2) {
        if (event.target.files[0].type !== "") {
          const fileType = event.target.files[0].type
            .split("/")[1]
            .toLowerCase();
          if (fileType !== "jpeg" && fileType !== "jpg" && fileType !== "png") {
            this.props.createChallengeFormSetError("rewards", "NOT_EMPTY");
            this.props.createChallengeFormResetError("rewards");
            return false;
          }
        } else {
          return false;
        }
      } else {
        this.props.createChallengeFormSetError("rewards", "NOT_EMPTY");
        this.props.createChallengeFormResetError("rewards");
        return false;
      }

      this.props.createChallengeFormResetError("rewards");
      this.props.createChallengeFormResetError("rewards");
      fileUpload(event.target.files)
        .then(url => {
          rewardData[i].rewardsimg = url;
          this.props.createChallengeFormUpdateField("rewards", rewardData);
        })
        .catch(() => {
          console.log(this);
        });
    } else {
      this.props.onUploadComplete("rewards", "");
    }
  }

  render() {
    var translationData = this.props.createChallengeForm.fields.challengeRewardsTranslation.value ? this.props.createChallengeForm.fields.challengeRewardsTranslation.value : [];
    return (
      <>
        <div
          className="col-md-12 col-lg-6 mb-5 "
          id={"reward_" + this.props.rewardIndex}
        >
          <div className="bg-rewards h-100">
            {this.props.rewardIndex !== 0 ? (
              <div
                onClick={e => this.removeRewardPlace(this.props.rewardIndex)}
                className="popup-dismiss"
                data-dismiss="modal"
                aria-label="Close"
              >
                <img src={IconClose} alt="" />
              </div>
            ) : (
                ""
              )}
            <div className="row ">
              <div className="col-sm-12 form-info">
                <h4 className="bold mb-3">
                  {this.props.rewardPlaceData + " place rewards"}
                </h4>
                <ul className=" list-inline d-flex flex-row flex-wrap w-100 mb-5">
                  <li className="list-group-control col-sm-6 mt-2">
                    <div className="select-game-box">
                      <label className="custom-control  fill-checkbox  w-100 mb-0 d-flex justify-content-center align-items-center">
                        <div className="labelc-content d-inline-flex align-items-center">
                          <img src={gameSetupOn} alt="" className="icon" />
                          <p className="mb-0 ml-3">Items</p>
                        </div>
                        <div className="ml-auto">
                          <input
                            className="fill-control-input"
                            type="checkbox"
                            checked={this.state.itemField ? true : false}
                            onChange={e => {
                              this.addText(
                                e.target.checked,
                                this.props.rewardIndex,
                                "itemsChk"
                              );
                              this.showItemInputField(
                                e.target.checked,
                                this.props.rewardIndex,
                                "itemsChk"
                              );
                              translationData[this.props.rewardIndex][this.props.defaultLang.locale]['itemDescription'] = ""
                              this.props.createChallengeFormUpdateField('challengeRewardsTranslation', translationData)
                            }}
                            id={this.props.rewardIndex}
                          />
                          <span className="fill-control-indicator backend" />
                        </div>
                      </label>
                    </div>
                  </li>
                  <li className="list-group-control col-sm-6 mt-2 ">
                    <div className="select-game-box">
                      <label className="custom-control  fill-checkbox  w-100 mb-0 d-flex justify-content-center align-items-center">
                        <div className="labelc-content d-inline-flex align-items-center">
                          <img src={token} alt="" className="icon" />
                          <p className="mb-0 ml-3">Points</p>
                        </div>
                        <div className="ml-auto">
                          <input
                            className="fill-control-input"
                            type="checkbox"
                            checked={this.state.pointField ? true : false}
                            onChange={e => {
                              this.addText(
                                e.target.checked,
                                this.props.rewardIndex,
                                "pointsChk"
                              );
                              this.showPointInputField(
                                e.target.checked,
                                this.props.rewardIndex,
                                "itemsChk"
                              );
                            }}
                            id={this.props.rewardIndex}
                          />
                          <span className="fill-control-indicator backend" />
                        </div>
                      </label>
                    </div>
                  </li>
                </ul>
              </div>

              {this.state.itemField ? (
                <div className="col-sm-12">
                  <h5 className="bold">Item</h5>
                  <div className="row">
                    <div className="col-md-12 col-lg">
                      <div className="form-group with-lable mt-4 ">
                        <AppInputComponent
                          onClick={() => {
                            this.props.createChallengeFormUpdateField("activeRewardIndex", this.props.rewardIndex);
                            this.props.createChallengeRewardsTranslationOpen();
                          }}
                          inputClass={
                            this.props.createChallengeForm.fields.rewards
                              .error &&
                              this.props.createChallengeForm.fields.rewards.value[
                                this.props.rewardIndex
                              ].itemsChk &&
                              !this.props.createChallengeForm.fields.rewards
                                .value[this.props.rewardIndex].items
                              ? "form-control effect-17"
                              : "form-control effect-16"
                          }
                          divClass="input-group-wrapper"
                          spanClass="focus-border"
                          withLabel={true}
                          labelText="Item Name"
                          readOnly="readOnly"
                          id={this.props.rewardIndex}
                          value={this.props.createChallengeForm.fields.rewards.value[
                            this.props.rewardIndex
                          ].items}
                        />
                      </div>
                    </div>
                    <div className="col-md-12 col-lg-auto upload-image d-flex ">
                      {this.props.createChallengeForm.fields.rewards.value[
                        this.props.rewardIndex
                      ].rewardsimg ? (
                          <>
                            <div className="col-md-12 upload-image d-flex">
                              <img
                                src={
                                  this.props.createChallengeForm.fields.rewards
                                    .value[this.props.rewardIndex].rewardsimg
                                }
                                className="profile-icon profile-pic-80"
                                alt=""
                              />
                              <div
                                className="upload-button d-flex justify-content-center align-items-center"
                                onClick={() => this.removeRewardImg(this.props.rewardIndex)}
                              >
                                <a
                                  href="javascript:void(0)"
                                  className="btn btn-link text-primary"
                                >
                                  Remove Image
                                </a>
                              </div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div className="col-md-12 upload-image d-flex">
                              <div
                                className="upload-button d-flex justify-content-center align-items-center"
                                onClick={() => {
                                  this.props.createChallengeFormUpdateField("activeRewardIndex", this.props.rewardIndex);
                                  this.props.createChallengeRewardsTranslationOpen();
                                }}
                              >
                                <img
                                  src={camera}
                                  className="img-fluid"
                                  alt=""
                                />
                                <p
                                  className={
                                    this.props.createChallengeForm.fields.rewards.error
                                      ? "choose-photo mb-0 ml-2 effect-17"
                                      : "choose-photo mb-0 ml-2 effect-16"
                                  }
                                >
                                  Upload Image
                                      </p>
                              </div>
                            </div>
                            {/* <div
                              className={
                                "upload-button d-flex justify-content-center align-items-center " +
                                (this.props.createChallengeForm.fields.rewards
                                  .error
                                  ? " add-new-rewards-danger"
                                  : "")
                              }
                              onClick={() => this.inputElement.click()}
                            >
                              <img src={camera} className="img-fluid" alt="" />
                              <p className="choose-photo mb-0 ml-2">
                                Upload Image
                            </p>
                            </div> */}
                          </>
                        )}
                    </div>
                  </div>
                </div>
              ) : (
                  ""
                )}

              {this.state.pointField ? (
                <div className="col-sm-12 mt-4">
                  <h5 className="bold">Points</h5>
                  <div className="form-group with-lable mt-4 ">
                    <div className="input-group-wrapper">
                      <AppInputComponent
                        onChange={e => {
                          this.addText(
                            e.target.value,
                            this.props.rewardIndex,
                            "points"
                          );
                          if (
                            Math.floor(e.target.value) === e.target.value ||
                            e.target.value > 100000 ||
                            e.target.value <= 0
                          ) {
                            this.props.createChallengeFormSetError(
                              "rewards",
                              "VALID_REWARDS"
                            );
                            return false;
                          }

                        }}
                        value={
                          this.props.createChallengeForm.fields.rewards.value[
                            this.props.rewardIndex
                          ].points
                        }
                        min="0"
                        max="100000"
                        type="number"
                        withLabel={true}
                        labelText="Points"
                        inputClass={
                          this.props.createChallengeForm.fields.rewards.error &&
                            this.props.createChallengeForm.fields.rewards.value[
                              this.props.rewardIndex
                            ].pointsChk &&
                            !this.props.createChallengeForm.fields.rewards.value[
                              this.props.rewardIndex
                            ].points
                            ? "form-control effect-17"
                            : "form-control effect-16"
                        }
                      />
                    </div>
                  </div>
                </div>
              ) : (
                  ""
                )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {
  createChallengeFormSubmit: createChallengeForm.submit,
  createChallengeFormUpdateField: createChallengeForm.updateField,
  createChallengeFormReset: createChallengeForm.reset,
  createChallengeFormResetField: createChallengeForm.resetField,
  createChallengeFormSetError: createChallengeForm.setError,
  createChallengeFormSetSuccess: createChallengeForm.setSuccess,
  createChallengeFormResetError: createChallengeForm.resetError,
  createChallengeFormResetSuccess: createChallengeForm.resetSuccess,

  createChallengeRewardsTranslationOpen: createChallengeRewardsTranslation.open
};

const mapStateToProps = state => {
  const { forms, user } = state;
  return {
    createChallengeForm: forms.createChallengeForm,
    defaultLang: user.item.defaultLanguage ? user.item.defaultLanguage : {}
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RewardsCardComponent);
