import { combineReducers } from "redux";
import { DetailReducerCreator, TListReducerCreator } from "./creators";
import {
  BrandAdvertisementsAvailable,
  ChallengeAdvertisementsAvailable
} from "../redux-config/tlistActionMap";
import {
  BrandAdvertisementsDetails,
  ChallengeAdvertisementsDetails
} from "../redux-config/detailActionMap";

const availablespace = combineReducers({
  [BrandAdvertisementsAvailable.stateSubKey]: TListReducerCreator(
    BrandAdvertisementsAvailable
  ),
  [ChallengeAdvertisementsAvailable.stateSubKey]: TListReducerCreator(
    ChallengeAdvertisementsAvailable
  ),
  [BrandAdvertisementsDetails.stateSubKey]: DetailReducerCreator(
    BrandAdvertisementsDetails
  ),
  [ChallengeAdvertisementsDetails.stateSubKey]: DetailReducerCreator(
    ChallengeAdvertisementsDetails
  )
});

export default availablespace;
