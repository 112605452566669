import React, { Component } from "react";
import { connect } from "react-redux";
import { createChallengeForm } from "../../../actions/forms";
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody
} from "react-accessible-accordion";
import profilePic from "../../../assets/images/avatar-demo.png";
import TournamentPoolCardContainer from "../../Tournaments/TournamentPoolCardContainer";
import ChallengeBannerComponent from "../ChallengeBannerComponent";
import adv7 from "../../../assets/images/adv/adv-2_.png";
import "../ChallengeBannerComponent.scss";
import "react-accessible-accordion/dist/fancy-example.css";
class PreviewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { currentLang: this.props.defaultLang }
  }

  render() {
    const { languagesList } = this.props;
    let challengeTranslationData = this.props.createChallengeForm.fields.challengeTranslation.value ? this.props.createChallengeForm.fields.challengeTranslation.value : [];
    let dataExistOrNot = challengeTranslationData.filter(e => e['languageId'] == this.state.currentLang.id)
    let rewardTranslatedData = this.props.createChallengeForm.fields.challengeRewardsTranslation.value ? this.props.createChallengeForm.fields.challengeRewardsTranslation.value : [];
    let rewardData = this.props.createChallengeForm.fields.rewards.value ? this.props.createChallengeForm.fields.rewards.value : [];
    rewardData.length && rewardData.map((reward, i) => {
      reward.items = rewardTranslatedData[i][this.state.currentLang.locale]['itemDescription'] ? rewardTranslatedData[i][this.state.currentLang.locale]['itemDescription'] : rewardTranslatedData[i][this.props.defaultLang.locale]['itemDescription']
      reward.rewardsimg = rewardTranslatedData[i][this.state.currentLang.locale]['itemUrl'] ? rewardTranslatedData[i][this.state.currentLang.locale]['itemUrl'] : rewardTranslatedData[i][this.props.defaultLang.locale]['itemUrl']
    })
    return (
      <>
        <div>
          <ChallengeBannerComponent />
          <div className="container-fluid todays-challange">
            <div className="row">
              <div className="col-sm-12">
                <div className="col-sm-12 mt-5 mt-lg-0">
                  <ul className="nav nav-pills mb-4 d-flex  justify-content-center align-items-center mt-4">
                    {languagesList.map((language, index) => {
                      return (
                        <li
                          key={index}
                          className="nav-item col text-center"
                        >
                          <span
                            className={
                              "a nav-link" +
                              (this.state.currentLang.id == language.id ? " active show " : "")
                            }
                            id="pills-today-tab"
                            data-toggle="pill"
                            role="tab"
                            aria-controls="pills-today"
                            aria-selected="true"
                            onClick={() => this.setState({
                              currentLang: language
                            })}
                          >
                            {language.locale}
                          </span>
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
            </div>

            <div className="padder-main">
              <div className="row">
                <div className="container">
                  <div className="row d-flex justify-content-center">
                    <div
                      className="col-lg-12 col-xl-9 text-center"
                      style={{ zIndex: 9 }}
                    >
                      <h2 className="mb-30 bold">{"Today's Challenge"}</h2>
                      <h5 className="challange-content mb-5-half">
                        Take part in {"today’s"} challenge for a chance to earn
                        gold tokens and <br /> win great prizes daily, weekly
                        and monthly:{" "}
                      </h5>
                      <div className="game-screen">
                        <img
                          src={
                            dataExistOrNot[0]['challengeImageUrl']?dataExistOrNot[0]['challengeImageUrl']:this.props.createChallengeForm.fields.challengeImageUrl.value
                          }
                          className="game-banner"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <section className="container-fluid prize-pull section-border-top">
            <div className="row">
              <div className="container">
                <div className="row d-flex align-items-center mb-30 ">
                  <div className="col-12 text-center d-flex justify-content-center">
                    <h2 className="heading bold">Prize Pool</h2>
                  </div>
                </div>
              </div>
            </div>
            <div className="row livetournament">
              <TournamentPoolCardContainer
                list={rewardData}
                showAd={true}
                type="createChallenge"
              />
            </div>
            <div className="container">
              <div className="row">
                <div className="col-sm-12 d-flex justify-content-center ">
                  <div
                    className="banner-advertising sm d-none d-lg-block mt-6 mb-3"
                    id="adv-2"
                  >
                    <img src={adv7} alt="" className="d-none d-lg-block mb-6" />
                  </div>
                </div>
              </div>
            </div>
          </section>
          <section className="container-fluid about-mtn-treasure section-border-top">
            <div className="padder-main">
              <div className="row">
                <div className="container">
                  <div className="row d-flex align-items-center justify-content-center">
                    <div className="col-lg-12 col-xl-9 text-center">
                      <div className="tournament-accordian">
                        <Accordion>
                          <AccordionItem className="card bb-1">
                            <AccordionItemTitle className="card-header">
                              <h4 className="semi-bold text-left u-position-relative">
                                Rules
                                <div
                                  className="accordion__arrow"
                                  role="presentation"
                                />
                              </h4>
                            </AccordionItemTitle>
                            <AccordionItemBody>
                              <div className="card-body text-left">
                                <div
                                  dangerouslySetInnerHTML={{
                                    __html: dataExistOrNot[0]['rules']
                                  }}
                                />
                              </div>
                            </AccordionItemBody>
                          </AccordionItem>

                          <AccordionItem className="card">
                            <AccordionItemTitle className="card-header">
                              <h4 className="semi-bold text-left u-position-relative">
                                Host
                                <div
                                  className="accordion__arrow"
                                  role="presentation"
                                />
                              </h4>
                            </AccordionItemTitle>
                            <AccordionItemBody>
                              <div className="card-body text-left">
                                <div className="d-flex align-items-center">
                                  <figure>
                                    <img
                                      src={this.props.organizerAvatarUrl}
                                      className="profile-pic-56"
                                      alt=""
                                    />
                                  </figure>

                                  <p className="bold ml-3 text-truncate">
                                    {this.props.createChallengeForm.fields.selectHostName.value}
                                  </p>
                                </div>
                              </div>
                            </AccordionItemBody>
                          </AccordionItem>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = {};

const mapStateToProps = state => {
  const { forms, user, languages } = state;
  return {
    createChallengeForm: forms.createChallengeForm,
    organizerName: user.item.name,
    organizerAvatarUrl: user.item.avtar_url ? user.item.avtar_url : profilePic,
    languagesList: languages.languagesList.items ? languages.languagesList.items : [],
    defaultLang: user.item.defaultLanguage ? user.item.defaultLanguage : {}
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviewComponent);
