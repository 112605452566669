import React, { Component } from "react";
import { connect } from "react-redux";
import { login, forgotPassword } from "../../../../actions/modals";
import { forgotPasswordForm } from "../../../../actions/forms";
import { AllMsgClear } from "../../../../actions/alerts";
import "./ForgotPasswordModal.scss";
import Modal from "../../Modal";
import { validateEmail } from "../../../../utils/validators";
import { APP_MESSAGES } from "../../../../services/config/messages";
import AppInputComponent from "../../../../layouts/components/sidebars/AppInputComponent";

class ForgotPasswordModal extends Component {
  constructor(props) {
    super(props);
    this.showForgotPassword = this.showForgotPassword.bind(this);
    this.hideForgotPassword = this.hideForgotPassword.bind(this);
    this.state = {
      isLandScape: false
    };
  }
  showForgotPassword() {
    this.props.forgotPasswordOpen();
  }
  hideForgotPassword() {
    this.props.forgotPasswordClose();
    this.props.AllMsgClear();
  }
  render() {
    let Success;
    let Error;
    Error = this.props.forgotPasswordForm.fields.email.errorMsg
      ? APP_MESSAGES[this.props.forgotPasswordForm.fields.email.errorMsg](
          this.props.forgotPasswordForm.fields.email.errorPayload,
          () => {
            this.props.forgotPasswordFormResetError("email");
          }
        )
      : props => <>{""}</>;
    Success = this.props.forgotPasswordForm.fields.email.successMsg
      ? APP_MESSAGES[this.props.forgotPasswordForm.fields.email.successMsg](
          this.props.forgotPasswordForm.fields.email.successPayload,
          () => {
            this.props.forgotPasswordFormResetSuccess("email");
          }
        )
      : props => <>{""}</>;

    return (
      <>
        <Modal
          handleHide={this.hideForgotPassword}
          isOpen={this.props.isForgotPasswordOpen}
          disableOuterClick={false}
        >
          <div className="card-popup login-form">
            <div className="row">
              <div className="col-sm-12 text-center mb-2 mb-sm-4">
                <h4 className="bold">Forgot Password</h4>
              </div>
            </div>

            <div className="row">
              <div className="col-sm-12">
                <Error />
                <Success />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                <form
                  onSubmit={e => {
                    e.preventDefault();
                    if (!this.props.forgotPasswordForm.isSubmitting) {
                      if (!this.props.forgotPasswordForm.fields.email.value) {
                        this.props.forgotPasswordFormSetError(
                          "email",
                          "NOT_EMPTY"
                        );
                        return false;
                      } else if (
                        !validateEmail(
                          this.props.forgotPasswordForm.fields.email.value
                        )
                      ) {
                        this.props.forgotPasswordFormSetError("email", "EMAIL");
                        return false;
                      }

                      this.props.forgotPasswordFormSubmit({
                        temporaryUrl: window.location.origin,
                        email: this.props.forgotPasswordForm.fields.email.value
                      });
                    }
                  }}
                >

                
                  <AppInputComponent
                    labelText="Email Address"
                    divClass="form-group with-lable mt-3"
                    value={this.props.forgotPasswordForm.fields.email.value}
                    onChange={event => {
                      this.props.forgotPasswordFormSetError("email", "");
                      this.props.forgotPasswordFormUpdateField(
                        "email",
                        event.target.value
                      );
                    }}
                  />
                  
                  <button
                    type="submit"
                    className="btn btn-pink light btn-block mt-2 mt-sm-5"
                  >
                    Submit
                  </button>
                  <h6 className="mt-4 bold text-center">
                    <span className="a text-secondary" onClick={() => {
                      this.props.loginOpen();
                    }}>Sign In</span>
                  </h6>

                </form>


              </div>
            </div>
              
          </div>
        </Modal>
      </>
    );
  }
}
const mapDispatchToProps = {
  loginOpen: login.open,
  forgotPasswordOpen: forgotPassword.open,
  forgotPasswordClose: forgotPassword.close,
  AllMsgClear,
  forgotPasswordFormSubmit: forgotPasswordForm.submit,
  forgotPasswordFormReset: forgotPasswordForm.reset,
  forgotPasswordFormResetField: forgotPasswordForm.resetField,
  forgotPasswordFormUpdateField: forgotPasswordForm.updateField,
  forgotPasswordFormSetError: forgotPasswordForm.setError,
  forgotPasswordFormSetSuccess: forgotPasswordForm.setSuccess,
  forgotPasswordFormResetError: forgotPasswordForm.resetError,
  forgotPasswordFormResetSuccess: forgotPasswordForm.resetSuccess
};

const mapStateToProps = state => {
  return {
    isForgotPassMailSend: state.modals.isForgotPassMailSend,
    isForgotPasswordOpen: state.modals.isForgotPasswordOpen,
    isLogging: state.user.isLogging,
    forgotPasswordForm: state.forms.forgotPasswordForm
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ForgotPasswordModal);
