import React, { Component } from "react";
import adv2 from "../../assets/images/adv-dashboard/adv-2.png";
import TournamentCardContainer from "../../components/Dashboard/Preview/TournamentCardContainer";
import adv3 from "../../assets/images/adv-dashboard/adv-1.png";
import DashboardPopularHost from "../Dashboard/DashboardPopularHost/DashboardPopularHostComponent";
import DashboardFreeToPlayGames from "../Dashboard/DashboardFreeToPlayGames/DashboardFreeToPlayGamesComponent";
import DashboardNews from "../Dashboard/DashboardNews/DashboardNewsComponents";
import DashboardBanner from "../Dashboard/DashboardBanner/DashboardBannerComponent";
import DashboardQuickPlay from "../Dashboard/DashboardQuickPlay/DashboardQuickPlayComponent";

class DashboardPreviewComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      type: "",
      advertisementPlace: "",
      advertisementUrl: "",
      iframeUrl: ""
    };
  }

  componentDidMount() {
    const queryParams = new URLSearchParams(this.props.location.search);
    let type = queryParams.get("type");
    let advertisementPlace = queryParams.get("advertisementPlace");
    let advertisementUrl = queryParams.get("advertisementUrl");
    let iframeUrl =
      "/dashboard/preview/?type=web&advertisementPlace=" +
      advertisementPlace +
      "&advertisementUrl=" +
      advertisementUrl;

    this.setState({
      type: type,
      advertisementPlace: advertisementPlace,
      advertisementUrl: advertisementUrl,
      iframeUrl: iframeUrl
    });
  }

  render() {
    let challengesLiveList = [
      {
        brandAvatar: "https://gbg-api-dev-2.herokuapp.com/images/11125",
        brandId: "20",
        brandName: "Nickelodeon",
        casual_game_id: "15",
        challengeImageUrl: "https://gbg-api-dev-2.herokuapp.com/images/11033",
        challengeTitle: "City Dunk 14-May",
        challenge_status_id: "3",
        id: "6231",
        logoUrl: "https://gbg-api-dev-2.herokuapp.com/images/11032",
        organizerId: "755",
        promoImage: "https://gbg-api-dev-2.herokuapp.com/images/11034",
        rewardItem: null,
        rewardItemUrl: null,
        rewardPoints: "500",
        seatsTaken: "4",
        timeDifference: "3 days 08:48:22.003559",
        timeOfEnd: "2019-05-30 18:30:00+00",
        timeOfStart: "2019-05-09 10:00:00+00"
      },
      {
        brandAvatar: "https://gbg-api-dev-2.herokuapp.com/images/11125",
        brandId: "20",
        brandName: "Nickelodeon",
        casual_game_id: "15",
        challengeImageUrl: "https://gbg-api-dev-2.herokuapp.com/images/11033",
        challengeTitle: "City Dunk 14-May",
        challenge_status_id: "3",
        id: "6231",
        logoUrl: "https://gbg-api-dev-2.herokuapp.com/images/11032",
        organizerId: "755",
        promoImage: "https://gbg-api-dev-2.herokuapp.com/images/11034",
        rewardItem: null,
        rewardItemUrl: null,
        rewardPoints: "500",
        seatsTaken: "4",
        timeDifference: "3 days 08:48:22.003559",
        timeOfEnd: "2019-05-30 18:30:00+00",
        timeOfStart: "2019-05-09 10:00:00+00"
      },
      {
        brandAvatar: "https://gbg-api-dev-2.herokuapp.com/images/11125",
        brandId: "20",
        brandName: "Nickelodeon",
        casual_game_id: "15",
        challengeImageUrl: "https://gbg-api-dev-2.herokuapp.com/images/11033",
        challengeTitle: "City Dunk 14-May",
        challenge_status_id: "3",
        id: "6231",
        logoUrl: "https://gbg-api-dev-2.herokuapp.com/images/11032",
        organizerId: "755",
        promoImage: "https://gbg-api-dev-2.herokuapp.com/images/11034",
        rewardItem: null,
        rewardItemUrl: null,
        rewardPoints: "500",
        seatsTaken: "4",
        timeDifference: "3 days 08:48:22.003559",
        timeOfEnd: "2019-05-30 18:30:00+00",
        timeOfStart: "2019-05-09 10:00:00+00"
      },
      {
        brandAvatar: "https://gbg-api-dev-2.herokuapp.com/images/11125",
        brandId: "20",
        brandName: "Nickelodeon",
        casual_game_id: "15",
        challengeImageUrl: "https://gbg-api-dev-2.herokuapp.com/images/11033",
        challengeTitle: "City Dunk 14-May",
        challenge_status_id: "3",
        id: "6231",
        logoUrl: "https://gbg-api-dev-2.herokuapp.com/images/11032",
        organizerId: "755",
        promoImage: "https://gbg-api-dev-2.herokuapp.com/images/11034",
        rewardItem: null,
        rewardItemUrl: null,
        rewardPoints: "500",
        seatsTaken: "4",
        timeDifference: "3 days 08:48:22.003559",
        timeOfEnd: "2019-05-30 18:30:00+00",
        timeOfStart: "2019-05-09 10:00:00+00"
      }
    ];

    let challengesQuickPlayList = [
      {
        brandAvatar: "https://gbg-api-dev-2.herokuapp.com/images/11125",
        brandId: "20",
        brandName: "Nickelodeon",
        casual_game_id: "15",
        challengeImageUrl: "https://gbg-api-dev-2.herokuapp.com/images/11033",
        challengeTitle: "City Dunk 14-May",
        challenge_status_id: "3",
        id: "6231",
        logoUrl: "https://gbg-api-dev-2.herokuapp.com/images/11032",
        organizerId: "755",
        promoImage: "https://gbg-api-dev-2.herokuapp.com/images/11034",
        rewardItem: null,
        rewardItemUrl: null,
        rewardPoints: "500",
        seatsTaken: "4",
        timeDifference: "3 days 08:48:22.003559",
        timeOfEnd: "2019-05-30 18:30:00+00",
        timeOfStart: "2019-05-09 10:00:00+00"
      },
      {
        brandAvatar: "https://gbg-api-dev-2.herokuapp.com/images/11125",
        brandId: "20",
        brandName: "Nickelodeon",
        casual_game_id: "15",
        challengeImageUrl: "https://gbg-api-dev-2.herokuapp.com/images/11033",
        challengeTitle: "City Dunk 14-May",
        challenge_status_id: "3",
        id: "6231",
        logoUrl: "https://gbg-api-dev-2.herokuapp.com/images/11032",
        organizerId: "755",
        promoImage: "https://gbg-api-dev-2.herokuapp.com/images/11034",
        rewardItem: null,
        rewardItemUrl: null,
        rewardPoints: "500",
        seatsTaken: "4",
        timeDifference: "3 days 08:48:22.003559",
        timeOfEnd: "2019-05-30 18:30:00+00",
        timeOfStart: "2019-05-09 10:00:00+00"
      }
    ];

    let challengesUpcomingList = [
      {
        brandAvatar: "https://gbg-api-dev-2.herokuapp.com/images/11125",
        brandId: "20",
        brandName: "Nickelodeon",
        casual_game_id: "15",
        challengeImageUrl: "https://gbg-api-dev-2.herokuapp.com/images/11033",
        challengeTitle: "City Dunk 14-May",
        challenge_status_id: "3",
        id: "6231",
        logoUrl: "https://gbg-api-dev-2.herokuapp.com/images/11032",
        organizerId: "755",
        promoImage: "https://gbg-api-dev-2.herokuapp.com/images/11034",
        rewardItem: null,
        rewardItemUrl: null,
        rewardPoints: "500",
        seatsTaken: "4",
        timeDifference: "3 days 08:48:22.003559",
        timeOfEnd: "2019-05-30 18:30:00+00",
        timeOfStart: "2019-05-09 10:00:00+00"
      },
      {
        brandAvatar: "https://gbg-api-dev-2.herokuapp.com/images/11125",
        brandId: "20",
        brandName: "Nickelodeon",
        casual_game_id: "15",
        challengeImageUrl: "https://gbg-api-dev-2.herokuapp.com/images/11033",
        challengeTitle: "City Dunk 14-May",
        challenge_status_id: "3",
        id: "6231",
        logoUrl: "https://gbg-api-dev-2.herokuapp.com/images/11032",
        organizerId: "755",
        promoImage: "https://gbg-api-dev-2.herokuapp.com/images/11034",
        rewardItem: null,
        rewardItemUrl: null,
        rewardPoints: "500",
        seatsTaken: "4",
        timeDifference: "3 days 08:48:22.003559",
        timefreeToPlayListOfEnd: "2019-05-30 18:30:00+00",
        timeOfStart: "2019-05-09 10:00:00+00"
      },
      {
        brandAvatar: "https://gbg-api-dev-2.herokuapp.com/images/11125",
        brandId: "20",
        brandName: "Nickelodeon",
        casual_game_id: "15",
        challengeImageUrl: "https://gbg-api-dev-2.herokuapp.com/images/11033",
        challengeTitle: "City Dunk 14-May",
        challenge_status_id: "3",
        id: "6231",
        logoUrl: "https://gbg-api-dev-2.herokuapp.com/images/11032",
        organizerId: "755",
        promoImage: "https://gbg-api-dev-2.herokuapp.com/images/11034",
        rewardItem: null,
        rewardItemUrl: null,
        rewardPoints: "500",
        seatsTaken: "4",
        timeDifference: "3 days 08:48:22.003559",
        timeOfEnd: "2019-05-30 18:30:00+00",
        timeOfStart: "2019-05-09 10:00:00+00"
      },
      {
        brandAvatar: "https://gbg-api-dev-2.herokuapp.com/images/11125",
        brandId: "20",
        brandName: "Nickelodeon",
        casual_game_id: "15",
        challengeImageUrl: "https://gbg-api-dev-2.herokuapp.com/images/11033",
        challengeTitle: "City Dunk 14-May",
        challenge_status_id: "3",
        id: "6231",
        logoUrl: "https://gbg-api-dev-2.herokuapp.com/images/11032",
        organizerId: "755",
        promoImage: "https://gbg-api-dev-2.herokuapp.com/images/11034",
        rewardItem: null,
        rewardItemUrl: null,
        rewardPoints: "500",
        seatsTaken: "4",
        timeDifference: "3 days 08:48:22.003559",
        timeOfEnd: "2019-05-30 18:30:00+00",
        timeOfStart: "2019-05-09 10:00:00+00"
      },
      {
        brandAvatar: "https://gbg-api-dev-2.herokuapp.com/images/11125",
        brandId: "20",
        brandName: "Nickelodeon",
        casual_game_id: "15",
        challengeImageUrl: "https://gbg-api-dev-2.herokuapp.com/images/11033",
        challengeTitle: "City Dunk 14-May",
        challenge_status_id: "3",
        id: "6231",
        logoUrl: "https://gbg-api-dev-2.herokuapp.com/images/11032",
        organizerId: "755",
        promoImage: "https://gbg-api-dev-2.herokuapp.com/images/11034",
        rewardItem: null,
        rewardItemUrl: null,
        rewardPoints: "500",
        seatsTaken: "4",
        timeDifference: "3 days 08:48:22.003559",
        timeOfEnd: "2019-05-30 18:30:00+00",
        timeOfStart: "2019-05-09 10:00:00+00"
      },
      {
        brandAvatar: "https://gbg-api-dev-2.herokuapp.com/images/11125",
        brandId: "20",
        brandName: "Nickelodeon",
        casual_game_id: "15",
        challengeImageUrl: "https://gbg-api-dev-2.herokuapp.com/images/11033",
        challengeTitle: "City Dunk 14-May",
        challenge_status_id: "3",
        id: "6231",
        logoUrl: "https://gbg-api-dev-2.herokuapp.com/images/11032",
        organizerId: "755",
        promoImage: "https://gbg-api-dev-2.herokuapp.com/images/11034",
        rewardItem: null,
        rewardItemUrl: null,
        rewardPoints: "500",
        seatsTaken: "4",
        timeDifference: "3 days 08:48:22.003559",
        timeOfEnd: "2019-05-30 18:30:00+00",
        timeOfStart: "2019-05-09 10:00:00+00"
      }
    ];

    let brandsPopularList = [
      {
        brandId: "20",
        organizerId: "755",
        brandName: "Nickelodeon",
        brandAvatar: "https://gbg-api-dev-2.herokuapp.com/images/11125",
        seatsTaken: "977",
        tournamentsCount: "777"
      },
      {
        brandId: "20",
        organizerId: "755",
        brandName: "Nickelodeon",
        brandAvatar: "https://gbg-api-dev-2.herokuapp.com/images/11125",
        seatsTaken: "977",
        tournamentsCount: "777"
      },
      {
        brandId: "20",
        organizerId: "755",
        brandName: "Nickelodeon",
        brandAvatar: "https://gbg-api-dev-2.herokuapp.com/images/11125",
        seatsTaken: "977",
        tournamentsCount: "777"
      }
    ];

    let freeToPlayList = [
      {
        id: "1",
        description: "Action",
        imageUrl: "https://gbg-api-dev-2.herokuapp.com/images/9681"
      },
      {
        id: "1",
        description: "Action",
        imageUrl: "https://gbg-api-dev-2.herokuapp.com/images/9681"
      },
      {
        id: "1",
        description: "Action",
        imageUrl: "https://gbg-api-dev-2.herokuapp.com/images/9681"
      },
      {
        id: "1",
        description: "Action",
        imageUrl: "https://gbg-api-dev-2.herokuapp.com/images/9681"
      }
    ];

    let newsListAll = [
      {
        id: "16",
        title: "Death Stranding is a Little Behind Schedule, Says Kojima",
        content:
          'Death Stranding has fallen slightly behind Kojima Productions\' internal planned release date, according to the man in charge. Hideo Kojima made the statement in an interview with J-Wave . As translated by IGN Japan, Kojima explained: “It’s common for studios to fall behind their planned release date, and Death Stranding is very slightly behind our initial plan, but not behind by much.” At one point, Kojima was hinting that the game would be out before 2019, so it does feel a little like there have been multiple planned release dates. Elsewhere in the interview, Kojima explains that the nature of making an open world game means that he\'s constantly making adjustments and playtesting new versions. He also discusses the complicated nature of trying to explain a brand new game, because it is "only visible inside me", and says that a new game is "high risk". Kojima joins the growing number of gaming luminaries to say that the future of gaming lies in streaming, going so far as to say that movies and games will become "one entertainment". After today\'s news, we can at least be reassured that Death Stranding is moving along.',
        imageUrl: "https://gbg-api-dev-2.herokuapp.com/images/10736",
        createdOn: "2019-03-19 05:07:24+00",
        categoryName: "Gaming",
        source:
          "https://za.ign.com/death-stranding/130120/news/death-stranding-is-a-little-behind-schedule-says-kojima",
        thumbImageUrl: "https://gbg-api-dev-2.herokuapp.com/images/10762"
      },
      {
        id: "16",
        title: "Death Stranding is a Little Behind Schedule, Says Kojima",
        content:
          'Death Stranding has fallen slightly behind Kojima Productions\' internal planned release date, according to the man in charge. Hideo Kojima made the statement in an interview with J-Wave . As translated by IGN Japan, Kojima explained: “It’s common for studios to fall behind their planned release date, and Death Stranding is very slightly behind our initial plan, but not behind by much.” At one point, Kojima was hinting that the game would be out before 2019, so it does feel a little like there have been multiple planned release dates. Elsewhere in the interview, Kojima explains that the nature of making an open world game means that he\'s constantly making adjustments and playtesting new versions. He also discusses the complicated nature of trying to explain a brand new game, because it is "only visible inside me", and says that a new game is "high risk". Kojima joins the growing number of gaming luminaries to say that the future of gaming lies in streaming, going so far as to say that movies and games will become "one entertainment". After today\'s news, we can at least be reassured that Death Stranding is moving along.',
        imageUrl: "https://gbg-api-dev-2.herokuapp.com/images/10736",
        createdOn: "2019-03-19 05:07:24+00",
        categoryName: "Gaming",
        source:
          "https://za.ign.com/death-stranding/130120/news/death-stranding-is-a-little-behind-schedule-says-kojima",
        thumbImageUrl: "https://gbg-api-dev-2.herokuapp.com/images/10762"
      },
      {
        id: "16",
        title: "Death Stranding is a Little Behind Schedule, Says Kojima",
        content:
          'Death Stranding has fallen slightly behind Kojima Productions\' internal planned release date, according to the man in charge. Hideo Kojima made the statement in an interview with J-Wave . As translated by IGN Japan, Kojima explained: “It’s common for studios to fall behind their planned release date, and Death Stranding is very slightly behind our initial plan, but not behind by much.” At one point, Kojima was hinting that the game would be out before 2019, so it does feel a little like there have been multiple planned release dates. Elsewhere in the interview, Kojima explains that the nature of making an open world game means that he\'s constantly making adjustments and playtesting new versions. He also discusses the complicated nature of trying to explain a brand new game, because it is "only visible inside me", and says that a new game is "high risk". Kojima joins the growing number of gaming luminaries to say that the future of gaming lies in streaming, going so far as to say that movies and games will become "one entertainment". After today\'s news, we can at least be reassured that Death Stranding is moving along.',
        imageUrl: "https://gbg-api-dev-2.herokuapp.com/images/10736",
        createdOn: "2019-03-19 05:07:24+00",
        categoryName: "Gaming",
        source:
          "https://za.ign.com/death-stranding/130120/news/death-stranding-is-a-little-behind-schedule-says-kojima",
        thumbImageUrl: "https://gbg-api-dev-2.herokuapp.com/images/10762"
      },
      {
        id: "16",
        title: "Death Stranding is a Little Behind Schedule, Says Kojima",
        content:
          'Death Stranding has fallen slightly behind Kojima Productions\' internal planned release date, according to the man in charge. Hideo Kojima made the statement in an interview with J-Wave . As translated by IGN Japan, Kojima explained: “It’s common for studios to fall behind their planned release date, and Death Stranding is very slightly behind our initial plan, but not behind by much.” At one point, Kojima was hinting that the game would be out before 2019, so it does feel a little like there have been multiple planned release dates. Elsewhere in the interview, Kojima explains that the nature of making an open world game means that he\'s constantly making adjustments and playtesting new versions. He also discusses the complicated nature of trying to explain a brand new game, because it is "only visible inside me", and says that a new game is "high risk". Kojima joins the growing number of gaming luminaries to say that the future of gaming lies in streaming, going so far as to say that movies and games will become "one entertainment". After today\'s news, we can at least be reassured that Death Stranding is moving along.',
        imageUrl: "https://gbg-api-dev-2.herokuapp.com/images/10736",
        createdOn: "2019-03-19 05:07:24+00",
        categoryName: "Gaming",
        source:
          "https://za.ign.com/death-stranding/130120/news/death-stranding-is-a-little-behind-schedule-says-kojima",
        thumbImageUrl: "https://gbg-api-dev-2.herokuapp.com/images/10762"
      },
      {
        id: "16",
        title: "Death Stranding is a Little Behind Schedule, Says Kojima",
        content:
          'Death Stranding has fallen slightly behind Kojima Productions\' internal planned release date, according to the man in charge. Hideo Kojima made the statement in an interview with J-Wave . As translated by IGN Japan, Kojima explained: “It’s common for studios to fall behind their planned release date, and Death Stranding is very slightly behind our initial plan, but not behind by much.” At one point, Kojima was hinting that the game would be out before 2019, so it does feel a little like there have been multiple planned release dates. Elsewhere in the interview, Kojima explains that the nature of making an open world game means that he\'s constantly making adjustments and playtesting new versions. He also discusses the complicated nature of trying to explain a brand new game, because it is "only visible inside me", and says that a new game is "high risk". Kojima joins the growing number of gaming luminaries to say that the future of gaming lies in streaming, going so far as to say that movies and games will become "one entertainment". After today\'s news, we can at least be reassured that Death Stranding is moving along.',
        imageUrl: "https://gbg-api-dev-2.herokuapp.com/images/10736",
        createdOn: "2019-03-19 05:07:24+00",
        categoryName: "Gaming",
        source:
          "https://za.ign.com/death-stranding/130120/news/death-stranding-is-a-little-behind-schedule-says-kojima",
        thumbImageUrl: "https://gbg-api-dev-2.herokuapp.com/images/10762"
      }
    ];

    let featureChallengesList = [
      {
        title: "Jungle Run 14-May",
        id: "5182",
        challengeStatusId: "3",
        gameName: "Jungle Run",
        seats: "8/8",
        seatsTaken: "8",
        seatsMaximum: "8",
        gameFormat: "Challenge",
        rewardItem: null,
        rewardItemUrl: null,
        rewardPoints: "1000",
        timeOfStart: "2018-08-22 01:22:00+00",
        timeOfEnd: "2019-08-30 18:30:00+00",
        organizerId: "755",
        organizerName: "ashok-03",
        organizerAvatarUrl: "https://gbg-api-dev-2.herokuapp.com/images/9406",
        hasAlreadyJoined: false,
        logoUrl: "https://gbg-api-dev-2.herokuapp.com/images/7991",
        imageUrl: "https://gbg-api-dev-2.herokuapp.com/images/7992",
        promoImage: "https://gbg-api-dev-2.herokuapp.com/images/11124",
        brandId: "20",
        brandName: "Nickelodeon",
        brandAsProfileId: "755",
        featured: true
      }
    ];

    return (
      <>
        {this.state.type == "mobile" ? (
          <div>
            <style>
              {
                "\
                .rr{\
                  color:red;\
                }\
                nav, footer{\
                  display:none !important;\
                }\
              "
              }
            </style>
          </div>
        ) : (
          ""
        )}

        <div className="su-iframe-center">
          <iframe
            src={this.state.iframeUrl}
            width="414"
            height="736"
            className={
              this.state.type == "mobile"
                ? "su-iframe-center previewShown"
                : "previewHidden"
            }
          />
        </div>
        <div
          className={
            this.state.type == "web" ? "previewShown" : "previewHidden"
          }
        >
          {/*Banner*/}
          <DashboardBanner featureChallengesList={featureChallengesList} />

          {/*Live Tournaments*/}
          <section className="[ container-fluid ] live-tournament ">
            <div className="padder-main">
              <div className="row">
                <div className="container">
                  <div className="row d-flex align-items-center mb-30 ">
                    <div className="col-8 d-flex justify-content-start">
                      <h2 className="bold">Live Tournaments</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row livetournament">
                <TournamentCardContainer
                  list={
                    challengesLiveList
                      ? challengesLiveList.map((ele, inde) => {
                          return {
                            ...ele,
                            cardClass: "card popular-arcadex",
                            cardHeaderClass: "card-header light"
                          };
                        })
                      : []
                  }
                  showAd={true}
                  challengeType="Live"
                  advertisementUrl={this.state.advertisementUrl}
                  advertisementPlace={this.state.advertisementPlace}
                />
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-center ">
                    <div
                      className="banner-advertising sm d-none d-lg-block mt-6"
                      id="adv-2"
                    >
                      {this.state.advertisementPlace == 2 ? (
                        <img
                          src={this.state.advertisementUrl}
                          alt=""
                          className="d-none d-lg-block"
                        />
                      ) : (
                        <img
                          src={adv2}
                          alt=""
                          className="d-none d-lg-block disabledAd"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/*Quick Play*/}
          <section className="[ container-fluid ] quick-play section-border-top">
            <div className="padder-main">
              <div className="row">
                <div className="container">
                  <div className="row d-flex align-items-center mb-30 ">
                    <div className="col-8 d-flex justify-content-start">
                      <h2 className="bold">Quick Play</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row livetournament">
                {challengesQuickPlayList &&
                challengesQuickPlayList.length > 0 ? (
                  <DashboardQuickPlay list={challengesQuickPlayList} />
                ) : (
                  <div className="col">
                    <h6 className="text-center">No tournaments found.</h6>
                  </div>
                )}
              </div>
            </div>
          </section>

          {/*Upcoming Tournaments*/}
          <section className="[ container-fluid ] upcoming-tournament section-border-top">
            <div className="padder-main">
              <div className="row">
                <div className="container">
                  <div className="row d-flex align-items-center mb-30 ">
                    <div className="col-8 d-flex justify-content-start">
                      <h2 className="bold">Upcoming Tournaments</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row livetournament">
                {challengesUpcomingList && challengesUpcomingList.length > 0 ? (
                  <TournamentCardContainer
                    list={
                      challengesUpcomingList
                        ? challengesUpcomingList.map((ele, inde) => {
                            return {
                              ...ele,
                              cardClass: "card popular-arcadex",
                              cardHeaderClass: "card-header light"
                            };
                          })
                        : []
                    }
                    challengeType="Upcoming"
                  />
                ) : (
                  <div className="col">
                    <h6 className="text-center">No tournaments found.</h6>
                  </div>
                )}
              </div>
              <div className="container">
                <div className="row">
                  <div className="col-sm-12 d-flex justify-content-center">
                    <div
                      className="banner-advertising sm d-none d-lg-block mt-6 "
                      id="adv-3"
                    >
                      {this.state.advertisementPlace == 3 ? (
                        <img
                          src={this.state.advertisementUrl}
                          alt=""
                          className="d-none d-lg-block"
                        />
                      ) : (
                        <img
                          src={adv3}
                          alt=""
                          className="d-none d-lg-block disabledAd"
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/*Popular Hosts*/}
          <section className="[ container-fluid ] popular-host section-border-top">
            <div className="padder-main">
              <div className="row">
                <div className="container">
                  <div className="row d-flex align-items-center mb-30 ">
                    <div className="col-8 d-flex justify-content-start">
                      <h2 className="bold">Popular Hosts</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row livetournament">
                {brandsPopularList && brandsPopularList.length > 0 ? (
                  <DashboardPopularHost list={brandsPopularList} />
                ) : (
                  <div className="col">
                    <h6 className="text-center">No hosts found.</h6>
                  </div>
                )}
              </div>
            </div>
          </section>

          {/*Free to Play Games*/}
          <section className="[ container-fluid ] free-to-play-games section-border-top">
            <div className="padder-main">
              <div className="row">
                <div className="container">
                  <div className="row d-flex align-items-center mb-30 ">
                    <div className="col-8 d-flex justify-content-start">
                      <h2 className="bold">Free to Play Games</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row livetournament">
                {freeToPlayList && freeToPlayList.length > 0 ? (
                  <DashboardFreeToPlayGames list={freeToPlayList} />
                ) : (
                  <div className="col">
                    <h6 className="text-center">No game found.</h6>
                  </div>
                )}
              </div>
            </div>
          </section>

          {/*News*/}
          <section className="[ container-fluid ] news section-border-top">
            <div className="padder-main">
              <div className="row">
                <div className="container">
                  <div className="row d-flex align-items-center mb-30 ">
                    <div className="col-8 d-flex justify-content-start">
                      <h2 className="bold">News</h2>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row livetournament">
                {newsListAll && newsListAll.length > 0 ? (
                  <DashboardNews list={newsListAll} />
                ) : (
                  <div className="col">
                    <h6 className="text-center">No news found.</h6>
                  </div>
                )}
              </div>
            </div>
          </section>
        </div>
      </>
    );
  }
}

export default DashboardPreviewComponent;
